<div class="w-full p-8">
  <div class="bg-white px-14 py-11 rounded-lg flex flex-col space-y-4">
    <form class="space-y-4" [formGroup]="purchaseOrderForm">
      <div class="flex">
        <div class="me-5">
          <label class="block text-md Poppins-Medium mb-2">{{
            "Vendor" | translate
          }}</label>
          <ng-select
            (change)="changeEventVendor($event)"
            [items]="vendors"
            class="p-0 w-56 text-sm focus:ring-0 new-dropdown"
            bindLabel="vendorName"
            formControlName="vendor"
            placeholder="{{ 'Select one' | translate }}"
          >
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <div class="px-2 py-1">
                <div
                  class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                >
                  {{ item.vendorName }}
                </div>
              </div>
            </ng-template>
          </ng-select>
          <div
            class="text-sm text-red-500 ms-2 mt-1"
            *ngIf="formErrors.vendorName"
          >
            {{ formErrors.vendorName }}
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="me-5">
          <label class="block text-sm Poppins-Medium mb-2">{{
            "Date" | translate
          }}</label>
          <input
            (change)="reloadPurchaseNumber()"
            id="date"
            formControlName="date"
            type="date"
            class="new-date"
            placeholder="{{ 'Ex. 1234567' | translate }}"
          />
          <div class="text-sm text-red-500 ms-2 mt-1" *ngIf="formErrors.date">
            {{ formErrors.date }}
          </div>
        </div>
        <div class="mr-5">
          <label class="block text-sm Poppins-Medium mb-2">{{
            "Due Date" | translate
          }}</label>
          <input
            id="dueDate"
            formControlName="dueDate"
            type="date"
            class="new-date"
            placeholder="{{ 'Ex. 1234567' | translate }}"
          />
          <div
            class="text-sm text-red-500 ms-2 mt-1"
            *ngIf="formErrors.dueDate"
          >
            {{ formErrors.dueDate }}
          </div>
        </div>
        <div class="mr-5">
          <label class="block text-sm Poppins-Medium mb-2">{{
            "Purchase Order #" | translate
          }}</label>
          <input
            id="purchaseOrder"
            formControlName="purchaseOrder"
            type="text"
            class="new-input"
            placeholder="{{ 'Ex. 1234567' | translate }}"
          />
          <div
            class="text-sm text-red-500 ms-2 mt-1"
            *ngIf="formErrors.purchaseOrder"
          >
            {{ formErrors.purchaseOrder }}
          </div>
        </div>
        <div class="mr-5">
          <label class="block text-sm Poppins-Medium mb-2">{{
            "Reference #" | translate
          }}</label>
          <input
            id="reference"
            formControlName="reference"
            type="text"
            class="new-input"
            placeholder="{{ 'Ex. 1234567' | translate }}"
          />
          <div
            class="text-sm text-red-500 ms-2 mt-1"
            *ngIf="formErrors.purchaseOrder"
          >
            {{ formErrors.purchaseOrder }}
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="w-8/12">
          <label class="block text-sm Poppins-Medium mb-2">{{
            "Notes" | translate
          }}</label>
          <textarea
            formControlName="notes"
            class="new-input !h-28"
            placeholder="{{ 'Add some notes' | translate }}"
          ></textarea>
          <div class="text-sm text-red-500 ms-2 mt-1" *ngIf="formErrors.notes">
            {{ formErrors.notes }}
          </div>
        </div>
      </div>
      <h3 class="Poppins-Medium">{{ "Items" | translate }}</h3>
      <table class="min-w-full divide-y divide-gray-300">
        <thead class="h-12">
          <tr class="text-left">
            <td
              class="Poppins-Medium text-[#1C1C1C33] border-[#1C1C1C33] border-b p-2"
            >
              {{ "Item" | translate }}
            </td>
            <td
              class="px-2 Poppins-Medium text-[#1C1C1C33] border-[#1C1C1C33] border-b p-2"
            >
              {{ "Quantity" | translate }}
            </td>
            <td
              class="px-2 Poppins-Medium text-[#1C1C1C33] border-[#1C1C1C33] border-b p-2"
            >
              {{ "Unit Price" | translate }}
            </td>
            <td
              class="px-2 Poppins-Medium text-[#1C1C1C33] border-[#1C1C1C33] border-b p-2"
            >
              {{ "Select Tax" | translate }}
            </td>
            <td
              class="px-2 Poppins-Medium text-[#1C1C1C33] border-[#1C1C1C33] border-b p-2"
            >
              {{ "Total" | translate }}
            </td>
            <td class="rounded-te-xl"></td>
          </tr>
        </thead>
        <tbody>
          <ng-container formArrayName="items">
            <ng-container
              *ngFor="let itemList of items.controls; let i = index"
            >
              <tr [formGroup]="itemList" class="">
                <td class="w-2/12 p-2">
                  <ng-select
                    [items]="availableProducts"
                    class="new-dropdown"
                    groupBy="type"
                    bindLabel="name"
                    formControlName="item"
                    [clearable]="false"
                    placeholder="{{ 'Select one' | translate }}"
                    (change)="changeEvent($event, i)"
                  >
                    <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-index="index"
                    >
                      <div class="px-2 py-1">
                        <div
                          class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                        >
                          {{ item.name }}
                        </div>
                      </div>
                    </ng-template>
                  </ng-select>
                </td>
                <td class="p-2">
                  <input
                    id="unit"
                    formControlName="unit"
                    type="number"
                    class="new-input"
                    placeholder="0"
                    min="1"
                  />
                  <div
                    class="text-red-500 whitespace-nowrap"
                    *ngIf="itemList.get('unit').hasError('min')"
                  >
                    {{ "Quantity should be more than 0" }}
                  </div>
                </td>
                <td class="p-2">
                  <input
                    id="price"
                    formControlName="price"
                    type="number"
                    class="new-input"
                    placeholder="0"
                  />
                </td>
                <td class="p-2 w-2/12">
                  <ng-select
                    class="new-dropdown border-0 p-0"
                    [items]="salesTaxes"
                    bindLabel="name"
                    formControlName="tax"
                    (change)="changeTax($event, i)"
                    placeholder="{{ 'Select Tax' | translate }}"
                  >
                  <ng-template ng-label-tmp let-item="item">
                    <span class="ng-value-label"
                      >{{ item.taxName }}
                      {{ item.tax | number : number }}%</span
                    >
                  </ng-template>
                  <ng-template
                    ng-option-tmp
                    let-item="item"
                    let-index="index"
                  >
                    <div class="px-2 py-1">
                      <div
                        class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                      >
                        {{ item.name }}
                      </div>
                    </div>
                  </ng-template>
                  </ng-select>
                </td>
                <td class="p-2">
                  <p>
                    {{
                      items.controls[i].get("unit").value *
                        items.controls[i].get("price").value
                        | currency
                          : currencyDetails?.currency
                          : "symbol-narrow"
                          : number
                    }}
                  </p>
                </td>
                <td class="w-1/12">
                  <svg
                    (click)="removeItem(i)"
                    xmlns="http://www.w3.org/2000/svg"
                    name="delete"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    class="mx-auto h-5 w-5 text-red-500"
                  >
                    <path
                      _ngcontent-hsm-c58=""
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    ></path>
                  </svg>
                </td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td>
                  <p
                    class="text-red-500 whitespace-nowrap"
                    *ngIf="itemList.get('unit').hasError('min')"
                  >
                    {{ "Quantity should be more than 0" }}
                  </p>
                </td>
                <td colspan="5"></td>
              </tr>
            </ng-container>
            <ng-container *ngIf="items.controls.length === 0">
              <tr>
                <td colspan="7" class="text-center p-2">
                  {{ "No item added" | translate }}
                </td>
              </tr>
            </ng-container>
          </ng-container>
          <tr>
            <td vAlign="top" colspan="4">
              <div class="ps-2">
                <app-button (handleClick)="addNewItem()" type="add-line">
                  <img
                    src="assets/images/delete-icon.svg"
                    style="transform: rotate(45deg)"
                    alt=""
                  />
                  {{ "Add a Line" | translate }}
                </app-button>
              </div>
            </td>
            <td colspan="2" class="pe-2">
              <div class="Poppins-Medium flex justify-between py-2">
                <p>{{ "Subtotal:" | translate }}</p>
                <p>
                  {{
                    subtotal
                      | currency
                        : currencyDetails?.currency
                        : "symbol-narrow"
                        : number
                  }}
                </p>
              </div>
              <div class="Poppins-Medium flex justify-between py-2">
                <p>{{ "Tax:" | translate }}</p>
                <p>
                  {{
                    totalTax
                      | currency
                        : currencyDetails?.currency
                        : "symbol-narrow"
                        : number
                  }}
                </p>
              </div>
              <div class="Poppins-Medium flex justify-between py-2 border-t">
                <p>{{ "Total Amount:" | translate }}</p>
                <p>
                  {{
                    toatalAmount
                      | currency
                        : currencyDetails?.currency
                        : "symbol-narrow"
                        : number
                  }}
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="flex mt-5 gap-x-5">
        <app-file-upload
          appAccessControl
          moduleType="storage"
          accessType="edit"
          [addedFiles]="addedFiles"
          (emitter)="saveFiles($event)"
          type="compact"
          tableType="new"
          class="w-5/6"
        ></app-file-upload>
        <div
          appAccessControl
          moduleType="purchase"
          accessType="edit"
          class="w-1/6 flex flex-col gap-3 justify-end"
        >
          <app-button
            *ngIf="!update"
            buttonText="{{ 'Save' | translate }}"
            (handleClick)="createPurchaseOrder()"
            type="primary"
            fullWidth="true"
          ></app-button>
          <app-button
            *ngIf="update"
            buttonText="{{ 'Update' | translate }}"
            (handleClick)="updatePurchaseOrder()"
            type="primary"
            fullWidth="true"
          ></app-button>
          <app-button
            buttonText="{{ 'Discard' | translate }}"
            routerLink="../"
            fullWidth="true"
          ></app-button>
        </div>
      </div>
    </form>
  </div>
</div>

<app-modal-container [isOpen]="outOfStock">
  <div header class="text-start border-b pb-2 Poppins-Medium">
    {{ "Check Items" | translate }}
  </div>
  <div class="text-start" content>
    <p>Following items have insufficient quantity in inventory:</p>
    <ul>
      <li class="Poppins list-disc" *ngFor="let item of checkQuantityInStock">
        <div>
          {{ item?.item }} added with {{ item?.quantityAdded }} units has
          {{ item?.openingStock }} units only
        </div>
      </li>
    </ul>
  </div>
  <div footer class="flex items-center ms-auto gap-x-2">
    <!-- <app-button buttonText='{{"Delete" | translate}}' type='danger' rounded=true (handleClick)='deleteEstimateslist()' ></app-button> -->
    <app-button
      buttonText="{{ 'Ok' | translate }}"
      type="primary"
      (handleClick)="outOfStock = false"
    ></app-button>
  </div>
</app-modal-container>
