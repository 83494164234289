<div class="flex justify-center p-4">
    <div class="w-3/4 flex flex-col space-y-5">
        <div class="flex items-center">
            <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                  </svg>
                {{'Date and Currency' | translate}}
            </h1>
        </div>
        <div class="py-6 px-8 rounded-3xl bg-light-gray-1 mt-2 mb-2">
            <h3 class="Gilroy-Bold text-lg mb-4">{{'Fiscal Year Start' | translate}}</h3>
            <form [formGroup]='fiscalform' class="space-y-6">
                <div class="flex space-x-8 rtl:space-x-reverse">
                    <div>
                        <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-64">
                            <label for='Country' class="block text-sm Gilroy-Bold font-color-05">{{'Month' | translate}}</label>
                            <!-- <select id='month-select' formControlName='month'  class="w-full p-0 border-0 focus:ring-0"></select>    -->
                            <ng-select (change)='chnageMonthEvent($event)' id='month-select' [items]="months" class="settings-select border-0 p-0 w-full focus:ring-0" bindLabel="text"
                            bindValue="text" formControlName="month" placeholder='{{"Select one" | translate}}'>
                            <ng-option *ngFor="let month of months" [value]="month.id">{{month.name}}</ng-option>
                            </ng-select>
                        </div>
                        <label *ngIf='formErrors.month' class="text-sm text-red-400 ms-2">{{formErrors.month}}</label>
                    </div>
                    <div>
                        <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-36">
                            <label class="text-sm Gilroy-Bold font-color-05">{{'Date' | translate}}</label>
                            <!-- <select id='date-select' formControlName='date' class="w-full p-0 border-0 focus:ring-0">
                            </select> -->
                            <ng-select id='date-select' [items]="dates" class="settings-select border-0 p-0 w-full focus:ring-0" bindLabel="text"
                            bindValue="text" formControlName="date" placeholder='{{"Select one" | translate}}'>
                            <ng-option *ngFor="let date of dates" [value]="date.id">{{date.name}}</ng-option>
                            </ng-select>
                        </div>
                        <label *ngIf='formErrors.date' class="text-sm text-red-400 ms-2">{{formErrors.date}}</label>
    
                    </div>
                    
                </div>
                <h3 class="Gilroy-Bold text-lg">{{'Business Currency' | translate}}</h3>
                <div class="flex space-x-8 rtl:space-x-reverse">
                    <div>
                        <div class=" rounded-xl bg-white px-3 py-2 w-64">
                            <label for='Country' class="block text-sm Gilroy-Bold font-color-05">{{'Selected Currency :' | translate}} {{currencySelect}}</label>
                            <!-- <select id="currency-select" formControlName='currency' class="w-full p-0 border-0 focus:ring-0">
                            </select> -->
                            <!-- <ng-select id='currency-select' disabled='true' [items]="currencyCodes" class="settings-select border-0 p-0 w-full" bindLabel="text"
                            bindValue="text" formControlName="currency" placeholder='Select one'>
                            <ng-option *ngFor="let currency of currencyCodes" [value]="currency.id">{{currency.name}}</ng-option>
                            </ng-select> -->
                            <!-- <p class="w-full p-0 border-0">{{currencySelect}}</p> -->
                        </div>
                        <!-- <label *ngIf='formErrors.currency' class="text-sm text-red-400 ms-2">{{formErrors.currency}}</label> -->
    
                    </div>
                  
                </div>
                <div class="flex items-center space-x-2 rtl:space-x-reverse">                    
                    <app-button appAccessControl moduleType='accounting' accessType='edit' buttonText='{{"Update" | translate}}' [rounded]='true' size='medium' type='primary' (handleClick)='addFiscal()'></app-button>
                    <span *ngIf='response' class="text-green-500 block text-sm ms-2">{{response}}</span>
                    <span *ngIf='error' class="text-green-500 block text-sm ms-2">{{error}}</span>
                </div>
            </form>
        </div>
    </div>
</div>