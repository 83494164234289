import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProfileManagementService } from '../../profile-management.service';
import { valueChanges } from 'src/app/shared/utils/formValidator';
import { select, Store } from '@ngrx/store';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { Observable } from 'rxjs';
import { BusinessService } from 'src/app/shared/services/business.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-invoice-credentials',
  templateUrl: './invoice-credentials.component.html',
  styleUrls: ['./invoice-credentials.component.scss']
})
export class InvoiceCredentialsComponent implements OnInit {

  constructor(private fb: FormBuilder,
    private profileManagementService: ProfileManagementService,
    private businessService: BusinessService,
    private store: Store<RootReducerState>,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService) {
      this.business$ = store.pipe(select(selectBusiness));
    }

  business$: Observable<any>;
  business = null;
  invoiceCredentialsForm: FormGroup;
  respMessage = '';
  error = '';

  formErrors = {
    clientId: '',
    secretCode: ''
    };
  resetErrorMessages = {
    clientId: {
      required: 'Client Id is required',
    },
    secretCode: {
      required: 'Secret Key is required',
    }
  };


  ngOnInit(): void {
    this.loadForm();
    this.getBusinessDetails();
  }

  getBusinessDetails(): void {
    this.business$.subscribe(business => {
      console.log(business);
      this.business = business?.businessId;
      const { clientId='', secretCode='', isSalesTax = false } = business?.businessId;
      this.invoiceCredentialsForm.patchValue({
        clientId,
        secretCode,
        isSalesTax
      })
    })
  }

  loadForm(): void {
    this.invoiceCredentialsForm = this.fb.group({
      clientId: ['', [Validators.required]],
      secretCode: ['', [Validators.required]],
      isSalesTax: [false],
      salesTaxNo: ['']
    });

    this.invoiceCredentialsForm.valueChanges.subscribe(() => {
      this.formErrors = valueChanges(this.invoiceCredentialsForm, {...this.formErrors}, this.resetErrorMessages);
    });
    this.formErrors = valueChanges(this.invoiceCredentialsForm, {...this.formErrors}, this.resetErrorMessages);
  }

  saveDetails(): void {
    if(this.invoiceCredentialsForm.invalid){
      this.invoiceCredentialsForm.markAllAsTouched();
      this.formErrors = valueChanges(this.invoiceCredentialsForm, {...this.formErrors}, this.resetErrorMessages);
      console.log(this.invoiceCredentialsForm.value);
      
      return;
    }
    this.spinner.show();
    const businessDetails = {
      ...this.business,
      businessId: this.business?._id,
      ...this.invoiceCredentialsForm.value
    }
    this.businessService.updateBusiness(businessDetails)
    .subscribe(resp => {
      if(resp?.success){
        this.toastr.success(resp?.message ?? 'Data Saved');
        this.businessService.updateBusinessInStore(resp.data);
      }
      else{
        this.toastr.error(resp?.message);
      }
    }, (err) => {
      this.toastr.error(err?.error?.message);
    }, () => this.spinner.hide())
  }

}
