import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceComponent } from './invoice/invoice.component';
import { EstimateComponent } from './estimate/estimate.component';
import { RouterModule, Routes } from '@angular/router';
import { OpenComponent } from './open.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CustomerStatementComponent } from './customer-statement/customer-statement.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReceiptComponent } from './receipt/receipt.component';
import { PaymentVoucherComponent } from './payment-voucher/payment-voucher.component';
import { BillComponent } from './bill/bill.component';
import { PurchaseOrderComponent } from './purchase-order/purchase-order.component';
import { VerifySuccessComponent } from './verify-success/verify-success.component';
import { VerifyFailureComponent } from './verify-failure/verify-failure.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HandlePaymentRedirectionComponent } from './handle-payment-redirection/handle-payment-redirection.component';
import { HandlePosPaymentRedirectionComponent } from './handle-pos-payment-redirection/handle-pos-payment-redirection.component';


const routes: Routes = [
  {
    path: '',
    component: OpenComponent,
    children: [
      {
        path: 'invoice/:businessId/:invoiceId',
        component: InvoiceComponent
      },
      {
        path: 'estimate/:businessId/:estimateId',
        component: EstimateComponent
      },
      {
        path: 'customer-statement/:type/:businessId/:customerId/:fromDate/:endDate/:userId',
        component: CustomerStatementComponent
      },
      {
        path: 'receipt/:businessId/:receiptId',
        component: ReceiptComponent
      },
      {
        path: 'payment-voucher/:businessId/:receiptId',
        component: PaymentVoucherComponent
      },
      {
        path: 'bill/:businessId/:billId',
        component: BillComponent
      },
      {
        path: 'purchase-order/:businessId/:purchaseId',
        component: PurchaseOrderComponent
      },
      {
        path:'verify-success',
        component: VerifySuccessComponent
      },
      {
        path:'verify-failure',
        component: VerifyFailureComponent
      },
      {
        path: 'redirect',
        component: HandlePaymentRedirectionComponent
      },
      {
        path: 'pos-redirect',
        component: HandlePosPaymentRedirectionComponent
      }
    ]
  }
];


@NgModule({
  declarations: [
    InvoiceComponent,
    EstimateComponent,
    OpenComponent,
    CustomerStatementComponent,
    ReceiptComponent,
    PaymentVoucherComponent,
    BillComponent,
    PurchaseOrderComponent,
    VerifySuccessComponent,
    VerifyFailureComponent,
    HandlePaymentRedirectionComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes)
  ]
})
export class OpenModule { }
