import { Component, OnDestroy, OnInit } from '@angular/core';
import { Form, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BusinessService } from 'src/app/shared/services/business.service';
import { valueChanges } from 'src/app/shared/utils/formValidator';
import * as cc from 'currency-codes';
import { getAllCountries, getAllStates } from 'src/app/shared/utils/countryStateData';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'src/app/shared/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RootReducerState } from 'src/app/store/reducers';
import { select, Store } from '@ngrx/store';
import { selectUser, selectUserBusinesses } from 'src/app/store/selectors/user.selectors';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { UpdateBusinessLimit } from 'src/app/store/actions/user.actions';
import { updateCurrentStoreLimit } from "src/app/store/actions/usage.action";
import { selectUsage } from 'src/app/store/selectors/usage.selector';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';

@Component({
  selector: 'app-businesses',
  templateUrl: './businesses.component.html',
  styleUrls: ['./businesses.component.scss']
})
export class BusinessesComponent implements OnInit, OnDestroy {

  constructor(private fb: FormBuilder,
              private businessService: BusinessService,
              private spinner: NgxSpinnerService,
              private userService: UserService,
              private route: ActivatedRoute,
              private router: Router,
              private toastr: ToastrService,
              private fileUploadService: FileUploadService,
              private store: Store<RootReducerState>) {
              this.businesses$ = this.store.pipe(select(selectUserBusinesses));
              this.business$ = store.pipe(select(selectBusiness));
              this.usage$ = store.pipe(select(selectUsage));
  }

  business$: Observable<any>;
  businessForm: FormGroup;
  storeForm: FormGroup;
  error = '';
  response = '';

  formErrors = {
    companyName: '',
    businessType: '',
    currency: '',
    organization: '',
    country: '',
  };

  storeFormErrors = {
    storeName: '',
    addressLineFirst: '',
    country: '',
    city: '',
    state: ''
  }

  formErrorMessages = {
    companyName: {
      required: 'Company Name is Required'
    },
    businessType: {
      required: 'Business Type is Required'
    },
    currency: {
      required: 'Currency is Required'
    },
    organization: {
      required: 'Organization is Required'
    },
    country: {
      required: 'Country is Required'
    },
  };
  storeFormErrorMessages = {
    storeName: {
      required: 'Store Name is Required'
    },
    addressLineFirst: {
      required: 'Address Line 1 is Required'
    },
    addressLineSecond: {
      required: 'Address Line 2 is Required'
    },
    country: {
      required: 'Country is Required'
    },
    state: {
      required: 'State is Required'
    },
    city: {
      required: 'City is Required'
    },
  };

  industrySelect;
  currenySelect;
  organisationSelect;
  countrySelect;
  businesses$: Observable<any>;
  usage$: Observable<any>;
  businessLimit;
  storeLimit;
  unsubscribe$ = new Subject();
  update = false;
  create = false;
  showForm = false;
  showStoreForm = false
  businessId = null;
  business = null;
  business_id = null;
  storeId = null;
  states = [];
  files: File[] = [];
  addedFiles = [];

  industries = [
    {text: 'Business services'},
    {text: 'Accounting'},
    {text: 'Advisory'},
    {text: 'Taxation'},
    {text: 'Advertising'},
    {text: 'Engineering'},
    {text: 'Legal'},
    {text: 'Research'},
    {text: 'Agencies'},
    {text: 'Computer'},
    {text: 'Programming'},
    {text: 'Technology'},
  ];

  organisations = [
    {text: 'Sole'},
    {text: 'Partnership'},
    {text: 'Corporation'},
    {text: 'Other'}
  ];

  ngOnInit(): void {
    this.loadForm();
    this.loadStoreForm()
    this.loadCurrencies();
    this.loadCountries();
    this.getBusinessLimit();
    this.route.queryParams.subscribe(({id, showForm, store, showStoreCreateForm}) => {
      if (showForm === 'true') {
        this.showForm = true;
      }
      else if(showStoreCreateForm){
        this.showStoreForm = true;
      }
      else if (store){
        this.getStoreDetails(store);
        console.log(store,this.showStoreForm, 'create');
      }
       else {
        this.showStoreForm = false;
        this.showForm = false;
      }
      this.businesses$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((businesses) => {
        const business = businesses.find(({_id}) => _id === id);
        if (business) {
          this.business = business.businessId;
          this.updateForm(business.businessId);
          this.addedFiles = business?.buisnessId?.files ?? [];
          this.businessId = business.businessId._id;
          this.showForm = true;
          this.update = true;
        }
      });
    });
    this.business$.subscribe(business => {
      const businessId = business?.businessId?._id;
      this.business_id = businessId;
      // this.loadStores(businessId);
    })
  }

  getStoreDetails(storeId): void {
    this.spinner.show();
    this.businessService.getStoreDetails(storeId)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
      if(resp?.success){
        this.spinner.hide();
        this.newStore(resp?.data);
        this.showStoreForm = true;
        this.storeId = storeId;
        this.update = true;
      }
      else {
        this.spinner.hide();
        this.toastr.error(resp?.message || 'Something went wrong!');
      }
    }, (err) => {
        this.spinner.hide();
        this.toastr.error(err?.message || 'Something went wrong!');
    })
  }


  getBusinessLimit(): void {
    this.usage$.subscribe(({currentUsage})=>{
      if(currentUsage){
        const { businessLimit, storeLimit } = currentUsage;
        this.businessLimit = businessLimit;
        console.log("businessLimit",businessLimit)
        this.storeLimit = storeLimit;
      }
    })
  }

  updateForm(business): void {
    this.businessForm.patchValue({
      companyName: business?.companyName || '',
      businessType: business?.businessType || '',
      currency: business?.currency || null,
      organization: business?.organization ? business?.organization : '',
      country: business?.country || null,
      addressLine1: business?.address?.addressLineFirst || '',
      addressLine2: business?.address?.addressLineSecond || '',
      city: business?.address?.city || '',
      state: business?.address?.state || null,
      taxId: business?.taxId || '',
      entityId: business?.entityId || '',
      clientId: business?.clientId ?? '',
      secretCode: business?.secretCode ?? '',
      isSalesTax: business?.isSalesTax ?? '',
      incomeSourceId: business?.incomeSourceId ?? '',
      showTax: business?.showTax || true,
      decimalSize: business?.decimalSize ? `${business?.decimalSize}`: '2'
    })
    if(business.currency){
      this.businessForm.get('currency').disable()
    }
    if(business?.address?.state) {
      this.businessForm.get('state').disable()
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadForm(business?): void {
    console.log(business?.currency);
    
    this.businessForm = this.fb.group({
      companyName: ['', [Validators.required]],
      businessType: ['', [Validators.required]],
      currency: [null, [Validators.required]],
      organization: ['', [Validators.required]],
      country: [null, [Validators.required]],
      addressLine1: [''],
      addressLine2: [''],
      city: [''],
      state: [null],
      taxId: [''],
      entityId: [''],
      showTax: [true],
      decimalSize: ['2'],
      clientId: [''],
      secretCode: [''],
      isSalesTax: [false],
      incomeSourceId: ['']
    });

    this.businessForm.valueChanges.subscribe(() => {
      this.formErrors = valueChanges(this.businessForm, {...this.formErrors}, this.formErrorMessages);
    });

    this.formErrors = valueChanges(this.businessForm, {...this.formErrors}, this.formErrorMessages);
  }

  loadStoreForm(): void {
      this.storeForm = this.fb.group({
          storeName: ['', Validators.required],
          addressLineFirst: ['', Validators.required],
          addressLineSecond: [''],
          city: ['', Validators.required],
          state: [ null, Validators.required],
          country: [null, Validators.required]
      })
      this.storeForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(()=> {
      this.storeFormErrors = valueChanges(this.storeForm, {...this.storeFormErrors}, this.storeFormErrorMessages);
      })
      this.storeFormErrors = valueChanges(this.storeForm, {...this.storeFormErrors}, this.storeFormErrorMessages);
  }

  newStore(storeDetails) {
    this.storeForm.patchValue({
      storeName: storeDetails?.storeName,
      addressLineFirst: storeDetails?.address?.addressLineFirst,
      addressLineSecond: storeDetails?.address?.addressLineSecond,
      city: storeDetails?.address?.city,
      state: storeDetails?.address?.state,
      country: storeDetails?.address?.country
    })
  }


  loadCurrencies(): void {
      const currencyCodes = cc.codes().map((code) => ({text : code}));
      this.currenySelect = currencyCodes;
  }

  loadCountries(): void{
   const countries = getAllCountries();
   this.countrySelect = countries;
  }
  
  changeEventContry(event): void {
    if(event?.text){
      this.states = getAllStates(event.text);
      if(this.businessForm.get('state').value && !this.states?.includes(this.businessForm.get('state').value) ){
        this.businessForm.get('state').setValue(null);
      }
      this.businessForm.get('state').enable();
    }
  }

  createBusiness(): void {
    this.error = '';
    this.response = '';
    if (this.businessForm.invalid) {
      this.businessForm.markAllAsTouched();
      this.formErrors = valueChanges(this.businessForm, {...this.formErrors}, this.formErrorMessages);
      return;
    }

    this.spinner.show();
    const body = {
      ...this.businessForm.value,
      address: {
        country: this.businessForm.value.country,
        state: this.businessForm.value.state,
        city: this.businessForm.value.country,
        addressLineFirst: this.businessForm.value.addressLine1,
        addressLineSecond: this.businessForm.value.addressLine2,
        postalCode: null
      },
      decimalSize: Number(this.businessForm.value.decimalSize)
    }
    this.businessService.createBusiness(body).subscribe((updatedUser) => {
      this.spinner.hide();
      if(updatedUser){
        this.userService.setUserDetails(updatedUser);
        const newBusiness = updatedUser.businesses[updatedUser.businesses.length - 1];
        this.businessService.setBusiness(newBusiness);
        this.store.dispatch(UpdateBusinessLimit({businessLimit: this.businessLimit + 1}));
        this.toastr.success('Business creation sucessful');
      }
      setTimeout(() => this.response = '', 2000);
      this.businessForm.reset();
    }, (error) => {
      this.error = error?.error?.message || 'Internal Server Error';
      this.spinner.hide();
    });
  }

  updateBusiness(): void {
    this.error = '';
    this.response = '';
    if (this.businessForm.invalid) {
      this.businessForm.markAllAsTouched();
      this.formErrors = valueChanges(this.businessForm, {...this.formErrors}, this.formErrorMessages);
      return;
    }

    this.spinner.show();
    const body = {
      businessId: this.businessId,
      ...this.businessForm.value,
      address: {
        country: this.businessForm.value.country,
        state: this.businessForm.value.state,
        city: this.businessForm.value.city,
        addressLineFirst: this.businessForm.value.addressLine1,
        addressLineSecond: this.businessForm.value.addressLine2,
        postalCode: null
      },
      decimalSize: Number(this.businessForm.value.decimalSize)
    };
    this.fileUploadService.emitFiles.next(true)
    this.fileUploadService.emitFiles.next(false);
    const formData = new FormData();
    this.files?.forEach((file, i) => {
      formData.append(`file${i}`, file);
    })
    formData.append('payload', JSON.stringify(body))
    this.businessService.updateBusiness(formData).subscribe((updatedBusiness) => {
      this.spinner.hide();
      this.businessService.updateBusinessInStore(updatedBusiness.data);
      this.response = 'Business updation sucessful';
      setTimeout(() => { this.response = ''; this.router.navigate(['/settings/profile']); }, 2000);
      this.businessForm.reset();
    }, (error) => {
      this.error = error?.error?.message || 'Internal Server Error';
      this.spinner.hide();
    });
  }

  createStore(): void {
    if(this.storeForm.invalid){
      this.storeForm.markAllAsTouched();
      this.storeFormErrors = valueChanges(this.storeForm, {...this.storeFormErrors}, this.storeFormErrorMessages);
      return;
    }
    const {storeName, addressLineFirst, addressLineSecond, city, state, country} = this.storeForm.value;
    this.spinner.show();
    const newStore = {
      storeId: this.storeId,
      storeName, address: {
        addressLineFirst, addressLineSecond, city, state, country
      }
    }
    this.businessService.createStore(newStore, this.business_id)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
      if(resp?.success){
        this.spinner.hide();
        this.toastr.success(resp?.success);
        this.hideForm();
        this.storeForm.reset();
        this.businessService.addStoreInStore(resp?.data);
        console.log(this.storeLimit);        
        this.store.dispatch(updateCurrentStoreLimit({storeLimit: this.storeLimit+1}))
      }
      else {
        this.spinner.hide();
        this.toastr.error(resp?.message || 'Something went wrong');
      }
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err?.message || 'Something went wrong');
    })
  }

  updateStore(): void {
    if(this.storeForm.invalid){
      this.storeForm.markAllAsTouched();
      this.storeFormErrors = valueChanges(this.storeForm, {...this.storeFormErrors}, this.storeFormErrorMessages);
      return;
    }
    const {storeName, addressLineFirst, addressLineSecond, city, state, country} = this.storeForm.value;
    this.spinner.show();
    const updatedStore = {
      storeId: this.storeId,
      storeName, address: {
        addressLineFirst, addressLineSecond, city, state, country
      }
    }
    this.businessService.updateStore(updatedStore)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
      if(resp?.success){
        this.spinner.hide();
        this.toastr.success(resp?.message);
        this.hideForm()
        this.businessService.updateStoreInStore({store:{...resp?.data}})
      }
      else {
        this.spinner.hide();
        this.toastr.error(resp?.message || 'Something went wrong');
      }
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err?.message || 'Something went wrong');
    })
    
  }

  deleteStore(storeId): void {
    this.spinner.show();
    this.businessService.deleteStore(storeId)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
      if(resp?.success){
        this.spinner.hide();
        this.toastr.success(resp?.message);
        this.businessService.deleteStoreFromStore({storeId});
      }
      else {
        this.spinner.hide();
        this.toastr.error(resp?.message);
      }
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err?.message)
    })
  }

  hideForm(): void {
    if(this.showForm){
      this.router.navigate(['/settings/businesses'], {queryParams: {showForm: false}});
    }
    else{
      this.router.navigate(['/settings/businesses'], {queryParams: {showStoreForm: false}});
    }
  }

  saveFiles(files: File[]): void {
    this.files = files;
  }

}