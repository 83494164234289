<div>
    <p class="text-2xl Poppins-Medium my-5 ml-5">
      {{ fileDetails?.displayFileName }}
    </p>
    <img
      class="w-[90%] h-[90%] ml-[5%]"
      *ngIf="fileData && fileType === 'image'"
      [src]="fileURL"
      alt=""
    />
    <!-- <ngx-doc-viewer *ngIf="fileData && fileType==='pdf'" [src]="fileURL" viewer="mammoth" ></ngx-doc-viewer> -->
    <!-- <pdf-viewer *ngIf="fileData && fileType==='pdf'" [src]="fileData"  ></pdf-viewer> -->
  </div>
  