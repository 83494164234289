import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PurchasesService } from 'src/app/modules/purchases/purchases.service';
import { selectedProducts } from 'src/app/modules/purchases/state/selectors/product.selector';
import { selectSalesTaxes } from 'src/app/modules/purchases/state/selectors/salesTaxes.selector';
import { selectedVendors } from 'src/app/modules/purchases/state/selectors/vendors.selector';
import { InvoicesService } from 'src/app/modules/sales/components/invoices/invoices.servcies';
import { SalesService } from 'src/app/modules/sales/sales.service';
import { selectCustomizationSettings } from 'src/app/modules/sales/state/selectors/customizationSettings.selector';
import { LanguageService } from 'src/app/shared/services/language.service';
import { NumberService } from 'src/app/shared/services/number.service';
import { copyToClipBoard } from 'src/app/shared/utils/copyToClipBoard';
import { updateCurrentTransactionLimit } from 'src/app/store/actions/usage.action';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectProjects } from 'src/app/store/selectors/project.selector';
import { selectUsage } from 'src/app/store/selectors/usage.selector';
import { selectUser } from 'src/app/store/selectors/user.selectors';
import { environment } from 'src/environments/environment';
import { BillsService } from '../../bills.services';
import { dateLessThan, DateValidator, valueChanges } from 'src/app/shared/utils/formValidator';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';

@Component({
  selector: 'app-view-bill',
  templateUrl: './view-bill.component.html',
  styleUrls: ['./view-bill.component.scss'],
  providers: [CurrencyPipe]
})
export class ViewBillComponent implements OnInit {
  billItems: any;
  billDetails2: any;

  constructor(private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private billService: BillsService,
    private purchaseService: PurchasesService,
    private store: Store<RootReducerState>,
    private router: Router,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private currencyPipe: CurrencyPipe,
    private numberService: NumberService,
    private toaster: ToastrService,
    private fileUploadService: FileUploadService,
    private fb: FormBuilder) {
    this.usage$ = this.store.pipe(select(selectUsage));
    this.business$ = this.store.pipe(select(selectBusiness));
  }

  billDetails = {
    billNumber: null,
    businessId:{
      accounting: {
        month: null, 
        date: null
      },
      businessType: null,
      companyName: null,
      country: null,
      createdAt: null,
      currency: null,
      decimalSize: null,
      emailConfig: null,
      organization: null,
      paypalConnected: null,
      subscriptionExpiry: null,
      updatedAt:null,
      userId: null,
      __v: null,
    _id: null,
    },
    createdAt: null,
    date:null,
    status: null,
    dueAmount:null,
    dueDate: null,
    itemTotal:null,
    items:[],
    notes:null,
    ownerId:null,
    paymentHistory: [],
    projectDetails:{
      projectId:null
    },
    purchaseOrder: null,
    refundHistory:[],
    returnItems:[],
    subtotal:null,
    tax:null,
    totalAmount:null,
    updatedAt: null,
    userId: {
      firstName: '',
      lastName: ''
    },
    vendor:{
      vendorId:{
        accountDetails:{
          accountId: null,
          accountName: null,
          accountSection: null
        },
        address:{
          addressLine1: null,
          addressLine2: null,
          city:null,
          country: null,
          postalCode: null,
          state: null,
        },
        availableBalance:null,
        businessId:null,
        contactDetails:{
          fax: null,
          mobileNumber: null,
          telephone: null,
          tollFree: null,
          webSite: null
        },
        email: null,
        firstName: null,
        iban: null,
        lastName: null,
        notes:null,
        taxId: null,
        userId: null,
        vendorName: null,
        __v: null,
        _id: null,
      },
      vendorName:null
    },
    files: [],
    _id: null,  
  };


  usage$: Observable<any>;
  business$: Observable<any>;
  unsubscribe = new Subject();
  transactionLimit;
  business = null;
  customizationSettings;
  shareURL;
  shareURLModal = false;
  copyButtonText = 'Copy';
  paymentShareURL = `${environment.frontBaseURL}/open/invoice-payment`;
  copyPaymentUrlButtonText = 'Copy';
  paypalConnected = false;
  emailModal = false;
  email = new FormControl(null, [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]);
  error = '';
  response = '';
  recordPaymentModal = false;
  amountDue = 0;
  page = 1;
  recordData = null;
  editRecordPaymentModal = false;
  confirmDeleteModal = false;
  recordToUpdate = {
    recordId: null,
    transactionId: null
  };
  recordToDelete = {
    recordId: null,
    transactionId: null,
    invoiceId: null
  };
  number = '1.2-2';
  currencyDetails = {
    currency: '',
    currencySymbol: ''
  };
  hasPaymentLink = false;

  fieldNames = {
    bill: 'Bill',
    billNumber: 'Bill Number',
    date: 'Bill Date',
    dueDate: 'Due Date',
    vendorDetails: 'Vendor Details',
    itemName: 'Item Name',
    quantity: 'Quantity',
    price: 'Price',
    total: 'Total',
    tax: 'Tax',
    subtotal: 'Subtotal',
    purchaseOrder: 'Purchase Order',
    notes: 'Notes',
    issuer: 'Issued By'
  }

  minDate = null;
  decimalSize = 2;

  paymentMethods = [
    'Cash',
    'Bank Transfer',
    'Cheque',
    'Online Payment',
    'Other'
  ];
  recordRefundModal = false;
  refundForm: FormGroup;
  refundFormErrors = {
    paymentMethod:'',
    paymentAccount:'',
    item:'',
  }

  refundFormErrorMessage = {
    paymentMethod:{
      required:'Payment Method is required'
    },
    paymentAccount:{
      required:'Payment Account is required'
    }
  }

  tableFields = {
    items: 'Items',
    units: 'Units',
    tax:'Tax',
    price: 'Price',
    amount: 'Amount'
  }

  invalidRefundAmount: boolean = false
  subTotal = 0;
  totalAmount = 0;
  averageTotalAmount = 0;
  tax = 0;
  itemList= [];
  taxOnItem: string = "No tax";
  refundAmount = 0.00;
  newReturnItems= [];
  files: File[] = [];
  refundFiles: File[] = [];
  addedFiles = [];
  billId;

  ngOnInit(): void {
    this.getCurrencyDetails();
    this.getTransactionLimit();
    this.loadBusiness();
    this.getCustomizationSettings();
    this.loadNumberConfig();
    this.route.queryParams.subscribe(({ id }) => {
      if (id) { this.loadBill(id); }
    });
    this.loadRefundForm()
  }


  loadRefundForm(): void {
    this.refundForm = this.fb.group({
      amount:[null],
      // paymentMethod:[null, [Validators.required]],
      paymentAccount:[null],
      memo:[null],
      items: this.fb.array([])
    });
    this.refundForm.get("paymentAccount").setValue(this.billDetails?.vendor?.vendorName)
    this.refundForm.valueChanges.subscribe(({items}) =>{
      this.refundFormErrors = valueChanges(this.refundForm, {...this.refundFormErrors}, this.refundFormErrorMessage, this.translateService)
      this.calcalutetotal(items)
    });
    this.refundFormErrors = valueChanges(this.refundForm, {...this.refundFormErrors}, this.refundFormErrorMessage, this.translateService)
  }

  calcalutetotal(estimateItems):void{
    let subTotal = 0;
    let total = 0;
    let totalTax = 0;
    let averageTotalAmount = 0;
    let totalDiscount = 0
    estimateItems.forEach(item =>{
      const {price, unit, tax, averagePrice} = item
      if(unit > 0){
        let itemTotal = price * unit
        let averageItemTotal = averagePrice * unit
        subTotal += itemTotal;
        total += itemTotal
        averageItemTotal += averageItemTotal

        if(tax){
          totalTax += this.calculateItemTax(itemTotal, tax)
        }
      }
    });

    

    this.tax = this.numberService.toFixed(totalTax);
    this.subTotal = this.numberService.toFixed(subTotal);
    this.totalAmount = this.numberService.toFixed(total + totalTax);
    this.averageTotalAmount = this.numberService.toFixed(averageTotalAmount)
    this.invalidRefundAmount= false
    if(this.totalAmount > (this.billDetails.totalAmount- this.amountDue)){
      this.invalidRefundAmount = true
    }
    

    // this.refundForm.controls['amount'].setValue(this.totalAmount)
    // this.refundForm.value.amount = this.totalAmount
    // console.log(this.refundForm.value.amount, this.totalAmount)


    console.log('total', this.totalAmount, this.subTotal, this.tax, estimateItems);
  }

  calculateItemTax(price, taxes): any{
    return taxes.reduce((a, b) => a += (price) * (b.tax/100), 0)
  }

  loadNumberConfig(): void {
    this.numberService.number
    .pipe((takeUntil(this.unsubscribe)))
    .subscribe((number) => {
      this.number = number
    })

    this.numberService.decimalSize
    .pipe((takeUntil(this.unsubscribe)))
    .subscribe((decimalSize) => {
      this.decimalSize = decimalSize
    })
  }

  getCustomizationSettings(): void {
    this.purchaseService.getCustomizationSettings()
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(resp => {
      if(resp?.success)
      this.customizationSettings = resp?.data;
      console.log(this.customizationSettings);
      
    })
  }

  getTransactionLimit(): void {
    this.usage$.subscribe(({ currentUsage }) => {
      if (currentUsage) {
        const
          { transactionLimit } = currentUsage;
        this.transactionLimit = transactionLimit;
      }
    })
  }

  getCurrencyDetails(): void {
    this.purchaseService.currencyDetails.subscribe(details => {
      if (details) {
        this.currencyDetails = details;
      }
    });
  }

  loadBusiness(): void {
    this.business$.subscribe((business) => {
      if (business?.businessId?._id) {
        if (business?.businessId?.paypalConnected) {
          this.paypalConnected = true;
        }
        this.business = business.businessId;
      }
    });
  }

  loadBill(id): void {
    this.spinner.show();
    this.billService.getBillId(id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(resp => {
        this.spinner.hide();
        if (resp?.success) {
          this.billDetails = resp?.data;
          this.billDetails2 = this.deepCopy(resp?.data)
          this.billItems = this.deepCopy(resp?.data?.items)
          this.addedFiles = this.billDetails?.files ?? []
          this.newReturnItems = this.newReturnList(this.billDetails.returnItems) 
          const items = this.deepCopy(resp?.data?.items)
          this.itemList = this.getDifference(items, this.newReturnItems)
          this.shareURL = `${environment.frontBaseURL}/open/bill/${this.billDetails.businessId._id}/${this.billDetails._id}`;
          console.log(this.shareURL);
          this.amountDue = this.numberService.toFixed(resp.data.dueAmount)
          this.refundAmount = this.billDetails?.refundHistory.length > 0 ? this.billDetails?.refundHistory.map(i=>i.amount).reduce((a,b)=>a+b): 0.00
          console.log("Load Bill",this.billDetails);
          this.minDate = this.billDetails.date;
          this.billId = id;
        }
      }, error => {
        this.spinner.hide();
      })
  }

  loadAmountDue(paymentHistory: Array<any>, totalAmount: number): number {
    const paidAmount: number = paymentHistory
      .map(payment => payment.amount)
      .reduce((prev, curr) => prev + curr, 0);
    const amountDue: number = totalAmount - paidAmount;
    return this.numberService.toFixed(amountDue);
  }

  openRecordPayment(): void {
    if (this.amountDue > 0) {
      this.recordPaymentModal = true;
    }
  }

  recordPayment(paymentData): void {
    this.spinner.show()
    const data = {
      _id: this.billDetails._id,
      businessId: this.business?._id,
      category: `Journal Entry`,
      paymentData,
      journalData: [
        {
          description: 'For vendor debit',
          businessId: this.business?._id,
          accountId: this.billDetails?.vendor?.vendorId?.accountDetails?.accountId,
          accountName: this.billDetails?.vendor?.vendorId?.accountDetails?.accountName,
          account: this.billDetails?.vendor?.vendorId?.accountDetails?.accountSection,
          debit: paymentData.amount,
          credit: 0,
          type: 'Debit'
        },
        {
          description: 'Payment',
          businessId: this.business?._id,
          accountId: paymentData.accountId,
          accountName: paymentData.accountName,
          account: paymentData.account,
          debit: 0,
          credit: paymentData.amount,
          type: 'Credit'
        }
      ]
    };
    if(data.journalData[0].accountId === "" || data.journalData[0].accountName === "" || data.journalData[1].accountId === "" || data.journalData[1].accountName === ""){
      this.toaster.error(this.translateService.instant('Something went wrong. Please refresh page and try again.'));
      this.recordPaymentModal = false;
      return
    }
    this.recordPaymentModal = false;
    this.fileUploadService.emitFiles.next(true);
    this.fileUploadService.emitFiles.next(false);
    const formData = new FormData();
    paymentData?.files?.forEach((file, i) => {
      formData.append(`file${i}`, file)
    });
    formData.append('payload', JSON.stringify(data));
    this.billService.recordPayment({formData, _id: data?._id}).subscribe(resp => {
      this.spinner.hide();
      if (resp.success) {
        this.amountDue = this.loadAmountDue(resp?.data?.paymentHistory, resp?.data?.totalAmount);
        this.billDetails.paymentHistory = [...resp?.data?.paymentHistory];
        this.billDetails.status = resp?.data?.status;
        this.store.dispatch(updateCurrentTransactionLimit({ transactionLimit: this.transactionLimit + 1 }));
        this.files = [];
        this.toaster.success(resp?.message ?? 'Payment Recorded');
      }
    }, (error) => {
      this.spinner.hide();
      this.toaster.error(this.translateService.instant(error?.error?.message ?? 'Something went wrong!'));
    });
  }

  editBill(id) {
    this.router.navigate(['/purchases/bills/create-bill'], { queryParams: { id } });
  }

  editRecord(record): void {
    this.recordToUpdate = {
      recordId: record._id,
      transactionId: record.transactionId
    };
    this.editRecordPaymentModal = true;
    this.recordData = {
      ...record,
    };
    
  }

  saveEditedRecord(recordInfo): void {
    const data = {
      recordInfo: {
        ...recordInfo,
        transactionId: this.recordToUpdate.transactionId
      },
      journalData: [
        {
          description: 'For vendor debit',
          businessId: this.business._id,
          accountId: this.billDetails?.vendor?.vendorId?.accountDetails?.accountId,
          accountName: this.billDetails?.vendor?.vendorId?.accountDetails?.accountName,
          account: this.billDetails?.vendor?.vendorId?.accountDetails?.accountSection,
          debit: recordInfo.amount,
          credit: 0,
          type: 'Debit'
        },
        {
          description: 'Payment',
          businessId: this.business._id,
          accountId: recordInfo.accountId,
          accountName: recordInfo.accountName,
          account: recordInfo.account,
          debit: 0,
          credit: recordInfo.amount,
          type: 'Credit'
        }
      ],
      invoiceId: this.billDetails._id,
      recordId: this.recordToUpdate.recordId,
    };
    this.spinner.show();
    this.billService.updateRecord(data).subscribe((resp) => {
      this.editRecordPaymentModal = false;
      if (resp?.success) {
        this.spinner.hide();
        this.billDetails.paymentHistory = resp.data.paymentHistory;
        this.amountDue = this.loadAmountDue(this.billDetails.paymentHistory, this.billDetails.totalAmount);
        this.billDetails.dueAmount = this.amountDue;
        this.billDetails.status = resp?.data?.status;
        this.toaster.success(this.translateService.instant('Payment record updated'));
      }
      else {
        this.spinner.hide();
        this.toaster.success(this.translateService.instant('Payment recorded'));
      }
    }, error => {
      this.spinner.hide();
      this.toaster.error(this.translateService.instant('Something went wrong!'));
    });
  }

  deleteRecord(record): void {
    console.log(record);
    const data = {
      recordId: record._id,
      invoiceId: this.billDetails._id,
      transactionId: record.transactionId
    };
    this.recordToDelete = data;
    this.confirmDeleteModal = true;
  }

  confirmDelete(): void {
    this.spinner.show()
    this.billService.deleteRecord(this.recordToDelete).subscribe((resp) => {
      this.confirmDeleteModal = false;
      if (resp?.success) {
        this.spinner.hide();
        this.billDetails.paymentHistory = this.billDetails.paymentHistory.filter(({ _id }) => _id !== this.recordToDelete.recordId);
        this.amountDue = this.loadAmountDue(this.billDetails.paymentHistory, this.billDetails.totalAmount);
        this.billDetails.dueAmount = this.amountDue;
        this.billDetails.status = moment(this.billDetails.dueDate).isBefore(moment()) ? 'Overdue' : this.amountDue > 0 ? 'Unpaid' : 'paid';
        this.store.dispatch(updateCurrentTransactionLimit({ transactionLimit: this.transactionLimit - 1 }))
        this.toaster.success(this.translateService.instant('Record deleted successfully'));
      }
      else {
        this.spinner.hide();
        this.toaster.success(this.translateService.instant('Payment recorded'));
      }
    }, error => {
      this.spinner.hide();
      this.toaster.error(this.translateService.instant('Cannot delete record'));
    });
  }

  downloadPdf(): void {
    const config = {
      fieldNames: this.languageService.translate(this.fieldNames),
      data: {...this.billDetails,
        items: this.billDetails.items.map(item => {
          return {...item,
            price: this.numberService.currencier(item?.price),
            total: this.numberService.currencier((item.price * item.unit))
          }
        }),
        date: moment(this.billDetails.date).format('DD-MM-YYYY'),
        dueDate: moment(this.billDetails.dueDate).format('DD-MM-YYYY'),
        dueAmount: this.numberService.currencier(this.billDetails?.dueAmount),
        totalAmount: this.numberService.currencier(this.billDetails?.totalAmount),
        tax: this.numberService.currencier(this.billDetails?.tax),
        subtotal: this.numberService.currencier(this.billDetails?.subtotal),
            },
      direction: localStorage.getItem('NuMetric|lang') === 'en'? 'ltr': 'rtl',
      decimalSize: this.decimalSize,
      companyLogo: this.customizationSettings?.companyLogo
    };
    this.spinner.show();
    this.billService.createBillPdf({config}).subscribe((resp) => {
      const a = document.createElement('a');
      const blob = new Blob([resp], {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `Bill ${this.billDetails.billNumber}.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
      this.toaster.success(this.translateService.instant('PDF downloaded'));
      this.spinner.hide();
    }, error => {
      console.log(error);
      this.spinner.hide();
      this.toaster.error(error?.error?.message || 'Something went wrong');
    })
  }

  sendEmail(): void {
    if (this.email.invalid) {
      this.email.markAsDirty();
      return;
    }
    const data = {
      email: this.email.value,
      id: this.billDetails._id,
      ownerId: this.business.userId
    };
    this.spinner.show();
    this.billService.sendEmail(data).subscribe((resp) => {
      this.spinner.hide();
      this.email.reset();
      this.toaster.success('Email sent successfully');
      this.emailModal = false;
    }, (error) => {
      this.spinner.hide();
      this.toaster.error('Email not sent');
      console.log(error);
    });
  }

  copyLink(): void {
    copyToClipBoard(this.shareURL);
    this.copyButtonText = 'Copied!';
    setTimeout(() => {
      this.copyButtonText = 'Copy';
    }, 1500);
  }

  get items(): FormArray {
    return this.refundForm.get('items') as FormArray;
  }

  openRefundPayment(): void {
    this.recordRefundModal = true;
  }

  removeItem(index): void {
    this.items.removeAt(index);
    const items = this.deepCopy(this.billItems)
    this.itemList = this.getDifference2(this.getDifference(items, this.newReturnItems), this.items.value)
  }

  addNewItem(): void {
    this.items.push(this.createItem());
    console.log(this.items.value)
    this.refundForm.controls.items.valueChanges.subscribe(items=>{
    })
  }

  changeEvent(event, index): void {
    this.items.controls[index].setValue({itemId: event.itemId, item:event.item, type:event.type,  sku:event.sku, hsn_sac:event.hsn_sac, price:event.price, tax:event.tax, isSale:event.isSale, cogsAccountDetails:event.cogsAccountDetails ? event.cogsAccountDetails: null, inventoryAccountDetails:event.inventoryAccountDetails ? event.inventoryAccountDetails: null, accountDetails:event.accountDetails, unit: event.unit, totalCost: event.price * 1 , averagePrice: this.numberService.toFixed(event.averagePrice || event.price)});
    this.items.controls[index].get('unit').clearValidators();
    this.items.controls[index].get('unit').updateValueAndValidity();
    this.items.controls[index].get('unit').setValidators([Validators.max(event.unit), Validators.min(1)])
    this.items.controls[index].get('unit').updateValueAndValidity();
    const items = this.deepCopy(this.billItems)
    console.log("Selected Items", this.items.value)
    this.itemList = this.getDifference2(this.getDifference(items, this.newReturnItems), this.items.value)
  }

  getDifference2(array1, array2) {
    return array1.filter(object1 => {
      return !array2.some(object2 => {
        return object1.itemId === object2.itemId;
      });
    });
  }

  getDifference(array1, array2) {
    return array1.filter(object1 => {
      return !array2.some(object2 => {
        if(object1.itemId === object2.itemId && object1.unit === object2.unit){
          return object1.itemId === object2.itemId
        } else if(object1.itemId === object2.itemId && object1.unit !== object2.unit ){
          object1.unit = Math.abs(object1.unit - object2.unit)
        }
      });
    });
  }

  createItem(): FormGroup {
    const itemForm = this.fb.group({
      item: null,
      itemId: '',
      sku: '',
      hsn_sac: '',
      price: [null,[Validators.min(0)]],
      unit: null,
      tax: null,
      type: '',
      totalCost: [null,[Validators.min(0)]],
      accountDetails: null,
      cogsAccountDetails: null,
      isSale: true,
      inventoryAccountDetails: null,
      averagePrice: 0
    });
    return itemForm;
  }

  generateRefundPayment():void{
    if(this.refundForm.invalid){
      this.refundForm.markAllAsTouched();
      this.refundFormErrors = valueChanges(this.refundForm, {...this.refundFormErrors}, this.refundFormErrorMessage)
      return
    }
    if(this.totalAmount > this.billDetails.totalAmount){
      return 
    }

    if(this.totalAmount === 0){
      console.log("No item selected")
      return
    }

    const formValue = this.refundForm.value;

    console.log("Refund Form Value", formValue)

    const journalDataEntry = []

    if (formValue.items.length === 0) {
      console.log("NO item selected")
      return;
    }

    if (formValue.items[0].item === null) {
      console.log("Item null")
      return;
    }

    // if(this.invalidRefundAmount){
    //   console.log("Refund Amount is more then paid amount")
    //   return 
    // }

    for(let i=0; i < formValue.items.length; i++){
        journalDataEntry.push({
          description:"For Item Credit", 
          businessId:this.business._id,
          accountId:formValue.items[i]?.inventoryAccountDetails.accountId,
          accountName :formValue.items[i]?.inventoryAccountDetails.accountName,
          account :formValue.items[i]?.inventoryAccountDetails.accountSection,
          credit : formValue.items[i].price * formValue.items[i].unit,
          debit : 0,
          type : "Credit",
          itemId :formValue.items[i].itemId 
        })
        if(formValue.items[i].tax.length > 0){
          journalDataEntry.push({
            description:"For Tax Credit", 
            businessId:this.business._id,
            accountId:formValue.items[i]?.tax[0]?.accountDetails?.accountId,
            accountName :formValue.items[i]?.tax[0]?.accountDetails?.accountName,
            account :formValue.items[i]?.tax[0]?.accountDetails?.accountSection,
            credit : (formValue.items[i]?.price * formValue.items[i].unit ) * (formValue.items[i]?.tax[0]?.tax / 100),
            debit :0,
            type : "Credit",
            itemId :formValue.items[i]?.itemId
          })
        }
    }
    let body = {
      _id:this.billDetails._id,
      businessId: this.business._id,
      category:"Journal Entry",
      paymentData:{ 
        amount:this.numberService.toFixed(this.totalAmount),
        // paymentMethod:"Customer Account Credited",
        memo:formValue?.memo ? formValue?.memo :"" ,
        accountId: this.billDetails?.vendor?.vendorId?.accountDetails?.accountId,
        accountName: this.billDetails?.vendor?.vendorId?.accountDetails?.accountName,
        account: this.billDetails?.vendor?.vendorId?.accountDetails?.accountSection,
      },
      returnItems:formValue?.items,
      journalData: [
        {
          description: 'For Vendor Debit',
          businessId: this.business._id,
          accountId: this.billDetails?.vendor?.vendorId?.accountDetails?.accountId,
          accountName: this.billDetails?.vendor?.vendorId?.accountDetails?.accountName,
          account:  this.billDetails?.vendor?.vendorId?.accountDetails?.accountSection,
          debit: this.totalAmount,
          credit: 0,
          type: 'Debit'
        },
        ...journalDataEntry
      ]
    }

    // if(this.billDetails.paymentHistory.length > 0){
    //   body.paymentData.amount = this.numberService.toFixed(this.totalAmount) 
    //   body.journalData[0].debit = this.totalAmount  
    // } else if(this.billDetails.paymentHistory.length === 0) {
    //   body.paymentData.amount = 0
    //   body.journalData[0].debit = 0
    // }
    this.spinner.show();
    this.recordRefundModal = false;
    this.fileUploadService.emitFiles.next(true);
    this.fileUploadService.emitFiles.next(false);
    const formData = new FormData();
    this.refundFiles?.forEach((file, i) => {
      formData.append(`file${i}`, file);
    })
    formData.append('payload', JSON.stringify(body))
    this.billService.refundPayment({formData, _id: body?._id}).subscribe((resp) => {
      if(resp?.success){
        this.spinner.hide();
        this.toaster.success(this.translateService.instant(resp?.message ?? 'Return Payment recorded'));
        this.billDetails.refundHistory = [...resp?.data?.refundHistory];
        this.billDetails.returnItems = [...resp?.data?.returnItems]
        this.amountDue = this.numberService.toFixed(resp?.data?.dueAmount)
        this.billDetails.status = resp?.data?.status;
        this.refundAmount = this.billDetails.refundHistory.map(i=>i.amount).reduce((a,b)=>a+b) 
        this.newReturnItems = this.newReturnList(this.billDetails.returnItems)
        this.cancelRefund();
      }
      else{
        this.spinner.hide();
        this.newReturnItems = this.newReturnList(this.billDetails.returnItems)
        this.cancelRefund();
        this.toaster.error(this.translateService.instant(resp?.message ?? 'Return Payment recorded'));
      }
    }, (error) => {
      this.spinner.hide();
      this.toaster.error(this.translateService.instant(error?.error?.message ?? 'Cannot record payment'))
    }); 
  }

  cancelRefund(){
    (this.refundForm.controls.items as FormArray).clear()
    this.refundForm.controls.amount.reset()
    this.refundForm.controls.memo.reset()
    this.recordRefundModal = false
    const items = this.deepCopy(this.billItems)
    this.itemList = this.getDifference(items, this.newReturnItems)
  }

  newReturnList(arr){
    return Object.values(arr.reduce(
      (c, {accountDetails, cogsAccountDetails, hsn_sac, inventoryAccountDetails,isSale, item, itemId, price, refundId, sku, tax, totalCost, unit, _id}) => {
      c[itemId] = c[itemId] || {accountDetails, cogsAccountDetails, hsn_sac, inventoryAccountDetails, isSale, item, itemId, price, refundId, sku, tax, totalCost, unit:0, _id};
      c[itemId].unit += unit;
      return c;
    }, {}));
  }

  deepCopy(obj){
    return JSON.parse(JSON.stringify(obj))
  }

  uploadFiles(): void {
    this.fileUploadService.emitFiles.next(true);
    this.fileUploadService.emitFiles.next(false);
    if(!this.files.length){
      this.toaster.error('Select files to upload');
      return;
    }
    this.spinner.show();
    const body = {
      businessId: this.billDetails?.businessId?._id,
      referenceId: this.billDetails?._id,
      documentType: 'Bill',
      parentDocType: 'Purchase'
    }
    const formData = new FormData();
    this.files.forEach((file, i) => {
      formData.append(`file${i}`, file);
    })
    formData.append('payload', JSON.stringify(body));
    this.fileUploadService.uploadFileForDocument(formData)
    .subscribe(resp => {
      if(resp?.success){
        console.log(resp?.data, 'view bill');
        this.addedFiles = resp?.data?.files ?? [];
        this.toaster.success(resp?.message);
      }
      else{
        this.toaster.error(resp?.message)
      }
      this.spinner.hide();
    }, (err) => {
      this.toaster.error(err?.error?.message);
      this.spinner.hide();
    })
  }

  saveFiles(files: File[]){
    this.files = files;
  }

  saveRefundFiles(files: File[]){
    this.refundFiles = files;
  }

}
