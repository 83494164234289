<div class="flex justify-center">
    <div class="w-full flex flex-col space-y-4">
        <div>
            <table class="mt-2 min-w-full border-separate" style="border-spacing: 0 15px;">
                <thead class="bg-table-header ps-5 rounded-xl h-12">
                    <tr class="rounded-xl">
                        <th class="Gilroy-Bold ps-8 text-start rounded-ts-xl rounded-bs-xl">
                            {{'Plan' | translate }}
                        </th>
                        <th class="Gilroy-Bold text-start">
                            {{'Subscription Date' | translate }}
                        </th>
                        <th class="Gilroy-Bold text-start">{{'Active Time' | translate }}</th>
                        <th class="Gilroy-Bold text-start">{{'Gross Amount' | translate }}</th>
                        <th class="Gilroy-Bold text-start">{{'Status' | translate}}</th>
                        <th class="Gilroy-Bold text-start rounded-te-xl rounded-be-xl">{{'Invoice' | translate }} </th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let history of subscriptionHistory" class=" bg-input h-16 mt-4 ">
                        <td class="ps-8 Gilroy-SemiBold rounded-ts-xl rounded-bs-xl">{{history.planName }}</td>
                        <td class="Gilroy-SemiBold">{{history.date | date }}</td>
                        <td class="Gilroy-SemiBold">
                            <p class="Gilroy-SemiBold">{{history.date | date}} {{'to' | translate}} {{history.expiryDate | date}}</p>
                        </td>
                        <td class="Gilroy-SemiBold">
                            <p class="Gilroy-SemiBold">{{history.planPrice | currency : currencySymbol : "symbol-narrow" : number}}</p>
                        </td>
                        <td [ngClass] = "history.status ? 'text-grey-900' : 'text-red-500'">{{ history.status ? 'Active' : 'Expired' | translate }}</td>
                        <td class="rounded-te-xl rounded-be-xl">
                            <a [href]="history?.invoiceUrl" target="_blank" class="Gilroy-SemiBold text-blue-600 hover:underline">{{'Reciept' | translate }}</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>


    </div>
</div>