import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.scss']
})
export class ModalContainerComponent implements OnInit {

  constructor() { }
  @Input() isOpen = false;
  ngOnInit(): void {
  }

}
