<div class="flex justify-center p-4">
    <div class="w-3/4 flex flex-col space-y-5">
        <div class="flex items-center">
            <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                  </svg>
                {{'Sales Taxes' | translate}}
            </h1>
            <app-button appAccessControl moduleType='accounting' accessType='edit' routerLink='create-tax' class="ms-auto" buttonText='{{"Create Tax" | translate }}' type='primary' rounded='true'></app-button>
        </div>
        <div class="flex justify-between py-4 px-6 rounded-full bg-light-gray-1 mb-4">
            <span class="Gilroy-Bold">{{'Name' | translate }}</span>
            <span class="Gilroy-Bold">{{'Actions' | translate }}</span>
        </div> 
        <ul class="space-y-4">
            <li *ngFor='let tax of salesTaxes' class="box-shadow-sales rounded-3xl mb-4 py-4 px-6">
                 <div class="flex items-center justify-between">
                     <p class="">{{tax.taxName}} ({{tax.taxRate | number: '.2-2'}}%) - <span class="font-color-black-05">{{tax.abbreviation}}</span></p>
                     <div appAccessControl moduleType='accounting' accessType='edit' class="flex items-center space-x-2 rtl:space-x-reverse">
                         <svg (click)='editSalestax(tax._id)' xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
                             <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                         </svg>
                         <svg (click)='openDeleteConfirmation(tax._id)' xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                             <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                           </svg>
                     </div>
                 </div>
            </li>
            <li class="text-center" *ngIf='salesTaxes.length === 0'>{{'No Taxes found' | translate }}</li>
        </ul>
    </div>
</div>

<app-modal-container [isOpen]='openDeleteModal'>
    <div header class="border-b pb-2 Gilroy-Bold">{{'Delete Tax' | translate}}</div>
    <div content>{{'Are you sure?' | translate}}</div>
    <div footer class="flex items-center space-x-2 rtl:space-x-reverse">
        <app-button buttonText='{{"Delete" | translate }}' type='danger' rounded=true (handleClick)='deletesalestax()'></app-button>
        <app-button buttonText='{{"Cancel" | translate }}' type='third' rounded=true (handleClick)='openDeleteModal = false'></app-button>
    </div>
</app-modal-container>


