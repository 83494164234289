import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChatsService } from 'src/app/modules/chats/chats.service';
import { NumberService } from 'src/app/shared/services/number.service';
import { RootReducerState } from 'src/app/store/reducers';
import { DashboardService } from '../../dashboard.service';
import { Observable } from 'rxjs';
import { selectBusiness } from 'src/app/store/selectors/business.selector';

@Component({
  selector: 'app-graph-cash-flow',
  templateUrl: './graph-cash-flow.component.html',
  styleUrls: ['./graph-cash-flow.component.scss']
})
export class GraphCashFlowComponent {
  public barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        ticks: {
          autoSkip: false, // Ensure all labels are displayed
          maxTicksLimit: 12 // Limit to the number of data points
        },
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        ticks: {
          beginAtZero: true,
          suggestedMax: 5
        },
        gridLines: {
          color: '#c8c8c8A4'
        },
        stacked: true
      }]
    }
  };

  public barChartLabels = ['January', 'February', 'March', 'April', 'May', 'June', 'July' , 'August', 'September', 'October', 'November', 'December'].forEach(item => (this.translateService.instant(item)));
  public barChartType = 'bar';
  public barChartLegend = false;
  public barChartData = [
    // {
    //   data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    //   label: this.translateService.instant('Net'),
    //   type: 'line',
    //   fill: false,
    //   // backgroundColor: 'rgba(0, 0, 0, 0)',
    //   // pointBackgroundColor: '#fff',
    //   borderColor: '#181818',
    //   lineTension: 0.4,
    // },
    {
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      label: this.translateService.instant('Outflow'),
      type: 'line',
      fill: false,
      borderColor: '#4E5BA6',
      borderDash: [5, 5],
      borderWidth: 2, // Adjust the border width
      lineTension: 0.4,
      pointRadius: 0, // Remove the dots
      pointHoverRadius: 0
      // backgroundColor: '#4E5BA6'
    },
    {
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      label: this.translateService.instant('Inflow'),
      type: 'line',
      fill: true,
      borderColor: '#0BA5EC',
      backgroundColor: (ctx) => {
        const gradient = this.createVerticalGradient(ctx.chart.ctx, '#f8f7f7');
        return gradient;
      },
      borderWidth: 2, // Adjust the border width
      lineTension: 0.4,
      pointRadius: 0, // Remove the dots
      pointHoverRadius: 0
    },
  ];
  subscription$: Observable<any>;
  business$: Observable<any>;


  constructor(private dashboardService: DashboardService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    private numberService: NumberService,
    private router: Router,
    private chatService: ChatsService,
    private deviceService: DeviceDetectorService,
    private store: Store<RootReducerState>) {
      // if(this.deviceService.isMobile()) {
      //   this.router.navigate(['/chats']);
      // };
      this.business$ = this.store.pipe(select(selectBusiness));
      // this.subscription$ = this.store.pipe(select(selectUserSubscription));
      // this.user$ = this.store.pipe(select(selectUser));
    }


  createVerticalGradient(ctx: CanvasRenderingContext2D, color: string): CanvasGradient {
    const chartWidth = ctx.canvas.clientWidth; // Width of the chart area
  
    // Create a vertical gradient from left to right
    const gradient = ctx.createLinearGradient(0, 0, chartWidth, 0);

    gradient.addColorStop(0, 'rgba(255, 255, 255, 0)'); // Transparent at the left
    gradient.addColorStop(0.1, 'rgba(255, 255, 255, 0)'); // Slight transparency
    gradient.addColorStop(0.2, color); // Start of shaded area
    gradient.addColorStop(0.85, color); // Extend shaded area towards the right
    gradient.addColorStop(1, 'rgba(255, 255, 255, 0)'); // Transparent at the right
  
    return gradient;
  }
}
