import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import * as cc from 'currency-codes';
import { base64ToFile } from 'ngx-image-cropper';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BusinessService } from 'src/app/shared/services/business.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { UserService } from 'src/app/shared/services/user.service';
import {
  getAllCountries,
  getAllStates,
} from 'src/app/shared/utils/countryStateData';
import { valueChanges } from 'src/app/shared/utils/formValidator';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectUsage } from 'src/app/store/selectors/usage.selector';
import { selectUserBusinesses } from 'src/app/store/selectors/user.selectors';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-business-update',
  templateUrl: 'business-update.component.html',
  styleUrls: ['business-update.component.scss'],
})
export class BusinessUpdateComponent implements OnInit, OnDestroy {
  showStoreForm: boolean;
  storeLimit: any;
  error: string;
  response: string;
  constructor(
    private fb: FormBuilder,
    private businessService: BusinessService,
    private spinner: NgxSpinnerService,
    private userService: UserService,
    private toastr: ToastrService,
    private fileUploadService: FileUploadService,
    private store: Store<RootReducerState>
  ) {
    this.businesses$ = this.store.pipe(select(selectUserBusinesses));
    this.business$ = store.pipe(select(selectBusiness));
    this.usage$ = store.pipe(select(selectUsage));
  }

  business$: Observable<any>;
  businessForm: FormGroup;

  @Output() close = new EventEmitter();
  formErrors = {
    companyName: '',
    businessType: '',
    currency: '',
    organization: '',
    country: '',
  };

  formErrorMessages = {
    companyName: {
      required: 'Company Name is Required',
    },
    businessType: {
      required: 'Business Type is Required',
    },
    currency: {
      required: 'Currency is Required',
    },
    organization: {
      required: 'Organization is Required',
    },
    country: {
      required: 'Country is Required',
    },
  };

  industrySelect;
  currenySelect;
  organisationSelect;
  countrySelect;
  businesses$: Observable<any>;
  usage$: Observable<any>;
  businessLimit;
  unsubscribe$ = new Subject();
  update = false;
  create = false;
  showForm = false;
  @Input()
  businessId = null;
  business_id = null;
  businessDetails;
  storeId = null;
  states = [];
  files: File[] = [];
  addedFiles = [];

  industries = [
    { text: 'Business services' },
    { text: 'Accounting' },
    { text: 'Advisory' },
    { text: 'Taxation' },
    { text: 'Advertising' },
    { text: 'Engineering' },
    { text: 'Legal' },
    { text: 'Research' },
    { text: 'Agencies' },
    { text: 'Computer' },
    { text: 'Programming' },
    { text: 'Technology' },
  ];

  organisations = [
    { text: 'Sole' },
    { text: 'Partnership' },
    { text: 'Corporation' },
    { text: 'Other' },
  ];
  showIndustryCreateNew = false;
  profilePic = 'assets/images/avatar.jpg';
  openModal = false;
  openDeleteModal = false;
  imageCropperConfig = {
    event: '',
    config: {
      maintainAspectRatio: true,
      roundCropper: true,
      aspectRatio: 1 / 1,
      title: 'Upload',
    },
  };
  openPreviewModal = false;
  selectedPreview = '';

  ngOnInit(): void {
    this.loadForm();
    this.loadCurrencies();
    this.loadCountries();
    this.getBusinessLimit();
    this.businesses$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((businesses) => {
        const business = businesses.find(({ _id }) => _id === this.businessId);
        if (business) {
          this.updateForm(business.businessId);
          this.addedFiles = business?.businessId?.files ?? [];
          this.businessId = business.businessId._id;
        }
      });
    this.business$.subscribe((business) => {
      const businessId = business?.businessId?._id;
      this.business_id = businessId;
      this.businessDetails = business;
      console.log('business?.businessId?.businessLogo1', business);
      if (business?.businessId?.businessLogo) {
        this.profilePic = `${environment.apiBaseURL}/${business?.businessId?.businessLogo}.png`;
      }
      this.showForm = true;
      this.update = true;
    });
  }

  getBusinessLimit(): void {
    this.usage$.subscribe(({ currentUsage }) => {
      if (currentUsage) {
        const { businessLimit, storeLimit } = currentUsage;
        this.businessLimit = businessLimit;
        console.log('businessLimit', businessLimit);
        this.storeLimit = storeLimit;
      }
    });
  }

  updateForm(business): void {
    console.log(business);
    this.businessForm.patchValue({
      companyName: business?.companyName || '',
      deliveryEmailAddress: business?.deliveryEmailAddress || '',
      businessType: business?.businessType || '',
      currency: business?.currency || null,
      organization: business?.organization ? business?.organization : '',
      country: business?.country || null,
      addressLine1: business?.address?.addressLineFirst || '',
      addressLine2: business?.address?.addressLineSecond || '',
      city: business?.address?.city || '',
      state: business?.address?.state || null,
      taxId: business?.taxId || '',
      entityId: business?.entityId || '',
      showTax: business?.showTax || true,
      isLogo: business?.isLogo || true,
      sendSms: business?.sendSms || true,
      footerNote: business?.footerNote || '',
      decimalSize: business?.decimalSize ? `${business?.decimalSize}` : '2',
      clientId: business?.clientId ?? '',
      secretCode: business?.secretCode ?? '',
      isSalesTax: business?.isSalesTax ?? '',
      incomeSourceId: business?.incomeSourceId ?? '',
    });
    if (business.currency) {
      this.businessForm.get('currency').disable();
    }
    if (business?.address?.state) {
      this.businessForm.get('state').disable();
    }
    if (business?.country) {
      this.states = getAllStates(business.country);
    }
    if (
      business?.businessType &&
      !this.industries.find(
        (industry) => industry.text === business.businessType
      )
    ) {
      this.industries.unshift({ text: business.businessType });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadForm(business?): void {
    console.log(business?.currency);

    this.businessForm = this.fb.group({
      companyName: ['', [Validators.required]],
      businessType: ['', [Validators.required]],
      currency: [null, [Validators.required]],
      organization: ['', [Validators.required]],
      country: [null, [Validators.required]],
      addressLine1: [''],
      addressLine2: [''],
      city: [''],
      state: [null],
      taxId: [''],
      entityId: [''],
      showTax: [true],
      isLogo: [true],
      sendSms: [true],
      decimalSize: ['2'],
      footerNote: [null],
      deliveryEmailAddress: [null],
      clientId: [''],
      secretCode: [''],
      isSalesTax: [false],
      incomeSourceId: [''],
    });

    this.businessForm.valueChanges.subscribe(() => {
      this.formErrors = valueChanges(
        this.businessForm,
        { ...this.formErrors },
        this.formErrorMessages
      );
    });

    this.formErrors = valueChanges(
      this.businessForm,
      { ...this.formErrors },
      this.formErrorMessages
    );
  }

  loadCurrencies(): void {
    const currencyCodes = cc.codes().map((code) => ({ text: code }));
    this.currenySelect = currencyCodes;
  }

  loadCountries(): void {
    const countries = getAllCountries();
    this.countrySelect = countries;
  }

  changeEventContry(event): void {
    if (event?.text) {
      this.states = getAllStates(event.text);
      if (
        this.businessForm.get('state').value &&
        !this.states?.includes(this.businessForm.get('state').value)
      ) {
        this.businessForm.get('state').setValue(null);
      }
      this.businessForm.get('state').enable();
    }
  }

  updateBusiness(): void {
    this.error = '';
    this.response = '';
    if (this.businessForm.invalid) {
      this.businessForm.markAllAsTouched();
      this.formErrors = valueChanges(
        this.businessForm,
        { ...this.formErrors },
        this.formErrorMessages
      );
      return;
    }

    this.spinner.show();
    const body = {
      businessId: this.businessId,
      ...this.businessForm.value,
      address: {
        country: this.businessForm.value.country,
        state: this.businessForm.value.state,
        city: this.businessForm.value.city,
        addressLineFirst: this.businessForm.value.addressLine1,
        addressLineSecond: this.businessForm.value.addressLine2,
        postalCode: null,
      },
      decimalSize: Number(this.businessForm.value.decimalSize),
    };
    this.fileUploadService.emitFiles.next(true);
    this.fileUploadService.emitFiles.next(false);
    const formData = new FormData();
    this.files?.forEach((file, i) => {
      formData.append(`file${i}`, file);
    });
    formData.append('payload', JSON.stringify(body));
    this.businessService.updateBusiness(formData).subscribe(
      (updatedBusiness) => {
        this.spinner.hide();
        this.businessService.updateBusinessInStore(updatedBusiness.data);
        this.response = 'Business updation sucessful';
        setTimeout(() => {
          this.response = '';
        }, 2000);
        this.businessForm.reset();
        this.close.emit();
      },
      (error) => {
        this.error = error?.error?.message || 'Internal Server Error';
        this.spinner.hide();
      }
    );
  }

  saveFiles(files: File[]): void {
    this.files = files;
  }

  openPreview(url): void {
    this.selectedPreview = url;
    this.openPreviewModal = true;
  }

  fileEvent(event): void {
    this.openModal = true;
    this.imageCropperConfig = {
      event,
      config: {
        maintainAspectRatio: true,
        roundCropper: true,
        aspectRatio: 1 / 1,
        title: 'Upload',
      },
    };
  }

  saveImage(image): void {
    console.log('image12341234', image);

    this.spinner.show();

    const imageName = 'name.jpeg';
    this.profilePic = image;
    const imageBlob = base64ToFile(this.profilePic);
    const imageFile = new File([imageBlob], imageName, { type: 'image/png' });

    console.log('imageFile', imageFile);
    const formData = new FormData();
    formData.append('payload', this.businessId);
    formData.append('businessLogo', imageFile);

    this.userService.uploadBusinessLogo(formData).subscribe(
      (resp) => {
        if (resp.success) {
          this.profilePic = image;
          const data = {
            businessId: resp.data,
            permission: this.businessDetails.permission,
            _id: this.businessDetails._id,
          };
          console.log('this.businessDetails', data);

          this.businessService.setBusiness(data);
          this.openModal = false;
          this.spinner.hide();
        }
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    );
  }

  removeLogo(): void {
    this.spinner.show();
    this.openDeleteModal = true;
    const formData = new FormData();
    formData.append('payload', this.businessId);
    this.userService.uploadBusinessLogo(formData).subscribe(
      (resp) => {
        this.spinner.hide();
        if (resp.success) {
          this.profilePic = 'assets/images/avatar.jpg';
          const data = {
            businessId: resp.data,
            permission: this.businessDetails.permission,
            _id: this.businessDetails._id,
          };
          this.businessService.setBusiness(data);
          this.openDeleteModal = false;
        }
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    );
  }
}
