<app-document-container [templateSelected]="true">
    <div id="template-option-one" class="bg-white m-auto w-A3 h-full text-zinc-600 relative p-20" style="width: 1200px; box-shadow: 0px 0px 14px #0000001a;">
        <div class="grid grid-cols-3">
            <div class="flex flex-col gap-4">
                <div class="font-bold">{{invoiceData?.businessDetails?.businessId?.companyName}}</div>
                <div class="font-bold text-7xl w-52" *ngIf="selectedDir === 'ar'"><img src="assets/images/minimalLogo.png" alt=""></div>
                <div class="font-bold text-7xl" *ngIf="selectedDir !== 'ar'">{{"invoice"}}</div>
                <div class="grid grid-cols-2 mt-3">
                    <div class="col-span-1 text-sm text-sky-600">
                        {{invoiceData?.invoiceNumber}}
                    </div>
                    <div class="col-span-1 text-sm">{{invoiceData?.invoiceDate | date}}</div>
                </div>
            </div>
            <div class="col-span-2"></div>
        </div>
        <div class="grid grid-cols-7 mt-40 gap-28" style="min-height: 600px;">
            <div class="col-span-3">
                <ng-container *ngIf="invoiceData?.Footer">
                    <div class="text-base border-b-2 border-black" style="padding: 1px 1px 24px 1px;">{{"Notes" | translate}}</div>
                    <ul class="text-base list-disc list-outside ps-6 pt-5">
                        <li class="text-justify">
                            {{invoiceData?.Footer}}
                        </li>
                    </ul>
                    <!-- <div class="font-bold mt-6">
                        {{"Thank you for your business" | translate}} &#129392;
                    </div> -->
                </ng-container>
            </div>
            <div class="col-span-4">
                <table class="w-full">
                    <thead>
                        <tr>
                            <td class="border-b-2 border-black pb-6 ltr:text-left rtl:text-right">{{"Product" | translate}}</td>
                            <td class="border-b-2 border-black pb-6 text-center w-40">
                                {{"Qty" | translate}}
                            </td>
                            <td class="border-b-2 border-black pb-6 ltr:text-right rtl:text-left">
                                {{"Price in" | translate}} <b>{{invoiceData?.currencyDetails?.currencySymbol}}</b>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor='let item of invoiceData?.items'>
                            <td class="text-base font-bold pt-5">{{item.item}}</td>
                            <td class="text-base text-center pt-5">{{item.unit}}</td>
                            <td class="text-base ltr:text-right rtl:text-left pt-5">{{item.price | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</td>
                        </tr>
                        <tr>
                        <tr *ngIf='invoiceData?.discount'>
                            <td></td>
                            <td class="text-base text-center pt-8">{{"Discount" | translate}} <b>({{invoiceData?.discount | number : number}}%)</b></td>
                            <td class="text-base ltr:text-right rtl:text-left pt-8">{{invoiceData?.subtotal - invoiceData?.discountValue | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td
                                class="text-base text-center pt-5 pb-6 border-b-2 border-black">
                                {{"TAX" | translate}} <b></b>
                            </td>
                            <td
                                class="text-base ltr:text-right rtl:text-left pt-5 pb-6 border-b-2 border-black">
                                {{invoiceData?.tax | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td
                                class="text-base text-center pt-5 pb-6 border-b-4 border-black">
                                {{"Subtotal" | translate}}
                            </td>
                            <td
                                class="text-base ltr:text-right rtl:text-left pt-5 pb-6 border-b-4 border-black">
                                {{invoiceData?.subtotal | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td class="text-base text-center pt-5 font-bold">{{"Total" | translate}}</td>
                            <td class="text-base ltr:text-right rtl:text-left pt-5 font-bold">{{invoiceData?.totalAmount | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div id="pdf-footer">
            <div class="w-full">
                <div class="grid grid-cols-4">
                    <div class="col-span-1 flex items-end">
                        <ng-container *ngIf="invoiceData?.qrCodeWithUuid">
                            <img  src="{{invoiceData?.qrCodeWithUuid}}" alt="" class="w-40 object-contain" />
                        </ng-container>
                    </div>
                    <div class="col-span-1 mt-8">
                        <div>{{"Customer" | translate}}</div>
                        <div class="font-bold mt-4">{{invoiceData?.customerDetails?.customerId?.customerName | titlecase}}</div>
                        <div>
                            {{invoiceData?.customerDetails?.customerId?.billingAddress?.addressLine1}} <br />
                            {{invoiceData?.customerDetails?.customerId?.billingAddress?.addressLine2}} <br />
                            {{invoiceData?.customerDetails?.customerId?.billingAddress?.city ? invoiceData?.customerDetails?.customerId?.billingAddress?.city + ',' : ''}} {{invoiceData?.customerDetails?.customerId?.billingAddress?.state}} <br />
                            {{invoiceData?.customerDetails?.customerId?.billingAddress?.postalCode}} <br />
                            {{invoiceData?.customerDetails?.customerId?.billingAddress?.country}} <br />
                            {{invoiceData?.customerDetails?.customerId?.mobileNumber}}
                        </div>
                    </div>
                    <div class="col-span-2 flex items-start mt-8 flex-col justify-end">
                        <ng-container *ngIf="invoiceData?.eSign">
                            <div>{{"Electronically Signed By" | translate}} {{invoiceData?.businessDetails?.businessId?.companyName}}</div>
                            <img src="{{invoiceData?.eSign}}" alt="" class="w-52 h-28 object-contain mt-2 object-center" />
                        </ng-container>
                    </div>
                </div>
                <hr class="border-t-4 border-black my-4" />
                <div class="grid grid-cols-4">
                    <div class="col-span-1">
                        <div class="font-bold">{{invoiceData?.businessDetails?.businessId?.companyName}}</div>
                        <div>
                            {{invoiceData?.businessDetails?.businessId?.address?.addressLineFirst}} <br />
                            {{invoiceData?.businessDetails?.businessId?.address?.addressLine2 ? invoiceData?.businessDetails?.businessId?.address?.addressLine2 + ',' : ''}} {{invoiceData?.businessDetails?.businessId?.address?.city ? invoiceData?.businessDetails?.businessId?.address?.city + ',' : '' }} {{invoiceData?.businessDetails?.businessId?.address?.state || ''}} <br />
                            {{invoiceData?.businessDetails?.businessId?.country}}
                        </div>
                    </div>
                    <div class="col-span-1">
                        <!-- <div><b>M</b> +962 77 777 7777</div>
                        <div><b>E</b> hello@brightpath.ws</div> -->
                        <div *ngIf="invoiceData?.businessDetails?.businessId?.entityId" class="mt-2"><b>{{"Entity ID" | translate}}</b> {{invoiceData?.businessDetails?.businessId?.entityId ? invoiceData?.businessDetails?.businessId?.entityId : '--'}}</div>
                        <div *ngIf="invoiceData?.businessDetails?.businessId?.taxId"><b>{{"TAX ID" | translate}}</b> {{invoiceData?.businessDetails?.businessId?.taxId ? invoiceData?.businessDetails?.businessId?.taxId : '--'}}</div>
                    </div>
                    <div class="col-span-2">
                        <div>
                            <b>{{"Payment Terms" | translate}} |</b> {{"Due Date" | translate}}
                            <b class="text-red-600">{{invoiceData?.dueDate | date}}</b>
                        </div>
                        <div *ngIf="invoiceData?.notesTerms">
                            {{invoiceData?.notesTerms}}
                        </div>
                    </div>
                </div>
                <div class="grid grid-cols-2">
                    <div class="col-span-1">
                        {{"Powered By" | translate}} <b class="text-sky-600">NuMetric</b>
                    </div>
                    <div class="col-span-1 ltr:text-right rtl:text-left">{{"Issued By" | translate}} <b>{{invoiceData?.userId?.firstName ? invoiceData?.userId?.firstName+' '+invoiceData?.userId?.lastName : ''}}</b></div>
                </div>
            </div>
        </div>
    </div>
</app-document-container>
