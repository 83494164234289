import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { UserService } from './user.service';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  isRefreshingToken: boolean = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private injector: Injector) {}

  userService = this.injector.get(UserService);

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({ setHeaders: { Authorization: 'Bearer ' + token }})
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
    return next.handle(this.addToken(request, this.userService.getAuthToken()))
      .pipe(catchError(err => {
        if (err instanceof HttpErrorResponse) {
            if (err.status === 411) {
                return this.handleUnauthorized(request, next);
            }
            return throwError(err);

        } else {
            return throwError(err);
        }
    }));
  }

  handleUnauthorized (req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isRefreshingToken) {
        this.isRefreshingToken = true;
        this.tokenSubject.next(null);
        const body = {
          refreshToken: localStorage.getItem('refresh_token') || null,
          accessToken: localStorage.getItem('auth_token') || null
        }
        return this.userService.refreshToken(body)
            .pipe(
              switchMap((newToken) => {
                this.isRefreshingToken = false;
                if (newToken) {
                    localStorage.setItem('auth_token', newToken.data.accessToken);
                    if (newToken.data.refreshtoken) {
                      localStorage.setItem('refresh_token', newToken.data.refreshtoken);
                    }
                    this.tokenSubject.next(newToken.data.accessToken);
                    return next.handle(this.addToken(req, newToken.data.accessToken));
                }
                this.userService.logout();
                return throwError({status: 401, message: 'Unauthorized access'});
              }),
              catchError(error => {
                this.isRefreshingToken = false;
                this.userService.logout();
                return throwError({status: 401, message: 'Unauthorized access'});
              }),
            );
    } else {
        return this.tokenSubject
            .pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {                  
                    return next.handle(this.addToken(req, token));
                })
            );
    }
  }
}
