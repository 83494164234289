import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SalesContainerComponent } from './sales-container.component';
import { ProductsAndServicesComponent } from './components/products-and-services/products-and-services.component';
import { CustomerStatementComponent } from './components/customer-statement/customer-statement.component';
import { SalesRoutingModule } from './sales-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { salesReducerMap } from './state/reducers';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from 'src/app/directives/directives.module';

@NgModule({
  declarations: [
    SalesContainerComponent,
    ProductsAndServicesComponent,
    CustomerStatementComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SalesRoutingModule,
    FormsModule,
    NgSelectModule,
    TranslateModule,
    DirectivesModule,
    StoreModule.forFeature('sales', salesReducerMap),
    ReactiveFormsModule
  ]
})
export class SalesModule { }
