import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurchaseContainerComponent } from './purchase-container.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PurchaseRoutingModule } from './purchase-routing.module';
import { AssetsComponent } from './components/assets/assets.component';
import { ProductsService } from '../sales/components/products-and-services/products.service';
import { PurchasesService } from './purchases.service';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { purchaseReducerMap } from './state/reducers';
import { StoreModule } from '@ngrx/store';
import { salesReducerMap } from '../sales/state/reducers';
import { ProductsAndServicesComponent } from './components/products-and-services/products-and-services.component';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { AccountingModule } from '../accounting/accounting.module';
import { AccountingService } from '../settings/settings-modules/accounting/accounting.service';



@NgModule({
  declarations: [
    PurchaseContainerComponent,
    ProductsAndServicesComponent,
    AssetsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgSelectModule,
    Ng2SearchPipeModule,
    PurchaseRoutingModule,
    FormsModule,
    TranslateModule,
    StoreModule.forFeature('purchase', purchaseReducerMap),
    // StoreModule.forFeature('sales', salesReducerMap),
    ReactiveFormsModule,
    DirectivesModule,
  ],
  providers: [
    PurchasesService,
    AccountingService
  ]
})
export class PurchasesModule { }
