<div class="flex justify-center p-4">
    <div class="w-3/4 flex flex-col space-y-4">
        <div class="flex items-center">
            <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
                {{'Personal Profile Management' | translate}}
            </h1>
        </div>
        <p class="text-lg mb-4 Gilroy-SemiBold text-gray-800">{{'Profile detail message' | translate }}</p>
        <app-personal-information></app-personal-information>
        <app-two-factor-authentication></app-two-factor-authentication>
        <app-emails></app-emails>
        <app-mailing-config></app-mailing-config>
        <app-password-settings></app-password-settings>
        <!-- <app-email-notifications></app-email-notifications> -->
        <app-businesses-management></app-businesses-management>
    </div>
</div>

