import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountingRoutingModule } from './accounting-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { AccountingComponent } from './accounting.component';
import { TransactionComponent } from './component/transaction/transaction.component';
import { JournalEntryComponent } from './component/journal-entry/journal-entry.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddIncomeFormComponent } from './component/transaction/forms/add-income-form/add-income-form.component';
import { FilterFormComponent } from './component/transaction/forms/filter-form/filter-form.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { AccountingService } from '../settings/settings-modules/accounting/accounting.service';

@NgModule({
  declarations: [
    AccountingComponent,
    TransactionComponent,
    JournalEntryComponent,
    AddIncomeFormComponent,
    FilterFormComponent,
],
  imports: [
    CommonModule,
    SharedModule,
    NgSelectModule,
    FormsModule,
    ClickOutsideModule,
    ReactiveFormsModule,
    AccountingRoutingModule,
    TranslateModule,
    DirectivesModule
  ]
})
export class AccountingModule { }
