<div class="w-full p-8">
    <div class="flex flex-col space-y-4 px-14 py-11 bg-white rounded-lg">
      <app-reports-filter-form
        #FilterForm
        [title]="reportType?.title"
        [lastDate]="lastDate"
        [asOfDate]="asOfDate"
        [projectsFilter]="projectsFilter"
        [accountsFilter]="accountsFilter"
        [contactsFilter]="contactsFilter"
        [depreciationFilter]="depreciationFilter"
        [assetsAccountFilter]="assetsAccountFilter"
        [itemFilter]="itemsFilter"
        [products]="products"
        [selectedProduct]="selectedProduct"
        [projects]="projects"
        [accounts]="accounts"
        [contacts]="contacts"
        [cashiers]="cashiers"
        [cashierFilter]="cashierFilter"
        [partialTrialFilter]="partialTrialFilter"
        [salesTaxTransaction]="salesTaxTransaction"
        [assetCategory]="allAssetsSection?.accounts"
        [assetSubCategory]="allAssetsSection?.accountDetails"
        [allTaxes]="allTaxes"
        [assetAccounts]="assetAccounts"
        (formData)="loadReport($event)"
        (download)="downloadPdf()"
      >
      </app-reports-filter-form>
      <app-reports-stats [stats]="stats"></app-reports-stats>
      <app-reports-view [config]="config"></app-reports-view>
    </div>
  </div>
  