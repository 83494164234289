import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingComponent } from './landing.component';
import { ContactComponent } from './component/contact/contact.component';
import { LandingRoutingModule } from './landing-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LandingPageComponent } from './component/landing-page/landing-page.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { ZapierComponent } from './component/zapier/zapier.component';
import { TermandconditionsComponent } from './component/termandconditions/termandconditions.component';
import { PrivacypolicyComponent } from './component/privacypolicy/privacypolicy.component';
import { EulaComponent } from './component/eula/eula.component';
import { CommonModule } from '@angular/common';


@NgModule({
  declarations: [
    LandingComponent,
    ContactComponent,
    LandingPageComponent,
    ZapierComponent,
    TermandconditionsComponent,
    PrivacypolicyComponent,
    EulaComponent
  ],
  imports: [
      CommonModule,
      LandingRoutingModule,
      SharedModule,
      ClickOutsideModule,
      ReactiveFormsModule,
      TranslateModule
  ]
})
export class LandingModule { }
