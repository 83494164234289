import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SettingsContainerComponent } from './settings-container.component';
import { OnlinePaymentComponent } from './settings-modules/online-payment/online-payment.component';

const routes: Routes = [
    {
        path: '',
        component: SettingsContainerComponent,
        children: [
            {
                path: 'profile',
                loadChildren: () => import('./settings-modules/profile-management/profile-management.module')
                                    .then((m) => m.ProfileManagementModule)
            },
            {
                path: 'sales',
                loadChildren: () => import('./settings-modules/sales/sales.module').then((m) => m.SalesModule)
            },
            {
                path: 'accounting',
                loadChildren: () => import('./settings-modules/accounting/accounting.module').then((m) => m.AccountingModule)
            },
            {
                path: 'subscription',
                loadChildren: () => import('./settings-modules/subscription/subscription.module').then((m) => m.SubscriptionModule)
            },
            {
                path: 'users-management',
                loadChildren: () => import('./settings-modules/user-management/user-management.module').then((m) => m.UserManagementModule)
            },
            {
                path: 'data-export',
                loadChildren: () => import('./settings-modules/data-export/data-export.module').then((m) => m.DataExportModule)
            },
            {
                path: 'businesses',
                loadChildren: () => import('./settings-modules/businesses/businesses.module').then((m) => m.BusinessesModule)
            },
            {
                path: 'online-payment',
                component: OnlinePaymentComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SettingsRoutingModule {}
