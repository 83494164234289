<ng-container *ngTemplateOutlet='primary ? primarySidebar : plus ? numPlusSideBar : secondarySidebar'></ng-container>
<ng-template #primarySidebar>
    <div class="h-full flex flex-col w-1/6 shadow-2xl Gilroy-Bold fixed bg-white rounded-e-3xl">
        <img [src]="logo" class="m-4 ms-6 w-[150px]" alt="Logo">
        <div class="relative mb-4 mx-4" *ngIf="business$ | async as currentBusiness">
            <div (click)='toggleBussiness = !toggleBussiness' (clickOutside)='toggleBussiness = false' class="cursor-pointer flex flex-row bg-light-gray-1 p-3 px-6 rounded-2xl items-center">
                <div class="Gilroy-Bold break-words">{{currentBusiness.businessId.companyName}}</div>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ms-auto" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
            </div>
            <div *ngIf='toggleBussiness' id="business-account"
                class="max-h-96 overflow-y-auto z-30 bg-white absolute ltr:left-0 rtl:right-0 w-full top-14 rounded-xl dropdown-shadow focus:outline-none">
            <div class="px-4 py-2 border-b border-gray-200 Gilroy-Bold ">{{'Businesses' | translate}}</div>
            <ng-container *ngIf="businesses$ | async as businesses">
                <span
                    *ngFor="let business of businesses"
                    [ngClass] = "currentBusiness?.businessId?._id === business?.businessId?._id ? 'bg-gray-50' : ''"
                    class="flex items-center px-4  py-2 text-sm m-2 rounded-full Gilroy-Bold hover:bg-gray-50 cursor-pointer"
                    (click)='selectBusiness(business)'>{{business?.businessId?.companyName}}
                </span>
            </ng-container>
          </div>
        </div>
        <div class="flex-1 flex flex-col overflow-x-hidden overscroll-y-auto">
            <a routerLink="/settings/profile" routerLinkActive='active' class="2xl:py-2 py-1 px-4 inline-flex text-gray-400 Gilroy-Bold items-center 2xl:text-base text-sm">
                <span class="opacity-0 bg-gradient w-1 h-10 me-4 rounded-full"></span>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
                {{'Profile Management' | translate}}
            </a>
            <a appAccessControl moduleType="setting" accessType="edit" routerLink='/settings/users-management' routerLinkActive='active' class="2xl:py-2 py-1 px-4 text-gray-400 Gilroy-Bold inline-flex items-center 2xl:text-base text-sm">
                <span class="opacity-0 bg-gradient w-1 h-10 me-4 rounded-full"></span>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
                {{'Users Management' | translate}}
            </a>
            <div appAccessControl moduleType="setting" accessType="edit" class="2xl:py-2 py-1 px-4 text-gray-400 inline-flex items-center">
                <span id='subscription-bar' class="opacity-0 bg-gradient w-1 h-full min-h-[32px] me-4 rounded-full"></span>
                <div class="flex flex-col">
                    <a routerLink='/settings/subscription/subscription-plans' [ngClass]="router.url.split('/')[2] === 'subscription' ? 'active' : ''" class="text-gray-400 Gilroy-Bold inline-flex items-center 2xl:text-base text-sm">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                        </svg>
                        {{'Subscription' | translate}}
                    </a>
                    <!-- <div class="hidden sub-menu ms-3 mt-2 items-center">
                        <span class="opacity-0 bg-black w-[2px] h-full min-h-[42px] me-4 rounded-full"></span>
                        <div class="flex flex-col">
                            <a routerLink='/settings/subscription/subscription-plans' routerLinkActive='sub-menu-active' class="2xl:text-base text-sm text-gray-400 Gilroy-Bold font-color-black-05">{{'Core' | translate}}</a>
                            <a routerLink='/settings/subscription/payroll' routerLinkActive='sub-menu-active' class="2xl:text-base text-sm text-gray-400 Gilroy-Bold font-color-black-05">{{'Add-Ons' | translate}}</a>
                        </div>
                    </div> -->
                </div>
            </div>
            <div appAccessControl moduleType='sales' accessType='edit' class="2xl:py-2 py-1 px-4 text-gray-400 inline-flex items-center">
                <span id='sales-bar' class="opacity-0 bg-gradient w-1 h-full min-h-[32px] me-4 rounded-full"></span>
                <div class="flex flex-col">
                    <a routerLink='/settings/sales/invoice-customization' [ngClass]="router.url.split('/')[2] === 'sales' ? 'sales-active' : ''" class="text-gray-400 Gilroy-Bold inline-flex items-center 2xl:text-base text-sm">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                        </svg>
                        {{'Sales' | translate}}
                    </a>
                    <div class="hidden sub-menu ms-3 mt-2 items-center">
                        <span class="opacity-0 bg-black w-[2px] h-full min-h-[42px] me-4 rounded-full"></span>
                        <div class="flex flex-col">
                            <a appAccessControl moduleType='sales' accessType='edit' routerLink='/settings/sales/invoice-customization' routerLinkActive='sub-menu-active' class="2xl:text-base text-sm text-gray-400 Gilroy-Bold font-color-black-05">{{'Invoice Customization' | translate}}</a>
                            <a appAccessControl moduleType='sales' accessType='edit' routerLink='/settings/sales/pos-settings' routerLinkActive='sub-menu-active' class="2xl:text-base text-sm text-gray-400 Gilroy-Bold font-color-black-05">{{'Cash Invoice and POS Settings' | translate}}</a>
                            <a appAccessControl moduleType='sales' accessType='edit' routerLink='/settings/sales/discount-code' routerLinkActive='sub-menu-active' class="2xl:text-base text-sm text-gray-400 Gilroy-Bold font-color-black-05">{{'Discount Code Management' | translate}}</a>
                            <!-- <a routerLink='/settings/sales/payment-settings' routerLinkActive='sub-menu-active' class="2xl:text-base text-sm text-gray-400 Gilroy-Bold font-color-black-05">{{'Payment Settings' | translate}}</a> -->
                        </div>
                    </div>
                </div>
            </div>
            <div appAccessControl moduleType='accounting' accessType='view' class="2xl:py-2 py-1 px-4 text-gray-400 inline-flex items-center">
                <span id='accounting-bar' class="opacity-0 bg-gradient w-1 h-full min-h-[32px] me-4 rounded-full"></span>
                <div class="flex flex-col">
                    <a routerLink='/settings/accounting/date-and-currency' [ngClass]="router.url.split('/')[2] === 'accounting' ? 'sales-active' : ''" class="Gilroy-Bold inline-flex items-center 2xl:text-base text-sm">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                        </svg>
                        {{'Accounting' | translate}}
                    </a>
                    <div class="hidden sub-menu ms-3 mt-2 items-center">
                        <span class="opacity-0 bg-black w-[2px] h-full min-h-[42px] me-4 rounded-full"></span>
                        <div class="flex flex-col">
                            <a routerLink='/settings/accounting/date-and-currency' routerLinkActive='sub-menu-active' class="2xl:text-base text-sm Gilroy-Bold font-color-black-05">{{'Date and Currency' | translate}}</a>
                            <a routerLink='/settings/accounting/sales-taxes' routerLinkActive='sub-menu-active' class="2xl:text-base text-sm Gilroy-Bold font-color-black-05">{{'Sales Taxes' | translate}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <a routerLink='/settings/data-export' routerLinkActive='active' class="2xl:py-2 py-1 px-4 Gilroy-Bold text-gray-400 inline-flex items-center 2xl:text-base text-sm">
                <span class="opacity-0 bg-gradient w-1 h-10 me-4 rounded-full"></span>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                </svg>
                Data Export
            </a> -->
            <a appAccessControl moduleType="setting" accessType="edit" routerLink='/settings/businesses' routerLinkActive='active' class="2xl:py-2 py-1 px-4 Gilroy-Bold text-gray-400 inline-flex items-center 2xl:text-base text-sm">
                <span class="opacity-0 bg-gradient w-1 h-10 me-4 rounded-full"></span>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                </svg>
                {{'Businesses' | translate}}
            </a>
            <a routerLink='/settings/online-payment' routerLinkActive='active' class="2xl:py-2 py-1 px-4 Gilroy-Bold text-gray-400 inline-flex items-center 2xl:text-base text-sm">
                <span class="opacity-0 bg-gradient w-1 h-10 me-4 rounded-full"></span>
                <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
                </svg> -->
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
                  </svg>                  
                {{'Online Payment' | translate}}
            </a>
            <a routerLink='/dashboard' routerLinkActive='active' class="2xl:py-2 py-1 px-4 Gilroy-Bold text-gray-400 inline-flex items-center 2xl:text-base text-sm">
                <span class="opacity-0 bg-gradient w-1 h-10 me-4 rounded-full"></span>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
                </svg>
                {{'Dashboard' | translate}}
            </a>
        </div>
        <!-- <a routerLink='/dashboard'>
            <span class="flex items-center px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 hover:rounded-xl">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
                  </svg>
                {{'Dashboard' | translate}}</span>
        </a> -->
        <div class="h-16 bottom-0 px-2 py-2 w-full cursor-pointer">
            <div class="relative">
                <div (click)='open = !open' (clickOutside)='open = false' class="flex flex-row border-t border-gray-300 p-2 items-center">
                    <img *ngIf="user$ | async as user" [src]="user.profileImageName ? baseURL+user.profileImageName : 'assets/images/avatar.jpg'" onerror="this.src='assets/images/avatar.jpg'" width="40" class="rounded-full me-2" alt="{{'avatar' | translate}}" srcset="">
                    <div class="flex flex-col">
                        <div class="Gilroy-Bold break-words" *ngIf="user$ | async as user">{{user.firstName}} {{user.lastName}}</div>
                    </div>
                </div>
                <div *ngIf='open'
                class="bg-white absolute ltr:left-0 rtl:right-0 w-full bottom-20 rounded-xl dropdown-shadow focus:outline-none">

                <span (click)='logout()' class="flex items-center px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 hover:roundex-xl cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 me-2" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clip-rule="evenodd" />
                    </svg>
                    {{'Logout' | translate}}
                </span>
              </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #secondarySidebar>
    <div class="h-full flex flex-col w-1/6 shadow-2xl Gilroy-Bold fixed bg-sidebar" [ngClass]="{'rounded-e-3xl' : router.url !== '/chats'}" id="secondary-sidebar">
        <img [src]="logo2" class="m-4 w-[160px]" alt="Logo">

        <div class="relative mb-4 mx-4" *ngIf="business$ | async as currentBusiness">
            <div (click)='toggleBussiness = !toggleBussiness' (clickOutside)='toggleBussiness = false' class="cursor-pointer flex flex-row bg-light-gray-1 p-3 px-6 rounded-2xl items-center">
                <div class="Gilroy-Bold break-words">{{currentBusiness.businessId.companyName}}</div>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ms-auto" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
            </div>
            <div *ngIf='toggleBussiness' id="business-account"
                class="bg-white absolute ltr:left-0 rtl:right-0 w-full top-14 max-h-96 overflow-y-auto z-30 rounded-xl dropdown-shadow focus:outline-none">
            <div class="px-4 py-2 border-b border-gray-200 Gilroy-Bold">{{'Businesses' | translate}}</div>
            <ng-container *ngIf="businesses$ | async as businesses">
                <span
                    *ngFor="let business of businesses"
                    [ngClass] = "currentBusiness.businessId?._id === business.businessId?._id ? 'bg-gray-50' : ''"
                    class="flex items-center px-4 py-2 text-sm m-2 rounded-full Gilroy-Bold hover:bg-gray-50 cursor-pointer"
                    (click)='selectBusiness(business)'>{{business.businessId?.companyName}}
                </span>
            </ng-container>
          </div>
        </div>
        <div class="flex flex-row items-center p-2 mx-auto mb-4 text-white">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
            </svg>
            <div class="flex items-center" dir='ltr'>
                <span class="me-3 text-sm Gilroy-Bold">EN</span>
                <label for="toggle-example" class="flex items-center cursor-pointer relative">
                    <input type="checkbox" [(ngModel)]='arabicSelected' (change)='changeLanguage()' id="toggle-example" class="sr-only">
                    <div class="toggle-bg bg-green-500 border border-green-500 h-6 w-11 rounded-full"></div>
                </label>
                <span class="ms-3 text-sm Gilroy-Bold">AR</span>
            </div>
        </div>
        <div class="flex-1 flex flex-col sidebarContent overflow-y-auto overflow-x-hidden">
            <div class="2xl:py-2 py-1 px-4 text-gray-300 inline-flex items-center">
                <span [ngClass]="router.url.split('/')[1] === 'chats' || router.url.split('/')[1] === 'task' || router.url.split('/')[1] === 'files' ? 'opacity-100' : 'opacity-0'"  class="opacity-0 bg-white w-1 h-full min-h-[32px] me-4 rounded-full"></span>
                <div class="flex flex-col">
                    <a routerLink='/chats' [ngClass]="router.url.split('/')[1] === 'chats' || router.url.split('/')[1] === 'task' || router.url.split('/')[1] === 'files' ? 'primary-sales-active' : ''" class="Gilroy-Bold text-gray-300 pt-2 flex items-center 2xl:text-base text-sm relative">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z" />
                        </svg>
                        <span class="relative">
                            {{ (router.url.split('/')[1] === 'chats' || router.url.split('/')[1] === 'task' || router.url.split('/')[1] === 'files' ? 'Chat' : 'Connect') | translate }}
                            <span *ngIf="(router.url.split('/')[1] === 'chats' || router.url.split('/')[1] === 'task' || router.url.split('/')[1] === 'files') && chatUpdated > 0" class="w-4 h-4 text-[10px] bg-red-600 absolute rounded-full flex justify-center items-center -top-2 ltr:-right-3 rtl:-left-3"></span>
                            <span *ngIf="(router.url.split('/')[1] !== 'chats' && router.url.split('/')[1] !== 'task' && router.url.split('/')[1] !== 'files') && (chatUpdated > 0 || taskCountNumber > 0 || fileCountNumber >  0)" class="w-4 h-4 text-[10px] bg-red-600 absolute rounded-full flex justify-center items-center -top-2 ltr:-right-3 rtl:-left-3"></span>
                        </span>
                    </a>
                    <div class="hidden sub-menu mt-2 items-center">
                        <!-- <span class="opacity-0 bg-white w-[2px] h-full min-h-[42px] me-4 rounded-full"></span> -->
                        <div class="flex flex-col">
                            <a routerLink="/task" routerLinkActive='primary-active' class="2xl:py-2 py-1 relative Gilroy-Bold text-gray-300 inline-flex items-center 2xl:text-base text-sm">
                                <svg class="h-6 w-6 me-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="currentColor"><path d="M9 6L20 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.80002 5.79999L4.60002 6.59998L6.60001 4.59999" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.80002 11.8L4.60002 12.6L6.60001 10.6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.80002 17.8L4.60002 18.6L6.60001 16.6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9 12L20 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9 18L20 18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                {{'Task-Manager' | translate}}
                                <span *ngIf="taskCountNumber > 0" class="w-4 h-4 text-[10px] bg-red-600 absolute rounded-full flex justify-center items-center top-[-2px] ltr:-right-3 rtl:-left-3"></span>
                            </a>
                            <a routerLink="/files" routerLinkActive='primary-active' class="2xl:py-2 relative py-1 Gilroy-Bold text-gray-300 inline-flex items-center 2xl:text-base text-sm">
                                <svg class="h-6 w-6 me-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6776 2.30337C11.5101 1.89888 12.4899 1.89888 13.3223 2.30337L20.6379 5.85815C22.454 6.74064 22.454 9.25936 20.6379 10.1419L13.3223 13.6966C12.4899 14.1011 11.5101 14.1011 10.6776 13.6966L3.36208 10.1419C1.54595 9.25936 1.54595 6.74064 3.36208 5.85815L10.6776 2.30337ZM12.4408 4.01685C12.1633 3.88202 11.8367 3.88202 11.5592 4.01685L4.24364 7.57163C3.88042 7.74813 3.88042 8.25187 4.24364 8.42837L11.5592 11.9831C11.8367 12.118 12.1633 12.118 12.4408 11.9831L19.7563 8.42837C20.1196 8.25187 20.1195 7.74813 19.7563 7.57163L12.4408 4.01685Z" fill="#fff"/>
                                    <path d="M2.10555 16.8172C2.35254 16.3232 2.95321 16.123 3.44719 16.37L11.5528 20.4227C11.8343 20.5635 12.1657 20.5635 12.4472 20.4227L20.5528 16.37C21.0467 16.123 21.6474 16.3232 21.8944 16.8172C22.1414 17.3111 21.9412 17.9118 21.4472 18.1588L13.3416 22.2116C12.497 22.6339 11.5029 22.6339 10.6583 22.2116L2.55276 18.1588C2.05878 17.9118 1.85856 17.3111 2.10555 16.8172Z" fill="#fff"/>
                                    <path d="M3.44742 12.1058C2.95344 11.8588 2.35277 12.059 2.10578 12.553C1.85879 13.047 2.05901 13.6477 2.55299 13.8946L10.6586 17.9474C11.5031 18.3697 12.4973 18.3697 13.3418 17.9474L21.4474 13.8946C21.9414 13.6477 22.1416 13.047 21.8946 12.553C21.6476 12.059 21.047 11.8588 20.553 12.1058L12.4474 16.1586C12.1659 16.2993 11.8345 16.2993 11.553 16.1586L3.44742 12.1058Z" fill="#fff"/>
                                </svg>
                                <span class="relative">
                                {{'Files' | translate}}
                                <span *ngIf="fileCountNumber > 0" class="w-4 h-4 text-[10px] bg-red-600 absolute rounded-full flex justify-center items-center -top-2 ltr:-right-3 rtl:-left-3"></span>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <a routerLink='/dashboard' routerLinkActive='primary-active' class="2xl:py-2 py-1 px-4 Gilroy-Bold text-gray-300 inline-flex items-center 2xl:text-base text-sm">
                <span class="opacity-0 bg-white w-1 h-10 me-4 rounded-full"></span>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                </svg>
                {{'Dashboard' | translate}}
            </a>
            <div appAccessControl moduleType='sales' accessType='view' class="2xl:py-2 py-1 px-4 text-gray-300 inline-flex items-center">
                <span [ngClass]="router.url.split('/')[1] === 'sales' ? 'opacity-100' : 'opacity-0'"  class="opacity-0 bg-white w-1 h-full min-h-[32px] me-4 rounded-full"></span>
                <div class="flex flex-col">
                    <a routerLink='/sales/estimates' [ngClass]="router.url.split('/')[1] === 'sales' ? 'primary-sales-active' : ''" class="text-gray-300 Gilroy-Bold inline-flex items-center 2xl:text-base text-sm">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                        </svg>
                        {{'Sales' | translate}}
                    </a>
                    <div class="hidden sub-menu ms-3 mt-2 items-center">
                        <span class="opacity-0 bg-gray-300 w-[2px] h-full min-h-[100px] me-4 rounded-full"></span>
                        <div class="flex flex-col">
                            <a routerLink='/sales/estimates' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm text-gray-300 Gilroy-Bold">{{'Estimates' | translate}}</a>
                            <a routerLink='/sales/invoices' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm text-gray-300 Gilroy-Bold">{{'Regular Invoices' | translate}}</a>
                            <a routerLink='/sales/recurring-invoices' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm text-gray-300 Gilroy-Bold">{{'Recurring Invoices' | translate}}</a>
                            <a routerLink='/sales/cash-invoice' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm text-gray-300 Gilroy-Bold">{{'Cash Invoices' | translate}}</a>
                            <a routerLink='/sales/customers' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm text-gray-300 Gilroy-Bold">{{'Customers' | translate}}</a>
                            <a routerLink='/sales/customers-statement' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm text-gray-300 Gilroy-Bold">{{'Customers statement' | translate}}</a>
                            <!-- <a routerLink='/sales/products-and-services' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm text-gray-300 Gilroy-Bold">{{'Products and services' | translate}}</a> -->
                        </div>
                    </div>
                </div>
            </div>
            <div appAccessControl moduleType='purchase' accessType='view' class="2xl:py-2 py-1 px-4 text-gray-300 inline-flex items-center">
                <span [ngClass]="router.url.split('/')[1] === 'purchases' ? 'opacity-100' : 'opacity-0'"  class="opacity-0 bg-white w-1 h-full min-h-[32px] me-4 rounded-full"></span>
                <div class="flex flex-col">
                    <a routerLink='/purchases/purchase-order' [ngClass]="router.url.split('/')[1] === 'purchases' ? 'primary-sales-active' : ''" class="text-gray-300 Gilroy-Bold inline-flex items-center 2xl:text-base text-sm">

                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2 text-gray-300 Gilroy-Bold inline-flex items-center 2xl:text-base text-sm" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                        </svg>
                        {{'Purchases' | translate}}
                    </a>
                    <div class="hidden sub-menu ms-3 mt-2 items-center">
                        <span class="opacity-0 bg-gray-300 w-[2px] h-full min-h-[100px] me-4 rounded-full"></span>
                        <div class="flex flex-col">
                            <a routerLink='/purchases/purchase-order' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm text-gray-300 Gilroy-Bold">{{'Purchase Order' | translate}}</a>
                            <a routerLink='/purchases/expenses' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm text-gray-300 Gilroy-Bold">{{'Expenses' | translate}}</a>
                            <a routerLink='/purchases/bills' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm text-gray-300 Gilroy-Bold">{{'Bills' | translate}}</a>
                            <a routerLink='/purchases/recurring-bill' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm text-gray-300 Gilroy-Bold">{{'Recurring Bill' | translate}}</a>
                            <a routerLink='/purchases/vendors' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm text-gray-300 Gilroy-Bold">{{'Vendors' | translate}}</a>
                            <a routerLink='/purchases/assets' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm text-gray-300 Gilroy-Bold">{{'Assets' | translate}}</a>
                            <!-- <a routerLink='/purchases/products-and-services' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm text-gray-300 Gilroy-Bold">{{'Products and services' | translate}}</a> -->
                        </div>
                    </div>
                </div>
            </div>
            <div appAccessControl moduleType='inventory' accessType='view' class="2xl:py-2 py-1 px-4 text-gray-300 inline-flex items-center">
                <span [ngClass]="router.url.split('/')[1] === 'inventory' ? 'opacity-100' : 'opacity-0'"  class="opacity-0 bg-white w-1 h-full min-h-[32px] me-4 rounded-full"></span>
                <div class="flex flex-col">
                    <a routerLink='/inventory/single-product' [ngClass]="router.url.split('/')[1] === 'inventory' ? 'primary-sales-active' : ''" class="Gilroy-Bold inline-flex items-center 2xl:text-base text-sm">
                        <!-- <span [ngClass]="router.url.split('/')[1] === 'inventory' ? 'opacity-100' : 'opacity-0'"  class="opacity-0 bg-white w-1 h-full min-h-[32px] me-4 rounded-full"></span> -->
                        <svg class="svg-icon" class="h-6 w-6 me-2" stroke="white" fill="white" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M867.84 437.76h-686.08c-14.336 0-25.6-11.264-25.6-25.6v-174.08c0-14.336 11.264-25.6 25.6-25.6h686.08c14.336 0 25.6 11.264 25.6 25.6v174.08c0 14.336-11.264 25.6-25.6 25.6z m-660.48-51.2h634.88v-122.88h-634.88v122.88z"  /><path d="M264.704 540.672m-34.816 0a34.816 34.816 0 1 0 69.632 0 34.816 34.816 0 1 0-69.632 0Z"  /><path d="M517.12 540.672m-34.816 0a34.816 34.816 0 1 0 69.632 0 34.816 34.816 0 1 0-69.632 0Z"  /><path d="M775.168 540.672m-34.816 0a34.816 34.816 0 1 0 69.632 0 34.816 34.816 0 1 0-69.632 0Z"  /><path d="M262.144 674.304m-34.816 0a34.816 34.816 0 1 0 69.632 0 34.816 34.816 0 1 0-69.632 0Z"  /><path d="M514.56 674.304m-34.816 0a34.816 34.816 0 1 0 69.632 0 34.816 34.816 0 1 0-69.632 0Z"  /><path d="M772.608 674.304m-34.816 0a34.816 34.816 0 1 0 69.632 0 34.816 34.816 0 1 0-69.632 0Z"  /><path d="M262.144 807.936m-34.816 0a34.816 34.816 0 1 0 69.632 0 34.816 34.816 0 1 0-69.632 0Z"  /><path d="M514.56 807.936m-34.816 0a34.816 34.816 0 1 0 69.632 0 34.816 34.816 0 1 0-69.632 0Z"  /><path d="M772.608 807.936m-34.816 0a34.816 34.816 0 1 0 69.632 0 34.816 34.816 0 1 0-69.632 0Z"  /><path d="M990.72 949.76H48.64c-14.336 0-25.6-11.264-25.6-25.6v-803.84c0-14.336 11.264-25.6 25.6-25.6h942.08c14.336 0 25.6 11.264 25.6 25.6v803.84c0 14.336-11.264 25.6-25.6 25.6z m-916.48-51.2h890.88v-752.64h-890.88v752.64z"  /></svg>
                        {{'Inventory' | translate}}
                    </a>
                    <div class="hidden sub-menu ms-3 mt-2 items-center">
                        <span class="opacity-0 bg-white w-[2px] h-full min-h-[42px] me-4 rounded-full"></span>
                        <div class="flex flex-col">
                            <a routerLink='/inventory/single-product' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm Gilroy-Bold text-gray-300">{{'Item' | translate}}</a>
                            <a routerLink='/inventory/bundled-products' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm Gilroy-Bold text-gray-300">{{'Bundled Products' | translate}}</a>
                            <a routerLink='/inventory/production-run' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm Gilroy-Bold text-gray-300">{{'Production Run' | translate}}</a>
                            <a routerLink='/inventory/write-off' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm Gilroy-Bold text-gray-300">{{'Write Off' | translate}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div appAccessControl moduleType='accounting' accessType='view' class="2xl:py-2 py-1 px-4 text-gray-300 inline-flex items-center">
                <span [ngClass]="router.url.split('/')[1] === 'accounting' ? 'opacity-100' : 'opacity-0'"  class="opacity-0 bg-white w-1 h-full min-h-[32px] me-4 rounded-full"></span>
                <div class="flex flex-col">
                    <a routerLink='/accounting/transaction' [ngClass]="router.url.split('/')[1] === 'accounting' ? 'primary-sales-active' : ''" class="Gilroy-Bold inline-flex items-center 2xl:text-base text-sm">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                        </svg>
                        {{'Accounting' | translate}}
                    </a>
                    <div class="hidden sub-menu ms-3 mt-2 items-center">
                        <span class="opacity-0 bg-white w-[2px] h-full min-h-[42px] me-4 rounded-full"></span>
                        <div class="flex flex-col">
                            <a routerLink='/accounting/transaction' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm Gilroy-Bold text-gray-300">{{'Transactions' | translate}}</a>
                            <a routerLink='/accounting/recurring-jv' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm Gilroy-Bold text-gray-300">{{'Recurring JV' | translate}}</a>
                            <a routerLink='/accounting/charts-of-account' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm Gilroy-Bold text-gray-300">{{'Charts of Accounts' | translate}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <a appAccessControl moduleType='projects' accessType='view' routerLink='/projects/list' routerLinkActive='primary-active' class="2xl:py-2 py-1 px-4 Gilroy-Bold text-gray-300 inline-flex items-center 2xl:text-base text-sm">
                <span [ngClass]="router.url.split('/')[1] === 'projects' ? 'opacity-100' : 'opacity-0'"  class="opacity-0 bg-white w-1 h-full min-h-[32px] me-4 rounded-full"></span>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" viewBox="0 0 20 20" stroke="currentColor" fill="none">
                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                    <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" />
                </svg>
                {{'Projects' | translate}}
            </a>

            <div  appAccessControl moduleType='budget' accessType='view'>
                <a  routerLink='/plus/budget' routerLinkActive='primary-active' class="2xl:py-2 py-1 px-4 Gilroy-Bold text-gray-300 inline-flex items-center 2xl:text-base text-sm" >
                  <span [ngClass]="router.url.split('/')[1] === 'plus' ? 'opacity-100' : 'opacity-0'"  class="opacity-0 bg-white w-1 h-full min-h-[32px] me-4 rounded-full"></span>
                  <svg fill = "lightgray"  class="h-6 w-6 me-2 text-gray-300" width="24" height="24" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 109.03"><path d="M0,19.4a6.13,6.13,0,0,1,12.26,0V96.78H116.75a6.13,6.13,0,1,1,0,12.25H0V19.4ZM107.82,8.22a6.77,6.77,0,1,1-4,12.24l-14.45,11a6.54,6.54,0,0,1,.06.89,6.77,6.77,0,0,1-13.53,0,6.59,6.59,0,0,1,.12-1.27l-7.42-5.35,0,0a15.08,15.08,0,0,1-21.33,0c-.29-.29-.57-.6-.83-.91l-7.57,5.38a6.51,6.51,0,0,1,.34,2.13,6.76,6.76,0,1,1-6.76-6.77,6.65,6.65,0,0,1,2.75.59l8.51-6.06a15.09,15.09,0,1,1,29.31-5,14.94,14.94,0,0,1-1.32,6.17l7.41,5.35a6.76,6.76,0,0,1,7.43.19L101.1,15.73a6.17,6.17,0,0,1,0-.75,6.76,6.76,0,0,1,6.76-6.76ZM63,12.27c-1.92-.21-3.32-.31-4.19-.31A8.07,8.07,0,0,0,57.3,12a.29.29,0,0,0-.21.29.4.4,0,0,0,.25.35,9.64,9.64,0,0,0,1.39.37c.75.17,1.38.32,1.86.47a9.47,9.47,0,0,1,1.4.55,2.47,2.47,0,0,1,1,.79,4.92,4.92,0,0,1,.63,2.73,4.44,4.44,0,0,1-1.24,3.52,3.31,3.31,0,0,1-2.76,1.16v1.52H56.35V22.26a14,14,0,0,1-3.28-.58l-.64-.14.19-3.61a36,36,0,0,0,5.12.46,2.2,2.2,0,0,0,.83-.11.38.38,0,0,0,.23-.35.32.32,0,0,0-.24-.31,12,12,0,0,0-1.32-.25A7.69,7.69,0,0,1,53.36,16a4.08,4.08,0,0,1-1-3.11c0-1.76.41-3,1.23-3.68a3.32,3.32,0,0,1,2.8-1.08V6.38h3.24V8.09a19.28,19.28,0,0,1,2.87.39l.67.11L63,12.27ZM58,2.52A12.56,12.56,0,1,1,45.39,15.08,12.56,12.56,0,0,1,58,2.52Zm43.51,33.82h12.69a1.33,1.33,0,0,1,1.33,1.33V87a1.34,1.34,0,0,1-1.33,1.33H101.47A1.34,1.34,0,0,1,100.14,87V37.67a1.33,1.33,0,0,1,1.33-1.33ZM76.36,53.23H89.05a1.34,1.34,0,0,1,1.33,1.33V87a1.34,1.34,0,0,1-1.33,1.33H76.36A1.34,1.34,0,0,1,75,87V54.56a1.34,1.34,0,0,1,1.33-1.33ZM51.24,36.34H63.93a1.33,1.33,0,0,1,1.33,1.33V87a1.34,1.34,0,0,1-1.33,1.33H51.24A1.34,1.34,0,0,1,49.91,87V37.67a1.33,1.33,0,0,1,1.33-1.33ZM26.13,56H38.82a1.34,1.34,0,0,1,1.33,1.33V87a1.34,1.34,0,0,1-1.33,1.33H26.13A1.34,1.34,0,0,1,24.8,87V57.29A1.34,1.34,0,0,1,26.13,56Z"/></svg>
                  {{'Budget' | translate}}
                </a>
            </div>

            <a appAccessControl moduleType='reports' accessType='view' routerLink="/reports" routerLinkActive='primary-active' class="2xl:py-2 py-1 px-4 Gilroy-Bold text-gray-300 inline-flex items-center 2xl:text-base text-sm">
                <span [ngClass]="router.url.split('/')[1] === 'reports' ? 'opacity-100' : 'opacity-0'"  class="opacity-0 bg-white w-1 h-full min-h-[32px] me-4 rounded-full"></span>

                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                </svg>
                {{'Reports' | translate}}
            </a>
            <div appAccessControl moduleType='payroll' accessType='view'>
                <a (click)="redirect('payroll')"  routerLinkActive='primary-active' class="2xl:py-2 py-1 px-4 Gilroy-Bold text-gray-300 inline-flex items-center 2xl:text-base text-sm cursor-pointer" >
                    <span  class="opacity-0 bg-white w-1 h-full min-h-[32px] me-4 rounded-full"></span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 me-2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                          
                    {{'Payroll' | translate}}
                </a>
            </div>
            <div class="2xl:py-2 py-1 px-4 text-gray-300 inline-flex items-center">
                <span
                  [ngClass]="
                    router.url.split('/')[1] === 'experts' ? 'opacity-100' : 'opacity-0'
                  "
                  class="opacity-0 bg-white w-1 h-full min-h-[32px] me-4 rounded-full"
                ></span>
                <div class="flex flex-col">
                  <a
                    routerLink="/experts/hire-expert"
                    [ngClass]="
                      router.url.split('/')[1] === 'experts'
                        ? 'primary-sales-active'
                        : ''
                    "
                    class="text-gray-300 Gilroy-Bold inline-flex items-center 2xl:text-base text-sm"
                    ><svg
                      class="h-5 w-[26px] me-2 text-gray-300 Gilroy-Bold expert-icon inline-flex items-center 2xl:text-base text-sm"
                      xmlns="http://www.w3.org/2000/svg"
                      width="15.238"
                      height="13.83"
                      viewBox="0 0 15.238 13.83"
                    >
                      <g
                        id="Group_7353"
                        data-name="Group 7353"
                        transform="translate(-1.5 -3.5)"
                      >
                        <path
                          id="Path_5308"
                          data-name="Path 5308"
                          d="M2,17.635v-.7A4.931,4.931,0,0,1,6.931,12h0"
                          transform="translate(0 -2.365)"
                          fill="none"
                          stroke="#2c03f5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1"
                        />
                        <path
                          id="Path_5309"
                          data-name="Path 5309"
                          d="M14.616,12.157a1.14,1.14,0,0,1,1.685,0h0a1.137,1.137,0,0,0,.9.371h0a1.14,1.14,0,0,1,1.192,1.192h0a1.14,1.14,0,0,0,.37.9h0a1.139,1.139,0,0,1,0,1.685h0a1.139,1.139,0,0,0-.37.9h0A1.14,1.14,0,0,1,17.2,18.388h0a1.14,1.14,0,0,0-.9.37h0a1.14,1.14,0,0,1-1.685,0h0a1.14,1.14,0,0,0-.9-.371h0A1.14,1.14,0,0,1,12.528,17.2h0a1.14,1.14,0,0,0-.371-.9h0a1.14,1.14,0,0,1,0-1.685h0a1.137,1.137,0,0,0,.371-.9h0a1.14,1.14,0,0,1,1.192-1.192h0a1.138,1.138,0,0,0,.9-.371h0Z"
                          transform="translate(-2.893 -2.301)"
                          fill="none"
                          stroke="#2c03f5"
                          stroke-width="1"
                        />
                        <path
                          id="Path_5310"
                          data-name="Path 5310"
                          d="M12.3,13.157l.768.768L14.6,12.389M7.817,9.635A2.817,2.817,0,1,0,5,6.817,2.817,2.817,0,0,0,7.817,9.635Z"
                          transform="translate(-0.887)"
                          fill="none"
                          stroke="#2c03f5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1"
                        />
                      </g>
                    </svg>
        
                    {{ "Experts" | translate }}
                  </a>
                  <div class="hidden sub-menu ms-3 mt-2 items-center">
                    <span
                      class="opacity-0 bg-gray-300 w-[2px] h-full min-h-[50px] me-4 rounded-full"
                    ></span>
                    <div class="flex flex-col">
                      <!-- <a
                        routerLink="/experts/become-expert"
                        routerLinkActive="primary-sub-menu-active"
                        class="2xl:text-base text-sm text-gray-300 Gilroy-Bold"
                        >{{ "Become an expert" | translate }}</a
                      > -->
                      <a
                        routerLink="/experts/hire-expert"
                        routerLinkActive="primary-sub-menu-active"
                        class="2xl:text-base text-sm text-gray-300 Gilroy-Bold"
                        >{{ "Hire an expert" | translate }}</a
                      >
                      <a
                        routerLink="/experts/enquiry-history"
                        routerLinkActive="primary-sub-menu-active"
                        class="2xl:text-base text-sm text-gray-300 Gilroy-Bold"
                        >{{ "Enquiry History" | translate }}</a
                      >
                    </div>
                  </div>
                </div>
            </div>
            <!-- <div> -->
            <div appAccessControl moduleType='files' accessType='view' *ngIf="haveStoragePlan" >
                <a routerLink="/storage"  routerLinkActive='primary-active' class="2xl:py-2 py-1 px-4 Gilroy-Bold text-gray-300 inline-flex items-center 2xl:text-base text-sm cursor-pointer" >
                    <span  class="opacity-0 bg-white w-1 h-full min-h-[32px] me-4 rounded-full"></span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 me-2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
                      </svg>                      
                    {{'Documents' | translate}}
                </a>
            </div>
            <a (click)="redirect('education')"  routerLinkActive='primary-active' class="2xl:py-2 py-1 px-4 Gilroy-Bold text-gray-300 inline-flex items-center 2xl:text-base text-sm cursor-pointer" >
                <span  class="opacity-0 bg-white w-1 h-full min-h-[32px] me-4 rounded-full"></span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 me-2 mr-2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                    </svg>
                {{'Learn and Support' | translate}}
            </a>
            <a routerLink='/settings/profile' routerLinkActive='primary-active' class="2xl:py-2 py-1 px-4 Gilroy-Bold text-gray-300 inline-flex items-center 2xl:text-base text-sm" >
                <span [ngClass]="router.url.split('/')[1] === 'settings' ? 'opacity-100' : 'opacity-0'"  class="opacity-0 bg-white w-1 h-full min-h-[32px] me-4 rounded-full"></span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z" />
                    </svg>
                {{'Settings' | translate}}
            </a>
        </div>
        <!-- <a routerLink='/settings/businesses' routerLinkActive='primary-active' class="2xl:py-2 py-1 px-4 Gilroy-Bold text-gray-300 inline-flex items-center 2xl:text-base text-sm">
            <span class="opacity-0 bg-gradient w-1 h-10 me-4 rounded-full"></span>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
              </svg>
            Hire an Advisor
        </a> -->
        <div class="h-16 bottom-0 px-2 py-2 w-full cursor-pointer">
            <div class="relative">
                <div (click)='open = !open' (clickOutside)='open = false' class="flex flex-row border-t border-gray-300 p-2 items-center">
                    <img *ngIf="user$ | async as user" [src]="user.profileImageName ? baseURL+user.profileImageName : 'assets/images/avatar.jpg'" onerror="this.src='assets/images/avatar.jpg'" width="40" class="rounded-full me-2" alt="avatar" srcset="">
                    <div class="flex flex-col">
                        <div class="Gilroy-Bold break-words text-white" *ngIf="user$ | async as user">{{user.firstName}} {{user.lastName}}</div>
                    </div>
                </div>
                <div *ngIf='open' (click)='open = true'
                class="bg-white absolute ltr:left-0 rtl:right-0 w-full bottom-20 rounded-xl dropdown-shadow focus:outline-none">
                    <span (click)='logout()' class="flex items-center px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 hover:rounded-xl cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 me-2" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clip-rule="evenodd" />
                    </svg>
                    {{'Logout' | translate}}
                    </span>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #numPlusSideBar>
  <div class="h-full flex flex-col w-1/6 shadow-2xl Gilroy-Bold fixed bg-sidebar rounded-e-3xl">
    <img [src]="logo2" class="m-4 w-[160px]" alt="Logo">

    <div class="relative mb-4 mx-4" *ngIf="business$ | async as currentBusiness">
        <div (click)='toggleBussiness = !toggleBussiness' (clickOutside)='toggleBussiness = false' class="cursor-pointer flex flex-row bg-light-gray-1 p-3 px-6 rounded-2xl items-center">
            <div class="Gilroy-Bold break-words">{{currentBusiness.businessId.companyName}}</div>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ms-auto" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
        </div>
        <div *ngIf='toggleBussiness' id="business-account"
            class="bg-white absolute ltr:left-0 rtl:right-0 w-full top-14 max-h-96 overflow-y-auto z-30 rounded-xl dropdown-shadow focus:outline-none">
        <div class="px-4 py-2 border-b border-gray-200 Gilroy-Bold">{{'Businesses' | translate}}</div>
        <ng-container *ngIf="businesses$ | async as businesses">
            <span
                *ngFor="let business of businesses"
                [ngClass] = "currentBusiness.businessId?._id === business.businessId?._id ? 'bg-gray-50' : ''"
                class="flex items-center px-4 py-2 text-sm m-2 rounded-full Gilroy-Bold hover:bg-gray-50 cursor-pointer"
                (click)='selectBusiness(business)'>{{business.businessId?.companyName}}
            </span>
        </ng-container>
      </div>
    </div>
    <div class="flex flex-row items-center p-2 mx-auto mb-4 text-white">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
        </svg>
        <div class="flex items-center" dir='ltr'>
            <span class="me-3 text-sm Gilroy-Bold">EN</span>
            <label for="toggle-example" class="flex items-center cursor-pointer relative">
                <input type="checkbox" [(ngModel)]='arabicSelected' (change)='changeLanguage()' id="toggle-example" class="sr-only">
                <div class="toggle-bg bg-green-500 border border-green-500 h-6 w-11 rounded-full"></div>
            </label>
            <span class="ms-3 text-sm Gilroy-Bold">AR</span>
        </div>
    </div>

    <div class="flex-1 flex flex-col sidebarContent overflow-y-auto overflow-x-hidden">
      <a routerLink='/plus/budget' routerLinkActive='primary-active' class="2xl:py-2 py-1 px-4 Gilroy-Bold text-gray-300 inline-flex items-center 2xl:text-base text-sm">
        <span class="opacity-0 bg-white w-1 h-10 me-4 rounded-full"></span>
        <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
        </svg> -->
        {{'P/L Budget' | translate}}
      </a>
      <a routerLink='/plus/financedashboard' routerLinkActive='primary-active' class="2xl:py-2 py-1 px-4 Gilroy-Bold text-gray-300 inline-flex items-center 2xl:text-base text-sm">
        <span class="opacity-0 bg-white w-1 h-10 me-4 rounded-full"></span>
        <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
        </svg> -->
        {{'P/L Analysis' | translate}}
      </a>
      <a routerLink='/plus/salesdashboard' routerLinkActive='primary-active' class="2xl:py-2 py-1 px-4 Gilroy-Bold text-gray-300 inline-flex items-center 2xl:text-base text-sm">
        <span class="opacity-0 bg-white w-1 h-10 me-4 rounded-full"></span>
        <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
        </svg> -->
        {{'Sales Dashboard' | translate}}
      </a>
        <!-- <a routerLink='/plus/cashflowdashboard' routerLinkActive='primary-active' class="2xl:py-2 py-1 px-4 Gilroy-Bold text-gray-300 inline-flex items-center 2xl:text-base text-sm"> -->
          <!-- <span class="opacity-0 bg-white w-1 h-10 me-4 rounded-full"></span> -->
          <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
          </svg> -->
          <!-- {{'Cash Flow Dashboard' | translate}} -->
        <!-- </a> -->

        <a routerLink='/dashboard' routerLinkActive='primary-active' class="2xl:py-2 py-1 px-4 Gilroy-Bold text-gray-300 inline-flex items-center 2xl:text-base text-sm">
          <span class="opacity-0 bg-white w-1 h-10 me-4 rounded-full"></span>
          <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
          </svg> -->
          {{'Dashboard' | translate}}
        </a>
    </div>
    <div class="h-16 bottom-0 px-2 py-2 w-full cursor-pointer">
        <div class="relative">
            <div (click)='open = !open' (clickOutside)='open = false' class="flex flex-row border-t border-gray-300 p-2 items-center">
                <img *ngIf="user$ | async as user" [src]="user.profileImageName ? baseURL+user.profileImageName : 'assets/images/avatar.jpg'" onerror="this.src='assets/images/avatar.jpg'" width="40" class="rounded-full me-2" alt="avatar" srcset="">
                <div class="flex flex-col">
                    <div class="Gilroy-Bold break-words text-white" *ngIf="user$ | async as user">{{user.firstName}} {{user.lastName}}</div>
                </div>
            </div>
            <div *ngIf='open' (click)='open = true'
            class="bg-white absolute ltr:left-0 rtl:right-0 w-full bottom-20 rounded-xl dropdown-shadow focus:outline-none">
                <span (click)='logout()' class="flex items-center px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 hover:rounded-xl cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 me-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clip-rule="evenodd" />
                </svg>
                {{'Logout' | translate}}
                </span>
            </div>
        </div>
    </div>
</div>

</ng-template>
