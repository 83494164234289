<div class="flex space-x-2 p-4 px-9 py-12 bg-white rounded-lg">
    <div class="w-3/4 mx-5">
      <div class="flex items-center justify-between">
        <h1 class="text-2xl Poppins-Medium inline-flex items-center">
          #{{ invoiceData?.recurringInvoiceNumber ?? "" }}
        </h1>
        <div class="space-x-2 flex gap-2 rtl:space-x-reverse">
          <app-button
            *ngIf="!invoiceData?.isCanceled && !invoiceData?.isEnded"
            type="danger"
            buttonText="{{ 'Cancel Recurring' | translate }}"
            size="large"
            (handleClick)="showStopModal = true"
          ></app-button>
          <app-button
            *ngIf="!invoiceData?.isCanceled && !invoiceData?.isEnded"
            type="third"
            buttonText="{{ 'Update End Date' | translate }}"
            size="large"
            (handleClick)="showExtendModal = true"
          ></app-button>
          <app-button
            appAccessControl
            moduleType="sales"
            accessType="edit"
            type="primary"
            buttonText="{{ 'Create another Invoice' | translate }}"
            size="large"
            routerLink="../create-invoice"
          ></app-button>
        </div>
      </div>
      <div class="flex justify-between p-6 my-3">
        <div class="flex space-x-8 rtl:space-x-reverse">
          <div>
            <p class="Poppins-Medium text-lg">{{ "Status" | translate }}</p>
            <app-button
              [customClass]="
                (!invoiceData?.isCanceled ? 'bg-green-400' : 'bg-red-800') +
                ' cursor-auto'
              "
              type="primary"
            >
              {{
                (invoiceData?.isCanceled
                  ? "Stopped"
                  : invoiceData?.isEnded
                  ? "Completed"
                  : "Active"
                ) | translate
              }}
            </app-button>
          </div>
          <div>
            <p class="Poppins-Medium text-lg">{{ "Customer" | translate }}</p>
            <h3 class="Poppins-Bold text-xl">
              {{
                invoiceData?.customerDetails?.customerId?.customerName | titlecase
              }}
            </h3>
          </div>
        </div>
        <div class="flex space-x-8 rtl:space-x-reverse">
          <div>
            <p class="Poppins-Medium text-lg">
              {{ "Payment Terms" | translate }}
            </p>
            <h3 class="Poppins-Bold text-xl">
              {{
                invoiceData?.paymentTerm +
                  " (" +
                  invoiceData?.dueDateDuration +
                  " Days)"
              }}
            </h3>
          </div>
        </div>
      </div>
      <div
        class="flex items-center justify-between new-design-round-border p-6 my-3"
      >
        <div class="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-6 h-6 me-4"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="#1c1c1c"
          >
            <path
              d="M320-240h320v-80H320v80Zm0-160h320v-80H320v80ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520v-200H240v640h480v-440H520ZM240-800v200-200 640-640Z"
            />
          </svg>
          <div>
            <p class="Poppins-Medium text-lg">{{ "Create" | translate }}</p>
            <h4 class="Poppins-Medium text-lg">
              {{ "Created:" | translate }}
              {{ invoiceData?.createdAt | date : "longDate" }}
            </h4>
          </div>
        </div>
        <div class="justify-self-end">
          <p class="Poppins-Medium text-lg">{{ "Frequency" | translate }}</p>
          <h4 class="Poppins-Medium text-lg">{{ invoiceData?.frequency }}</h4>
        </div>
      </div>
      <div class="new-design-round-border p-6 my-3">
        <div>
          <p class="Poppins-Medium text-lg">
            {{
              invoiceData?.isCanceled
                ? "Canceled Date"
                : invoiceData?.isEnded
                ? "Completion Date"
                : ("Next Invoice Date" | translate)
            }}
          </p>
          <h4 class="Poppins-Medium text-lg">
            {{
              (invoiceData?.isCanceled
                ? invoiceData?.cancelDate
                : invoiceData?.isEnded
                ? invoiceData?.endDate
                : invoiceData?.nextInvoiceDate
              ) | date : "longDate"
            }}
          </h4>
        </div>
      </div>
      <div class="new-design-round-border my-4 p-6 flex flex-col justify-between">
        <app-file-upload
          appAccessControl
          moduleType="storage"
          *ngIf="showFiles"
          type="compact"
          tableType="new"
          accessType="view"
          [addedFiles]="addedFiles"
          (onUpload)="uploadFiles()"
          (emitter)="saveFiles($event)"
        ></app-file-upload>
      </div>
      <div>
        <!-- <app-template-one *ngIf='customizationSettings?.selectedTemplate === "modern"' [invoiceData]='invoiceData2'></app-template-one>
          <app-template-two *ngIf='customizationSettings?.selectedTemplate === "classic"' [invoiceData]='invoiceData2'></app-template-two>
          <app-template-three *ngIf='customizationSettings?.selectedTemplate === "contemporary"' [invoiceData]='invoiceData2'></app-template-three> -->
        <div class="w-full">
          <div
            class="p-8 text-white"
            style="border-bottom: 1px solid #0c4d71; background-color: #0c4d71"
          >
            <table class="w-full border-collapse">
              <tr>
                <td vAlign="top" class="w-1/2 ps-8">
                  <div class="text-3xl Poppins-Bold uppercase">
                    {{ invoiceData?.title }}
                  </div>
                  <div class="text-xl">{{ invoiceData?.subHeading }}</div>
                </td>
                <td vAlign="top" class="pe-8 text-end">
                  <div
                    *ngIf="invoiceData?.companylogo"
                    class="w-[200px] h-[112px] mb-2 ms-auto"
                  >
                    <img [src]="invoiceData?.companylogo" alt="" />
                  </div>
                  <div class="Poppins-Bold text-lg">
                    {{ invoiceData?.businessDetails?.businessId?.companyName }}
                  </div>
                  <div>
                    {{
                      invoiceData?.businessDetails?.businessId?.address
                        ?.addressLineFirst
                    }}
                  </div>
                  <div>
                    {{
                      invoiceData?.businessDetails?.businessId?.address
                        ?.addressLine2
                    }}
                  </div>
                  <div>
                    {{
                      invoiceData?.businessDetails?.businessId?.address?.city
                        ? invoiceData?.businessDetails?.businessId?.address
                            ?.city + ","
                        : ""
                    }}
                    {{
                      invoiceData?.businessDetails?.businessId?.address?.state ||
                        ""
                    }}
                  </div>
                  <div>
                    {{ invoiceData?.businessDetails?.businessId?.country }}
                  </div>
                  <div>
                    {{
                      invoiceData?.businessDetails?.businessId?.entityId
                        ? "Entity ID -" +
                          invoiceData?.businessDetails?.businessId?.entityId
                        : ""
                    }}
                  </div>
                  <div>
                    {{
                      invoiceData?.businessDetails?.businessId?.taxId
                        ? "Tax ID -" +
                          invoiceData?.businessDetails?.businessId?.taxId
                        : ""
                    }}
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="py-8 px-12">
            <table class="w-full border-collapse">
              <tr>
                <td class="w-1/2">
                  <table>
                    <tr>
                      <td
                        valign="top"
                        class="Poppins-Bold"
                        style="color: #0c4d71"
                      >
                        {{ "BILL TO" | translate }}
                      </td>
                      <td class="ps-4 text-start">
                        <div>
                          {{
                            invoiceData?.customerDetails?.customerId?.customerName
                              | titlecase
                          }}
                        </div>
                        <div>
                          {{
                            invoiceData?.customerDetails?.customerId
                              ?.billingAddress?.addressLine1
                          }}
                        </div>
                        <div>
                          {{
                            invoiceData?.customerDetails?.customerId
                              ?.billingAddress?.addressLine2
                          }}
                        </div>
                        <div>
                          {{
                            invoiceData?.customerDetails?.customerId
                              ?.billingAddress?.city
                          }},
                          {{
                            invoiceData?.customerDetails?.customerId
                              ?.billingAddress?.state
                          }}
                        </div>
                        <div>
                          {{
                            invoiceData?.customerDetails?.customerId
                              ?.billingAddress?.postalCode
                          }}
                        </div>
                        <div>
                          {{
                            invoiceData?.customerDetails?.customerId
                              ?.billingAddress?.country
                          }}
                        </div>
                        <div>
                          {{
                            invoiceData?.customerDetails?.customerId?.mobileNumber
                          }}
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table class="border-collapse text-end w-full">
                    <tr>
                      <td class="Poppins-Bold" style="color: #0c4d71">
                        {{ "Recurring Invoice Number" | translate }}
                      </td>
                      <td class="ps-4">
                        {{ invoiceData?.recurringInvoiceNumber }}
                      </td>
                    </tr>
                    <tr>
                      <td class="Poppins-Bold" style="color: #0c4d71">
                        {{ "PO Number" | translate }}
                      </td>
                      <td class="ps-4">{{ invoiceData?.purchaseOrder }}</td>
                    </tr>
                    <tr>
                      <td class="Poppins-Bold" style="color: #0c4d71">
                        {{ "Start Date" | translate }}
                      </td>
                      <td class="ps-4">{{ invoiceData?.startDate | date }}</td>
                    </tr>
                    <tr>
                      <td class="Poppins-Bold" style="color: #0c4d71">
                        {{ "End Date" | translate }}
                      </td>
                      <td class="ps-4">{{ invoiceData?.endDate | date }}</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table
              class="w-full"
              style="
                margin-top: 2em;
                margin-bottom: 2em;
                border-bottom: 1px solid black;
              "
            >
              <thead>
                <tr>
                  <td
                    class="Poppins-Bold p-2 text-white"
                    style="background-color: #0c4d71"
                  >
                    {{ "Item Name" | translate }}
                  </td>
                  <td
                    class="Poppins-Bold p-2 text-white"
                    style="background-color: #0c4d71"
                  >
                    {{ "Description" | translate }}
                  </td>
                  <td
                    class="Poppins-Bold p-2 text-white"
                    style="background-color: #0c4d71"
                  >
                    {{ "SKU" | translate }}
                  </td>
                  <td
                    class="Poppins-Bold p-2 text-white"
                    style="background-color: #0c4d71"
                  >
                    {{ "HSN/SAC" | translate }}
                  </td>
                  <td
                    class="Poppins-Bold p-2 text-white"
                    style="background-color: #0c4d71"
                  >
                    {{ "Quantity" | translate }}
                  </td>
                  <td
                    class="Poppins-Bold p-2 text-white"
                    style="background-color: #0c4d71"
                  >
                    {{ "Unit Price" | translate }}
                  </td>
                  <td
                    class="Poppins-Bold p-2 text-white"
                    style="background-color: #0c4d71"
                  >
                    {{ "Tax" | translate }}
                  </td>
                  <td
                    class="Poppins-Bold p-2 text-white"
                    style="background-color: #0c4d71"
                  >
                    {{ "Total Amount" | translate }}
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr class="" *ngFor="let item of invoiceData?.items">
                  <td class="p-2">{{ item.item }}</td>
                  <td class="p-2 break-words">
                    {{ item.itemDescription || "-" }}
                  </td>
                  <td class="p-2">{{ item.sku }}</td>
                  <td class="p-2">{{ item.hsn_sac }}</td>
                  <td class="p-2">{{ item.unit }}</td>
                  <td class="p-2">
                    {{
                      item.price
                        | currency
                          : invoiceData?.currencyDetails?.currencySymbol
                          : "symbol-narrow"
                          : number
                    }}
                  </td>
                  <td class="p-2" *ngIf="item?.tax?.tax">
                    {{ item?.tax?.tax }}%
                  </td>
                  <td class="p-2" *ngIf="!item?.tax?.tax">No Tax</td>
                  <td class="p-2">
                    {{
                      item.unit * item.price
                        | currency
                          : invoiceData?.currencyDetails?.currencySymbol
                          : "symbol-narrow"
                          : number
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="w-full mb-8">
              <tr>
                <td class="w-4/6"></td>
                <td class="w-2/6">
                  <table class="w-full">
                    <tr>
                      <td class="Poppins-Bold" style="color: #0c4d71">
                        {{ "Subtotal" | translate }}
                      </td>
                      <td class="text-end">
                        {{
                          invoiceData?.subtotal
                            | currency
                              : invoiceData?.currencyDetails?.currencySymbol
                              : "symbol-narrow"
                              : number
                        }}
                      </td>
                    </tr>
                    <tr *ngIf="invoiceData?.discount">
                      <td class="">
                        {{ "Discount:" | translate }} ({{
                          invoiceData?.discount | number : number
                        }}%)
                      </td>
                      <td class="text-end">
                        -{{
                          invoiceData?.subtotal - invoiceData?.discountValue
                            | currency
                              : invoiceData?.currencyDetails?.currencySymbol
                              : "symbol-narrow"
                              : number
                        }}
                      </td>
                    </tr>
                    <tr *ngIf="invoiceData?.discount">
                      <td class="">{{ "Discounted Subtotal" | translate }}</td>
                      <td class="text-end">
                        {{
                          invoiceData?.discountValue
                            | currency
                              : invoiceData?.currencyDetails?.currencySymbol
                              : "symbol-narrow"
                              : number
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="pb-2 Poppins-Bold" style="color: #0c4d71">
                        {{ "Tax" | translate }}
                      </td>
                      <td class="pb-2 text-end">
                        {{
                          invoiceData?.tax
                            | currency
                              : invoiceData?.currencyDetails?.currencySymbol
                              : "symbol-narrow"
                              : number
                        }}
                      </td>
                    </tr>
                    <tr class="border-t">
                      <td class="Poppins-Bold pt-2" style="color: #0c4d71">
                        {{ "Total" | translate }}
                      </td>
                      <td class="Poppins-Bold pt-2 text-end">
                        {{
                          invoiceData?.totalAmount
                            | currency
                              : invoiceData?.currencyDetails?.currencySymbol
                              : "symbol-narrow"
                              : number
                        }}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
  
            <div
              *ngIf="invoiceData?.notesTerms"
              class="Poppins-Bold"
              style="color: #0c4d71"
            >
              {{ "Terms and Conditions" | translate }}
            </div>
            <pre
              *ngIf="invoiceData?.notesTerms"
              id="pre"
              class="whitespace-pre-wrap Poppins"
              >{{ invoiceData?.notesTerms }}</pre
            >
            <div *ngIf="invoiceData?.eSign" class="flex">
              <div *ngIf="invoiceData?.eSign" class="max-w-sm mr-5 mb-5">
                <h3 class="block text-sm Poppins-Bold font-color-05 mb-2">
                  {{ "Digitally signed document" | translate }}
                </h3>
                <div class="px-8 py-5 rounded-xl items-center justify-center">
                  <img class="w-300" src="{{ invoiceData?.eSign }}" />
                </div>
              </div>
            </div>
            <div class="flex border rounded-xl my-5">
              <div *ngIf="invoiceData?.qrCodeWithUuid" class="w-1/4 p-5">
                <h3 class="block text-sm Poppins-Bold font-color-05 mb-2">
                  {{ "E-Invoice" | translate }}
                </h3>
                <div class="">
                  <img src="{{ invoiceData?.qrCodeWithUuid }}" />
                </div>
              </div>
              <div *ngIf="invoiceData?.qrCode" class="w-1/4 p-5">
                <h3 class="block text-sm Poppins-Bold font-color-05 mb-2">
                  {{ invoiceData?.qrHeading }}
                </h3>
                <div class="">
                  <img src="{{ invoiceData?.qrCode }}" />
                </div>
              </div>
            </div>
            <div class="w-full justify-end flex space-x-2 rtl:space-x-reverse">
              <h5 class="Poppins-Bold">{{ "Issued By" | translate }}:</h5>
              <div>
                {{
                  invoiceData?.userId?.firstName
                    ? invoiceData?.userId?.firstName +
                      " " +
                      invoiceData?.userId?.lastName
                    : ""
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-1/4 border-s border-gray-200 ps-10">
      <h2 class="Poppins-Bold text-2xl inline-flex items-center">
        {{ "Invoices Created" | translate }} -
      </h2>
      <div class="space-y-3 mt-5">
        <ul>
          <li
            (click)="viewInvoice(invoice?._id)"
            class="list-disc border-custom px-3 py-2 rounded flex justify-between items-center box-shadow"
            *ngFor="let invoice of createdInvoices; let i = index"
          >
            <a
              target="_blank"
              class="Poppins-SemiBold text-blue-600 hover:underline cursor-pointer text-lg"
              >{{ invoice?.invoiceNumber }}</a
            >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4 h-4 me-2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
              />
            </svg>
          </li>
        </ul>
      </div>
    </div>
  </div>
  
  <app-modal-container [isOpen]="showStopModal">
    <div header class="Poppins-Bold">
      {{ "Stop Recurring Invoice" | translate }}
    </div>
    <div class="space-y-2" content>
      <p class="Poppins-SemiBold">{{ "Are you Sure?" | translate }}</p>
      <p class="Poppins">
        {{
          "Stopping recurring invoice will not create any invoice from this template in NuMetirc"
            | translate
        }}
      </p>
    </div>
    <div footer class="flex gap-2">
      <app-button
        type="danger"
        buttonText="{{ 'Stop' | translate }}"
        (handleClick)="stopRecurringInvoice()"
      ></app-button>
      <app-button
        type="third"
        buttonText="{{ 'Cancel' | translate }}"
        (handleClick)="showStopModal = false"
      ></app-button>
    </div>
  </app-modal-container>
  
  <app-modal-container [isOpen]="showExtendModal">
    <div header class="Poppins-Bold">{{ "Select New End Date" | translate }}</div>
    <div content class="flex flex-col" style="overflow-y: auto">
      <label for="">{{ "New end date" | translate }}</label>
      <input
        [formControl]="updatedDate"
        [min]="minDate"
        type="date"
        class="w-1/3 new-date"
      />
      <div
        *ngIf="updatedDate.getError('required') && updatedDate.dirty"
        class="text-sm text-red-400 ms-2"
      >
        {{ "End date is required" | translate }}
      </div>
    </div>
    <div footer class="flex gap-x-2">
      <app-button
        type="primary"
        buttonText="{{ 'Save' | translate }}"
        (handleClick)="updateInvoiceDate()"
      ></app-button>
      <app-button
        type="third"
        buttonText="{{ 'Cancel' | translate }}"
        (handleClick)="showExtendModal = false"
      ></app-button>
    </div>
  </app-modal-container>
  