<div class="w-full p-8">
    <div class="bg-white px-14 py-11 rounded-xl flex space-x-4">
      <div class="w-3/4 flex flex-col space-y-4">
        <div class="flex items-center ps-5 pt-4 pr-7 justify-between">
          <div class="">
            <h1 class="text-2xl Poppins-medium inline-flex items-center">
              #{{ jvDetails?.recurringJVId }}
            </h1>
          </div>
          <div class="flex gap-2">
            <app-button
              type="primary"
              buttonText="Upload Files"
              size="large"
              (handleClick)="uploadFiles()"
            ></app-button>
            <app-button
              type="primary"
              *ngIf="!jvDetails?.isCanceled && !jvDetails?.isEnded"
              buttonText="Update End Date"
              (handleClick)="showExtendModal = true"
            ></app-button>
            <app-button
              *ngIf="!jvDetails?.isCanceled && !jvDetails?.isEnded"
              buttonText="Cancel Recurring JV"
              (handleClick)="showCancelModal = true"
              type="danger"
            ></app-button>
            <app-button
              type="primary"
              [customClass]="
                (!jvDetails?.isCanceled ? 'bg-green-400' : 'bg-red-800') +
                ' cursor-auto'
              "
              [buttonText]="
                jvDetails?.isCanceled
                  ? 'Stopped'
                  : jvDetails?.isEnded
                  ? 'Completed'
                  : 'Active'
              "
            ></app-button>
          </div>
        </div>
  
        <my-tabs class="px-2" className="w-[37%]">
          <my-tab tabTitle="{{ 'Details' | translate }}">
            <div class="flex justify-between mb-4">
              <div class="flex gap-x-5 flex-1">
                <div
                  class="bg-proile px-3 py-2 rounded-xl flex flex-col flex-grow w-full"
                >
                  <fieldset disabled>
                    <label
                      for="startDate"
                      class="block text-sm Poppins-medium bg-transparent font-color-05 mb-2"
                      >{{ "Start Date" | translate }}</label
                    >
                    <!-- <input id='startDate' [value]="jvDetails?.startDate" type="date"
                                          class="w-full p-0 border-0 focus:ring-0"> -->
                    <p class="text-[20px] Poppins-SemiBold">
                      {{ jvDetails?.startDate | date : "dd-MM-YYYY" }}
                    </p>
                  </fieldset>
                </div>
                <div
                  class="bg-proile px-3 py-2 rounded-xl flex flex-col flex-grow w-full"
                >
                  <fieldset disabled>
                    <label
                      for="endDate"
                      class="block text-sm Poppins-medium bg-transparent font-color-05 mb-2"
                      >{{
                        (jvDetails?.isCanceled
                          ? "Cancel Date"
                          : jvDetails?.isCompleted
                          ? "Completed Date"
                          : "End Date"
                        ) | translate
                      }}</label
                    >
                    <p class="text-[20px] Poppins-SemiBold">
                      {{
                        (jvDetails?.isCanceled
                          ? jvDetails?.cancelDate
                          : jvDetails?.endDate
                        ) | date : "dd-MM-YYYY"
                      }}
                    </p>
                  </fieldset>
                </div>
                <div
                  class="bg-proile px-3 py-2 rounded-xl flex flex-col flex-grow w-full"
                >
                  <label
                    class="block text-sm Poppins-medium bg-transparent font-color-05 mb-2"
                    >{{ "Frequency" | translate }}</label
                  >
                  <fieldset>
                    <p class="text-[20px] Poppins-SemiBold">
                      {{ jvDetails?.frequency }}
                    </p>
                  </fieldset>
                </div>
                <div
                  class="bg-proile px-3 py-2 rounded-xl flex flex-col flex-grow w-full"
                >
                  <label
                    for="description"
                    class="block text-sm Poppins-medium bg-transparent font-color-05 mb-2"
                    >{{ "Description" | translate }}</label
                  >
                  <p class="text-[20px] Poppins-SemiBold">
                    {{ jvDetails?.description }}
                  </p>
                </div>
              </div>
            </div>
            <div class="ps-5 pe-7">
              <table class="w-full">
                <tr>
                  <td class="p-3 bg-input Poppins-medium rounded-s-xl w-1/12">
                    {{ "Type" | translate }}
                  </td>
                  <td class="p-3 bg-input Poppins-medium w-3/12">
                    {{ "Description" | translate }}
                  </td>
                  <td class="p-3 bg-input Poppins-medium">
                    {{ "Account" | translate }}
                  </td>
                  <td class="p-3 bg-input Poppins-medium w-2/12">
                    {{ "Debit" | translate }}
                  </td>
                  <td
                    class="p-3 bg-input Poppins-medium w-2/12 last:rounded-e-xl"
                  >
                    {{ "Credit" | translate }}
                  </td>
                </tr>
                <ng-container>
                  <ng-container
                    *ngFor="let control of jvDetails?.journal; let i = index"
                  >
                    <tr class="">
                      <td class="w-2/12 p-2">
                        <!-- <ng-select [items]="['Credit', 'Debit']" class="custom-selector"
                                                  placeholder='{{"Select one" | translate }}'
                                                  [selecteed]="control?.type"
                                                  readonly>
                                              </ng-select> -->
                        <p>{{ control?.type }}</p>
                      </td>
                      <td class="w-3/12 p-2">
                        <!-- <input disabled [value]="control?.description" type="text"
                                                  class="w-full p-3 rounded-xl bg-input border-0" /> -->
                        <p>{{ control?.description }}</p>
                      </td>
                      <td class="p-2">
                        <!-- <ng-select [items]="accounts" class="custom-selector" bindLabel="accountName"
                                                  [selected]="control?." placeholder='{{"Select one" | translate }}'
                                                  groupBy='accountType' readonly>
                                              </ng-select> -->
                        <p>{{ control?.accountName }}</p>
                      </td>
                      <td class="p-2 w-2/12">
                        <fieldset disabled>
                          <input
                            [ngClass]="
                              control?.type === 'Credit' ? 'hidden' : 'block'
                            "
                            [value]="control?.debit"
                            type="number"
                            [class.new-input]="control?.type === 'Debit'"
                          />
                        </fieldset>
                      </td>
                      <td class="p-2 w-2/12">
                        <fieldset disabled>
                          <input
                            [ngClass]="
                              control?.type === 'Debit' ? 'hidden' : 'block'
                            "
                            [value]="control?.credit"
                            type="number"
                            [class.new-input]="control?.type === 'Credit'"
                          />
                        </fieldset>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="jvDetails?.journal.length === 0">
                    <tr>
                      <td colspan="7" class="text-center p-2">
                        {{ "No items added" | translate }}
                      </td>
                    </tr>
                  </ng-container>
                </ng-container>
                <tr>
                  <td vAlign="top" colspan="3"></td>
                  <td colspan="3" class="p-3 bg-[#F7F9FA] rounded-xl">
                    <div class="flex justify-between py-2">
                      <p class="">{{ "Total Debits" | translate }}</p>
                      <p></p>
                      <p class="">{{ "Total Credits" | translate }}</p>
                    </div>
                    <div class="flex justify-between">
                      <p class="text-lg Poppins-medium">
                        {{
                          debitTotal
                            | currency
                              : currencyDetails?.currency
                              : "symbol-narrow"
                              : number
                        }}
                      </p>
                      <p></p>
                      <p class="text-lg Poppins-medium">
                        {{
                          creditTotal
                            | currency
                              : currencyDetails?.currency
                              : "symbol-narrow"
                              : number
                        }}
                      </p>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </my-tab>
          <my-tab tabTitle="{{ 'Notes' | translate }}">
            <div class="px-6">
              <textarea
                name="notes"
                readonly
                id="notes"
                [value]="jvDetails?.description"
                placeholder="{{ 'Write notes here' | translate }}"
                class="new-input"
                cols="30"
                rows="11"
              ></textarea>
            </div>
          </my-tab>
          <my-tab
            appAccessControl
            moduleType="storage"
            accessType="view"
            tabTitle="{{ 'Files' | translate }}"
          >
            <app-file-upload
              [addedFiles]="addedFiles"
              (emitter)="saveFiles($event)"
              type="compact"
              tableType="new"
            ></app-file-upload>
          </my-tab>
        </my-tabs>
  
        <div class="flex items-center px-8 border-t py-4">
          <div class="ms-auto flex items-center">
            <span *ngIf="issuedBy" class="me-2">
              <span class="Poppins-medium me-2"
                >{{ "Issued By" | translate }} -</span
              >
              <span>{{ issuedBy }}</span>
            </span>
          </div>
        </div>
      </div>
      <div class="w-1/4 border-s border-gray-200 ps-10">
        <h2 class="Poppins-medium text-2xl inline-flex items-center">
          {{ "JV Created" | translate }} -
        </h2>
        <div class="space-y-3 mt-5">
          <ul>
            <li
              (click)="downloadJV(jv)"
              class="list-disc border-custom px-3 py-2 rounded flex justify-between items-center box-shadow"
              *ngFor="let jv of creatdJV; let i = index"
            >
              <a
                target="_blank"
                class="Poppins-Semimedium text-blue-600 hover:underline cursor-pointer text-lg"
                >{{ jv?.transactionNumber }}</a
              >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 me-2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m8.25 3v6.75m0 0l-3-3m3 3l3-3M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
                />
              </svg>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  
  <app-modal-container [isOpen]="showCancelModal">
    <div header class="Poppins-medium">
      {{ "Cancel Recurring JV" | translate }}
    </div>
    <div class="space-y-2" content>
      <p class="Poppins-Semimedium">{{ "Are you Sure?" | translate }}</p>
      <p class="Poppins">
        {{
          "Cancelling recurring JV will not create any JV from this template in NuMetirc"
            | translate
        }}
      </p>
    </div>
    <div footer class="flex gap-x-2">
      <app-button
        type="danger"
        buttonText="{{ 'Cancel' | translate }}"
        (handleClick)="stopRecurringJV()"
      ></app-button>
      <app-button
        type="third"
        buttonText="{{ 'Close' | translate }}"
        (handleClick)="showCancelModal = false"
      ></app-button>
    </div>
  </app-modal-container>
  
  <app-modal-container [isOpen]="showExtendModal">
    <div header class="Poppins-medium">
      {{ "Select New End Date" | translate }}
    </div>
    <div content class="flex flex-col" style="overflow-y: auto">
      <label for="">{{ "New end date" | translate }}</label>
      <input
        [formControl]="updatedDate"
        [min]="minDate"
        type="date"
        class="new-date"
      />
      <div
        *ngIf="updatedDate.getError('required') && updatedDate.dirty"
        class="text-sm text-red-400 ms-2"
      >
        {{ "End date is required" | translate }}
      </div>
      <div
        *ngIf="
          updatedDate.getError('invalidDate') &&
          updatedDate.dirty &&
          !updatedDate.getError('required')
        "
        class="text-sm text-red-400 ms-2"
      >
        {{ "End date must be in correct format" | translate }}
      </div>
    </div>
    <div footer class="flex gap-x-2">
      <app-button
        type="primary"
        buttonText="{{ 'Save' | translate }}"
        (handleClick)="updateEndDate()"
      ></app-button>
      <app-button
        buttonText="{{ 'Cancel' | translate }}"
        (handleClick)="showExtendModal = false"
      ></app-button>
    </div>
  </app-modal-container>
  