<div class="flex flex-col w-4/5 mx-auto">
    <app-button buttonText='{{"Download" | translate }}' type='primary' (click)='downloadPdf()'></app-button>
    <table class="d-block overflow-hidden box-shadow-sales rounded-xl my-5">
        <div class="p-12">
            <table class="w-full" style="border-collapse: collapse;">
                <tr>
                    <td valign='top'>
                        <div>
                            <h1 class="text-2xl mb-4 font-semibold">{{'Purchase Order' | translate}}</h1>
                        </div>
                    </td>
                    <td class="pe-8 text-end" style='width: 50%;'></td>
                </tr>
                <tr>
                    <td valign='top'>
                        <img src="{{customizationSettings?.companyLogo}}" width="200" alt="">
                        <div>{{purchaseOrderDetail?.businessId.companyName}}</div>
                        <div>{{purchaseOrderDetail?.businessId?.address?.addressLineFirst}}</div>
                        <div>{{purchaseOrderDetail?.businessId?.address?.addressLine2}}</div>
                        <div>{{purchaseOrderDetail?.businessId?.address?.city ?
                            purchaseOrderDetail?.businessId?.address?.city + ',' : ''}}
                            {{purchaseOrderDetail?.businessId?.address?.state}}</div>
                        <div>{{purchaseOrderDetail?.businessId.country}}</div>
                    </td>
                    <td class="text-end pe-8" style='width: 50%;'>

                    </td>
                </tr>
            </table>
        </div>
        <div class="px-12">
            <table class="w-full" style="border-collapse: collapse;">
                <tr>
                    <td>
                        <table>
                            <tr>
                                <td>
                                    <div class="Gilroy-Bold">{{'BILL TO' | translate}}</div>
                                    <div>{{purchaseOrderDetail?.vendor?.vendorName}}</div>
                                    <div>{{purchaseOrderDetail?.vendor?.vendorId?.email}}</div>
                                    <div>{{purchaseOrderDetail?.vendor?.vendorId?.address?.addressLine1}}</div>
                                    <div>{{purchaseOrderDetail?.vendor?.vendorId?.address?.addressLine2}}</div>
                                    <div>{{purchaseOrderDetail?.vendor?.vendorId?.address?.city}},
                                        {{purchaseOrderDetail?.vendor?.vendorId?.address?.state}}</div>
                                    <div>{{purchaseOrderDetail?.vendor?.vendorId?.address?.postalCode}}</div>
                                    <div>{{purchaseOrderDetail?.vendor?.vendorId?.address?.country}}</div>
                                </td>
                            </tr>
                        </table>
                    </td>
                    <td class="w-1/2">
                        <table class="w-full" style="border-collapse: collapse;">
                            <tr>
                                <td class="Gilroy-Bold">{{'P.O. Number:' | translate}}</td>
                                <td class="text-end ps-4">{{purchaseOrderDetail?.purchaseOrder}}</td>
                            </tr>
                            <tr>
                                <td class="Gilroy-Bold">{{'P.O Date:' | translate }}</td>
                                <td class="text-end ps-4">{{purchaseOrderDetail?.date | date}}</td>
                            </tr>
                            <tr>
                                <td class="Gilroy-Bold">{{'Due Date:' | translate}}</td>
                                <td class="text-end ps-4">{{purchaseOrderDetail?.dueDate | date}}</td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
            <table class="w-full my-8" style="margin-top: 2em; margin-bottom: 2em; border-bottom: 1px solid #0C4D71;">
                <thead class="bg-light-gray-1 h-12 ps-2 pe-2">
                    <tr>
                        <td align="left" class="Gilroy-Bold ps-2">{{'Item Name' | translate}}</td>
                        <td align="center" class="Gilroy-Bold">{{'Quantity' | translate}}</td>
                        <td align="center" class="Gilroy-Bold">{{'Unit Price' | translate}}</td>
                        <td align="center" class="Gilroy-Bold">{{'Tax' | translate}}</td>
                        <td align="right" class="Gilroy-Bold pe-2">{{'Total Amount' | translate}}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr class="row-1" *ngFor="let item of purchaseOrderDetail?.items">
                        <td align="left" style="padding: 0.5em;">
                            <h2 class="Poppins-Bold">{{item.item}}</h2>
                        </td>
                        <td align="center" style="padding: 0.5em;">{{item.unit}}</td>
                        <td align="center" style="padding: 0.5em;">{{item.price | currency: currencyDetails?.currency :
                            'symbol-narrow' : number}}</td>
                        <td align="center" class="p-2" *ngIf='item?.tax?.tax'>{{item?.tax?.tax}}%</td>
                        <td align="center" class="p-2" *ngIf='!item?.tax?.tax'>No Tax</td>
                        <td align="right" style="padding: 0.5em;">{{item.unit * item.price | currency:
                            currencyDetails?.currency : 'symbol-narrow' : number}}</td>
                    </tr>
                </tbody>
            </table>
            <table class="w-full" style="margin-bottom: 2em;">
                <tr>
                    <td style="width: 65%;"></td>
                    <td align="right" style="width: 35%;">
                        <table class="w-full">
                            <tr>
                                <td>{{'Subtotal:' | translate}}</td>
                                <td align="right">{{purchaseOrderDetail?.subTotal | currency: currencyDetails?.currency
                                    : 'symbol-narrow' : number}}</td>
                            </tr>
                            <tr *ngIf='purchaseOrderDetail?.tax'>
                                <td style="padding-bottom: 0.5em;">{{'Tax' | translate}}</td>
                                <td align="right" style="padding-bottom: 0.5em;">{{purchaseOrderDetail?.tax | currency:
                                    currencyDetails?.currency : 'symbol-narrow' : number}}</td>
                            </tr>
                            <tr class="border-top" style="border-top: 1px solid #0C4D71;">
                                <td class="bold" style="font-weight: bold; padding-top: 0.5em;">{{'Total' | translate}}
                                </td>
                                <td align="right" class="bold" style="font-weight: bold; padding-top: 0.5em;">
                                    {{purchaseOrderDetail?.toatalAmount | currency: currencyDetails?.currency :
                                    'symbol-narrow' : number}}</td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </div>
        <div *ngIf='purchaseOrderDetail?.notes' class="px-16">
            <div class="Gilroy-Bold">{{'Notes' | translate}}</div>
            <pre class="whitespace-pre-wrap Gilroy">{{purchaseOrderDetail?.notes}}</pre>
        </div>
        <div class="flex justify-end space-x-2 mx-16 rtl:space-x-reverse">
            <h4 class="Gilroy-Bold">{{'Issued By'}}:</h4>
            <span>{{purchaseOrderDetail?.userId?.firstName+' '+purchaseOrderDetail?.userId?.lastName}}</span>
        </div>
    </table>
</div>