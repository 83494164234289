import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { SalesReducerState } from 'src/app/modules/sales/state/reducers';
import { selectProducts } from 'src/app/modules/sales/state/selectors/products.selector';
import { valueChanges, dateLessThan, DateValidator } from 'src/app/shared/utils/formValidator';
import { selectSalesTaxes } from 'src/app/modules/sales/state/selectors/salesTaxes.selector';
import { EstimatesService } from '../../estimates.service';
import { selectCustomers } from 'src/app/modules/sales/state/selectors/customers.selector';
import { ActivatedRoute, Router } from '@angular/router';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { takeUntil } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { RootReducerState } from 'src/app/store/reducers';
import { selectCustomizationSettings } from 'src/app/modules/sales/state/selectors/customizationSettings.selector';
import { SalesService } from 'src/app/modules/sales/sales.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { NumberService } from 'src/app/shared/services/number.service';
import { selectUserSubscription } from 'src/app/store/selectors/subscription.selectors';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';


interface StockCheck {
  item: string;
  openingStock: number,
  quantityAdded: number
} 

@Component({
  selector: 'app-create-estimate',
  templateUrl: './create-estimate.component.html',
  styleUrls: ['./create-estimate.component.scss'],
})

export class CreateEstimateComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private store: Store<SalesReducerState>,
    private rootStore: Store<RootReducerState>,
    private estimateService: EstimatesService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private router: Router,
    private numberService: NumberService,
    private salesService: SalesService,
    private fileUploadService: FileUploadService
  ) {
    this.business$ = this.rootStore.pipe(select(selectBusiness));
    this.products$ = this.store.pipe(select(selectProducts));
    this.salesTaxes$ = this.store.pipe(select(selectSalesTaxes));
    this.customers$ = this.store.pipe(select(selectCustomers));
    this.customizationSettings$ = this.store.pipe(select(selectCustomizationSettings));
    this.subscription$ = rootStore.pipe(select(selectUserSubscription));
  }

  estimateForm: FormGroup;
  products$: Observable<any>;
  business$: Observable<any>;
  salesTaxes$: Observable<any>;
  customers$: Observable<any>;
  subscription$: Observable<any>;
  unsubscribe$ = new Subject();
  customizationSettings$: Observable<any>;
  salesTaxes = [];
  update = false;
  business = null;
  businessId = null;
  products = [];
  availableProducts = [];
  customers = [];
  files: File[] = [];
  addedFiles = [];
  subscription = null
  currencyDetails ={
    currency: '',
    currencySymbol: ''
  }
  number = '1.2-2';
  error = '';
  estimateToUpdate = null;
  response = '';
  productSelect;
  customizationSettings = {
    companyLogo: ''
  };
  
  formErrors = {
    estimateNumber: '',
    customerId: '',
    date: '',
    expiryDate: '',
    purchaseOrder: '',
    subheading: '',
    footer: '',
    memo: '',
    quantity: ''
  };

  formErrorMessages = {
    estimateNumber: {
      required: 'Estimate is Required',
    },
    customerId: {
      required: 'Customers is Required',
    },
    date: {
      required: 'Date is Required',
      invalidDate: 'Invalid Date'
    },
    expiryDate: {
      required: 'Expiry Date is Required',
      dates: 'Invalid date',
      invalidDate: 'Invalid Date'
    },
    purchaseOrder: {
      required: 'Purchase Order is Required',
    },
    subheading: {
      required: 'Sub heading is Required',
    },
    footer: {
      required: 'Footer is Required',
    },
    memo: {
      required: 'Memo is Required',
    },
    quantity: {
      min: 'Quantity should be atleast 1'
    }
  };

  outOfStock: boolean = false;
  checkQuantityInStock : Array<StockCheck | null> = []

  subTotal = 0;
  totalTax = 0;
  totalAmount = 0;
  averageTotalAmount = 0;

  ngOnInit(): void {
    this.getCurrencyDetails();
    this.loadForm();
    this.loadSubscription();
    this.loadSalesTaxes();
    this.loadCustomers();
    this.loadCustomizationSettings();
    this.loadNumberConfig();
    this.loadBusiness();
    this.route.queryParams.subscribe(({ id }) => {
      if (id) {
        this.estimateToUpdate = id;
        this.spinner.show();
        this.estimateService.getEstimate(id).subscribe(
          (resp) => {
            this.update = true;
            this.spinner.hide();
            this.appendEstimateData(resp.data);
          },
          (error) => {
            this.spinner.hide();
            this.toastr.error(this.translateService.instant('Something went wrong!'));
          }
        );
      } else {
        this.loadEstimateNumber();
      }
    });
  }

  loadBusiness(): void{
    this.business$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((business) => {
        this.business = business?.businessId;
        this.businessId = business.businessId._id;
    });
  }

  loadSubscription(): void {
    this.subscription$.pipe(takeUntil(this.unsubscribe$))
    .subscribe(({planName}) => {
      this.subscription = planName;
      this.products$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(products => {
        if(this.subscription === 'Retail' || this.subscription === 'Retail Plus'){
          products = products?.filter(product => product?.isReviewed)
        }
        this.products = products?.filter((item) => {
          if (item?.itemType === 'Service' && item?.isSale) {
            return false;
          }
          return true;
        });

        this.availableProducts = [... this.products];
      });
    })
  }

  loadNumberConfig(): void {
    this.numberService.number
    .pipe((takeUntil(this.unsubscribe$)))
    .subscribe((number) => {
      this.number = number;
    })
  }

  getCurrencyDetails():void {
    this.salesService.currencyDetail.subscribe(details=>{
      if(details)
        this.currencyDetails = details;
    })
  }

  appendEstimateData(estimateData): void {
    const {
      estimateNumber,
      customerDetails: {
        customerId: { _id },
      },
      date,
      expiryDate,
      purchaseOrder,
      subheading ='',
      footer ='',
      memo ='',
      subTotal,
      tax,
      totalAmount,
      items,
      averageTotalAmount = 0,
      files = []
    } = estimateData;
    const mappedItems = items.map(
      ({ item, itemId, description, quantity, type, isSale = false, cogsAccountDetails = null, inventoryAccountDetails = null, tax, sku='', hsn_sac='', unitPrice, totalCost, accountDetails, averagePrice }) => {
        return { item, itemId, description, sku, hsn_sac, quantity, tax, type, unitPrice, isSale, cogsAccountDetails, inventoryAccountDetails, totalCost, accountDetails, averagePrice: (averagePrice || unitPrice) };
      }
    );
    const data = {
      estimateNumber,
      date: date?.split('T')[0],
      expiryDate: expiryDate?.split('T')[0],
      purchaseOrder,
      subheading,
      footer,
      memo,
      items: mappedItems,
      customerId: _id,
    };
    mappedItems.forEach(() => this.items.push(this.createItem()));
    this.estimateForm.setValue(data);
    this.addedFiles = files;
    this.subTotal = subTotal;
    this.totalAmount = totalAmount;
    this.totalTax = tax;
    this.averageTotalAmount = averageTotalAmount;
    this.updateAvailableItems();
  }

  loadEstimateNumber(): void {
    this.estimateService.getEstimateNumber().subscribe(
      (resp) => {
        if (resp.success) {
          this.estimateForm.get('estimateNumber').setValue(resp.data);
        }
      },
      (error) => {
        this.router.navigate(['/sales/estimates']);
      }
    );
  }

  loadSalesTaxes(): void {
    this.salesTaxes$.subscribe((taxes) => {
      this.salesTaxes = taxes.map((tax) => ({
        ...tax,
        name: tax.taxName,
        tax: tax.taxRate,
      }));
    });
  }

  loadProducts(): void {
    this.products$.subscribe((products) => {
      this.products = products?.filter(item => item?.isReviewed)?.map((product) => ({
        ...product,
        text: product.name,
      }));
    });
  }

  loadCustomers(): void {
    this.customers$.subscribe((customers) => {
      this.customers = customers?.filter(customer => customer?.isValidate);
  });
  }

  loadCustomizationSettings(): void {
    this.customizationSettings$.subscribe((settings) => {
      if (settings) {
        this.customizationSettings = settings;
        this.estimateForm.get('subheading').setValue(settings?.estimateSettings?.scheduling);
        this.estimateForm.get('memo').setValue(settings?.estimateSettings?.memo);
        this.estimateForm.get('footer').setValue(settings?.estimateSettings?.footer);
      }
    });
  }

  loadForm(): void {
    this.estimateForm = this.fb.group({
      estimateNumber: [null, [Validators.required]],
      customerId: [null, [Validators.required]],
      date: [null, [Validators.required, DateValidator()]],
      expiryDate: [null, [Validators.required, DateValidator()]],
      purchaseOrder: [null],
      subheading: [null],
      footer: [null],
      memo: [null],
      items: this.fb.array([]),
    }, {validator: dateLessThan('date', 'expiryDate')});

    this.estimateForm.valueChanges.subscribe(({ items }) => {
      this.formErrors = valueChanges(
        this.estimateForm,
        { ...this.formErrors },
        this.formErrorMessages,
        this.translateService
      );
      this.calculateTotal(items);
    });

    this.formErrors = valueChanges(
      this.estimateForm,
      { ...this.formErrors },
      this.formErrorMessages,
      this.translateService
    );
    
  }

  get items(): FormArray {
    return this.estimateForm.get('items') as FormArray;
  }

  calculateTotal(estimateItems): void {
    let subTotal = 0;
    let totalTax = 0;
    let averageTotalAmount = 0;
    estimateItems.forEach((item) => {
      const { unitPrice, quantity, tax, averagePrice } = item;
      subTotal += unitPrice * quantity;
      averageTotalAmount += (averagePrice || unitPrice) * quantity;
      if (tax) {
        totalTax += this.calculateItemTax(unitPrice * quantity, this.business?.isSalesTax ? tax : null);
      }
    });
    this.subTotal = this.numberService.toFixed(subTotal);
    this.totalTax = this.numberService.toFixed(totalTax);
    this.totalAmount = this.numberService.toFixed((subTotal + totalTax));
    this.averageTotalAmount = this.numberService.toFixed(averageTotalAmount)
  }

  createItem(): FormGroup {
    const itemForm = this.fb.group({
      item: null,
      itemId: '',
      sku: '',
      hsn_sac: '',
      description: '',
      type: '',
      unitPrice: 0,
      quantity: [1, Validators.min(1)],
      tax: null,
      totalCost: 0,
      accountDetails: null,
      cogsAccountDetails: null,
      isSale: false,
      inventoryAccountDetails: null,
      averagePrice: 0
    });
    return itemForm;
  }

  addNewItem(): void {
    this.items.push(this.createItem());
  }

  removeItem(index): void {
    this.items.removeAt(index);
    this.updateAvailableItems()
  }

  updateAvailableItems(){
    const allIds = this.items.value.map(item=>item.itemId)
    this.availableProducts = this.products.filter(el => !allIds.includes(el?._id));
  }

  changeEvent(event, index): void {
    const { _id, name, itemType, description, sellPrice, tax, accountDetails, sku, hsn, sac, isSale = false, inventoryAccountDetails = null, cogsAccountDetails = null, averagePrice = 0 } = event;
    this.items.controls[index].setValue({
      itemId:_id,
      item: name,
      sku,
      type: itemType,
      hsn_sac: hsn || sac,
      description,
      unitPrice: this.numberService.toFixed(sellPrice),
      tax: this.business?.isSalesTax ? tax : null,
      quantity: 1,
      totalCost: this.numberService.toFixed((sellPrice * 1)),
      accountDetails,
      isSale,
      cogsAccountDetails,
      inventoryAccountDetails,
      averagePrice: this.numberService.toFixed((averagePrice || sellPrice) * 1)
    });
    this.updateAvailableItems();
  }

  changeTaxEvent(event, index): void {
    const { unitPrice, quantity } = this.items.controls[index].value;
    this.items.controls[index].get('total').setValue(this.numberService.toFixed(unitPrice * quantity));
  }

  createEstimate(): void {
    if (this.estimateForm.invalid) {
      this.estimateForm.markAllAsTouched();
      this.formErrors = valueChanges(
        this.estimateForm,
        { ...this.formErrors },
        this.formErrorMessages,
        this.translateService
      );
      return;
    }
    const body: any = this.estimateDataMapper();
    if(this.business?.isSalesTax && body?.items?.some(item =>  item?.tax?.length === 0)){
      this.toastr.error(this.translateService.instant('All item must have taxes to proceed'));
      return;
    }

    if (body.items.length === 0) {
      this.spinner.hide();
      return;
    }

    if (body.items[0].item === null) {
      this.spinner.hide();
      return;
    }

    if(this.subscription === 'Retail' || this.subscription === 'Retail Plus' ){
      let stockCheck: Array<StockCheck | null> = [];
      body.items?.forEach(item => {
        const product = this.products.find(product => product?._id == item?.itemId);
        if(product && product?.stockDetails?.openingStock < item?.quantity){
          stockCheck.push({
            item: item?.item,
            openingStock: product?.stockDetails?.openingStock,
            quantityAdded: item?.quantity
          })
          console.log(stockCheck, this.checkQuantityInStock); 
        }
      })
      this.checkQuantityInStock = stockCheck;

      if(this.checkQuantityInStock.length){
        this.outOfStock = true;
        return
      }
    }

    this.spinner.show();
    this.fileUploadService.emitFiles.next(true)
    this.fileUploadService.emitFiles.next(false);
    const formData = new FormData();
    this.files.forEach((file, i) => {
      formData.append(`file${i}`, file)
    });
    formData.append('payload', JSON.stringify(body));
    this.estimateService.createEstimate(formData).subscribe(
      (resp) => {
        this.spinner.hide();
        this.estimateForm.reset();
        this.router.navigate(['/sales/estimates']);
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(this.translateService.instant('Something went wrong!'));
      }
    );
  }

  updateEstimate(): void {
    if (this.estimateForm.invalid) {
      this.estimateForm.markAllAsTouched();
      this.formErrors = valueChanges(
        this.estimateForm,
        { ...this.formErrors },
        this.formErrorMessages,
        this.translateService
      );
      return;
    }
    const body:any = {...this.estimateDataMapper(),_id:this.estimateToUpdate};
   
    if(this.subscription === 'Retail' || this.subscription === 'Retail Plus' ){
      let stockCheck: Array<StockCheck | null> = [];
      body.items?.forEach(item => {
        const product = this.products.find(product => product?._id == item?.itemId);
        if(product && product?.stockDetails?.openingStock < item?.quantity){
          stockCheck.push({
            item: item?.item,
            openingStock: product?.stockDetails?.openingStock,
            quantityAdded: item?.quantity
          })
        }
      })
      this.checkQuantityInStock = stockCheck;

      if(this.checkQuantityInStock.length){
        this.outOfStock = true;
        return
      }
    }
    
    this.spinner.show();
    this.fileUploadService.emitFiles.next(true)
    this.fileUploadService.emitFiles.next(false);
    const formData = new FormData();
    this.files.forEach((file, i) => {
      formData.append(`file${i}`, file)
    });
    formData.append('payload', JSON.stringify(body));
    this.estimateService.updateEstimate({formData, estimateId: this.estimateToUpdate}).subscribe(
      (resp) => {
        this.spinner.hide();
        this.estimateForm.reset();
        this.router.navigate(['/sales/estimates']);
      },
      () => {
        this.spinner.hide();
        this.toastr.error(this.translateService.instant('Something went wrong!'))
      }
    );
  }

  estimateDataMapper(): object {
    const body = {
      ...this.estimateForm.value,
      businessId:this.businessId,
      customerDetails: {
        customerId: this.estimateForm.value.customerId,
      },
      totalAmount: this.numberService.toFixed(this.totalAmount),
      averageTotalAmount: this.numberService.toFixed(this.averageTotalAmount),
      subTotal: this.numberService.toFixed(this.subTotal),
      tax: this.numberService.toFixed(this.totalTax),
      items: this.estimateForm.value.items?.map(item=>{
        return ({
          ...item,
          totalCost: this.numberService.toFixed((item?.unitPrice * item?.quantity)),
          unitPrice: this.numberService.toFixed(item?.unitPrice),
          tax: this.business?.isSalesTax ? item?.tax : null
        })
      })
    };
    if(body?.items?.some(item => !item.itemId)){
      this.toastr.error('Please select item for each line item before proceeding');
      return;
    }
    return body;
  }

  calculateItemTax(unitPrice, taxes): any {
    return [taxes].reduce((a, b) => (a += unitPrice * (b.tax / 100)), 0);
  }

  changeCustomer(event): void {
    this.estimateForm.get('customerId').setValue(event._id);
  }

  saveFiles(files: File[]): void {
    this.files = files;
  }

  changeTax(event, i) {
    console.log(event,i);
    if(this.items.length>0){
      if(event.length > 1){
        event.shift()
        this.items.controls[i].get('tax').setValue(event)
      }
    }
  }
}
