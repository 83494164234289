import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { RootReducerState } from 'src/app/store/reducers';
import { selectUser } from 'src/app/store/selectors/user.selectors';

@Component({
  selector: 'app-email-notifications',
  templateUrl: './email-notifications.component.html',
  styleUrls: ['./email-notifications.component.scss']
})
export class EmailNotificationsComponent implements OnInit {

  constructor(private store: Store<RootReducerState>,
              private fb: FormBuilder,
              private userService: UserService,
              private spinner: NgxSpinnerService) {
    this.user$ = this.store.pipe(select(selectUser));
  }

  user$: Observable<any>;
  user;
  notificationsForm: FormGroup;
  response = '';
  error = '';

  ngOnInit(): void {
    this.loadForm();
    this.user$.subscribe((user) => {
      this.user = user;
      if (user.notification) {
        this.loadForm(user.notification);
      }
    });
  }

  loadForm(notification?): void {
    this.notificationsForm = this.fb.group({
      sales: [notification?.sales ? notification.sales : false],
      purchase: [notification?.purchase ? notification.purchase : false],
      accounting: [notification?.accounting ? notification.accounting : false],
      payment: [notification?.payment ? notification.payment : false]
    });
  }

  updateNotifications(): void {
    this.error = '';
    this.spinner.show();
    this.userService.updateEmailNotifications({...this.notificationsForm.value}).subscribe((resp) => {
      this.spinner.hide();
      console.log(resp);
      if (resp.success) {
        this.spinner.show();
        let businessId = localStorage.getItem('selectedBusiness');
        this.userService.getUserDetails(businessId).subscribe((user) => {
          this.spinner.hide();
          this.userService.setUserDetails(user.data);
          this.response = 'Email notification settings updated';
        }, (error) => {
          this.spinner.hide();
        });
      }
      setTimeout(() => this.response = '', 2000);
    }, (error) => {
      this.error = error?.error?.message || 'Internal server error';
      this.spinner.hide();
    });
  }

}
