<div class="flex justify-center">
  <div class="w-full flex flex-col space- y-4">
    <div class="m-auto w-full">
      <form class="space-y-4 mb-4" [formGroup]="invoiceForm">
        <div class="px-9 py-12 rounded-lg bg-white">
          <div class="space-y-6 w-full">
            <div class="flex space-x-8 rtl:space-x-reverse w-full">
              <div class="mb-4 gap-4 grid grid-cols-2 gap-x-12 w-full">
                <div class="flex flex-col gap-[10px]">
                  <label class="block text-base Poppins-Medium mb-2">{{
                    "Invoice Summary" | translate
                  }}</label>
                  <fieldset [disabled]="!isFieldEditable">
                    <textarea
                      formControlName="subHeading"
                      placeholder="{{ 'Invoice Summary' | translate }}"
                      class="new-input"
                    ></textarea>
                  </fieldset>
                </div>
                <div class="flex flex-col gap-2">
                  <label
                    for="toggle"
                    class="flex gap-2 mt-12 cursor-pointer relative"
                  >
                    <input
                      type="checkbox"
                      formControlName="reminder"
                      id="toggle"
                      class="sr-only peer"
                    />
                    <div
                      [ngClass]="{
                        'after:bg-white': invoiceForm.get('reminder')?.value,
                        'after:bg-black bg-[#d9d9d9]':
                          !invoiceForm.get('reminder')?.value
                      }"
                      class="toggle-bg peer-checked:bg-black h-6 w-11 rounded-full"
                    ></div>
                    <span class="block text-base Poppins-Medium mb-2">{{
                      "Remind customers 3, 7, and 14 days after due date"
                        | translate
                    }}</span>
                  </label>
                  <p class="mb-4">
                    {{
                      "You can edit the reminders on individual invoices anytime."
                        | translate
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="rounded-lg px-9 py-12 bg-white">
          <div class="w-full space-y-8">
            <div class="grid grid-cols-4 gap-8 rtl:space-x-reverse">
              <div class="flex flex-col col-span-2 gap-[10px]">
                <label class="block text-base Poppins-Medium leading-5">{{
                  "Add a Customer" | translate
                }}</label>
                <fieldset [disabled]="!isFieldEditable">
                  <ng-select
                    [items]="customers"
                    class="new-dropdown h-11"
                    bindLabel="customerName"
                    bindValue="_id"
                    formControlName="customerId"
                    [readonly]="!isFieldEditable"
                    placeholder="{{ 'Type Customer Name' | translate }}"
                  >
                    <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-index="index"
                    >
                      <div class="px-2 py-1">
                        <div
                          class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                        >
                          {{ item.customerName }}
                        </div>
                      </div>
                    </ng-template>
                  </ng-select>
                </fieldset>
              </div>
              <div class="flex flex-col col-span-2 gap-[10px]">
                <label class="block text-base Poppins-Medium leading-5">{{
                  "Add a Project" | translate
                }}</label>
                <fieldset [disabled]="!isFieldEditable">
                  <ng-select
                    [items]="projects"
                    class="new-dropdown h-11"
                    bindLabel="projectName"
                    bindValue="_id"
                    formControlName="projectId"
                    [readonly]="!isFieldEditable"
                    placeholder="{{ 'Select Project Name' | translate }}"
                  >
                    <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-index="index"
                    >
                      <div class="px-2 py-1">
                        <div
                          class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                        >
                          {{ item.projectName }}
                        </div>
                      </div>
                    </ng-template>
                  </ng-select>
                </fieldset>
              </div>
              <div class="flex flex-col gap-[10px]">
                <label class="Poppins-Medium leading-5">{{
                  "Purchase Order" | translate
                }}</label>
                <fieldset [disabled]="!isFieldEditable">
                  <input
                    formControlName="purchaseOrder"
                    type="text"
                    class="new-input"
                  />
                  <div class="text-red-500" *ngIf="formErrors.purchaseOrder">
                    {{ formErrors.purchaseOrder }}
                  </div>
                </fieldset>
              </div>
              <div class="flex flex-col gap-[10px]">
                <label class="Poppins-Medium leading-5">{{
                  "Frequency" | translate
                }}</label>
                <fieldset [disabled]="!isFieldEditable">
                  <ng-select
                    [items]="frequency"
                    class="new-dropdown p-0 h-11"
                    formControlName="frequency"
                    [readonly]="!isFieldEditable"
                    [clearable]="false"
                    placeholder="{{ 'Select Frequency' | translate }}"
                  >
                    <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-index="index"
                    >
                      <div class="px-2 py-1">
                        <div
                          class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                        >
                          {{ item }}
                        </div>
                      </div>
                    </ng-template>
                  </ng-select>
                  <div class="text-red-500" *ngIf="formErrors.frequency">
                    {{ formErrors.frequency }}
                  </div>
                </fieldset>
              </div>
              <div class="flex flex-col gap-[10px]">
                <label class="Poppins-Medium leading-5">{{
                  "Payment Terms" | translate
                }}</label>
                <fieldset [disabled]="!isFieldEditable">
                  <ng-select
                    #select
                    [items]="paymentTerms"
                    class="new-dropdown p-0 h-11"
                    formControlName="paymentTerms"
                    bindLabel="paymentTerm"
                    bindValue="_id"
                    [readonly]="!isFieldEditable"
                    [clearable]="false"
                    placeholder="{{ 'Select Payment Terms' | translate }}"
                  >
                    <ng-template ng-footer-tmp>
                      <p
                        class="create-new cursor-pointer"
                        (click)="showCustomPaymentTermsModal(select)"
                      >
                        Create New
                      </p>
                    </ng-template>
                    <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-index="index"
                    >
                      <div class="px-2 py-1">
                        <div
                          class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                        >
                          {{ item.paymentTerm }}
                        </div>
                      </div>
                    </ng-template>
                  </ng-select>
                  <div class="text-red-500" *ngIf="formErrors.paymentTerms">
                    {{ formErrors.paymentTerms }}
                  </div>
                  <!-- <input formControlName='purchaseOrder' type="text" class="new-input bg-input-light " /> -->
                </fieldset>
              </div>
              <div class="flex flex-col gap-[10px]">
                <label class="Poppins-Medium leading-5">{{
                  "Start Date" | translate
                }}</label>
                <fieldset [disabled]="!isFieldEditable">
                  <input
                    formControlName="startDate"
                    [min]="today"
                    type="date"
                    class="new-date"
                  />
                  <div class="text-red-500" *ngIf="formErrors.startDate">
                    {{ formErrors.startDate }}
                  </div>
                </fieldset>
              </div>
              <div class="flex flex-col gap-[10px]">
                <label class="Poppins-Medium leading-5">{{
                  "End Date" | translate
                }}</label>
                <input
                  formControlName="endDate"
                  [min]="invoiceForm.get('startDate').value"
                  type="date"
                  class="new-date"
                />
                <div class="text-red-500" *ngIf="formErrors.endDate">
                  {{ formErrors.endDate }}
                </div>
              </div>
            </div>
            <table class="w-full">
              <thead class="text-[#1C1C1C66] border-[#1C1C1C33] border-b">
                <tr>
                  <td class="px-4 py-2">
                    {{ tableFields.items | translate }}
                  </td>
                  <td class="px-4 py-2">
                    {{ tableFields.itemDescription | translate }}
                  </td>
                  <td class="px-4 py-2">
                    {{ tableFields.units | translate }}
                  </td>
                  <td class="px-4 py-2">
                    {{ tableFields.price | translate }}
                  </td>
                  <td class="px-4 py-2">
                    {{ "Tax:" | translate }}
                  </td>
                  <td class="px-4 py-2">
                    {{ tableFields.amount | translate }}
                  </td>
                  <td class="p-2 rounded-e-xl"></td>
                </tr>
              </thead>
              <ng-container formArrayName="items">
                <ng-container
                  *ngFor="let control of items.controls; let i = index"
                >
                  <tr [formGroup]="control" class="">
                    <td class="w-3/12 p-1">
                      <ng-select
                        [items]="availableProducts"
                        class="new-dropdown h-11"
                        bindLabel="name"
                        bindValue="name"
                        formControlName="item"
                        [readonly]="!isFieldEditable"
                        [hideSelected]="true"
                        [clearable]="false"
                        placeholder="{{ 'Select one' | translate }}"
                        (change)="changeEvent($event, i)"
                      >
                        <ng-template
                          ng-option-tmp
                          let-item="item"
                          let-index="index"
                        >
                          <div class="px-2 py-1">
                            <div
                              class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                            >
                              {{ item.name }}
                            </div>
                          </div>
                        </ng-template>
                      </ng-select>
                    </td>
                    <td class="p-1 w-3/12">
                      <fieldset>
                        <input
                          formControlName="itemDescription"
                          type="text"
                          class="new-input"
                        />
                      </fieldset>
                    </td>
                    <td class="p-1 w-1/12">
                      <fieldset [disabled]="!isFieldEditable">
                        <input
                          id="quantity"
                          formControlName="unit"
                          type="number"
                          min="0"
                          class="new-input"
                        />
                      </fieldset>
                    </td>
                    <td class="p-1 w-1/6">
                      <fieldset [disabled]="!isFieldEditable">
                        <input
                          id="price"
                          formControlName="price"
                          type="number"
                          min="0"
                          class="new-input"
                        />
                        <div class="text-red-500" *ngIf="formErrors.price">
                          {{ formErrors.price }}
                        </div>
                      </fieldset>
                    </td>
                    <td class="p-1 w-1/6">
                      <ng-select
                        class="new-dropdown h-11 p-0 border-0"
                        [readonly]="!isFieldEditable"
                        [items]="taxes"
                        bindLabel="name"
                        formControlName="tax"
                        placeholder="{{ 'Select Tax' | translate }}"
                      >
                        <ng-template
                          ng-label-tmp
                          let-item="item"
                          let-clear="clear"
                        >
                          <span class="ng-value-label"
                            >{{ item.taxName }}
                            {{ item.tax | number : number }}%</span
                          >
                          <span
                            class="ng-value-icon right"
                            (click)="clear(item)"
                            aria-hidden="true"
                            >×</span
                          >
                        </ng-template>
                        <ng-template
                          ng-option-tmp
                          let-item="item"
                          let-index="index"
                        >
                          <div class="px-2 py-1">
                            <div
                              class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                            >
                              {{ item.name }}
                            </div>
                          </div>
                        </ng-template>
                      </ng-select>
                    </td>
                    <td class="p-1">
                      <p>
                        {{
                          items.controls[i].get("unit").value *
                            items.controls[i].get("price").value
                            | currency
                              : currencySymbol
                              : "symbol-narrow"
                              : number
                        }}
                      </p>
                    </td>
                    <td *ngIf="isFieldEditable" class="w-1/12">
                      <svg
                        (click)="removeItem(i)"
                        xmlns="http://www.w3.org/2000/svg"
                        name="delete"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        class="mx-auto h-5 w-5 text-red-500"
                      >
                        <path
                          _ngcontent-hsm-c58=""
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        ></path>
                      </svg>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td>
                      <p
                        class="text-red-500 whitespace-nowrap"
                        *ngIf="control.get('unit').hasError('min')"
                      >
                        {{ "Quantity should be more than 0" }}
                      </p>
                    </td>
                    <td colspan="5"></td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="items.controls.length === 0">
                  <tr>
                    <td colspan="8" class="text-center p-2">
                      {{ "No items added" | translate }}
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
              <tr>
                <td vAlign="top" colspan="4">
                  <div class="ps-2 text-black">
                    <button
                      (click)="addNewItem()"
                      id="add"
                      class="flex items-center space-x-4"
                    >
                      <span
                        class="p-2 rounded-sm border border-[#1C1C1C0D] bg-[#f6f6f6c9] flex gap-2 me-2"
                      >
                        <img
                          src="assets/images/delete-icon.svg"
                          style="transform: rotate(45deg)"
                          alt=""
                        />
                        {{ "Add a Line" | translate }}
                      </span>
                    </button>
                  </div>
                </td>
                <td colspan="4">
                  <div class="flex justify-between py-2">
                    <p>{{ "Subtotal:" | translate }}</p>
                    <p>
                      {{
                        subTotal
                          | currency : currencySymbol : "symbol-narrow" : number
                      }}
                    </p>
                  </div>
                  <div class="flex items-center justify-between py-2">
                    <p>{{ "Discount (%):" | translate }}</p>
                    <fieldset class="w-1/4" [disabled]="!isFieldEditable">
                      <input
                        type="number"
                        class="p-2 rounded-xl new-input border-0"
                        formControlName="discount"
                        min="0"
                      />
                      <div class="text-red-400" *ngIf="formErrors.discount">
                        {{ formErrors.discount }}
                      </div>
                    </fieldset>
                    <p>
                      {{
                        discount
                          | currency : currencySymbol : "symbol-narrow" : number
                      }}
                    </p>
                  </div>
                  <div class="flex justify-between py-2">
                    <p>{{ "Discount Subtotal:" | translate }}</p>
                    <p>
                      {{
                        discountValue
                          | currency : currencySymbol : "symbol-narrow" : number
                      }}
                    </p>
                  </div>
                  <div class="flex justify-between py-2">
                    <p>{{ "Tax:" | translate }}</p>
                    <p>
                      {{
                        tax
                          | currency : currencySymbol : "symbol-narrow" : number
                      }}
                    </p>
                  </div>
                  <div
                    class="flex justify-between py-2 Poppins-Medium border-t"
                  >
                    <p>{{ "Total Amount:" | translate }}</p>
                    <p>
                      {{
                        totalAmount
                          | currency : currencySymbol : "symbol-narrow" : number
                      }}
                    </p>
                  </div>
                </td>
              </tr>
            </table>
            <div class="flex flex-col gap-6">
              <div class="mt-4 flex gap-[10px] flex-col col-span-2">
                <label class="text-base Poppins-Medium leading-5">{{
                  "Notes & Terms" | translate
                }}</label>
                <fieldset [disabled]="!isFieldEditable" class="h-full">
                  <textarea
                    formControlName="notesTerms"
                    class="rounded-xl new-input !h-32"
                  ></textarea>
                </fieldset>
              </div>
              <div class="flex gap-16">
                <div
                  class="flex flex-col justify-between new-design-round-border p-4 flex-grow"
                >
                  <h3 class="block text-base Poppins-Medium mb-2">
                    {{ "E-Sign" | translate }}
                  </h3>
                  <div
                    class="bg-white rounded-xl flex items-center justify-center p-4"
                  >
                    <label
                      (click)="openEsignModal()"
                      *ngIf="!invoiceData.eSign"
                      [ngClass]="invoiceData.eSign ? 'hidden' : ''"
                      class="flex items-center gap-4"
                    >
                      <div
                        class="h-16 w-16 flex justify-center items-center rounded-[80px] bg-[#1C1C1C0D] shadow-btn"
                      >
                        <img src="assets/images/upload.svg" alt="" class="" />
                      </div>
                      <span>{{ "Add E-Sign" | translate }}</span>
                    </label>
                    <img
                      class="esign"
                      [src]="invoiceData.eSign"
                      [ngClass]="invoiceData.eSign ? '' : 'hidden'"
                      width="120px"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div
                    [ngClass]="invoiceData.eSign ? '' : 'hidden'"
                    class="flex justify-center gap-x-2 my-4"
                  >
                    <app-button
                      *ngIf="isFieldEditable"
                      type="third"
                      buttonText="{{ 'Change' | translate }}"
                      [rounded]="true"
                      size="small"
                      (click)="openCreateEsign = true"
                    ></app-button>
                    <app-button
                      *ngIf="isFieldEditable"
                      type="third"
                      buttonText="{{ 'Remove' | translate }}"
                      [rounded]="true"
                      size="small"
                      (click)="removeEsign()"
                    ></app-button>
                  </div>
                </div>
                <div class="new-design-round-border p-4 flex-grow">
                  <h3 class="block text-base Poppins-Medium mb-2">
                    {{ "QR Code" | translate }}
                  </h3>
                  <div
                    class="flex gap-12 rounded-xl justify-center items-baseline"
                  >
                    <div class="flex flex-col gap-[10px]">
                      <div class="text-base Poppins-Medium leading-5">
                        {{ "Heading" | translate }}
                      </div>
                      <fieldset [disabled]="!isFieldEditable">
                        <input
                          formControlName="qrHeading"
                          type="text"
                          class="mb-2 new-input w-full"
                        />
                      </fieldset>
                    </div>
                    <div class="flex flex-col gap-[10px]">
                      <div class="text-base Poppins-Medium leading-5">
                        {{ "Image" | translate }}
                      </div>
                      <div
                        class="bg-white flex rounded-xl items-center justify-center"
                      >
                        <label
                          for="file"
                          [ngClass]="invoiceData.qrCode ? 'hidden' : ''"
                          class="flex flex-col items-center gap-4"
                        >
                          <div
                            class="h-16 w-16 flex justify-center items-center rounded-[80px] bg-[#1C1C1C0D] shadow-btn"
                          >
                            <img
                              src="assets/images/upload.svg"
                              alt=""
                              class=""
                            />
                            <fieldset [disabled]="!isFieldEditable">
                              <input
                                type="file"
                                id="file"
                                accept="image/png, image/jpeg, image/jpg"
                                (change)="fileEventForQR($event)"
                                hidden
                              />
                            </fieldset>
                          </div>
                        </label>
                        <img
                          class="qrCode"
                          [src]="invoiceData.qrCode"
                          [ngClass]="invoiceData.qrCode ? '' : 'hidden'"
                          width="120"
                          alt=""
                          srcset=""
                        />
                      </div>
                      <div
                        [ngClass]="invoiceData.qrCode ? '' : 'hidden'"
                        class="flex justify-center mb-4"
                      >
                        <label
                          for="qrCode"
                          class="h-[44px] px-4 whitespace-nowrap Poppins-Medium text-base disabled:cursor-not-allowed rounded-md shadow-md leading-4 hover:shadow-lg flex gap-2 items-center justify-center border-2 border-[#DCDCDC] rounded-lg"
                        >
                          {{ "Change" | translate }}
                        </label>
                        <fieldset [disabled]="!isFieldEditable">
                          <input
                            id="qrCode"
                            type="file"
                            (change)="fileEventForQR($event)"
                            hidden
                          />
                        </fieldset>
                        <app-button
                          type="third"
                          buttonText="{{ 'Remove' | translate }}"
                          [rounded]="true"
                          size="small"
                          (click)="removeQRCode()"
                        ></app-button>
                      </div>
                    </div>
                  </div>
                </div>
                <app-file-upload
                  appAccessControl
                  moduleType="storage"
                  accessType="edit"
                  [type]="'compact'"
                  class="flex-grow self-end"
                  (emitter)="saveFiles($event)"
                ></app-file-upload>
                <div class="flex flex-col justify-end gap-4 flex-grow">
                  <app-submit
                    [value]="'Create Invoice'"
                    className="bg-[#026AA2] text-white w-44"
                    (clicked)="createInvoice()"
                  ></app-submit>
                  <app-submit
                    value="Discard"
                    className="bg-[#F6F6F6CC] text-[#1C1C1C66] w-44 shadow-btn"
                    routerLink="../"
                  ></app-submit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<app-modal-container [isOpen]="openCreateEsign">
  <div header class="border-b pb-2">
    {{ "E-Sign Settings" | translate }}
  </div>
  <div content>
    <div class="">
      <div class="bg-white">
        <div class="sm:flex sm:items-start">
          <div
            class="mt-3 w-full text-center sm:mt-0 sm:mx-4 sm:ltr:text-left sm:rtl:text-right"
          >
            <div *ngIf="previewSignatureForDoc">
              <div class="mt-2 w-full">
                <div
                  class="my-5 py-5 flex items-center justify-center border-b"
                >
                  <div class="">
                    <input
                      id="eSignFile"
                      type="file"
                      (change)="fileEventForESign($event)"
                      hidden
                    />
                    <label
                      for="eSignFile"
                      class="h-[44px] px-4 whitespace-nowrap Poppins-Medium text-base disabled:cursor-not-allowed rounded-md shadow-md leading-4 hover:shadow-lg flex gap-2 items-center justify-center border-2 border-[#DCDCDC] rounded-lg"
                      >{{ "Add Image" | translate }}</label
                    >
                  </div>
                </div>
                <div class="space-y-1">
                  <label for="eSignInput">{{
                    "Type your signature" | translate
                  }}</label>
                  <input
                    type="text"
                    [(ngModel)]="eSignValue"
                    class="new-input"
                  />
                </div>
              </div>
            </div>
            <div *ngIf="!previewSignatureForDoc">
              <div class="flex space-y-4 flex-col mt-4">
                <div class="flex space-x-4 rtl:space-x-reverse items-center">
                  <input
                    [(ngModel)]="eSignSelected"
                    type="radio"
                    id="eSign1"
                    name="esignRadio"
                    value="eSignType1"
                  />
                  <label
                    for="eSign1"
                    id="eSignType1"
                    class="flex-1 whitespace-nowrap h-[110px] px-6 Allison-Regular text-6xl text-center leading-[110px]"
                  >
                    {{ eSignValue }}
                  </label>
                </div>
                <div class="flex space-x-4 rtl:space-x-reverse items-center">
                  <input
                    [(ngModel)]="eSignSelected"
                    type="radio"
                    id="eSign2"
                    name="esignRadio"
                    value="eSignType2"
                  />
                  <label
                    for="eSign2"
                    id="eSignType2"
                    class="flex-1 whitespace-nowrap h-[110px] px-6 Dancing-Regular text-6xl text-center leading-[110px]"
                  >
                    {{ eSignValue }}
                  </label>
                </div>
                <div class="flex space-x-4 rtl:space-x-reverse items-center">
                  <input
                    [(ngModel)]="eSignSelected"
                    type="radio"
                    id="eSign3"
                    name="esignRadio"
                    value="eSignType3"
                  />
                  <label
                    for="eSign3"
                    id="eSignType3"
                    class="flex-1 whitespace-nowrap h-[110px] px-6 Saint-Regular text-6xl text-center leading-[110px]"
                  >
                    {{ eSignValue }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div footer>
    <div *ngIf="previewSignatureForDoc">
      <button
        (click)="previewSignatureForDoc = false"
        type="button"
        class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 button-new-class text-base font-medium text-white hover:dark-blue-2 focus:outline-none sm:ms-3 sm:w-auto sm:text-sm"
      >
        {{ "Preview" | translate }}
      </button>
      <button
        (click)="openCreateEsign = false"
        type="button"
        class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ms-3 sm:w-auto sm:text-sm"
      >
        {{ "Cancel" | translate }}
      </button>
    </div>
    <div *ngIf="!previewSignatureForDoc">
      <button
        (click)="createSignature()"
        type="button"
        class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 button-new-class text-base font-medium text-white hover:dark-blue-2 focus:outline-none sm:ms-3 sm:w-auto sm:text-sm"
      >
        {{ "Save" | translate }}
      </button>
      <button
        (click)="previewSignatureForDoc = true"
        type="button"
        class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ms-3 sm:w-auto sm:text-sm"
      >
        {{ "Back" | translate }}
      </button>
    </div>
  </div>
</app-modal-container>

<app-modal-container [isOpen]="showCustomPaymentTerms">
  <div header class="text-start border-b pb-2">
    {{ "Add New Payment Term" | translate }}
  </div>
  <div class="text-start" [formGroup]="paymentTermForm" content>
    <div class="flex flex-col w-1/2">
      <label class="">{{ "New Payment Term" | translate }}</label>
      <fieldset>
        <input
          id="paymentTerm"
          formControlName="paymentTerm"
          type="text"
          class="new-input bg-input-light"
        />
        <div class="text-red-500" *ngIf="paymentTermFormErrors.paymentTerm">
          {{ paymentTermFormErrors.paymentTerm }}
        </div>
      </fieldset>
    </div>
    <div class="flex flex-col w-1/2">
      <label class="">{{ "New Payment Term Days" | translate }}</label>
      <fieldset>
        <input
          formControlName="days"
          type="number"
          class="new-input bg-input-light"
        />
        <div class="text-red-500" *ngIf="paymentTermFormErrors.days">
          {{ paymentTermFormErrors.days }}
        </div>
      </fieldset>
    </div>
  </div>
  <div footer class="flex items-center ms-auto gap-x-2">
    <app-button
      buttonText="{{ 'Create' | translate }}"
      type="primary"
      rounded="true"
      (handleClick)="createPaymentTerms()"
    ></app-button>
    <app-button
      buttonText="{{ 'Close' | translate }}"
      type="third"
      rounded="true"
      (handleClick)="showCustomPaymentTerms = false"
    ></app-button>
  </div>
</app-modal-container>

<app-image-cropper
  [isOpen]="openModalForEsign"
  [imageCropperConfig]="imageCropperConfig"
  (base64Image)="loadEsign($event)"
  (closeImage)="openModalForEsign = false"
></app-image-cropper>

<app-image-cropper
  [isOpen]="openModal"
  [imageCropperConfig]="imageDataQR"
  (base64Image)="loadQR($event)"
  (closeImage)="openModal = false"
></app-image-cropper>

<!-- <app-image-cropper [imageData]='imageDataEsign' (base64Image)='loadEsign($event)'></app-image-cropper> -->
