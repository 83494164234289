<div subscriptionCheck featureToCheck="recurring" class="w-full p-8">
    <div class="bg-white px-14 py-11 rounded-lg flex flex-col space-y-4">
      <div>
        <div class="flex items-center justify-between">
          <div class=""></div>
          <div class="">
            <app-button
              appAccessControl
              moduleType="accounting"
              accessType="edit"
              buttonText="{{ 'Create Recurring JV' | translate }}"
              type="primary"
              routerLink="create-recurring-jv"
            ></app-button>
          </div>
        </div>
      </div>
      <app-table-container
        [tableHeadings]="tableHeadings"
        [tableData]="tableData"
        [keys]="tableKeys"
        [actionTemplate]="actionTemplate"
      >
        <ng-template let-item #actionTemplate>
          <td class="p-2">
            <p
              class="Poppins"
              [ngClass]="item?.isCanceled ? 'text-red-600' : 'text-green-500'"
            >
              {{
                item?.isCanceled
                  ? "Stopped"
                  : item?.isEnded
                  ? "Completed"
                  : "Active"
              }}
            </p>
          </td>
          <td class="p-2 text-center">
            <!-- <app-dropdown #dropdown class="">
              <div
                button
                (click)="dropdown.open = true"
                (clickOutside)="dropdown.open = false"
              >
                <div
                  class="h-8 w-8 rounded-full flex justify-center items-center bg-gray-50 border border-gray-200"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>
              </div>
              <div options>
                <div
                  (click)="viewJV(item._id)"
                  class="cursor-pointer px-4 py-2 text-sm Poppins-Bold hover:bg-gray-50 text-center"
                >
                  {{ "View" | translate }}
                </div>
              </div>
            </app-dropdown> -->
            <app-dropdown #dropdown>
              <div
                button
                (click)="dropdown.open = true"
                (clickOutside)="dropdown.open = false"
              >
                <div
                  class="h-8 w-8 rounded-lg flex justify-center items-center border-2 border-[#DCDCDC]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>
              </div>
              <div options class="new-shadow p-1">
                <div
                  (click)="viewJV(item._id)"
                  class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF] text-left"
                >
                  {{ "View" | translate }}
                </div>
              </div>
            </app-dropdown>
          </td>
        </ng-template>
      </app-table-container>
    </div>
  </div>
  
  <div
    routeToSubscription
    fromComponent="recurring"
    class="hidden absolute top-[40%] z-[29] w-[40%] left-0 right-0 m-auto"
  >
    <app-send-to-sub from="recurring"></app-send-to-sub>
  </div>
  