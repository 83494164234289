import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsContainerComponent } from './settings-container.component';
import { SettingsRoutingModule } from './settings-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DirectivesModule } from "src/app/directives/directives.module";
import { BusinessesManagementComponent } from './shared/businesses-management/businesses-management.component';
import { TranslateModule } from '@ngx-translate/core';
import { OnlinePaymentComponent } from './settings-modules/online-payment/online-payment.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    SettingsContainerComponent,
    OnlinePaymentComponent
  ],
  imports: [
    CommonModule,
    SettingsRoutingModule,
    SharedModule,
    NgxSpinnerModule,
    DirectivesModule,
    TranslateModule,
    ReactiveFormsModule
  ]
})
export class SettingsModule { }
