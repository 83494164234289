import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'empty',
})
export class EmptyPipe implements PipeTransform {
  transform(
    value: undefined | null | string | number,
    emptyValue = '--'
  ): string {
    return value === undefined ||
      value === null ||
      value.toString().trim() === ''
      ? emptyValue
      : value.toString();
  }
}
