import { Component, OnDestroy, OnInit, Input, AfterViewChecked } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { getCodesFromSymbol } from 'currency-code-symbol-map';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { filter, ignoreElements, map } from 'rxjs/operators';
import { SalesService } from 'src/app/modules/sales/sales.service';
import { selectCustomizationSettings } from 'src/app/modules/sales/state/selectors/customizationSettings.selector';
import { copyToClipBoard } from 'src/app/shared/utils/copyToClipBoard';
import { updateCurrentTransactionLimit } from 'src/app/store/actions/usage.action';
import { SetUser, UpdateTransactionLimit } from 'src/app/store/actions/user.actions';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectUser } from 'src/app/store/selectors/user.selectors';
import { InvoicesService } from '../../invoices.servcies';
import { environment } from 'src/environments/environment';
import { LanguageService } from 'src/app/shared/services/language.service';
import { NumberService } from 'src/app/shared/services/number.service';
import { takeUntil } from 'rxjs/operators';
import { selectAccounts } from 'src/app/store/selectors/account.selector';
import { dateLessThan, DateValidator, valueChanges } from 'src/app/shared/utils/formValidator';
import { selectCustomers } from 'src/app/modules/sales/state/selectors/customers.selector';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import * as _html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'app-view-invoice',
  templateUrl: './view-invoice.component.html',
  styleUrls: ['./view-invoice.component.scss']
})
export class ViewInvoiceComponent implements OnInit, OnDestroy, AfterViewChecked {
  taxOnItem: string = "No tax";
  showFiles: boolean = true;
  invoiceData2: any;

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private invoiceService: InvoicesService,
              private store: Store<RootReducerState>,
              private router: Router,
              private salesService: SalesService,
              private translateService: TranslateService,
              private languageService: LanguageService,
              private numberService: NumberService,
              private utlity: UtilityService,
              private fileUploadService: FileUploadService,
              private toaster: ToastrService) {
                this.customizationSettings$ = this.store.pipe(select(selectCustomizationSettings));
                this.business$ = this.store.pipe(select(selectBusiness));
                this.usage$ = store.pipe(select(selectUser));
                this.customers$ = this.store.pipe(select(selectCustomers));
                this.paymentAccounts$ = this.store.pipe(
                  select(selectAccounts),
                  map(accounts => accounts.filter((account) => !account.isArchive && !account.isDeleted && ( this.type === 'bill' ? account.accountType === 'Cash and Bank' : account.accountType === 'Cash and Bank' || account.accountType === 'Money in Transit' ))));

              }

  @Input() type = ''
  customers$: Observable<any>;
  customers = [];
  unsubscribe$ = new Subject();
  invoiceData = {
    status: {
      primaryStatus: 'draft',
      secondaryStatus: 'draft'
    },
    returnItems:[],
    createdAt: null,
    updatedAt: null,
    totalAmount: null,
    customerDetails: null,
    invoiceNumber: null,
    invoiceDate: null,
    _id: null,
    dueDate: null,
    paymentHistory: [],
    refundHistory:[],
    subHeading: null,
    notesTerms: null,
    Footer: null,
    title: null,
    summary: null,
    purchaseOrder: null,
    items: [],
    subtotal: null,
    discount: null,
    discountValue: null,
    tax: null,
    dueAmount: null,
    currencyDetails : {
      currencySymbol: null,
      convertedAmount: null,
      currencyValue: null,
      currency: ''
    },
    userId: {
      firstName: '',
      lastName: ''
    },
    eSign: null,
    qrCode: null,
    qrHeading: null,
    qrCodeWithUuid:null,
    businessDetails: null,
    paymentLink: null,
    paypalInvoiceId: null,
    installments: [],
    isInstallment: null
  };

  paymentAccounts$: Observable<any>;

  tableFields = {
    items: 'Items',
    units: 'Units',
    sku: 'SKU',
    hsn_sac: 'HSN/SAC',
    price: 'Price',
    amount: 'Amount'
  }

  customizationSettings$: Observable<any>;
  usage$: Observable<any>;
  transactionLimit;
  business$: Observable<any>;
  business = null;
  businessId = null;
  unsubscribe = new Subject();
  customizationSettings;
  shareURL;
  shareURLModal = false;
  copyButtonText = 'Copy';
  paymentShareURL = `${environment.frontBaseURL}/open/invoice-payment`;
  copyPaymentUrlButtonText = 'Copy';
  paypalConnected = false;
  emailModal = false;
  email = new FormControl(null, [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]);
  error = '';
  response = '';
  recordPaymentModal = false;
  recordRefundModal = false;
  amountDue: number = 0;
  page = 1;
  recordData = null;
  editRecordPaymentModal = false;
  confirmDeleteModal = false;
  recordToUpdate = {
    recordId: null,
    transactionId: null
  };
  recordToDelete = {
    recordId: null,
    transactionId: null,
    invoiceId: null
  };
  number = '1.2-2';
  refundAmount = 0.00;
  decimalSize = 2;
  currencyDetails = {
    currency: '',
    currencySymbol: ''
  };
  paymentMethods = [
    'Cash',
    'Bank Transfer',
    'Cheque',
    'Online Payment',
    'Other'
  ];
  hasPaymentLink = false;
  fieldNames = {
    type: 'Invoice',
    invoiceNumber: 'Invoice Number',
    invoiceDate: 'Invoice Date',
    dueDate: 'Due Date',
    purchaseOrder: 'PO Number',
    billingAddress: 'Billing Address',
    shippingAddress: 'Shipping Address',
    total: 'Total',
    receiptAmount: 'Receipt Amount',
    subtotal: 'Subtotal',
    tax: 'Tax',
    terms: 'Terms & Conditions',
    itemName: 'Item Name',
    itemDescription: 'Item Description',
    quantity: 'Quantity',
    unitPrice: 'Unit Price',
    totalAmount: 'Total Amount',
    billTo: 'Bill To',
    eSign: 'Digitally signed document',
    discount: 'Discount',
    discountSubtotal: 'Discount Subtotal',
    issuer: 'Issued By',
    taxId: 'Tax ID',
    entityId: 'Entity ID',
    refundDate: 'Date',
    amount: "Amount",
    paymentMethod: "Payment Method",
    memo: "Memo"
  };
  refundForm: FormGroup;
  
  refundFormErrors = {
    paymentMethod:'',
    paymentAccount:'',
    refundDate:'',
    unit:'',
    item:'',
  }

  refundFormErrorMessage = {
    paymentMethod:{
      required:'Payment Method is required'
    },
    paymentAccount:{
      required:'Payment Account is required'
    },
    
  }


  invalidRefundAmount: boolean = false
  products = [];
  showTax:string = ""
  maxUnit:number = 0;
  subTotal = 0;
  totalAmount = 0;
  averageTotalAmount = 0;
  tax = 0;
  discount=0;
  itemList= [];
  newReturnItems= [];
  maxUnitError: boolean = false
  invoiceItems:[] = [];
  files: File[] = []
  refundFiles: File[] = [];
  addedFiles = [];
  isCustomerEligible: boolean = false;
  isBusinessEligible: boolean = false;

  ngOnInit(): void {
    this.paymentAccounts$.subscribe(accounts => {
      console.log(".............",accounts)
    })
    this.getCurrencyDetails();
    this.getTransactionLimit();
    this.loadNumberConfig();
    
    this.loadCustomers();
    this.route.queryParams.subscribe(({id}) => {
      if (id) { this.loadInvoice(id); }
    });

    this.loadRefundForm();
  }

  ngAfterViewChecked(): void {
    document.getElementById('template-option-one')?.classList.add('w-full');
  }

  loadRefundForm(): void {
    this.refundForm = this.fb.group({
      amount:[null],
      // paymentMethod:[null, [Validators.required]],
      paymentAccount:[null],
      memo:[null],
      items: this.fb.array([])
    });
    this.refundForm.get("paymentAccount").setValue(this.invoiceData?.customerDetails?.customerId?.accountDetails?.accountName)
    this.refundForm.valueChanges.subscribe(({items}) =>{
      this.refundFormErrors = valueChanges(this.refundForm, {...this.refundFormErrors}, this.refundFormErrorMessage, this.translateService)
      this.calcalutetotal(items)
    });
    this.refundFormErrors = valueChanges(this.refundForm, {...this.refundFormErrors}, this.refundFormErrorMessage, this.translateService)
  }

  calcalutetotal(estimateItems):void{
    let subTotal = 0;
    let total = 0;
    let totalTax = 0;
    let averageTotalAmount = 0;
    let discountPercent = 0
    let totalDiscount = 0
    discountPercent = this.numberService.toFixed(this.invoiceData.discount)


    estimateItems.forEach(item =>{
      const {price, unit, tax, averagePrice} = item
      if(unit > 0){
        let itemTotal = price * unit
        let averageItemTotal = averagePrice * unit
        subTotal += itemTotal

        if(this.invoiceData.discount > 0){
          const discount = itemTotal * (discountPercent/100)
          itemTotal -= discount
          averageItemTotal -= discount
        }


        total += itemTotal
        averageItemTotal += averageItemTotal
        if(tax){
          totalTax += this.calculateItemTax(itemTotal, tax)
        }
      }
    });
    
    this.tax = this.numberService.toFixed(totalTax);
    this.subTotal = this.numberService.toFixed(subTotal);
    this.totalAmount = this.numberService.toFixed(total + totalTax);
    this.averageTotalAmount = this.numberService.toFixed(averageTotalAmount)
    this.discount = this.numberService.toFixed(subTotal - total);
    console.log("Total Amount", )
    // if(this.invoiceData?.paymentHistory.length > 0){
    //   this.invalidRefundAmount= false
    //   if(this.totalAmount > (this.invoiceData.totalAmount-this.invoiceData.dueAmount)){
    //     this.invalidRefundAmount = true
    //   }
    // }
    

    // this.refundForm.controls['amount'].setValue(this.totalAmount)
    // this.refundForm.value.amount = this.totalAmount
    // console.log(this.refundForm.value.amount, this.totalAmount)


    console.log('total', this.totalAmount, this.subTotal, this.tax, estimateItems);
  }

  calculateItemTax(price, taxes): any{
    return taxes.reduce((a, b) => a += (price) * (b.tax/100), 0)
  }


  loadProducts():void{

  }
  loadNumberConfig(): void {
    this.numberService.number
    .pipe((takeUntil(this.unsubscribe)))
    .subscribe((number) => {
      this.number = number
    })

    this.numberService.decimalSize
    .pipe((takeUntil(this.unsubscribe)))
    .subscribe((decimalSize) => {
      this.decimalSize = decimalSize
    })
  }

  getTransactionLimit(): void {
    this.usage$.subscribe(({currentUsage})=>{
      if(currentUsage){
        const 
        { transactionLimit } = currentUsage;
        this.transactionLimit = transactionLimit;
      }
    })
  }

  getCurrencyDetails(): void {
    this.salesService.currencyDetail.subscribe(details => {
      if (details) {
      this.currencyDetails = details;
      }
    });
  }

  loadBusiness(): void {
    this.business$.subscribe((business) => {
      if (business?.businessId?._id) {
        if (business?.businessId?.paypalConnected) {
          this.paypalConnected = true;
        }
        this.business = business.businessId;
        console.log("alleditBusiness", business);
        this.businessId = business._id
        
        if(this.business?.clientId && this.business?.secretCode && this.business?.taxId && this.business?.incomeSourceId  ){
          this.isBusinessEligible = true;
        }
        console.log("Business Data using id1", this.invoiceData?.customerDetails)
        console.log("Business Data using id2", this.isBusinessEligible, this.isCustomerEligible)
      }
    });
  }

  loadCustomers(): void {
    this.customers$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(customers => {
      this.customers = customers;
      console.log("Customer Data",this.customers)
    });
  }

  loadCustomer(id): void {
    this.customers$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(customers => {
      this.customers = customers.
      console.log("Customers Data",this.customers)
    });
  }

  loadInvoice(id): void {
    this.spinner.show('view');
    this.loadCustomizations();
    this.loadBusiness();
    this.invoiceService.getInvoice(id).subscribe((resp) => {
      this.invoiceData = resp.data;
      this.invoiceData2 = JSON.parse(JSON.stringify(resp.data))
      this.invoiceItems = this.deepCopy(resp.data.items)
      const items = JSON.parse(JSON.stringify(resp.data.items))
      this.addedFiles = resp?.data?.files;
      this.newReturnItems = this.newReturnList(this.invoiceData.returnItems)
      
      this.itemList = this.getDifference(items, this.newReturnItems)
     

      this.invoiceData.currencyDetails.currency =  this.currencyDetails.currency;
      this.invoiceData.currencyDetails.currencySymbol =  this.currencyDetails.currencySymbol;
      this.shareURL = `${environment.frontBaseURL}/open/invoice/${resp.data.businessDetails.businessId._id}/${resp.data._id}`;
      this.amountDue = resp.data.dueAmount ?? this.numberService.toFixed(resp.data.totalAmount);
      this.refundAmount = this.invoiceData?.refundHistory.length > 0 ? this.invoiceData?.refundHistory.map(i=>i.amount).reduce((a,b)=>a+b): 0.00 
      if (this.invoiceData.paymentLink) {
        this.hasPaymentLink = true;
        this.paymentShareURL = this.invoiceData.paymentLink;
      } else {
        this.hasPaymentLink = false;
        this.paymentShareURL = null;
      }
      console.log("this.invoiceData?.customerDetails", this.invoiceData?.customerDetails);
      
      if(this.invoiceData?.customerDetails?.customerId?.customerDocNumber && this.invoiceData?.customerDetails?.customerId?.billingAddress?.postalCode){
        this.isCustomerEligible = true;
      }
      console.log("this.invoiceData?.customerDetails1", this.isCustomerEligible, this.isBusinessEligible);
      
      this.spinner.hide('view');
    }, (error) => {
      this.spinner.hide('view');
      this.toaster.error(this.translateService.instant('Something went wrong!'));
    });
  }

  loadCustomizations(): void {
    this.customizationSettings$.subscribe((settings) => {
      if (settings) {
        this.customizationSettings = settings;
        console.log(this.customizationSettings);
      }
    });
  }

  editInvoice(): void {
    this.router.navigate(['/sales/invoices/create-invoice'], { queryParams: { id: this.invoiceData._id } });
  }

  getInvoiceNumberData(): any {
    const invoiceDateField = this.invoiceData.invoiceDate;
    const invoiceDateYear = new Date(invoiceDateField).getFullYear();
    const body = {
      from: moment(`${invoiceDateYear}`).startOf('year').format('YYYY-MM-DD'),
      to: moment(`${invoiceDateYear}`).endOf('year').format('YYYY-MM-DD'),
      year: invoiceDateYear
    };
    return body;
  }

  approveInvoice(): void {
    this.spinner.show();
    if(this.isBusinessEligible){
      if(!this.isCustomerEligible) {
        const validationType = this.invoiceData?.customerDetails?.customerId?.customerDocNumber ? 'Postal code in billing address of customer is missing' : 'Customer Document Number is missing';
        this.spinner.hide();
        this.toaster.error(validationType);
        return;
      } 
      // else {
      //   const businessValidation = !this.business?.clientId ? 'ClienId' : !this.business?.secretCode ? 'Secret Code' : !this.business?.taxId ? 'Tax ID' : !this.business?.incomeSourceId ? 'Income Source ID' : '';
      //   this.toaster.error(`${businessValidation} in business information is missing`);
      //   return;
      // }
    }
    
    const body = {
      businessId: this.business._id,
      ...this.getInvoiceNumberData(),
      invoiceId: this.invoiceData._id,
      isJOValid: this.isBusinessEligible && this.isCustomerEligible
    };
    this.invoiceService.approveInvoice(body).subscribe((resp) => {
      this.spinner.hide();
      if (resp.status === 200) {
        this.invoiceData.status = resp?.data?.status;
        this.invoiceData.invoiceNumber = resp?.data?.invoiceNumber;
        this.invoiceData.qrCodeWithUuid =resp?.data?.qrCodeWithUuid;
        this.store.dispatch(updateCurrentTransactionLimit({transactionLimit: this.transactionLimit + 1}));
        this.toaster.success(this.translateService.instant('Invoice Approved'));
        this.loadInvoice(this.invoiceData._id)
      }
    }, (error) => {
      this.spinner.hide();
      this.toaster.error(this.translateService.instant(error?.error?.message ?? 'Something went wrong!'));
    });
  }

  markAsSent(): void {
    this.spinner.show();
    this.invoiceService.markAsSent(this.invoiceData._id).subscribe((resp) => {
      if (resp?.success){
        this.toaster.success(this.translateService.instant('Marked as sent'));
        this.invoiceData.status.secondaryStatus = resp.data?.status?.secondaryStatus;
      }
      this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
      this.toaster.error(this.translateService.instant('Something went wrong!'));
    });
  }

  sendEmail(): void {
    if (this.email.invalid) {
      this.email.markAsDirty();
      return;
    }
    const data = {
      email: this.email.value,
      _id: this.invoiceData._id,
      ownerId: this.business.userId
    };
    this.spinner.show();
    this.invoiceService.sendEmail(data).subscribe((resp) => {
      this.spinner.hide();
      if (resp?.success){
        this.emailModal = false;
        this.toaster.success(this.translateService.instant('Email sent successfully'));
      }
      this.email.reset();
      setTimeout(() => {
        this.response = '';
      });
    }, (error) => {
      this.spinner.hide();
      this.toaster.error(this.translateService.instant(error?.error?.message || 'Email Not Sent'));
    });
  }

  copyLink(): void {
    copyToClipBoard(this.shareURL);
    this.copyButtonText = 'Copied!';
    setTimeout(() => {
      this.copyButtonText = 'Copy';
    }, 1500);
  }

  copyPaymentLink(): void {
    copyToClipBoard(this.paymentShareURL);
    this.copyPaymentUrlButtonText = 'Copied!';
    setTimeout(() => {
      this.copyPaymentUrlButtonText = 'Copy';
    }, 1500);
  }

  loadAmountDue(paymentHistory: Array<any>, totalAmount: number): number {
    const paidAmount: number = paymentHistory
    .map(payment => payment.amount)
    .reduce((prev, curr) => prev + curr, 0);
    const amountDue: Number = totalAmount - paidAmount;
    return this.numberService.toFixed(amountDue);
  }

  get items(): FormArray {
    return this.refundForm.get('items') as FormArray;
  }

  openRecordPayment(): void {
    if (this.amountDue > 0) {
      this.recordPaymentModal = true;
    }
  }

  openRefundPayment(): void {
    this.recordRefundModal = true;
  }

  removeItem(index): void {
    this.items.removeAt(index);
    const items = this.deepCopy(this.invoiceItems)
    this.itemList = this.getDifference2(this.getDifference(items, this.newReturnItems), this.items.value)
    console.log("Remove Item invoice Items", this.invoiceItems)
    console.log("Remove Item", this.items.value)
  }

  addNewItem(): void {
    this.items.push(this.createItem());
    console.log(this.items.value)
    this.refundForm.controls.items.valueChanges.subscribe(items=>{
    })
  }

  changeEvent(event, index): void {
    console.log("event",event)
    this.items.controls[index].setValue({itemId: event?.itemId, item:event?.item, type:event?.type,  sku:event?.sku, hsn_sac:event?.hsn_sac, price:event?.price, tax:event?.tax, isSale:event?.isSale, cogsAccountDetails:event.cogsAccountDetails ? event.cogsAccountDetails : null, inventoryAccountDetails:event.inventoryAccountDetails ? event.inventoryAccountDetails: null, accountDetails:event?.accountDetails, unit: event?.unit, totalCost: event?.price * 1 , averagePrice: this.numberService.toFixed(event?.averagePrice || event?.price)});
    this.items.controls[index].get('unit').clearValidators();
    this.items.controls[index].get('unit').updateValueAndValidity();
    this.items.controls[index].get('unit').setValidators([Validators.max(event.unit), Validators.min(1)])
    this.items.controls[index].get('unit').updateValueAndValidity();
    const items = this.deepCopy(this.invoiceItems)
    this.itemList = this.getDifference2(this.getDifference(items, this.newReturnItems), this.items.value)
    console.log("change Event InvoiceItems",this.invoiceItems)
  }

  getDifference(array1, array2) {
    let result = array1.filter(object1 => {
      return !array2.some(object2 => {
        if(object1.itemId === object2.itemId && object1.unit === object2.unit){
          return object1.itemId === object2.itemId
        } else if(object1.itemId === object2.itemId && object1.unit !== object2.unit ){
          object1.unit = Math.abs(object1.unit - object2.unit)  
        }
      });
    });
    return result
  }

  getDifference2(array1, array2) {
    return array1.filter(object1 => {
      return !array2.some(object2 => {
        return object1.itemId === object2.itemId
      });
    });
  }

  createItem(): FormGroup {
    const itemForm = this.fb.group({
      item: null,
      itemId: '',
      sku: '',
      hsn_sac: '',
      price: [null,[Validators.min(0)]],
      unit: [null,[Validators.min(1)]],
      tax: null,
      type: '',
      totalCost: [null,[Validators.min(0)]],
      accountDetails: null,
      cogsAccountDetails: null,
      isSale: true,
      inventoryAccountDetails: null,
      averagePrice: 0
    });
    return itemForm;
  }



  recordPayment(paymentData): void {
    const body = {
      _id: this.invoiceData._id,
      businessId: this.business?._id,
      category: `Journal Entry`,
      paymentData,
      journalData: [
        {
          description: 'For customer credit',
          businessId: this.business._id,
          accountId: this.invoiceData?.customerDetails?.customerId?.accountDetails?.accountId,
          accountName: this.invoiceData?.customerDetails?.customerId?.accountDetails?.accountName,
          account: this.invoiceData?.customerDetails?.customerId?.accountDetails?.accountSection,
          debit: 0,
          credit: paymentData.amount,
          type: 'Credit'
        },
        {
          description: 'Payment',
          businessId: this.business._id,
          accountId: paymentData.accountId,
          accountName: paymentData.accountName,
          account: paymentData.account,
          debit: paymentData.amount,
          credit: 0,
          type: 'Debit'
        }
      ]
    };
    if(body.journalData[0].accountId === "" || body.journalData[0].accountName === "" || body.journalData[1].accountId === "" || body.journalData[1].accountName === ""){
      this.toaster.error(this.translateService.instant('Something went wrong. Please refresh page and try again.'));
      this.recordPaymentModal = false;
      return
    }
    console.log(JSON.stringify(body));
    this.spinner.show();
    this.recordPaymentModal = false;
    this.fileUploadService.emitFiles.next(true);
    this.fileUploadService.emitFiles.next(false);
    const formData = new FormData();
    paymentData?.files?.forEach((file, i) => {
      formData.append(`file${i}`, file)
    });
    formData.append('payload', JSON.stringify(body));
    this.invoiceService.recordPayment({formData, _id: body?._id}).subscribe((resp) => {
      if(resp?.success){
        this.spinner.hide();
        this.toaster.success(this.translateService.instant('Payment recorded'));
        this.invoiceData.paymentHistory = [...resp?.data?.paymentHistory];
        this.invoiceData.installments = [...resp?.data?.installments]
        this.amountDue = this.loadAmountDue(this.invoiceData?.paymentHistory, this.invoiceData?.totalAmount);
        this.invoiceData.dueAmount = this.amountDue;
        this.invoiceData.status.primaryStatus = resp?.data?.status?.primaryStatus;
        this.store.dispatch(updateCurrentTransactionLimit({transactionLimit: this.transactionLimit + 1}));
      }
      else{
        this.spinner.hide();
        if(resp.message === 'Your Payment Recorded Successfully.Please setup Your Mailling Config.'){
          this.toaster.success(this.translateService.instant('Your Payment Recorded Successfully.Please setup Your Mailling Config.'));
        } else {
          this.toaster.success(this.translateService.instant('Payment recorded'));
        }
      }
    }, (error) => {
      this.spinner.hide();
      this.toaster.error(this.translateService.instant('Cannot record payment'))
    });
  }

  // Update the edit record flow using single modal, instead of two modals.

  editRecord(record): void {
    this.recordToUpdate = {
      recordId: record._id,
      transactionId: record.transactionId
    };
    
    this.editRecordPaymentModal = true;
    this.recordData = {
      ...record,
    };
  }

  saveEditedRecord(recordInfo): void {
    const data = {
      recordInfo: {
        ...recordInfo,
        transactionId: this.recordToUpdate.transactionId
      },
      journalData: [
        {
          description: 'For customer credit',
          businessId: this.business._id,
          accountId: this.invoiceData?.customerDetails?.customerId?.accountDetails?.accountId,
          accountName: this.invoiceData?.customerDetails?.customerId?.accountDetails?.accountName,
          account: this.invoiceData?.customerDetails?.customerId?.accountDetails?.accountSection,
          debit: 0,
          credit: recordInfo.amount,
          type: 'Credit'
        },
        {
          description: 'Payment',
          businessId: this.business._id,
          accountId: recordInfo.accountId,
          accountName: recordInfo.accountName,
          account: recordInfo.account,
          debit: recordInfo.amount,
          credit: 0,
          type: 'Debit'
        }
      ],
      invoiceId: this.invoiceData._id,
      recordId: this.recordToUpdate.recordId,
    };
    console.log(JSON.stringify(data));
    this.spinner.show();
    this.invoiceService.updateRecordPayment(data).subscribe((resp) => {
    this.editRecordPaymentModal = false;
    if (resp?.success){
        this.spinner.hide();
        this.invoiceData.paymentHistory = resp.data.paymentHistory;
        this.invoiceData.installments = [...resp?.data?.installments]
        this.amountDue = this.loadAmountDue(this.invoiceData.paymentHistory, this.invoiceData.totalAmount);
        this.invoiceData.dueAmount = this.amountDue;
        this.invoiceData.status.primaryStatus = resp?.data?.status?.primaryStatus;
        this.toaster.success(this.translateService.instant('Payment record updated'));
      }
      else{
        this.spinner.hide();
        this.toaster.success(this.translateService.instant('Payment recorded'));
      }
    }, error => {
      this.spinner.hide();
      this.toaster.error(this.translateService.instant('Something went wrong!'));
    });
  }

  deleteRecord(record): void {
    console.log(record);
    const data = {
      recordId: record._id,
      invoiceId: this.invoiceData._id,
      transactionId: record.transactionId
    };
    this.recordToDelete = data;
    this.confirmDeleteModal = true;
  }

  confirmDelete(): void {
    this.spinner.show()
    this.invoiceService.deleteRecordPayment(this.recordToDelete).subscribe((resp) => {
      this.confirmDeleteModal = false;
      if (resp?.success){
        this.spinner.hide();
        this.invoiceData.paymentHistory = this.invoiceData.paymentHistory.filter(({_id}) => _id !== this.recordToDelete.recordId);
        this.amountDue = this.loadAmountDue(this.invoiceData.paymentHistory, this.invoiceData.totalAmount);
        this.invoiceData.dueAmount = this.amountDue;
        this.invoiceData.status.primaryStatus = moment(this.invoiceData.dueDate).isBefore(moment())? 'Overdue' : this.amountDue > 0 ? 'Unpaid' : 'paid';
        this.store.dispatch(updateCurrentTransactionLimit({transactionLimit: this.transactionLimit - 1}))
        this.toaster.success(this.translateService.instant('Record deleted successfully'));
      }
      else{
        this.spinner.hide();
        this.toaster.success(this.translateService.instant('Payment recorded'));
      }
    }, error => {
      this.spinner.hide();
      this.toaster.error(this.translateService.instant('Cannot delete record'));
    });
  }

  generatePaymentLink(): void {
    const paypalInvoiceData = {
      detail: {
        invoice_number: `${new Date().getTime() % 1000000}-${this.invoiceData.invoiceNumber}`,
        invoice_date: moment(this.invoiceData.invoiceDate.split('T')[0]).subtract(1, 'd').format('YYYY-MM-DD'),
        currency_code: 'USD',
        term: this.invoiceData.notesTerms
      },
      invoicer: {
          name: {
              full_name: this.invoiceData.businessDetails.businessId.companyName
          },
          address: {
              address_line_1: this.invoiceData.businessDetails.businessId.address.addressLineFirst ?? '',
              address_line_2: this.invoiceData.businessDetails.businessId.address.addressLineSecond ?? '',
              admin_area_1: this.invoiceData.businessDetails.businessId.address.state ?? '',
              admin_area_2: this.invoiceData.businessDetails.businessId.address.city ?? '',
              admin_area_3: this.invoiceData.businessDetails.businessId.country ?? ''
          },
          email_address: this.invoiceData.businessDetails.businessId.paypalEmail,
      },
      primary_recipients: [
          {
              billing_info: {
                  name: {
                      full_name: this.invoiceData.customerDetails.customerId.customerName
                  },
                  address: {
                      address_line_1: this.invoiceData?.customerDetails?.customerId?.billingAddress?.addressLine1 || '' ,
                      address_line_2: this.invoiceData?.customerDetails?.customerId?.billingAddress?.addressLine2 ?? '',
                      admin_area_1: this.invoiceData?.customerDetails?.customerId?.billingAddress?.state ?? '',
                      admin_area_2: this.invoiceData?.customerDetails?.customerId?.billingAddress?.city ?? '',
                      admin_area_3: this.invoiceData?.customerDetails?.customerId?.billingAddress?.country ?? '',
                      postal_code: `${this.invoiceData?.customerDetails?.customerId?.billingAddress?.postalCode}` ?? ''
                  },
                  email_address: this.invoiceData?.customerDetails?.customerId?.email
              },
              shipping_info: {
                  name: {
                      full_name: this.invoiceData.customerDetails.customerId.customerName
                  },
                  address: {
                    address_line_1: this.invoiceData.customerDetails.customerId.shippingDetails.addressLine1,
                    address_line_2: this.invoiceData.customerDetails.customerId.shippingDetails.addressLine2 ?? '',
                    admin_area_1: this.invoiceData.customerDetails.customerId.shippingDetails.state ?? '',
                    admin_area_2: this.invoiceData.customerDetails.customerId.shippingDetails.city ?? '',
                    admin_area_3: this.invoiceData.customerDetails.customerId.shippingDetails.country ?? '',
                    postal_code: `${this.invoiceData.customerDetails.customerId.shippingDetails.postalCode}` ?? ''
                  }
              }
          }
      ],
      items: [],
      configuration: {
          tax_calculated_after_discount: true,
          tax_inclusive: false,
          partial_payment: {
            allow_partial_payment: true,
          },
      }
    };
    this.invoiceData.items.forEach((item) => {
      paypalInvoiceData.items.push({
        name: item.item,
        quantity: item.unit,
        unit_amount: {
          currency_code: 'USD',
          value: item.price
        },
        tax: {
          name: item.tax.length ? item.tax[0].taxName : 'Tax',
          percent: item.tax.length ? item.tax[0].tax : 0
        },
        discount: {
          percent: this.invoiceData.discount || '0'
        },
        unit_of_measure: 'QUANTITY'
      });
    });
    if (this.invoiceData?.currencyDetails?.currencySymbol !== '$') {
      const data = {
        invoiceId: this.invoiceData._id,
        baseCurrency: 'USD',
        symbol: this.invoiceData.currencyDetails.currencySymbol
      };
      this.spinner.show();
      this.invoiceService.convertCurrency(data).subscribe((resp) => {
        if (resp.success) {
          paypalInvoiceData.items = paypalInvoiceData.items.map((item) => {
            return {
              ...item,
              unit_amount: {
                currency_code: 'USD',
                value: (item.unit_amount.value / resp.data.currencyDetails.currencyValue)
              }
            };
          });
          this.generateLink(paypalInvoiceData);
        } else {
          this.spinner.hide();
          this.toaster.error(this.translateService.instant('Unable to convert currency'));
        }
      }, (error) => {
        this.spinner.hide();
        this.toaster.error(this.translateService.instant('Unable to convert currency'));
      });
    } else {
      this.generateLink(paypalInvoiceData);
    }
  }

  generateLink(paypalInvoiceData): void {
    const body = {
      businessId: this.invoiceData.businessDetails.businessId._id,
      invoiceId: this.invoiceData._id,
      ...paypalInvoiceData
    };
    this.invoiceService.generatePaymentLink(body).subscribe((resp) => {
      this.spinner.hide();
      if (resp.status === 200) {
        this.invoiceData.paypalInvoiceId = resp.data.split('#')[1];
        this.invoiceData.paymentLink = resp.data;
        this.paymentShareURL = resp.data;
        this.hasPaymentLink = true;
        this.toaster.success(this.translateService.instant('Link Generated'));
      }
    }, (error) => {
      this.spinner.hide();
      this.toaster.error(this.translateService.instant('Something went wrong!'));
    });
  }

  cancelRefund(){
    (this.refundForm.controls.items as FormArray).clear()   
    this.refundForm.controls.amount.reset()
    this.refundForm.controls.memo.reset()
    this.recordRefundModal = false
    const items = this.deepCopy(this.invoiceItems)
    this.itemList = this.getDifference(items, this.newReturnItems)
    console.log("Cancel Fund Invoice Items", this.invoiceItems)
  }

  generateRefundPayment():void{
    if(this.refundForm.invalid){
      this.refundForm.markAllAsTouched();
      this.refundFormErrors = valueChanges(this.refundForm, {...this.refundFormErrors}, this.refundFormErrorMessage)
      return
    }
    if(this.totalAmount > this.invoiceData.totalAmount){
      return 
    }

    if(this.totalAmount === 0){
      console.log("No item selected")
      return
    }

    const formValue = this.refundForm.value;

    console.log("Refund Form Value", formValue)

    const journalDataEntry = []

    if (formValue.items.length === 0) {
      console.log("NO item selected")
      return;
    }

    if (formValue.items[0].item === null) {
      console.log("Item null")
      return;
    }

    // if(this.invoiceData.paymentHistory.length > 0){
    //   if(this.invalidRefundAmount === true){
    //     console.log("Refund Amount is more then paid amount")
    //     return 
    //   }
    // }


    let discountPercent = 0;
    discountPercent = this.numberService.toFixed(this.invoiceData.discount);

    for(let i=0; i < formValue.items.length; i++){
      let itemDebit = formValue.items[i].price * formValue.items[i].unit;
        if(this.invoiceData.discount > 0){
          const discount = itemDebit * (discountPercent/100)
          itemDebit -= discount
        }
        journalDataEntry.push({
          description:"For Item Credit", 
          businessId:this.business._id,
          accountId:formValue.items[i].accountDetails.accountId,
          accountName :formValue.items[i].accountDetails.accountName,
          account :formValue.items[i].accountDetails.accountSection,
          credit : 0,
          debit : itemDebit,
          type : "Debit",
          itemId :formValue.items[i].itemId 
        })
        if(formValue.items[i].tax.length > 0){
          journalDataEntry.push({
            description:"For Tax Credit", 
            businessId:this.business._id,
            accountId:formValue.items[i]?.tax[0]?.accountDetails?.accountId,
            accountName :formValue.items[i]?.tax[0]?.accountDetails?.accountName,
            account :formValue.items[i]?.tax[0]?.accountDetails?.accountSection,
            credit : 0,
            debit : (formValue.items[i]?.price * formValue.items[i].unit ) * (formValue.items[i]?.tax[0]?.tax / 100),
            type : "Debit",
            itemId :formValue.items[i]?.itemId
          })
        }
    }
    let body = {
      _id:this.invoiceData._id,
      businessId: this.business?._id,
      category:"Journal Entry",
      paymentData:{ 
        amount:this.numberService.toFixed(this.totalAmount),
        paymentMethod:"Customer Account Credited",
        memo:formValue?.memo ? formValue?.memo :"" ,
        accountId: this.invoiceData?.customerDetails?.customerId?.accountDetails?.accountId,
        accountName: this.invoiceData?.customerDetails?.customerId?.accountDetails?.accountName,
        account: this.invoiceData?.customerDetails?.customerId?.accountDetails?.accountSection,
      },
      returnItems:formValue?.items,
      journalData: [
        {
          description: 'For Customer Debit',
          businessId: this.business._id,
          accountId: this.invoiceData?.customerDetails?.customerId?.accountDetails?.accountId,
          accountName: this.invoiceData?.customerDetails?.customerId?.accountDetails?.accountName,
          account: this.invoiceData?.customerDetails?.customerId?.accountDetails?.accountSection,
          debit: 0,
          credit: this.totalAmount,
          type: 'Credit'
        },
        ...journalDataEntry
      ]
    }

    console.log("Payload", body)

    this.spinner.show();
    this.recordRefundModal = false;
    this.fileUploadService.emitFiles.next(true);
    this.fileUploadService.emitFiles.next(false);
    const formData = new FormData();
    this.refundFiles?.forEach((file, i) => {
      formData.append(`file${i}`, file);
    })
    formData.append('payload', JSON.stringify(body))
    this.invoiceService.refundPayment({formData, _id: body?._id}).subscribe((resp) => {
      if(resp?.success){
        this.spinner.hide();
        this.toaster.success(this.translateService.instant(' Refund Payment recorded'));
        this.invoiceData.refundHistory = [...resp?.data?.refundHistory];
        this.invoiceData.returnItems = [...resp?.data?.returnItems];
        this.invoiceData.installments = [...resp?.data?.installments];
        this.amountDue = resp.data.dueAmount ?? this.numberService.toFixed(resp.data.totalAmount);
        this.invoiceData.status.primaryStatus = resp?.data?.status?.primaryStatus;
        this.refundAmount = this.invoiceData.refundHistory.map(i=>i.amount).reduce((a,b)=>a+b) 
        this.store.dispatch(updateCurrentTransactionLimit({transactionLimit: this.transactionLimit + 1}));
        this.newReturnItems = this.newReturnList(this.invoiceData.returnItems)
        this.cancelRefund()
       
      }
      else{
        this.spinner.hide();
        this.newReturnItems = this.newReturnList(this.invoiceData.returnItems)
        this.cancelRefund()
        this.toaster.success(this.translateService.instant('Refund Payment recorded'));
      }
    }, (error) => {
      this.spinner.hide();
      this.toaster.error(this.translateService.instant('Cannot record payment'))
    }); 
  }
  downloadPdf(): void {
    if (
      this.customizationSettings?.selectedTemplate === 'templateOption1' ||
      this.customizationSettings?.selectedTemplate === 'templateOption2' ||
      this.customizationSettings?.selectedTemplate === 'jordan' ||
      this.customizationSettings?.selectedTemplate === 'saudi'
    ) {
      this.spinner.show();
      const html2canvas: any = _html2canvas;
      let mainContent = document.getElementById('template-option-one');
      let footerContent = document.getElementById('pdf-footer');
      let footerContentDisplay = document.getElementById('pdf-footer-display');

      footerContent.style.display = 'none';

      if(this.customizationSettings?.selectedTemplate === 'templateOption2'){
            footerContentDisplay.style.display = 'none';
      }

      html2canvas(mainContent, { scale: 2 }).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');

          
          footerContent.style.padding = this.customizationSettings?.selectedTemplate === 'templateOption1' ? '0rem 5rem 5rem 5rem' : this.customizationSettings?.selectedTemplate === 'templateOption2' ? '0rem' : '0rem 2rem 2.5rem 2rem';

          if(this.customizationSettings?.selectedTemplate === 'templateOption2'){
            footerContentDisplay.style.display = 'block';
            footerContentDisplay.style.padding = '0rem';
          }
          footerContent.style.display = 'block';

          html2canvas(footerContent, { scale: 2 }).then((footerCanvas) => {
              const footerImgData = footerCanvas.toDataURL('image/png');

              const pdf = new jsPDF({
                  orientation: 'portrait',
                  unit: 'mm',
                  format: 'a3',
              });

              const pdfWidth = 297;
              const pdfHeight = 420;
              const imgProps = pdf.getImageProperties(imgData);
              const imgWidth = pdfWidth;
              const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

              const footerProps = pdf.getImageProperties(footerImgData);
              const footerWidth = pdfWidth;
              const footerHeight = (footerProps.height * footerWidth) / footerProps.width;

              const pageHeight = pdfHeight - footerHeight;
              const pageCount = Math.ceil(imgHeight / pageHeight);

              for (let i = 0; i < pageCount; i++) {
                  if (i > 0) {
                      pdf.addPage();
                  }
                  const yOffset = -i * pageHeight;
                  pdf.addImage(
                      imgData,
                      'PNG',
                      0,
                      yOffset,
                      imgWidth,
                      imgHeight,
                      '',
                      'FAST'
                  );

                  // Add footer to each page
                  pdf.addImage(
                      footerImgData,
                      'PNG',
                      0,
                      pdfHeight - footerHeight,
                      footerWidth,
                      footerHeight,
                      '',
                      'FAST'
                  );
              }

              pdf.save(this.invoiceData?.invoiceNumber + '.pdf');
              footerContent.style.padding = '';
              if(this.customizationSettings?.selectedTemplate === 'templateOption2'){
                footerContent.style.display = 'none';
              }
              this.spinner.hide();
          });
      });

    } else {
    const body = {
      config: {
        data: {
          ...this.invoiceData,
          items: this.invoiceData.items.map((item) => {
            return {
              ...item,
              price: this.numberService.currencier(item.price),
              totalPrice: this.numberService.currencier(item.price * item.unit)
            };
          }),
          refundAmount:this.invoiceData?.refundHistory.length > 0 ? this.numberService.currencier(this.invoiceData?.refundHistory.map(i=>i.amount).reduce((a,b)=>a+b)): 0.00 ,

          subtotal: this.numberService.currencier(this.invoiceData.subtotal),
          dueDate: moment(this.invoiceData.dueDate).format('DD-MM-YYYY'),
          invoiceDate: moment(this.invoiceData.invoiceDate).format('DD-MM-YYYY'),
          discount: this.invoiceData.discount ? this.numberService.currencier(this.invoiceData.discount) : 0,
          discountValue: this.numberService.currencier(this.invoiceData.discountValue),
          discountGiven: this.numberService.currencier(this.invoiceData.subtotal - this.invoiceData.discountValue),
          tax: this.numberService.currencier(this.invoiceData.tax),
          totalAmount: this.numberService.currencier(this.invoiceData.totalAmount),
          companylogo: this.customizationSettings?.companyLogo},
        direction: localStorage.getItem('NuMetric|lang')=== 'en'? 'ltr': 'rtl',
        showTax: this.invoiceData.items.some(el => el.tax > 0),
        decimalSize: this.decimalSize,
        signatureDate: moment(this.invoiceData.updatedAt).format('LLL'),
        fieldNames: this.languageService.translate(this.fieldNames)
      }
    };
    this.spinner.show();
    this.invoiceService.createInvoicePdf(body).subscribe((resp) => {
        const a = document.createElement('a');
        const blob = new Blob([resp], {type: 'application/pdf'});
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = `Invoice ${this.invoiceData.invoiceNumber}.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
        this.toaster.success(this.translateService.instant('PDF downloaded'));

        this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
      const errorMessage = this.translateService.instant('Something went wrong!');
      this.toaster.error(errorMessage);
    });
  }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    document.getElementById('template-option-one')?.classList.remove('w-full');
  }

  roundToTwo(num): any {
    return +(Math.round(num + Number.EPSILON) * 100 / 100);
  }

  newReturnList(arr){
    return Object.values(arr.reduce(
      (c, {accountDetails, averagePrice, hsn_sac, item, itemId, price, refundId, sku, tax, totalCost, unit, _id}) => {
      c[itemId] = c[itemId] || {accountDetails, averagePrice, hsn_sac, item, itemId, price, refundId, sku, tax, totalCost, unit:0, _id};
      c[itemId].unit += unit;
      return c;
    }, {}));
  } 

  deepCopy(arr){
    return JSON.parse(JSON.stringify(arr))
  }

  uploadFiles(): void {
    this.fileUploadService.emitFiles.next(true);
    this.fileUploadService.emitFiles.next(false);
    if(!this.files.length){
      this.toaster.error('Select files to upload');
      return;
    }
    this.spinner.show();
    const body = {
      businessId: this.business?._id,
      referenceId: this.invoiceData?._id,
      documnetType: 'Invoice',
      parentDocType: 'Sales'
    }
    const formData = new FormData();
    this.files.forEach((file, i) => {
      formData.append(`file${i}`, file);
    })
    formData.append('payload', JSON.stringify(body));
    this.fileUploadService.uploadFileForDocument(formData)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
      if(resp?.success){
        this.toaster.success(resp?.message);
      }
      else{
        this.toaster.error(resp?.message)
      }
      this.spinner.hide();
    }, (err) => {
      this.toaster.error(err?.error?.message);
      this.spinner.hide();
      this.showFiles = false;
      setTimeout(() => {
      this.showFiles = true;
      }, 100);
    })
  }

  saveFiles(files: File[]){
    this.files = files;
  }

  editBusiness(id): void {
    // this.router.navigate(['/settings/businesses'], { queryParams: { id } });
    this.utlity.showHideSettingsModal.next({ open: true, tab: 'business' });
  }

  saveRefundFiles(files: File[]){
    this.refundFiles = files;
  }

  fetchSendInvoiceEmail(): void {
    this.email.setValue(this.invoiceData.customerDetails.customerId.email)
  }

}