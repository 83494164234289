import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExpertService } from 'src/app/modules/experts/expert.service';
import { RootReducerState } from 'src/app/store/reducers';
import { selectUser } from 'src/app/store/selectors/user.selectors';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-experts-list',
  templateUrl: './experts-list.component.html',
  styleUrls: ['./experts-list.component.scss'],
})
export class ExpertsListComponent implements OnInit {
  openFilterModal = false;
  expertsData: any;
  originalExpertsData: any;
  finalData: any;
  environmentPath = 'https://numetric-expert.s3.us-east-2.amazonaws.com/';
  unsubscribe$ = new Subject();
  user$: Observable<any>;
  userId: string;
  countryFilters = [];
  specialisationFilters = [];
  qualificationFilters = [];
  expertStatus: string;
  values = {
    accounting: false,
    bookkeeping: false,
    payroll: false,
    internal: false,
    external: false,
    sales: false,
    income: false,
    financial: false,
    under: false,
    graduate: false,
    post: false,
    global: false,
    jo: false,
    sa: false,
  }

  constructor(
    private router: Router,
    private expertService: ExpertService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    private toster: ToastrService,
    private store: Store<RootReducerState>
  ) {
    this.user$ = this.store.pipe(select(selectUser));
  }

  ngOnInit(): void {
    this.spinner.show();

    this.user$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      this.userId = user._id;
    });

    this.getAllExperts();
  }

  getAllExperts(): void {
    this.expertService.getAllExpertsLambda({ id: this.userId }).subscribe(
      (result) => {
        this.expertStatus = result.data.filter(
          (item) => item.numetricUserId === this.userId
        )[0]?.status;
        result.data = result.data.filter(
          (item) =>
            item.numetricUserId !== this.userId && item.status === 'Approved'
        );
        console.log('result', result, this.userId, this.expertStatus);
        this.expertService.setExpertDetails(result);
        this.expertsData = result.data;
        this.originalExpertsData = JSON.parse(JSON.stringify(result.data));
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toster.error(
          this.translateService.instant('Something went wrong!')
        );
      }
    );
  }

  openFilter(): void {
    this.openFilterModal = true;
  }

  badgeType(type: string): string {
    const expertBadge =
      type === 'Silver'
        ? 'ExpertSilver'
        : type === 'Platinum'
        ? 'ExpertPlatinum'
        : type === 'Gold'
        ? 'ExpertGold'
        : 'ExpertElite';
    return expertBadge;
  }

  selectOption(option: string, type: string): void {
    this.finalData = JSON.parse(JSON.stringify(this.originalExpertsData));
    const filters =
      type === 'specialisation'
        ? this.specialisationFilters
        : type === 'qualification'
        ? this.qualificationFilters
        : this.countryFilters;
    if (!filters.includes(option)) {
      filters.push(option);
    } else {
      const index = filters.indexOf(option);
      filters.splice(index, 1);
    }

    if(this.specialisationFilters.length === 0 && this.qualificationFilters.length === 0 && this.countryFilters.length === 0){
      this.finalData = JSON.parse(JSON.stringify(this.originalExpertsData));
    } else {
      this.finalData = this.originalExpertsData.filter((k) => {
        return this.countryFilters.includes(k.region) || k.specialization.some((e) => this.specialisationFilters.includes(e.name)) || k.qualification.some((e) => this.qualificationFilters.includes(e.name))
      });
      // this.finalData = this.originalExpertsData.filter((k) =>
      //   k.specialization.some((e) => this.specialisationFilters.includes(e.name))
      // );
      // this.finalData = this.originalExpertsData.filter((k) =>
      //   k.qualification.some((e) => this.qualificationFilters.includes(e.name))
      // );
    }
    console.log('this.finalData', this.finalData);

    // if (type === 'country') {
    //   this.finalData = this.originalExpertsData.filter((item) => {
    //     return this.countryFilters.includes(item.region) && this.originalExpertsData.filter(k => k.specialization.some(e => this.specialisationFilters.includes(e.name))) && this.originalExpertsData.filter(k => k.qualification.some(e => this.qualificationFilters.includes(e.name)))
    //   })
    //   console.log("this.finalData", this.finalData);
    // } else if(type === 'specialisation') {
    //   this.finalData = this.originalExpertsData.filter(k => k.specialization.some(e => this.specialisationFilters.includes(e.name)));
    //   console.log("this.finalData", this.finalData);

    // } else if(type === 'qualification') {
    //   this.finalData = this.originalExpertsData.filter(k => k.qualification.some(e => this.qualificationFilters.includes(e.name)));
    //   console.log("this.finalData", this.finalData);
    // }
  }

  closeFilterForm(): void {
    this.expertsData = JSON.parse(JSON.stringify(this.originalExpertsData));
    for (var key of Object.keys(this.values)) {
      this.values[key] = false
  }
    this.openFilterModal = false;
  }

  submitFilterForm(): void {
    this.expertsData = JSON.parse(JSON.stringify(this.finalData));
    this.openFilterModal = false;
  }

  becomeAnExpert(): void {
    if (!this.expertStatus) {
      this.router.navigate(['/experts/become-expert/expert-application']);
    } else if (
      this.expertStatus === 'In-Process' ||
      this.expertStatus === 'Rejected' ||
      this.expertStatus === 'Deactivated'
    ) {
      this.router.navigate(['/experts/become-expert/application-history']);
    } else if (this.expertStatus === 'Approved') {
      let a = document.createElement('a');
      const token = localStorage.getItem('refresh_token');
      a.href = `http://expert.numetric.work/login?redirect=${token}&db=jo-numetric`;
      // a.href = `http://localhost:4200/login?redirect=${token}&db=numetric`;
      a.target = 'blank';
      a.click();
    }
  }
}
