import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectUser } from 'src/app/store/selectors/user.selectors';
import { UserManagementService } from '../../user-management.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit, OnDestroy {

  constructor(private userManagementService: UserManagementService,
              private spinner: NgxSpinnerService,
              private toastr:ToastrService,
              private store: Store<RootReducerState>) {
                this.business$ = this.store.pipe(select(selectBusiness));
                this.user$ = this.store.pipe(select(selectUser));
              }

  business$: Observable<any>;
  unsubscribe = new Subject();
  selectedBusiness;
  companyName: string = '';
  usersList = [];
  businessOwner = {
    email: '',
    firstName: '',
    lastName: ''
  };
  user$: Observable<any>;
  userFromStore = null;
  ngOnInit(): void {
    this.getCompanyName();
    this.getBusiness();
    this.getUsers();
  }
  
  getBusiness(): void {
    this.business$
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((business) => {
      if (business?.businessId?._id) {
        this.spinner.show();
        this.userManagementService.getInvitedUsers(business.businessId._id).subscribe((resp) => {
          this.spinner.hide();
          this.usersList = resp.data.invites;
          this.userManagementService.invitedUsers.next(resp.data.invites);
          this.businessOwner = resp.data.businessOwner;
        }, (error) => {
          this.spinner.hide();
        });
      }
    });
  }
  
  getUsers(): void {
    this.user$
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((user) => {
      if (user) {
        this.userFromStore = user;
      }
    });
  }

  getCompanyName(): void {
    this.userManagementService.companyName.subscribe(name => {
      this.companyName = name;
    })
  }

  removeInvitedUser(inviteId): void {
    this.spinner.show()
    this.userManagementService.removeInvitedUser(inviteId)
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(resp => {
      this.spinner.hide();
      if(resp?.success){
        this.toastr.success(resp?.message);
        this.usersList = this.usersList.filter((user) => (user._id !== inviteId));
        this.userManagementService.invitedUsers.next(this.usersList);
      }
      else {
        this.toastr.error('Something went wrong!');
      }
    },(err) => {
      this.spinner.hide();
      this.toastr.error('Something went wrong!');
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
