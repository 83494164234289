<div class="space-y-4 p-4">
    <div class="flex items-center justify-between">
      <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24"
          stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
        </svg>
        {{"Bill #" | translate}} {{billDetails?.billNumber}}
      </h1>
    </div>
    <div class="w-4/5 mx-auto">
      <div class="flex items-center rounded-xl border-custom p-6 my-3">
        <img src="/assets/images/icon-1.svg" class="me-4" />
        <div>
          <p class="Gilroy-Medium text-lg">{{'Create' | translate}}</p>
          <h4 class="Gilroy-Medium text-lg">{{'Created:' | translate }} {{billDetails?.createdAt | date: 'longDate'}}</h4>
        </div>
        <div class="space-x-2 rtl:space-x-reverse ms-auto">
          <app-button (handleClick)='downloadPdf()' type='primary' buttonText='{{"Download PDF" | translate}}'></app-button>
          <!-- <button  class="rounded-xl px-4 py-1 border-custom">{{"Edit Bill" | translate}}</button> -->
        </div>
      </div>
      <div class="rounded-xl border-custom p-6 my-3">
        <div class='mt-2' *ngIf='billDetails?.paymentHistory?.length > 0'>
          <table class="w-full">
            <tr>
              <td class="bg-gray-100 Gilroy-Bold p-2">{{'Date' | translate}}</td>
              <td class="bg-gray-100 Gilroy-Bold">{{'Amount' | translate}}</td>
              <td class="bg-gray-100 Gilroy-Bold">{{'Payment Method' | translate}}</td>
              <td class="bg-gray-100 Gilroy-Bold">{{'Memo' | translate }}</td>
            </tr>
            <tr *ngFor='let payment of billDetails?.paymentHistory '>
              <td class='p-2'>{{payment.paymentDate | date : 'yyyy-MM-dd'}}</td>
              <td>{{payment.amount | currency : billDetails?.businessId?.currency : 'symbol-narrow' : number}}</td>
              <td>{{payment.paymentMethod}}</td>
              <td>{{payment.memo}}</td>
            </tr>
          </table>
          <!-- <pagination-controls autoHide="true" (pageChange)="page = $event"></pagination-controls> -->
        </div>
      </div>
      <div class="rounded-xl border-custom p-6 my-3">
        <span class="text-lg mb-2 Gilroy-Medium">{{'Items' | translate }}</span>
        <table class="w-full">
          <tr>
            <td class="bg-gray-100 Gilroy-Bold p-2">{{'Item Name' | translate}}</td>
            <td class="bg-gray-100 Gilroy-Bold">{{'Quantity' | translate}}</td>
            <td class="bg-gray-100 Gilroy-Bold">{{'Price' | translate}}</td>
            <td class="bg-gray-100 Gilroy-Bold">{{'Total' | translate }}</td>
            <td class="bg-gray-100 Gilroy-Bold">{{'Tax' | translate }}</td>
          </tr>
          <tr *ngFor='let item of billDetails?.items'>
            <td class="p-2">{{item.item}}</td>
            <td>{{item.unit}}</td>
            <td>{{item.price | currency : billDetails?.businessId?.currency : 'symbol-narrow' : number}}</td>
            <td>{{item.unit * item.price | currency : billDetails?.businessId?.currency : 'symbol-narrow' : number}}</td>
            <td>{{item.tax?.tax ? item.tax.tax + "%" : 'No Tax'}}</td>
          </tr>
        </table>
      </div>
      <div class="rounded-xl border-custom p-6 my-3">
        <div class="">
          <span class="Gilroy-Medium text-lg mb-2">{{'Total Amount' | translate}}</span>
          <table>
            <tr>
              <td class="pe-2">{{'Subtotal' | translate}}</td>
              <td>{{billDetails?.subtotal | currency : billDetails?.businessId?.currency : 'symbol-narrow' : number}}</td>
            </tr>
            <tr>
              <td class="pe-2">{{'Tax' | translate}}</td>
              <td>{{billDetails?.tax | currency : billDetails?.businessId?.currency : 'symbol-narrow' : number}}</td>
            </tr>
            <tr>
              <td class="pe-2">{{'Total Amount' | translate}}</td>
              <td>{{billDetails?.totalAmount | currency : billDetails?.businessId?.currency : 'symbol-narrow' : number}}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  
  <!-- <ngx-spinner name='view' bdColor = "rgba(138,170,172,0.49)" size = "medium" color = "#0C4D71" type = "ball-clip-rotate" [fullScreen] = "false"></ngx-spinner> -->
  