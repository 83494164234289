<div class="w-full p-8">
    <div class="bg-white px-14 py-4 rounded-xl space-y-6">
      <div class="w-full flex justify-between">
        <!-- <h1 class="text-2xl Poppins-Medium" >{{'Cloud Storage' | translate}}</h1> -->
      </div>
      <div class="">
        <p class="Poppins-Medium text-lg">{{ "Quick Access" }}</p>
        <div class="flex space-x-4 rtl:space-x-reverse rtl:px-2 w-full">
          <div class="bg-[#F7F9FB] mt-4 rounded-lg px-4 py-2 shadow w-1/5">
            <p class="Poppins-Medium text-xl">{{ "Storage" | translate }}</p>
            <progress
              [value]="statisticsData?.usedPercentage"
              max="100"
              class="h-1 w-5/6"
            ></progress>
            <p class="Poppins">
              {{
                statisticsData?.usedStorage +
                  " " +
                  "GB used out of" +
                  " " +
                  statisticsData?.totalStorage +
                  " " +
                  "GB" | translate
              }}
            </p>
            <!-- yha se continue krna h, dynamic statistics data integrate krke -->
          </div>
          <div
            class="bg-[#F7F9FB] mt-4 rounded-lg px-4 py-3 shadow w-[10%] space-y-4"
          >
            <p class="Poppins-Medium text-center text-xl">
              {{ "Total Files" | translate }}
            </p>
            <p class="Poppins-Medium text-center text-xl">
              {{ statisticsData?.totalFiles | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="flex gap-x-4">
        <div class="w-full">
          <div *ngIf="!listView">
            <table class="w-full border-separate" style="border-spacing: 0 10px">
              <ng-container *ngFor="let sectionType of folderData; let i = index">
                <thead>
                  <tr>
                    <td
                      class="Poppins-Medium text-lg border-b border-[#1C1C1C33] text-[#1C1C1C33] p-2 w-96"
                    >
                      {{
                        sectionType?.parentType + " " + "Documents" | translate
                      }}
                    </td>
                    <td
                      class="Poppins-Medium text-lg border-b border-[#1C1C1C33] text-[#1C1C1C33] p-2"
                    >
                      {{ "Files" | translate }}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    (click)="showFilesForSection(docType?.docType)"
                    *ngFor="let docType of sectionType?.child; let j = index"
                    class="cursor-pointer"
                  >
                    <td class="p-3 flex gap-3 rounded-l-lg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 -960 960 960"
                        width="24px"
                        fill="#47494C"
                        class="w-6 h-6"
                      >
                        <path
                          d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h240l80 80h320q33 0 56.5 23.5T880-640v400q0 33-23.5 56.5T800-160H160Zm0-80h640v-400H447l-80-80H160v480Zm0 0v-480 480Z"
                        />
                      </svg>
  
                      <span class="Poppins-SemiMedium text-base mt-0">
                        {{ docType?.docType }}
                      </span>
                    </td>
                    <td class="p-3 rounded-r-lg">
                      <span class="text-base Poppins-Medium">
                        {{ docType?.totalFile }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </ng-container>
            </table>
          </div>
        </div>
        <div *ngIf="listView"></div>
      </div>
      <!-- <div class="w-1/4">
              <div class="rounded-lg bg-gray-100 p-2" >
                  <img class="w-full h-full" src="../../../../../assets/images/storage.png" alt="storage image">
                  <div>
                      <p class="text-xl my-5" >{{'Upgrade Storage!' | translate}}</p>
                      <p class="my-3">{{'Get more space to keep your documents safe'}}</p>
                      <app-button [buttonText]="'Upgrade'" ></app-button>
                  </div>
              </div>
          </div> -->
    </div>
  </div>
  <!-- </div> -->
  <!-- <div class="mt-3 ms-6 flex flex-col gap-3">
                <div
                  (click)="showFilesForSection(docType?.docType)"
                  class="rounded-lg flex items-center p-3 gap-3 hover:bg-[#F0F5FF] cursor-pointer"
                  *ngFor="let docType of sectionType?.child; let j = index"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#47494C"
                    class="w-6 h-6"
                  >
                    <path
                      d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h240l80 80h320q33 0 56.5 23.5T880-640v400q0 33-23.5 56.5T800-160H160Zm0-80h640v-400H447l-80-80H160v480Zm0 0v-480 480Z"
                    />
                  </svg>
  
                  <span class="Poppins-SemiMedium text-base mt-0">
                    {{ docType?.docType }}
                  </span>
                  <span class="text-base Poppins-Medium">
                    {{ docType?.totalFile }}
                  </span>
                </div>
              </div> -->
  