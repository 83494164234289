import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { base64ToFile } from 'ngx-image-cropper';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExpertService } from 'src/app/modules/experts/expert.service';
import {
  DateValidator,
  valueChanges,
} from 'src/app/shared/utils/formValidator';
import { RootReducerState } from 'src/app/store/reducers';
import {
  selectUser,
  selectUserEmails,
} from 'src/app/store/selectors/user.selectors';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-expert-application',
  templateUrl: './expert-application.component.html',
  styleUrls: ['./expert-application.component.scss'],
})
export class ExpertApplicationComponent implements OnInit {
  profilePic = 'assets/images/avatar.jpg';
  companyImage = 'assets/images/avatar.jpg';
  expertApplicationForm: FormGroup;
  openModal = false;
  imageType: string;
  openedAccordion = [
    'personal',
    'contact',
    'qualification',
    'certificate',
    'experience',
    'corporate',
    'specialization',
    'language',
  ];
  imageCropperConfig = {
    event: '',
    config: {
      maintainAspectRatio: true,
      roundCropper: true,
      aspectRatio: 1 / 1,
      title: 'Upload',
    },
  };
  openPreviewModal = false;
  selectedPreview = '';
  openAddMoreModal = false;
  openCardModal = false;
  cardModalField: string;
  cardFieldName: string;
  fieldError = false;
  modalType: string;
  modalFieldName: string;
  modalSubFieldName: string;
  isEditInfo = false;
  currentIndex: number;
  addMoreForm: FormGroup;
  addMoreFormErrors = {
    fieldName: '',
    fieldSubName: '',
    toDate: '',
    fromDate: '',
  };

  addMoreFormErrorMessages = {
    fieldName: {
      required: 'Field is required',
    },
    fieldSubName: {
      required: 'Field is required',
    },
    toDate: {
      required: 'Date is required',
      invalidDate: 'Invalid Date',
    },
    fromDate: {
      required: 'Date is required',
      invalidDate: 'Invalid Date',
    },
  };
  applicationFormErrors = {
    profileImage: '',
    firstName: '',
    lastName: '',
    description: '',
    contactNumber: '',
    email: '',
    address: '',
    companyImage: '',
    companyName: '',
    aboutCompany: '',
    qualification: '',
    specialization: '',
    language: '',
  };

  applicationFormErrorMessages = {
    profileImage: {
      required: 'Field is required',
    },
    firstName: {
      required: 'Field is required',
    },
    lastName: {
      required: 'Field is required',
    },
    description: {
      required: 'Field is required',
    },
    contactNumber: {
      required: 'Field is required',
    },
    email: {
      required: 'Field is required',
      pattern: 'Email must be in correct format',
    },
    address: {
      required: 'Field is required',
    },
    companyImage: {
      required: 'Field is required',
    },
    companyName: {
      required: 'Field is required',
    },
    aboutCompany: {
      required: 'Field is required',
    },
    qualification: {
      required: 'Field is required',
    },
    specialization: {
      required: 'Field is required',
    },
    language: {
      required: 'Field is required',
    },
  };
  companyImageFile: File;
  profileImageFile: File;
  emails$: Observable<any>;
  userId: string;
  userDetails: any;
  unsubscribe$ = new Subject();
  user$: Observable<any>;
  expertStatus: string;
  applyAgain = false;
  specializationList = [
    'Accounting',
    'Bookkeeping',
    'Payroll',
    'Internal Audit',
    'External Audit',
    'Sales Tax',
    'Income Tax',
    'Financial Advisor',
  ];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    private expertService: ExpertService,
    private store: Store<RootReducerState>,
    private toster: ToastrService,
    public datepipe: DatePipe,
    private activatedRoute: ActivatedRoute
  ) {
    this.emails$ = this.store.pipe(select(selectUserEmails));
    this.user$ = this.store.pipe(select(selectUser));
  }

  ngOnInit(): void {
    const type = this.activatedRoute.snapshot.params.type;
    if (type) {
      this.applyAgain = true;
    }
    this.spinner.show();
    this.loadForm();
    this.loadAddMoreForm();
    this.user$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      console.log('user', user);
      this.userDetails = user;

      this.userId = user._id;
      this.expertService
        .getApplicationStatusLambda({ id: this.userId })
        .subscribe(
          (result) => {
            this.expertStatus = result.data[0]?.status;

            this.spinner.hide();
            if (!this.expertStatus) {
              this.expertService
                .getCertificatesLambda({ id: this.userId })
                .subscribe((item) => {
                  this.spinner.hide();
                  for (let i = 0; i < item.data.length; i++) {
                    console.log('itemRunning');
                    (<FormArray>(
                      this.expertApplicationForm.get('certificate')
                    )).push(
                      this.fb.group({
                        fieldName: item.data[i].courseName,
                        fieldSubName: item.data[i].testName,
                        fromDate: this.datepipe.transform(
                          item.data[i].createdAt,
                          'yyyy-MM-dd'
                        ),
                        toDate: this.datepipe.transform(
                          item.data[i].updatedAt,
                          'yyyy-MM-dd'
                        ),
                      })
                    );
                  }
                });
            } else if (
              this.expertStatus === 'In-Process' ||
              (this.expertStatus === 'Rejected' && !this.applyAgain)
            ) {
              this.router.navigate([
                '/experts/become-expert/application-history',
              ]);
            } else if (this.expertStatus === 'Approved') {
              let a = document.createElement('a');
              const token = localStorage.getItem('refresh_token');
              // a.href = `http://localhost:4200/login?redirect=${token}&db=numetric`;
              a.href = `http://expert.numetric.work/login?redirect=${token}&db=numetric`;
              a.target = 'blank';
              a.click();
              this.router.navigate([
                '/experts/hire-expert',
              ]); 
            }
          },
          (error) => {
            this.spinner.hide();
            this.toster.error(
              this.translateService.instant('Something went wrong!')
            );
          }
        );
    });
  }

  loadForm(): void {
    this.expertApplicationForm = this.fb.group({
      profileImage: [null, [Validators.required]],
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      description: [null, [Validators.required]],
      contactNumber: [null, [Validators.required]],
      email: [
        null,
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      address: [null, [Validators.required]],
      qualification: this.fb.array([]),
      certificate: this.fb.array([]),
      experience: this.fb.array([]),
      companyImage: ['', [Validators.required]],
      companyName: [null, [Validators.required]],
      aboutCompany: [null, [Validators.required]],
      specialization: this.fb.array([]),
      language: this.fb.array([]),
    });

    this.expertApplicationForm.valueChanges.subscribe(() => {
      this.applicationFormErrors = valueChanges(
        this.expertApplicationForm,
        { ...this.applicationFormErrors },
        this.applicationFormErrorMessages,
        this.translateService
      );
    });

    this.expertApplicationForm.controls.qualification.setValidators([
      Validators.required,
    ]);
    this.expertApplicationForm.controls.specialization.setValidators([
      Validators.required,
    ]);
    this.expertApplicationForm.controls.language.setValidators([
      Validators.required,
    ]);

    this.emails$.subscribe((result) => {
      this.expertApplicationForm.controls.email.setValue(result[0]?.email);
    });

    this.onValueChanges();
  }

  loadAddMoreForm(): void {
    this.addMoreForm = this.fb.group({
      fieldName: [null, [Validators.required]],
      fieldSubName: [null, [Validators.required]],
      fromDate: [null, [Validators.required, DateValidator()]],
      toDate: [null, [Validators.required, DateValidator()]],
    });

    this.addMoreForm.valueChanges.subscribe(() => {
      console.log(this.addMoreFormErrors);
      this.addMoreFormErrors = valueChanges(
        this.addMoreForm,
        { ...this.addMoreFormErrors },
        this.addMoreFormErrorMessages,
        this.translateService
      );
    });

    this.onAddMoreValueChanges();
  }

  fileEvent(event, type: string): void {
    this.imageType = type;
    this.openModal = true;
    this.imageCropperConfig = {
      event,
      config: {
        maintainAspectRatio: true,
        roundCropper: true,
        aspectRatio: 1 / 1,
        title: 'Upload',
      },
    };
  }

  saveImage(image): void {
    const base64 = image;
    const imageName = `image${Date.now()}`;
    const imageBlob = base64ToFile(base64);
    const imageFile = new File([imageBlob], imageName, { type: 'image/png' });

    if (this.imageType === 'personalInfo') {
      this.profilePic = image;
      this.profileImageFile = imageFile;
      this.expertApplicationForm.value.profileImage = imageName;
      this.expertApplicationForm.controls.profileImage.setValue(imageName);
    } else {
      this.companyImage = image;
      this.companyImageFile = imageFile;
      this.expertApplicationForm.controls.companyImage.setValue(imageName);
    }

    const bucket = new S3({
      accessKeyId: 'AKIAQNERMZU7MHQHTCPS',
      secretAccessKey: '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',
      region: 'us-east-2',
    });

    const params = {
      Bucket: 'numetric-expert',
      Key: imageName,
      Body: imageFile,
      ACL: 'public-read',
      ContentType: 'image/jpeg',
    };
    bucket.upload(params, function (err: any, data: any) {
      if (err) {
        console.log('There was an error uploading your file: ', err);
        return false;
      }
      console.log('Successfully uploaded file.', data);
      return true;
    });

    this.openModal = false;
  }

  submitApplication(): void {
    console.log(this.expertApplicationForm);
    if (this.expertApplicationForm.invalid) {
      this.expertApplicationForm.markAllAsTouched();
      this.onValueChanges();
      return;
    }

    var form = new FormData();
    let formData = this.expertApplicationForm.value;
    formData.numetricUserId = this.userId;
    formData.region = 'JO';
    formData.status = this.userDetails.expertDetails?.isInvited
      ? 'Approved'
      : 'In-Process';
    formData.expertType = this.userDetails.expertDetails?.isInvited
      ? this.userDetails.expertDetails?.expertType
      : '';
    // this.expertApplicationForm.value.profileImage = '';
    // this.expertApplicationForm.value.companyImage = '';
    // form.append('profileImage', this.profileImageFile);
    // form.append('companyImage', this.companyImageFile);
    // form.append('form', JSON.stringify(this.expertApplicationForm.value));
    console.log('form', this.expertApplicationForm.value);

    this.spinner.show();
    this.expertService
      .createApplicationLambda(this.expertApplicationForm.value)
      .subscribe(
        (result) => {
          if (result.success) {
            this.spinner.hide();
            this.router.navigate([
              '/experts/become-expert/application-history',
            ]);
          }
        },
        (error) => {
          this.spinner.hide();
          this.toster.error(
            this.translateService.instant('Something went wrong!')
          );
        }
      );
  }

  openAddMore(type: string): void {
    this.openAddMoreModal = true;
    this.modalFieldName =
      type === 'qualification'
        ? 'university'
        : type === 'certificate'
        ? 'certificate'
        : 'experience';
    this.modalSubFieldName =
      type === 'qualification'
        ? 'course'
        : type === 'certificate'
        ? 'certificate course'
        : 'organisation';
    this.modalType = type;
    this.isEditInfo = false;
    this.addMoreForm.reset();
  }

  addMoreCard(type: string): void {
    this.cardModalField = type;
    this.fieldError = false;
    this.openCardModal = true;
  }

  saveMoreCard(): void {
    if (!this.cardFieldName) {
      this.fieldError = true;
      return;
    }
    (<FormArray>this.expertApplicationForm.get(this.cardModalField)).push(
      this.fb.group({
        name: [this.cardFieldName],
      })
    );
    this.removeCardItems();
    this.openCardModal = false;
    this.cardFieldName = null;
  }

  closeModal(modal: string): void {
    if (modal === 'card') {
      this.openCardModal = false;
    } else {
      this.openAddMoreModal = false;
    }
  }

  openRow(event: Event, type: string): void {
    console.log('type', event, type);
    if (event) {
      this.openedAccordion.push(type);
    } else {
      const typeIndex = this.openedAccordion.indexOf(type);
      this.openedAccordion.splice(typeIndex, 1);
    }
  }

  saveMoreInfo(): void {
    if (this.addMoreForm.invalid) {
      this.addMoreForm.markAllAsTouched();
      this.onAddMoreValueChanges();
      return;
    }

    if (this.isEditInfo) {
      switch (this.modalType) {
        case 'qualification':
          this.qualification.controls[this.currentIndex].setValue({
            fieldName: [this.addMoreForm.value.fieldName],
            fieldSubName: [this.addMoreForm.value.fieldSubName],
            fromDate: [this.addMoreForm.value.fromDate],
            toDate: [this.addMoreForm.value.toDate],
          });
          break;
        case 'certificate':
          this.certificate.controls[this.currentIndex].setValue({
            fieldName: [this.addMoreForm.value.fieldName],
            fieldSubName: [this.addMoreForm.value.fieldSubName],
            fromDate: [this.addMoreForm.value.fromDate],
            toDate: [this.addMoreForm.value.toDate],
          });
          break;
        case 'experience':
          this.experience.controls[this.currentIndex].setValue({
            fieldName: [this.addMoreForm.value.fieldName],
            fieldSubName: [this.addMoreForm.value.fieldSubName],
            fromDate: [this.addMoreForm.value.fromDate],
            toDate: [this.addMoreForm.value.toDate],
          });
          break;
      }
    } else {
      (<FormArray>this.expertApplicationForm.get(this.modalType)).push(
        this.fb.group({
          fieldName: [this.addMoreForm.value.fieldName],
          fieldSubName: [this.addMoreForm.value.fieldSubName],
          fromDate: [this.addMoreForm.value.fromDate],
          toDate: [this.addMoreForm.value.toDate],
        })
      );
    }
    this.openAddMoreModal = false;
  }

  editInfo(type: string, index: number): void {
    this.isEditInfo = true;
    this.modalType = type;
    this.currentIndex = index;
    switch (this.modalType) {
      case 'qualification':
        this.addMoreForm.setValue({
          fieldName: [this.qualification.controls[index].value.fieldName],
          fieldSubName: [this.qualification.controls[index].value.fieldSubName],
          fromDate: [this.qualification.controls[index].value.fromDate],
          toDate: [this.qualification.controls[index].value.toDate],
        });

        break;
      case 'certificate':
        console.log(this.certificate.controls[index].value);

        this.addMoreForm.setValue({
          fieldName: [this.certificate.controls[index].value.fieldName],
          fieldSubName: [this.certificate.controls[index].value.fieldSubName],
          fromDate: [this.certificate.controls[index].value.fromDate],
          toDate: [this.certificate.controls[index].value.toDate],
        });

        break;
      case 'experience':
        this.addMoreForm.setValue({
          fieldName: [this.experience.controls[index].value.fieldName],
          fieldSubName: [this.experience.controls[index].value.fieldSubName],
          fromDate: [this.experience.controls[index].value.fromDate],
          toDate: [this.experience.controls[index].value.toDate],
        });
        break;
    }
    this.openAddMoreModal = true;
  }

  get qualification() {
    return this.expertApplicationForm.get('qualification') as FormArray;
  }
  get certificate() {
    return this.expertApplicationForm.get('certificate') as FormArray;
  }
  get experience() {
    return this.expertApplicationForm.get('experience') as FormArray;
  }

  onValueChanges(): void {
    this.applicationFormErrors = valueChanges(
      this.expertApplicationForm,
      { ...this.applicationFormErrors },
      this.applicationFormErrorMessages,
      this.translateService
    );
  }
  onAddMoreValueChanges(): void {
    this.addMoreFormErrors = valueChanges(
      this.addMoreForm,
      { ...this.addMoreFormErrors },
      this.addMoreFormErrorMessages,
      this.translateService
    );
  }

  trackByFn(index: number): number {
    return index;
  }

  change(event): void {
    console.log(event);
  }

  removeItem(index, type): void {
    (<FormArray>this.expertApplicationForm.controls[type]).removeAt(index);
    this.removeCardItems();
  }

  selectExpertType(value: string): void {
    this.cardFieldName = value;
    if (this.cardFieldName) {
      this.fieldError = false;
    }
  }

  removeCardItems(): void {
    this.specializationList = [
      'Accounting',
      'Bookkeeping',
      'Payroll',
      'Internal Audit',
      'External Audit',
      'Sales Tax',
      'Income Tax',
      'Financial Advisor',
    ];
    const values = this.expertApplicationForm.get(this.cardModalField).value;
    const updateValues = values.map((item) => item.name);
    this.specializationList = this.specializationList.filter(function (el) {
      return updateValues.indexOf(el) < 0;
    });
  }

  removeValidation(): void {
    if (this.cardFieldName) {
      this.fieldError = false;
    }
  }
}
