<div class="p-8 rounded-3xl bg-light-gray-1 mt-2 mb-2">
    <span class="flex items-center">
    <h3 class="Gilroy-Bold text-lg">{{'Two-Factor Authentication' | translate }}</h3>
    </span>
    <p class="mt-2 Gilory-Medium">{{'2FA message' | translate }}</p>
    <hr class="w-0.5 border-gray-300 my-3">
    <div class="flex space-x-4 rtl:space-x-reverse items-center">
        <p class="Gilroy" >{{'Enable Two-factor Authentication' | translate}}:</p>
        <label for="toggle" class="flex items-center cursor-pointer relative">
            <input type="checkbox" (change)="confirm2FA = true" [formControl]="authentication" id="toggle" class="sr-only">
            <div [ngClass]="authentication?.value ? 'after:bg-white' : 'after:bg-blue-600'" class="toggle-bg rtl:after:translate-x-[260%]  bg-white border  h-6 w-11 rounded-full"></div>
        </label>
    </div>
</div>

<app-modal-container [isOpen]='confirm2FA'>
    <div header class="Gilroy-Bold border-b pb-2">{{authentication?.value ? 'Disable' : 'Enable'}} {{'Two-Factor Authentication' | translate }}</div>
    <div content>
        <p class="mb-2">
            {{'Are you sure you want to' | translate }} {{(authentication?.value ? 'Disable' : 'Enable') | lowercase }} {{'Two-Factor Authentication' | translate}}?
        </p>
    </div>
    <div footer class="flex space-x-4">
        <app-button type='third' buttonText='{{"Ok" | translate }}' rounded='true' (handleClick)='changeAuthentication()'></app-button>
        <app-button type='danger' buttonText='{{"Cancel" | translate }}' rounded='true' (handleClick)='confirm2FA = false'></app-button>
    </div>
</app-modal-container>