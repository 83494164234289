<!-- <div class="min-h-screen">
    <div class="p-4 space-y-6">
        <div class="flex justify-between items-start">
            <div class="1/3 flex flex-col items-start">
                <h1 class="Poppins-Bold text-2xl">{{'My Files' | translate}}</h1>
                <button (click)="openFileModal()" class="bg-black mt-4 text-white Poppins-Bold px-6 py-3 rounded-2xl">{{'Upload File' | translate}}</button>
            </div>
            <div class="flex flex-col">
                <svg routerLink="/files/shared" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" data-slot="icon" class="w-36 h-16 cursor-pointer outline-white">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
                </svg>
                <p class="text-sm Poppins-SemiBold text-center">Shared with me</p>
            </div>
        </div> -->
        <div class="mt-4">
            <app-button type="third" (handleClick)="openFileModal()">{{
              "Upload File" | translate
            }}</app-button>
            <app-table-container
              [tableHeadings]="tableHeadings"
              [tableData]="allFiles"
              [keys]="tableKeys"
              [actionTemplate]="actionTemplate"
              class="task-grid-table"
            >
              <ng-template let-item #actionTemplate>
                <td class="p-2">
                  <p>{{ item.createdAt | date }}</p>
                </td>
                <td class="p-2 ltr:pr-10 rtl:pl-10 text-center">
                  <svg
                    (click)="viewOrDownloadFile(item.files[0]?._id)"
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#1c1c1ccc"
                  >
                    <path
                      d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"
                    />
                  </svg>
                </td>
                <td class="p-2 ltr:pr-8 rtl:pl-8 text-center">
                  <svg
                    (click)="shareFile(item._id)"
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#1c1c1ccc"
                  >
                    <path
                      d="M680-80q-50 0-85-35t-35-85q0-6 3-28L282-392q-16 15-37 23.5t-45 8.5q-50 0-85-35t-35-85q0-50 35-85t85-35q24 0 45 8.5t37 23.5l281-164q-2-7-2.5-13.5T560-760q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-24 0-45-8.5T598-672L317-508q2 7 2.5 13.5t.5 14.5q0 8-.5 14.5T317-452l281 164q16-15 37-23.5t45-8.5q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-80q17 0 28.5-11.5T720-200q0-17-11.5-28.5T680-240q-17 0-28.5 11.5T640-200q0 17 11.5 28.5T680-160ZM200-440q17 0 28.5-11.5T240-480q0-17-11.5-28.5T200-520q-17 0-28.5 11.5T160-480q0 17 11.5 28.5T200-440Zm480-280q17 0 28.5-11.5T720-760q0-17-11.5-28.5T680-800q-17 0-28.5 11.5T640-760q0 17 11.5 28.5T680-720Zm0 520ZM200-480Zm480-280Z"
                    />
                  </svg>
                </td>
                <td class="p-2 ltr:pr-8 rtl:pl-8 text-center">
                  <svg
                    (click)="viewFileInNewTab(item.files[0]?._id)"
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#1c1c1ccc"
                  >
                    <path
                      d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"
                    />
                  </svg>
                </td>
                <td class="p-2 ltr:pl-12 rtl:pr-12 text-center">
                  <svg
                    (click)="deleteModal(item)"
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#1c1c1ccc"
                  >
                    <path
                      d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"
                    />
                  </svg>
                </td>
              </ng-template>
            </app-table-container>
          </div>
          <!-- </div>
          </div> -->
          
          <app-modal-container [isOpen]="isFileModalOpen">
            <div header class="flex justify-between items-center">
              <h1 class="Poppins-Bold text-2xl">{{ "Add a file" | translate }}</h1>
              <svg
                (click)="closeFileModal()"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 cursor-pointer"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
            <div content>
              <div class="space-y-3 border-b pb-8">
                <div class="flex flex-col space-y-1">
                  <label
                    for="fileCompact"
                    class="bg-input-light flex gap-2 items-center p-5 rounded-lg shadow-card"
                  >
                    <div
                      class="h-16 w-16 flex justify-center items-center rounded-[80px] bg-[#1C1C1C0D] shadow-btn"
                    >
                      <img src="assets/images/upload.svg" alt="" class="" />
                      <input
                        id="fileCompact"
                        (change)="onFileSelect($event)"
                        [(ngModel)]="fileUploaded"
                        (click)="$event.target.value = null"
                        hidden
                        #fileInput
                        accept=".jpeg, .jpg, .png, .pdf, .docx, .xlsx, .csv, .xml"
                        type="file"
                      />
                    </div>
                    <span *ngIf="files.length" class="text-sm Poppins-Medium">
                      {{ this.files[0]?.name }}
                    </span>
                    <span *ngIf="!files.length" class="text-sm Poppins-Medium">
                      {{ "Add a File" | translate }}
                    </span>
                  </label>
                  <span class="text-red-500 text-sm" *ngIf="fileError">{{
                    "File is required" | translate
                  }}</span>
                </div>
                <!-- <label for="file">
                          <div  class="fileDropZone border-2 border-dashed rounded-2xl cursor-pointer h-[150px] flex flex-col items-center justify-center space-y-1"  ngFileDragDrop (uploadedFiles)="getUploadedFiles($event)" (fileDropped)="getUploadedFiles($event)">
                              <div class="flex space-x-2 items-center">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13" />
                                  </svg>
                              </div>
                              <p class="Poppins-Medium text-gray-500">Click or drag file to this area to upload from local drive.</p>
                              <input #fileInput id="file" class="hidden" (change)="onFileSelect($event)"  type="file"  multiple>
                          </div>
                      </label> -->
              </div>
            </div>
            <div footer>
              <app-button type="third" (handleClick)="uploadFiles()">{{
                "Upload File" | translate
              }}</app-button>
            </div>
          </app-modal-container>
          
          <app-modal-container [isOpen]="isShareModalOpen">
            <div header>
              <ng-container *ngIf="isRemoveFileAccess; else shareFileAbility">
                <div class="flex justify-start gap-2 items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 inline cursor-pointer ltr:rotate-0 rtl:rotate-180"
                    (click)="goBackToList()"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                    />
                  </svg>
                  <h1 class="Poppins-Bold text-2xl">{{ "Remove access" | translate }}</h1>
                </div>
              </ng-container>
              <ng-template #shareFileAbility>
                <div class="flex justify-between items-center">
                  <h1 *ngIf="!isRemoveFileAccess" class="Poppins-Bold text-2xl">
                    {{ "Share a file" | translate }}
                  </h1>
                  <svg
                    *ngIf="!isRemoveFileAccess"
                    (click)="closeShareModal()"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 cursor-pointer"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </ng-template>
            </div>
            <div content>
              <div *ngIf="!isRemoveFileAccess">
                <div class="space-y-3 border-b pb-8 share-file-modal">
                  <div class="flex flex-col space-y-1">
                    <ng-select
                      [items]="allBusinessUsers"
                      class="new-dropdown"
                      bindLabel="name"
                      bindValue="_id"
                      [(ngModel)]="selectedUser"
                      (change)="userSelected($event)"
                      placeholder="{{ 'Select Users to Share' | translate }}"
                    >
                      <ng-template ng-option-tmp let-item="item" let-index="index">
                        <div class="px-2 py-1">
                          <div
                            class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                          >
                            {{ item.name }}
                          </div>
                        </div>
                      </ng-template>
                    </ng-select>
                    <span class="text-red-500 text-sm" *ngIf="selectUserError">{{
                      "Please select a user" | translate
                    }}</span>
                  </div>
                </div>
                <div class="pt-4">
                  <h4 class="text-base Poppins-SemiBold pb-4">
                    {{ "File is shared with below users" | translate }}
                  </h4>
                  <ng-container
                    *ngIf="fileSharedWithUsers?.length > 0; else fileNotShared"
                  >
                    <table class="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr class="h-12 text-start">
                          <th class="Poppins-Bold">{{ "Name" | translate }}</th>
                          <th class="Poppins-Bold text-end">
                            {{ "Action" | translate }}
                          </th>
                        </tr>
                      </thead>
                      <tbody class="divide-y divide-gray-300">
                        <tr *ngFor="let item of fileSharedWithUsers" class="h-12">
                          <td>
                            {{ item.userId.firstName + " " + item.userId.lastName }}
                          </td>
                          <td class="text-center">
                            <span class="flex justify-end" (click)="removeAccess(item)">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                data-slot="icon"
                                class="w-12 h-6 cursor-pointer"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                />
                              </svg>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </ng-container>
                  <ng-template #fileNotShared>{{
                    "No data available" | translate
                  }}</ng-template>
                </div>
              </div>
              <div *ngIf="isRemoveFileAccess">
                <div>
                  <h4 class="text-lg">
                    {{
                      "Are you sure you want to remove access of this file for"
                        | translate
                    }}
                    <span class="Poppins-SemiBold">{{
                      removeUserDetails.userId.firstName +
                        " " +
                        removeUserDetails.userId.lastName
                    }}</span>
                    ?
                  </h4>
                </div>
              </div>
            </div>
            <div footer>
              <app-button
                type="third"
                (handleClick)="confirmShare()"
                *ngIf="!isRemoveFileAccess"
                >{{ "Share" | translate }}</app-button
              >
              <app-button
                type="third"
                (handleClick)="confirmRemoveAccess()"
                *ngIf="isRemoveFileAccess"
                >{{ "Remove" | translate }}</app-button
              >
            </div>
          </app-modal-container>
          
          <app-modal-container [isOpen]="isDeleteModalOpen">
            <div header class="flex justify-between">
              <h1 class="Poppins-Bold text-2xl">
                {{ "Delete File" | translate }}
              </h1>
              <svg
                (click)="closeDeleteModal()"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 cursor-pointer"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
            <div content>
              <h4 class="text-lg">
                {{ "Are you sure you want to delete this file" | translate }}
                <span class="Poppins-SemiBold">{{ fileToDelete?.fileName }}</span
                >?
              </h4>
            </div>
            <div footer>
              <app-button type="third" (handleClick)="confirmDelete()">{{
                "Delete File" | translate
              }}</app-button>
            </div>
          </app-modal-container>
          