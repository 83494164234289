<div class="flex justify-center p-4">
    <div class="w-11/12 space-y-8">
        <div class="flex justify-between items-center">
            <h1 class="Gilroy-Bold text-xl">{{'Discount Code Management' | translate}}</h1>
            <app-button buttonText='{{"Create" | translate }}' [rounded]='true' type='primary' (handleClick)='openDiscountModal()'></app-button>
        </div>
        <app-table-container [tableHeadings]='tableHeadings' [tableData]='tableData' [keys]='tableKeys' [actionTemplate]='actionTemplate'>
            <ng-template let-item #actionTemplate>
                <td>
                    <p class="Gilroy" >{{item?.usedByMobile.length}}</p>
                </td>
                <td>
                    <p class="Gilroy" >{{item?.expiryDate | date }}</p>
                </td>
                <td class="flex space-x-2 items-center">
                    <!-- <p class="Gilroy" [ngClass]="!item?.isActive ? 'text-red-600' : 'text-green-500' " >{{item?.isActive ? 'Active' : 'Inactive'}}</p> -->
                    <label class="toggle mt-1">
                        <input type="checkbox" (change)="changeStatus(item)" [(ngModel)]="item.isActive" />
                        <span class="slider round"></span>
                    </label>
                </td>
                <td class="p-2 text-center last:pe-10">
                    <app-dropdown #dropdown>
                        <div button (click)='dropdown.open = true' (clickOutside)='dropdown.open = false'>
                            <div class="h-8 w-8 rounded-full flex justify-center items-center bg-gray-50 border border-gray-200">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                                </svg>
                            </div>
                        </div>
                        <div options>
                            <div (click)="openEditModal(item)" class="cursor-pointer px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center">{{'Edit' | translate}}</div>
                            <div (click)="navToView(item._id)" class="cursor-pointer px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center">{{'View' | translate}}</div>
                        </div>
                    </app-dropdown>             
                </td>
            </ng-template>
        </app-table-container>
    </div>
</div>
<app-modal-container [isOpen]='showDiscountCodeModal'>
    <div header class="text-start border-b pb-2 Gilroy-Bold">{{(editCode ? 'Updated Discount Code' : 'Create Discount Code') | translate}}</div>
    <div class="text-start" content>
       <form [formGroup]="discountCodeForm" class="space-y-4">
            <div class="space-y-1">
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-full">
                    <label for='discountCode' class="block text-sm Gilroy-Bold font-color-05">{{'Discount Code' | translate }}</label>
                    <input id='discountCode' formControlName='code' placeholder="{{'Discount Code' | translate }}" type="text" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-300" />
                </div>
                <p *ngIf="formErrors.code" class="text-red-500 text-sm">{{formErrors.code}}</p>
            </div>
            <div class="space-y-1">
                <label class="block text-sm Gilroy-Bold font-color-05">{{'Discount Type' | translate }}</label>
                <div class="flex space-x-8 items">
                    <div class="flex space-x-2 items-center">
                        <input type="radio" formControlName="discountType" value="Percentage" name="discountType"/>
                        <p class="Gilroy-Medium">{{'Percentage' | translate}}</p>
                    </div>
                    <!-- <div class="flex space-x-2 items-center">
                        <input type="radio" formControlName="discountType" value="Value" name="discountType"/>
                        <p class="Gilroy-Medium">{{'Value' | translate}}</p>
                    </div> -->
                </div>
                <p *ngIf="formErrors.discountType" class="text-red-500 text-sm">{{formErrors.discountType}}</p>
            </div>
            <div class="space-y-1">
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-full">
                    <label for='discountValue' class="block text-sm Gilroy-Bold font-color-05">{{'Discount Value' | translate }}</label>
                    <input id='discountValue' formControlName='discountValue' placeholder="{{'Discount Value' | translate }}" type="number" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-300" />
                </div>
                <p *ngIf="formErrors.discountValue" class="text-red-500 text-sm">{{formErrors.discountValue}}</p>
            </div>

            <div class="space-y-1">
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-full">
                    <label for='limit' class="block text-sm Gilroy-Bold font-color-05">{{'Usage Limit' | translate }}</label>
                    <input id='limit' formControlName='discountCount' placeholder="{{'Usage Limit' | translate }}" type="number" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-300" />
                </div>
                <p *ngIf="formErrors.discountCount" class="text-red-500 text-sm">{{formErrors.discountCount}}</p>
            </div>
            <div class="space-y-1">
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-full">
                    <label for='singleCustomer' class="block text-sm Gilroy-Bold font-color-05">{{'Single Customer Usage Limit' | translate }}</label>
                    <input id='singleCustomer' formControlName='customerUsageCount' placeholder="{{'Single Customer Usage Limit' | translate }}" type="number" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-300" />
                </div>
                <p *ngIf="formErrors.customerUsageCount" class="text-red-500 text-sm">{{formErrors.customerUsageCount}}</p>
            </div>
            <div class="space-y-1">
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-full">
                    <label for='expiryDate' class="block text-sm Gilroy-Bold font-color-05">{{'Expiry Date' | translate }}</label>
                    <input id='expiryDate' formControlName='expiryDate' placeholder="{{'Expiry Date' | translate }}" type="date" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-300" />
                </div>
                <p *ngIf="formErrors.expiryDate" class="text-red-500 text-sm">{{formErrors.expiryDate}}</p>
            </div>
            <div class="space-y-1">
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-full">
                    <label for='description' class="block text-sm Gilroy-Bold font-color-05">{{'Description' | translate }}</label>
                    <textarea id='description' formControlName='description' placeholder="{{'Description' | translate }}" rows="4" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-300" ></textarea>
                </div>
                <p *ngIf="formErrors.description" class="text-red-500 text-sm">{{formErrors.description}}</p>
            </div>
       </form>
    </div>
    <div footer class="flex space-x-2 rtl:space-x-reverse">
        <app-button *ngIf="editCode" buttonText='{{"Update" | translate}}' type='primary' rounded=true (handleClick)='updateDiscountCode()'></app-button>
        <app-button *ngIf="!editCode" buttonText='{{"Create" | translate}}' type='primary' rounded=true (handleClick)='createDiscountCode()'></app-button>
        <app-button buttonText='{{"Cancel" | translate}}' type='danger' rounded=true (handleClick)='showDiscountCodeModal = false' ></app-button>
    </div>
  </app-modal-container>