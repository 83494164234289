<div class="w-full p-8 min-h-[85svh]" [ngClass]="{'!w-[100vw] !p-0': isMobileDevice}">
  <div class="bg-white px-14 py-11 rounded-xl space-y-6 relative" [ngClass]="{'!px-6 !py-7': isMobileDevice}">
    <div class="w-full flex flex-col space-y-4">
      <div class="flex justify-between items-center">
        <!-- <h1 class="text-2xl Poppins-Medium inline-flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="me-2" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000">
                        <path d="M222-200 80-342l56-56 85 85 170-170 56 57-225 226Zm0-320L80-662l56-56 85 85 170-170 56 57-225 226Zm298 240v-80h360v80H520Zm0-320v-80h360v80H520Z"/>
                    </svg>
                    {{'Task Manager' | translate}}</h1> -->
        <app-dropdown
          class="absolute ltr:right-4 rtl:left-4 right-options connect-redirection-options"
          #dropdownOptions
        >
          <!-- <app-button button type='primary' buttonText='{{"Add New Transaction" | translate}}' size='large' (click)='dropdownOptions.open=true' (clickOutside)='dropdownOptions.open=false'></app-button> -->
          <svg
            button
            (click)="dropdownOptions.open = true"
            (clickOutside)="dropdownOptions.open = false"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 grip-icon-mobile"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
          <div options>
            <span
              routerLink="/chats"
              class="flex items-center cursor-pointer px-4 py-2 text-sm Poppins-Medium hover:bg-gray-50 text-center rounded-t-xl"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 me-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z"
                /></svg>{{ "Chats" | translate }}</span
            >
            <span
              routerLink="/task"
              class="flex items-center cursor-pointer px-4 py-2 text-sm Poppins-Medium hover:bg-gray-50 text-center rounded-b-xl"
            >
              <!-- <svg class="h-6 w-6 me-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="currentColor"><path d="M9 6L20 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.80002 5.79999L4.60002 6.59998L6.60001 4.59999" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.80002 11.8L4.60002 12.6L6.60001 10.6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.80002 17.8L4.60002 18.6L6.60001 16.6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9 12L20 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9 18L20 18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg> -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="me-2"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#000"
              >
                <path
                  d="M222-200 80-342l56-56 85 85 170-170 56 57-225 226Zm0-320L80-662l56-56 85 85 170-170 56 57-225 226Zm298 240v-80h360v80H520Zm0-320v-80h360v80H520Z"
                />
              </svg>
              {{ "Task-Manager" | translate }}</span
            >
            <span
              routerLink="/files"
              class="flex items-center cursor-pointer px-4 py-2 text-sm Poppins-Medium hover:bg-gray-50 text-center rounded-b-xl"
              ><svg
                class="h-6 w-6 me-2"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.6776 2.30337C11.5101 1.89888 12.4899 1.89888 13.3223 2.30337L20.6379 5.85815C22.454 6.74064 22.454 9.25936 20.6379 10.1419L13.3223 13.6966C12.4899 14.1011 11.5101 14.1011 10.6776 13.6966L3.36208 10.1419C1.54595 9.25936 1.54595 6.74064 3.36208 5.85815L10.6776 2.30337ZM12.4408 4.01685C12.1633 3.88202 11.8367 3.88202 11.5592 4.01685L4.24364 7.57163C3.88042 7.74813 3.88042 8.25187 4.24364 8.42837L11.5592 11.9831C11.8367 12.118 12.1633 12.118 12.4408 11.9831L19.7563 8.42837C20.1196 8.25187 20.1195 7.74813 19.7563 7.57163L12.4408 4.01685Z"
                  fill="#000"
                />
                <path
                  d="M2.10555 16.8172C2.35254 16.3232 2.95321 16.123 3.44719 16.37L11.5528 20.4227C11.8343 20.5635 12.1657 20.5635 12.4472 20.4227L20.5528 16.37C21.0467 16.123 21.6474 16.3232 21.8944 16.8172C22.1414 17.3111 21.9412 17.9118 21.4472 18.1588L13.3416 22.2116C12.497 22.6339 11.5029 22.6339 10.6583 22.2116L2.55276 18.1588C2.05878 17.9118 1.85856 17.3111 2.10555 16.8172Z"
                  fill="#000"
                />
                <path
                  d="M3.44742 12.1058C2.95344 11.8588 2.35277 12.059 2.10578 12.553C1.85879 13.047 2.05901 13.6477 2.55299 13.8946L10.6586 17.9474C11.5031 18.3697 12.4973 18.3697 13.3418 17.9474L21.4474 13.8946C21.9414 13.6477 22.1416 13.047 21.8946 12.553C21.6476 12.059 21.047 11.8588 20.553 12.1058L12.4474 16.1586C12.1659 16.2993 11.8345 16.2993 11.553 16.1586L3.44742 12.1058Z"
                  fill="#000"
                /></svg>{{ "Files" | translate }}</span
            >
            <a
              (click)="goToBusiness()"
              class="flex items-center cursor-pointer px-4 py-2 text-sm Poppins-Medium hover:bg-gray-50 text-center rounded-b-xl"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-6 w-6 me-2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
                />
              </svg>
              {{ "Switch Business" | translate }}</a
            >
            <a
              href="https://onelink.to/q8xxk4"
              target="_blank"
              class="flex items-center cursor-pointer px-4 py-2 text-sm Poppins-Medium hover:bg-gray-50 text-center rounded-b-xl"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-6 w-6 me-2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                />
              </svg>
              {{ "Download App" | translate }}</a
            >
            <span
              (click)="logout()"
              class="flex items-center cursor-pointer px-4 py-2 text-sm Poppins-Medium hover:bg-gray-50 text-center rounded-b-xl"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-6 me-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                  clip-rule="evenodd"
                />
              </svg>
              {{ "Logout" | translate }}
            </span>
          </div>
        </app-dropdown>
      </div>
      <div class="flex justify-between task-type-options">
        <div class="w-[75%] flex flex-wrap gap-4 task-sub-option">
          <ng-container *ngFor="let quickFilter of quickFilters">
            <app-button
              (handleClick)="applyQuickFilter(quickFilter.filterBy)"
              [customClass]="quickFilter.active ? 'bg-[#F0F5FF]' : ''"
              type="third"
              class="task-manage-buttons"
            >
              <svg
                *ngIf="quickFilter.status === 'allTasks'"
                class="task-filter-icons"
                width="24px"
                stroke-width="1.5"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                color="currentColor"
              >
                <path
                  d="M9 6L20 6"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M3.80002 5.79999L4.60002 6.59998L6.60001 4.59999"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M3.80002 11.8L4.60002 12.6L6.60001 10.6"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M3.80002 17.8L4.60002 18.6L6.60001 16.6"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M9 12L20 12"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M9 18L20 18"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <svg
                *ngIf="quickFilter.status === 'inProgress'"
                width="24px"
                height="24px"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 task-filter-icons"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
                />
              </svg>
              <svg
                *ngIf="quickFilter.status === 'completed'"
                width="24px"
                height="24px"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 task-filter-icons"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <svg
                *ngIf="quickFilter.status === 'upcoming'"
                width="24px"
                height="24px"
                stroke-width="1.5"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                color="currentColor"
                class="task-filter-icons"
              >
                <path
                  d="M1.5 12.5L5.57574 16.5757C5.81005 16.8101 6.18995 16.8101 6.42426 16.5757L9 14"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                ></path>
                <path
                  d="M16 7L12 11"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                ></path>
                <path
                  d="M7 12L11.5757 16.5757C11.8101 16.8101 12.1899 16.8101 12.4243 16.5757L22 7"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                ></path>
              </svg>
              <svg
                *ngIf="quickFilter.status === 'overdue'"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 task-filter-icons"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3 8.25V18a2.25 2.25 0 002.25 2.25h13.5A2.25 2.25 0 0021 18V8.25m-18 0V6a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 6v2.25m-18 0h18M5.25 6h.008v.008H5.25V6zM7.5 6h.008v.008H7.5V6zm2.25 0h.008v.008H9.75V6z"
                />
              </svg>
              <svg
                *ngIf="quickFilter.status === 'assignedToMe'"
                width="24px"
                height="24px"
                stroke-width="1.5"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                color="currentColor"
                class="task-filter-icons"
              >
                <path
                  d="M5 20V19C5 15.134 8.13401 12 12 12V12C15.866 12 19 15.134 19 19V20"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M12 12C14.2091 12 16 10.2091 16 8C16 5.79086 14.2091 4 12 4C9.79086 4 8 5.79086 8 8C8 10.2091 9.79086 12 12 12Z"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h2 class="Poppins-Medium text-sm" [ngClass]="{ '!text-[12px]': isMobileDevice }">
                {{ quickFilter.filterBy | translate }}
              </h2>
            </app-button>
          </ng-container>
        </div>
        <div class="1/3 flex items-end flex-col gap-4 full-width email-digest-filter">
          <div class="filter-create-task flex items-center gap-3">
            <app-dropdown #dropdown>
              <app-button
                button
                buttonText="{{ 'Filter' | translate }}"
                type="primary"
                class="self-end task-manage-buttons"
                (handleClick)="dropdown.open = true"
                (clickOutside)="dropdown.open = false"
              ></app-button>
              <div class="ltr:ml-6 rtl:mr-6 py-4" options>
                <h3 class="text-sm Poppins-Medium">
                  {{ "Filter" | translate }}
                </h3>
                <ul class="flex flex-col gap-3 mt-4">
                  <li class="text-xs items-center">
                    <input
                      type="checkbox"
                      (change)="filterByStatus($event, 'All')"
                      class="me-2 filter-checkbox focus:ring-0"
                      id="isSelected"
                    />{{ "All Tasks" | translate }}
                  </li>
                  <li class="text-xs items-center">
                    <input
                      type="checkbox"
                      (change)="filterByStatus($event, 'Today(s) task')"
                      class="me-2 filter-checkbox focus:ring-0"
                      id="isSelected"
                    />{{ "Today's task" | translate }}
                  </li>
                </ul>
              </div>
            </app-dropdown>
            <!-- <button (click)="openCreateTask()" class="button-new-class text-white Poppins-Medium px-6 py-3 rounded-2xl filter-mobile-view">{{'Create Task' | translate}}</button> -->
            <app-button
              appAccessControl
              moduleType="sales"
              accessType="edit"
              size="large"
              buttonText="{{ 'Create Task' | translate }}"
              type="primary"
              (handleClick)="openCreateTask()"
              class="task-manage-buttons"
            ></app-button>
          </div>
          <ng-container *ngIf="!isMobileDevice">
            <app-dropdown #dropdownEmail>
              <app-button
                type="third"
                button
                (click)="dropdownEmail.open = true"
                (clickOutside)="dropdownEmail.open = false"
                class="task-manage-buttons"
              >
                <p class="Poppins-Medium">
                  {{ "Email Digest:" | translate }}
                  {{ isEmailSettings ? "On" : "Off" }}
                  <span *ngIf="isEmailSettings"
                    >({{ mailFrequency | titlecase }})</span
                  >
                </p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </app-button>
              <div options class="p-4 space-y-4">
                <div
                  class="flex space-x-2 items-center cursor-pointer"
                  (click)="
                    dropdownEmail.open = false;
                    emailSettingsModal = true;
                    isEmailSettings = true
                  "
                >
                  <span class="w-6">
                    <svg
                      *ngIf="isEmailSettings"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m4.5 12.75 6 6 9-13.5"
                      />
                    </svg>
                  </span>
                  <p class="Poppins-Medium">
                    {{ "On" | translate
                    }}<span *ngIf="isEmailSettings"
                      >({{ "Edit" | titlecase }})</span
                    >
                  </p>
                </div>
                <div
                  class="flex space-x-2 items-center cursor-pointer"
                  (click)="
                    dropdownEmail.open = false;
                    emailSettingsModal = true;
                    isEmailSettings = false
                  "
                >
                  <span class="w-6">
                    <svg
                      *ngIf="!isEmailSettings"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m4.5 12.75 6 6 9-13.5"
                      />
                    </svg>
                  </span>
                  <p class="Poppins-Medium">{{ "Off" | translate }}</p>
                </div>
              </div>
            </app-dropdown>
          </ng-container>
        </div>
      </div>
      <div class="mt-4">
        <app-table-container
          [tableHeadings]="tableHeadings"
          [tableData]="filteredTask"
          [actionTemplate]="actionTemplate"
          class="task-grid-table"
        >
          <ng-template let-item #actionTemplate>
            <ng-container>
              <td
                [routerLink]="
                  item.taskTitle ? ['/task/view-task', item._id] : ''
                "
                class="p-2 px-10 cursor-pointer"
                [ngClass]="{'!px-4' : isMobileDevice}"
              >
                <p class="">{{ item.taskTitle || "-" }}</p>
              </td>
              <td
                [routerLink]="
                  item.taskTitle ? ['/task/view-task', item._id] : ''
                "
                class="p-2 cursor-pointer whitespace-nowrap"
              >
                <p class="">{{ item.assignToName || "-" }}</p>
              </td>
              <td
                [routerLink]="
                  item.taskTitle ? ['/task/view-task', item._id] : ''
                "
                class="p-2 cursor-pointer"
              >
                <p class="">{{ item.taskType || "-" }}</p>
              </td>
              <td
                [routerLink]="
                  item.taskTitle ? ['/task/view-task', item._id] : ''
                "
                class="p-2 cursor-pointer"
              >
                <p class="">{{ item.dueDate | date }}</p>
              </td>
              <td
                [routerLink]="
                  item.taskTitle ? ['/task/view-task', item._id] : ''
                "
                class="p-2 cursor-pointer last:pe-10 text-center"
              >
                <p
                  [ngClass]="{
                    'text-green-500': item.status === 'Completed',
                    'text-black': item.status === 'Upcoming',
                    'text-blue-500': item.status === 'In Progress',
                    'text-red-500': item.status === 'Overdue'
                  }"
                  class="bg-gray-50 px-6 py-2 rounded-2xl Poppins-Medium w-fit"
                >
                  {{ item.status | translate }}
                </p>
              </td>
              <td class="p-2 text-center task-menu-list">
                <app-dropdown [isFromSetting]="true" #dropdown>
                  <div
                    button
                    (click)="dropdown.open = true"
                    (clickOutside)="dropdown.open = false"
                  >
                    <div
                      class="h-8 w-8 rounded-[4px] flex justify-center items-center border-2 border-[#DCDCDC]"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </div>
                  </div>
                  <div options class="new-shadow p-1 text-start">
                    <div
                      (click)="viewTask(item._id)"
                      class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                      (click)="dropdown.open = false"
                    >
                      <p class="Poppins-Medium">
                        {{ "View Task" | translate }}
                      </p>
                    </div>
                    <div
                      *ngIf="isCreatedByMe(item)"
                      (click)="openEditModel(item)"
                      class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                      (click)="dropdown.open = false"
                    >
                      <p class="Poppins-Medium">
                        {{ "Edit Task" | translate }}
                      </p>
                    </div>
                    <div
                      *ngIf="isCreatedByMe(item)"
                      (click)="openDeleteModal(item)"
                      class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                    >
                      <p class="Poppins-Medium">
                        {{ "Delete Task" | translate }}
                      </p>
                    </div>
                  </div>
                </app-dropdown>
              </td>
            </ng-container>
          </ng-template>
        </app-table-container>
      </div>
    </div>
  </div>
</div>
<app-modal-container [isOpen]="isOpen">
  <div header>
    <div class="flex justify-between items-center">
      <h1 class="Poppins-Medium text-2xl">
        {{ edit ? "Update Task" : ("Create Task" | translate) }}
      </h1>
      <svg
        (click)="isOpen = false; isRecurringTaskUpdate = false"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6 cursor-pointer"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </div>
  </div>
  <div content>
    <form [formGroup]="createTaskForm" class="space-y-6">
      <div class="space-y-3 border-b pb-8">
        <div class="flex flex-col space-y-1">
          <label class="Poppins-Medium text-gray-600">{{
            "Task Title" | translate
          }}</label>
          <input
            type="text"
            formControlName="taskTitle"
            class="new-input"
            placeholder="e.g. Work Meeting"
          />
          <span
            class="text-red-500 text-sm"
            *ngIf="createTaskFormErrors?.taskTitle"
            >{{ createTaskFormErrors.taskTitle }}</span
          >
        </div>
        <div class="flex flex-col space-y-1">
          <label class="Poppins-Medium text-gray-600">{{
            "Description" | translate
          }}</label>
          <textarea
            rows="5"
            formControlName="description"
            class="new-input !h-20"
          ></textarea>
          <span
            class="text-red-500 text-sm"
            *ngIf="createTaskFormErrors?.description"
            >{{ createTaskFormErrors.description }}</span
          >
        </div>
        <div class="flex flex-col space-y-2">
          <div class="flex flex-col space-y-1">
            <label class="Poppins-Medium">{{ "Task Type" | translate }}</label>
            <ng-select
              class="new-dropdown"
              (ngModelChange)="changeTaskType($event)"
              [clearable]="false"
              [items]="taskTypes"
              formControlName="taskType"
              placeholder="Select task type"
            >
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <div class="px-2 py-1">
                  <div
                    class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                  >
                    {{ item }}
                  </div>
                </div>
              </ng-template>
            </ng-select>
            <span
              class="text-red-500 text-sm"
              *ngIf="createTaskFormErrors?.taskType"
              >{{ createTaskFormErrors.taskType }}</span
            >
          </div>
          <div
            *ngIf="createTaskForm?.value?.taskType === 'Recurring'"
            class="flex flex-col space-y-1"
          >
            <label class="Poppins-Medium">{{ "Frequency" | translate }}</label>
            <div class="flex space-x-4 items-center">
              <div class="flex space-x-2 items-center">
                <input
                  type="radio"
                  value="Daily"
                  formControlName="frequency"
                  name="frequency"
                />
                <h2 class="Poppins-Medium">{{ "Daily" | translate }}</h2>
              </div>
              <div class="flex space-x-2 items-center">
                <input
                  type="radio"
                  value="Weekly"
                  formControlName="frequency"
                  name="frequency"
                />
                <h2 class="Poppins-Medium">{{ "Weekly" | translate }}</h2>
              </div>
              <div class="flex space-x-2 items-center">
                <input
                  type="radio"
                  value="Monthly"
                  formControlName="frequency"
                  name="frequency"
                />
                <h2 class="Poppins-Medium">{{ "Monthly" | translate }}</h2>
              </div>
            </div>
            <span
              class="text-red-500 text-sm"
              *ngIf="createTaskFormErrors?.frequency"
              >{{ createTaskFormErrors.frequency }}</span
            >
          </div>
        </div>
        <div class="flex justify-between gap-8 task-dates">
          <div class="flex flex-col w-full gap-2">
            <label class="Poppins-Medium text-gray-600">{{
              "Start Date" | translate
            }}</label>
            <input
              type="date"
              formControlName="startDate"
              [min]="today"
              value="today"
              class="new-date"
            />
            <!-- <input placeholder="DD/MM/YYYY"
                                class="bg-transparent border-none w-full outline-none" formControlName="startDate"
                                [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1">
                            <owl-date-time [pickerMode]="'popup'" [min]="today" [pickerType]="'calendar'" #dt1></owl-date-time> -->
            <span
              class="text-red-500 text-sm"
              *ngIf="createTaskFormErrors?.startDate"
              >{{ createTaskFormErrors.startDate }}</span
            >
          </div>
          <div class="flex flex-col w-full gap-2">
            <label class="Poppins-Medium text-gray-600">{{
              "Due Date" | translate
            }}</label>
            <input
              type="date"
              formControlName="dueDate"
              class="new-date"
              [min]="createTaskForm.get['startDate']?.value"
              placeholder="DD/MM/YYYY"
            />
            <span
              class="text-red-500 text-sm"
              *ngIf="createTaskFormErrors?.dueDate"
              >{{ createTaskFormErrors.dueDate }}</span
            >
          </div>
        </div>
        <div
          *ngIf="createTaskForm?.value?.taskType === 'Recurring'"
          class="flex flex-col space-y-1"
        >
          <div class="flex flex-col space-y-1">
            <label class="Poppins-Medium text-gray-600">{{
              "End Date" | translate
            }}</label>
            <input
              type="date"
              (change)="dateChange($event)"
              [min]="createTaskForm.get('dueDate').value"
              formControlName="endDate"
              class="new-input"
              placeholder="DD/MM/YYYY"
            />
            <span
              class="text-red-500 text-sm"
              *ngIf="createTaskFormErrors?.endDate"
              >{{ createTaskFormErrors.endDate }}</span
            >
          </div>
          <!-- <div class="flex flex-col space-y-1">
                        <label class="Poppins-Medium text-gray-600">{{'Duration' | translate}} <span class="Poppins-Medium">{{'(Optional)'|translate}}</span></label>
                        <div class="flex space-x-2 items-center border rounded-2xl px-4 py-1">
                            <svg width="24px" height="24px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M9 2L15 2" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12 10L12 14" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12 22C16.4183 22 20 18.4183 20 14C20 9.58172 16.4183 6 12 6C7.58172 6 4 9.58172 4 14C4 18.4183 7.58172 22 12 22Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                            <input type="number" formControlName="duration" class="bg-transparent border-none w-full outline-none focus:ring-white" placeholder="e.g. 5 hours"/>
                        </div>
                    </div>
                    <div class="flex flex-col space-y-1">
                        <label class="Poppins-Medium text-gray-600">{{'Due Time' | translate}} <span class="Poppins-Medium">{{'(Optional)'|translate}}</span></label>
                        <div class="flex space-x-2 items-center border rounded-2xl px-4 py-1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <input type="time" formControlName="dueTime" class="bg-transparent border-none w-full outline-none"/>
                        </div>
                    </div> -->
        </div>
        <div class="flex flex-col space-y-1">
          <label class="Poppins-Medium">{{ "Assign Task" | translate }}</label>
          <ng-select
            class="new-dropdown"
            [clearable]="false"
            bindLabel="name"
            bindValue="_id"
            [items]="allBusinessUsers"
            formControlName="assignToUserId"
            placeholder="Assign task"
          >
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <div class="px-2 py-1">
                <div
                  class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                >
                  {{ item.name }}
                </div>
              </div>
            </ng-template>
          </ng-select>
          <span
            class="text-red-500 text-sm"
            *ngIf="createTaskFormErrors?.assignToUserId"
            >{{ createTaskFormErrors.assignToUserId }}</span
          >
        </div>
        <!-- <div class="flex flex-col space-y-1 bg-gray-100 rounded-xl p-4">
                    <label class="Poppins-Medium">{{'Reminder' | translate}}</label>
                    <ng-select class="custom" [clearable]="false" [items]="['10 Minutes','1 Hour', '24 Hours']" formControlName="reminder" placeholder="Select Reminder"></ng-select>
                    <span
                        class="text-red-500 text-sm"
                        *ngIf="createTaskFormErrors?.reminder"
                        >{{ createTaskFormErrors.reminder }}</span
                    >
                </div> -->
      </div>
      <!-- <app-file-upload
        appAccessControl
        moduleType="storage"
        accessType="edit"
        [addedFiles]="files"
        (emitter)="getUploadedFiles($event)"
        [type]="'compact'"
      ></app-file-upload> -->
      <div class="flex gap-4 p-4 w-full items-center bg-input-light h-[7.5rem] rounded-lg">
        <label for="file" (click)="clearFileInput()" class="cursor-pointer">
            <div class="h-16 w-16 flex justify-center items-center rounded-[80px] bg-[#1C1C1C0D] shadow-btn"  ngFileDragDrop (uploadedFiles)="getUploadedFiles($event)" (fileDropped)="getUploadedFiles($event)">
                <img src="assets/images/upload.svg" alt="" class="" />
                <input #fileInput id="file" class="hidden" (change)="onFileSelect($event)"  type="file"  multiple>
            </div>
        </label>
        <!-- <div *ngFor="let file of files" class="space-y-1 pt-1">
            <div class="flex justify-between bg-gray-100 py-2 px-6 rounded-2xl w-full">
                <p class="Gilroy-Medium w-11/12 overflow-hidden">{{file.name ?? file.displayFileName}}</p>
                <div class="w-1/12 flex justify-end">
                    <svg (click)="removeFile(file)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-red-500 cursor-pointer">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                    </svg>                          
                </div>
            </div>
        </div> -->
        <div
        class="flex flex-col overflow-auto max-h-[5rem] w-full"
      >
        <ng-container *ngIf="files.length; else noFiles">
          <div
            class="flex gap-2 items-center w-full"
            *ngFor="let file of files"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              height="20"
              width="20"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
              />
            </svg>
            <div class="text-xs flex flex-col max-w-[79%] overflow-scroll gap-1">
              <span>
                {{ file?.name || file?.displayFileName }}
              </span>
              <span>
                {{ file?.size }}
              </span>
            </div>
            <div class="ms-auto mr-3" (click)="removeFile(file)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#1c1c1ccc"
              >
                <path
                  d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
                />
              </svg>
            </div>
          </div>
        </ng-container>
      </div>
      <!-- <ng-container
        [ngTemplateOutlet]="noFiles"
      ></ng-container> -->
        <!-- <ng-file-drag-drop (uploadedFiles)="getUploadedFiles($event)" [fileConfig]="dragDropConfig"> </ng-file-drag-drop> -->
    </div>
      <div
        *ngIf="isRecurringTaskUpdate && edit"
        class="flex items-start flex-col gap-2 justify-between mt-8 pt-4 border-t border-[#e6e6e6]"
      >
        <label class="flex items-center gap-1"
          ><input
            type="radio"
            value="single"
            formControlName="recurringUpdateType"
            name="recurringUpdateType"
          />
          <h2 class="Poppins-Medium">Update single task</h2></label
        >
        <label class="flex items-center gap-1"
          ><input
            type="radio"
            value="all"
            formControlName="recurringUpdateType"
            name="recurringUpdateType"
          />
          <h2 class="Poppins-Medium">
            Update this task and upcoming recurring tasks
          </h2></label
        >
        <span
          class="text-red-500 text-sm"
          *ngIf="createTaskFormErrors?.recurringUpdateType"
          >{{ createTaskFormErrors.recurringUpdateType }}</span
        >
      </div>
    </form>
  </div>
  <div footer>
    <app-button (handleClick)="createTaskOrUpdate()" type="primary">
      {{ (edit ? "Update" : "Next") | translate }}
    </app-button>
  </div>
</app-modal-container>

<app-modal-container [isOpen]="isDeleteModalOpen">
  <div header class="flex justify-between">
    <h1 class="Poppins-Medium text-2xl">
      {{ "Delete Task" | translate }}
    </h1>
    <svg
      (click)="closeDeleteModal()"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-6 h-6 cursor-pointer"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  </div>
  <div content>
    <h4 class="text-lg">
      {{ "Are you sure you want to delete this task" | translate }}
      <span class="Poppins-Medium">{{ taskToDelete?.taskTitle }}</span
      >?
    </h4>
  </div>
  <div footer>
    <app-button (handleClick)="confirmDelete()" type="danger">
      {{ "Delete" | translate }}
    </app-button>
  </div>
</app-modal-container>

<app-modal-container [isOpen]="isRecurringTaskModal">
  <div header class="flex justify-between">
    <h1 class="Poppins-Medium text-2xl">
      {{ "Delete Recurring Task" | translate }}
    </h1>
    <svg
      (click)="closeDeleteModal()"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-6 h-6 cursor-pointer"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  </div>
  <div content>
    <div class="flex items-start flex-col gap-2 justify-between">
      <label class="flex items-center gap-1"
        ><input
          type="radio"
          value="single"
          [(ngModel)]="recurringDeleteType"
          name="recurringDeleteType"
        />
        <h2 class="Poppins-Medium">
          {{ "Delete single task" | translate }}
        </h2></label
      >
      <label class="flex items-center gap-1"
        ><input
          type="radio"
          value="all"
          [(ngModel)]="recurringDeleteType"
          name="recurringDeleteType"
        />
        <h2 class="Poppins-Medium">
          {{ "Delete this task and upcoming recurring tasks" | translate }}
        </h2></label
      >
    </div>
  </div>
  <div footer>
    <app-button (handleClick)="confirmRecurringDelete()" type="danger">
      {{ "Delete" | translate }}
    </app-button>
  </div>
</app-modal-container>

<app-modal-container [isOpen]="emailSettingsModal">
  <div header class="flex justify-between">
    <h1 class="Poppins-Medium text-2xl">
      {{ "Task Email Settings" | translate }}
    </h1>
    <svg
      (click)="emailSettingsModal = false; openCloseEmailSettingsModal()"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-6 h-6 cursor-pointer"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  </div>
  <div content>
    <ng-container *ngIf="isEmailSettings; else emailSettingsOff">
      <div class="flex items-start flex-col gap-2 justify-between">
        <label class="flex items-center gap-1"
          ><input
            type="radio"
            value="daily"
            [(ngModel)]="mailFrequency"
            name="mailFrequency"
          />
          <h2 class="Poppins-Medium">
            {{ "Receive daily updates of Task on email" | translate }}
          </h2></label
        >
        <label class="flex items-center gap-1"
          ><input
            type="radio"
            value="weekly"
            [(ngModel)]="mailFrequency"
            name="mailFrequency"
          />
          <h2 class="Poppins-Medium">
            {{ "Receive weekly updates of Task on email" | translate }}
          </h2></label
        >
      </div>
    </ng-container>
    <ng-template #emailSettingsOff>
      {{ "Are you sure you want to turn off email digest?" | translate }}
    </ng-template>
  </div>
  <div footer>
    <app-button (handleClick)="confirmEmailSettings()" type="primary">
      {{ "Confirm" | translate }}
    </app-button>
  </div>
</app-modal-container>
<ng-template #noFiles>
    <div class="text-xs flex flex-col gap-2 items-start justify-center h-full">
      <div class="font-Medium">
        {{ "Add Files" | translate }}
      </div>
      <div class="text-xs text-[#1C1C1C66]">
        {{ "Allowed file types: png, pdf, jpeg." | translate }}
      </div>
    </div>
  </ng-template>
