<div class="h-[100vh] w-[100vw] flex flex-col items-center bg-gray-50 pt-52 " >
    <div class="max-h-64 flex flex-col justify-center items-center bg-white shadow rounded-xl p-5 space-y-3">
        <svg *ngIf="response?.transactionStatus === 'Approved'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="green" class="w-20 h-20 ">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>
        <svg *ngIf="response?.transactionStatus === 'Failed'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="red" class="w-20 h-20 ">
            <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>
        <svg *ngIf="response?.transactionStatus === 'Pending' && response.isActive === false" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-20 h-20">
            <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
        </svg>
        <svg *ngIf="response?.transactionStatus === 'Pending' && response.isActive === true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-20 h-20">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
        </svg>                   
          
        <ng-container *ngIf="response?.transactionStatus === 'Approved' || response?.transactionStatus === 'Failed'; else expiredOrDeleted">
            <p class=" text-2xl Gilroy-Bold" >{{'Payment Status - ' + response?.transactionStatus ?? ''}}</p>
            <p class=" text-xl Gilroy-Bold " >{{'Payment message - ' + response?.transactionMessage ?? ''}}</p>
            <p class="" >{{'Transaction Time - ' + (response?.transactionTime | date: 'medium')}}</p>
        </ng-container>
        <ng-template #expiredOrDeleted>
            <p class=" text-2xl Gilroy-Bold">{{response.isActive === false ? 'Order has been canceled' : 'Link has been Expired'}}</p>
        </ng-template>
    </div>
    <p *ngIf="transactionStatus === 'Approved'" class="text-center pt-2" >{{'You will be redirected to the source automatically in 5 seconds, if not redirected'}} <a class="text-blue-600 underline cursor-pointer" (click)="close()" >Click Here</a></p>
    <p *ngIf="transactionStatus != 'Approved'" class="text-center pt-2" >{{'You can close this window by clicking here, if not redirected'}} <a class="text-blue-600 underline cursor-pointer" (click)="close()" >Click Here</a></p>
</div>