<div class="flex justify-between">
    <div class=" space-y-4 border-gray-300 border-r w-1/2">
        <div *ngIf='!update else elseUpdate'>
            <h3 class="text-2xl Gilroy-Bold">{{'Invite new user to' | translate }} {{companyName}}</h3>
            <p class="Gilroy-SemiBold text-sm mt-2">{{'Send email invitation to invite user' | translate }}</p>
        </div>
        <ng-template #elseUpdate>
            <h3 class="text-2xl Gilroy-Bold">{{'Edit invited user permissions' | translate }}</h3>
        </ng-template>
        <form [formGroup]="inviteForm" class="space-y-4 w-10/12">
            <div>
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2">
                    <label for='firstName' class="block text-sm Gilroy-Bold font-color-05">{{'First Name' | translate }}</label>
                    <fieldset [disabled]="update">
                        <input id='firstName' formControlName='firstName' type="text"
                            class="w-full p-0 border-0 focus:ring-0 placeholder-gray-300" placeholder="{{'Enter first name' | translate }}" />
                    </fieldset>
                </div>
                <div *ngIf='formErrors.firstName' class="text-sm text-red-400 ms-2">{{formErrors.firstName}}</div>
            </div>
            <div>
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2">
                    <label for='lastName' class="block text-sm Gilroy-Bold font-color-05">{{'Last Name' | translate }}</label>
                    <fieldset [disabled]="update">
                        <input id='lastName' formControlName='lastName' type="text"
                            class="w-full p-0 border-0 focus:ring-0 placeholder-gray-300" placeholder="{{'Enter last name' | translate }}" />
                    </fieldset>
                </div>
                <div *ngIf='formErrors.lastName' class="text-sm text-red-400 ms-2">{{formErrors.lastName}}</div>
            </div>
            <div>
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2">
                    <label for='email' class="block text-sm Gilroy-Bold font-color-05">{{'Email Address' | translate }}</label>
                    <fieldset [disabled]="update">
                        <input id='email' formControlName='email' type="text"
                            class="w-full p-0 border-0 focus:ring-0 placeholder-gray-300" placeholder="{{'Enter email' | translate }}" />
                    </fieldset>
                </div>
                <div *ngIf='formErrors.email' class="text-sm text-red-400 ms-2">{{formErrors.email}}</div>
            </div>
            <div class="space-y-1">
                <div class="text-lg Gilroy-Bold">{{'Position' | translate }}</div>
                <div>
                    <label class="inline-flex items-center space-x-2 rtl:space-x-reverse">
                        <input type="radio" formControlName='position' value="admin" name="position">
                        <span>{{'Admin' | translate }}</span>
                    </label>
                </div>
                <div>
                    <label class="inline-flex items-center">
                        <input type="radio" value="viewer" formControlName='position' name="position">
                        <span class="ms-2">{{'Viewer' | translate }}</span>
                    </label>
                </div>
                <div>
                    <label class="inline-flex items-center">
                        <input type="radio" value="custom" formControlName='position' name="position">
                        <span class="ms-2">{{'Custom' | translate }}</span>
                    </label>
                </div>
                <div>
                    <label class="inline-flex items-center">
                        <input type="radio" value="cashier" formControlName='position' name="position">
                        <span class="ms-2">{{'Cashier' | translate }}</span>
                    </label>
                </div>
            </div>
            <div class="flex items-center space-x-2 rtl:space-x-reverse">
                <app-button *ngIf='!update' buttonText='{{"Invite" | translate }}' rounded='full' size='large' type='primary' (handleClick)='inviteUser()'></app-button>
                <app-button *ngIf='update' buttonText='{{"Update" | translate }}' rounded='full' size='large' type='primary' (handleClick)='updateUser()'></app-button>
                <span *ngIf="error" class="text-red-500">{{error}}</span>
                <span *ngIf="response" class="text-green-500">{{response}}</span>
            </div>
        </form>
    </div>
    <div class="w-1/2 flex justify-end">
        <div class="space-y-4 w-10/12">
            <div>
                <h3 class="text-2xl Gilroy-Bold">{{inviteForm.controls.position.value | titlecase}} {{'permissions' | translate }}</h3>
                <p *ngIf="inviteForm.controls.position.value === 'admin'" class="Gilroy-SemiBold text-sm mt-2">{{'Best for anyone who needs full access' | translate }}</p>
                <p *ngIf="inviteForm.controls.position.value === 'viewer'" class="Gilroy-SemiBold text-sm mt-2">{{'Best for anyone who needs view-only access' | translate }}</p>
                <p *ngIf="inviteForm.controls.position.value === 'custom'" class="Gilroy-SemiBold text-sm mt-2">{{'Customize your own set of permissions' | translate }}</p>
            </div>
            <ul [formGroup]='permissionsForm'>
                <li class="border-b py-2">
                    <div class="flex items-center justify-between">
                        <p class="Gilroy-SemiBold">{{'Sales' | translate }}</p>
                        <select formControlName='sales' class="rounded-lg border-gray-300 py-1 focus:ring-0">
                            <option value="view">{{'View Only' | translate}}</option>
                            <option value="edit">{{'Edit' | translate }}</option>
                            <option value="none">{{'None' | translate }}</option>
                        </select>
                    </div>
                </li>
                <li class="border-b py-2">
                    <div class="flex items-center justify-between">
                        <p class="Gilroy-SemiBold">Purchase</p>
                        <select formControlName='purchase' class="rounded-lg border-gray-300 py-1 focus:ring-0">
                            <option value="view">{{'View Only' | translate}}</option>
                            <option value="edit">{{'Edit' | translate }}</option>
                            <option value="none">{{'None' | translate }}</option>
                        </select>
                    </div>
                </li>
                <li class="border-b py-2">
                    <div class="flex items-center justify-between">
                        <p class="Gilroy-SemiBold">Accounting</p>
                        <select (change)="handleMarkAsReviewed($event)" formControlName='accounting' class="rounded-lg border-gray-300  py-1 focus:ring-0">
                            <option value="view">{{'View Only' | translate}}</option>
                            <option value="edit">{{'Edit' | translate }}</option>
                            <option value="none">{{'None' | translate }}</option>
                        </select>
                    </div>
                    
                </li>
                <li class="border-b py-2">
                    <div class="flex items-center justify-between">
                        <p class="Gilroy-SemiBold">Reports</p>
                        <select formControlName='reports' class="rounded-lg border-gray-300 py-1 focus:ring-0">
                            <option value="view">{{'View Only' | translate}}</option>
                            <option value="edit">{{'Edit' | translate }}</option>
                            <option value="none">{{'None' | translate }}</option>
                        </select>
                    </div>
                </li>
                <li class="border-b py-2">
                    <div class="flex items-center justify-between">
                        <p class="Gilroy-SemiBold">Users Management</p>
                        <select formControlName='userManagement' class="rounded-lg border-gray-300 py-1 focus:ring-0">
                            <option value="view">{{'View Only' | translate}}</option>
                            <option value="edit">{{'Edit' | translate }}</option>
                            <option value="none">{{'None' | translate }}</option>
                        </select>
                    </div>
                </li>
                <li class="border-b py-2">
                    <div class="flex items-center justify-between">
                        <p class="Gilroy-SemiBold">Projects</p>
                        <select formControlName='projects' class="rounded-lg border-gray-300 py-1 focus:ring-0">
                            <option value="view">{{'View Only' | translate}}</option>
                            <option value="edit">{{'Edit' | translate }}</option>
                            <option value="none">{{'None' | translate }}</option>
                        </select>
                    </div>
                </li>
                <li class="border-b py-2">
                    <div class="flex items-center justify-between">
                        <p class="Gilroy-SemiBold">Dashboard</p>
                        <select formControlName='dashboard' class="rounded-lg border-gray-300 py-1 focus:ring-0">
                            <option value="view">{{'View Only' | translate}}</option>
                            <option value="edit">{{'Edit' | translate }}</option>
                            <option value="none">{{'None' | translate }}</option>
                        </select>
                    </div>
                </li>
                <li class="border-b py-2">
                    <div class="flex items-center justify-between">
                        <p class="Gilroy-SemiBold">Inventory</p>
                        <select formControlName='inventory' class="rounded-lg border-gray-300 py-1 focus:ring-0">
                            <option value="view">{{'View Only' | translate}}</option>
                            <option value="edit">{{'Edit' | translate }}</option>
                            <option value="none">{{'None' | translate }}</option>
                        </select>
                    </div>
                </li>
                <li class="border-b py-2">
                    <div class="flex items-center justify-between">
                        <p class="Gilroy-SemiBold">Budget</p>
                        <select formControlName='budget' class="rounded-lg border-gray-300 py-1 focus:ring-0">
                            <option value="view">{{'View Only' | translate}}</option>
                            <option value="edit">{{'Edit' | translate }}</option>
                            <option value="none">{{'None' | translate }}</option>
                        </select>
                    </div>
                </li>
                <li class="border-b py-2">
                    <div class="flex items-center justify-between">
                        <p class="Gilroy-SemiBold">Payroll</p>
                        <select formControlName='payroll' class="rounded-lg border-gray-300 py-1 focus:ring-0">
                            <option value="view">{{'View Only' | translate}}</option>
                            <option value="edit">{{'Edit' | translate }}</option>
                            <option value="none">{{'None' | translate }}</option>
                        </select>
                    </div>
                </li>
                <li class="border-b py-2">
                    <div class="flex items-center justify-between">
                        <p class="Gilroy-SemiBold">Files</p>
                        <select formControlName='files' class="rounded-lg border-gray-300 py-1 focus:ring-0">
                            <option value="view">{{'View Only' | translate}}</option>
                            <option value="edit">{{'Edit' | translate }}</option>
                            <option value="none">{{'None' | translate }}</option>
                        </select>
                    </div>
                </li>
                <li class="border-b py-2">
                    <div class="flex items-center justify-between">
                        <p class="Gilroy-SemiBold">Setting</p>
                        <select formControlName='setting' class="rounded-lg border-gray-300 py-1 focus:ring-0">
                            <option value="view">{{'View Only' | translate}}</option>
                            <option value="edit">{{'Edit' | translate }}</option>
                            <option value="none">{{'None' | translate }}</option>
                        </select>
                    </div>
                </li>
                <li class="border-b py-2">
                    <div class="flex items-center justify-between">
                        <p class="Gilroy-SemiBold">Mark As Reviewed</p>
                        <select (change)="handleMarkAsReviewed($event)" formControlName='markAsReviewed' class="rounded-lg border-gray-300 py-1 focus:ring-0">
                            <option value="view">{{'View Only' | translate}}</option>
                            <option value="edit">{{'Edit' | translate }}</option>
                            <option value="none">{{'None' | translate }}</option>
                        </select>
                    </div>
                </li>
                <li class="border-b py-2">
                    <div class="flex items-center justify-between">
                        <p class="Gilroy-SemiBold">Manage Chat Channels</p>
                        <select formControlName='mangeChannel' class="rounded-lg border-gray-300 py-1 focus:ring-0">
                            <option value="view">{{'View Only' | translate}}</option>
                            <option value="edit">{{'Edit' | translate }}</option>
                            <option value="none">{{'None' | translate }}</option>
                        </select>
                    </div>
                </li>
                <!-- <li class="border-b py-2">
                    <div class="flex items-center justify-between">
                        <p class="Gilroy-SemiBold">Data Export</p>
                        <select formControlName='dataExport' class="rounded-lg border-gray-300  py-1 focus:ring-0">
                            <option value="view">View Only</option>
                            <option value="edit">Edit</option>

                        </select>
                    </div>
                </li> -->
            </ul>
        </div>
    </div>
</div>