<div class="p-12 w-3/4 mx-auto">
    <div class="bg-gray-50 p-6 rounded-xl space-y-6">
        <h1 class="Gilroy-Bold text-xl">{{'Cash Invoice and POS Settings' | translate}}</h1>
        
        <div>
            <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-1/2">
                <label for='deliveryEmailAddress' class="block text-sm Gilroy-Bold font-color-05">{{'Email of ware house owner' | translate }}</label>
                <input id='deliveryEmailAddress' [formControl]='deliveryEmailAddress' placeholder="{{'Email of ware house owner' | translate }}" type="text" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-300" />
            </div>
        </div>
        <div>
            <label class="flex items-center space-x-2 rtl:space-x-reverse">
                <input type="checkbox" class="toggle" [formControl]='isLogo' />
                <span>{{'Show company logo on invoices' | translate }}</span>
            </label>
        </div>
        <div>
            <label class="flex items-center space-x-2 rtl:space-x-reverse">
                <input type="checkbox" class="toggle" [formControl]='sendSms' />
                <span>{{'Send invoice by SMS' | translate }}</span>
            </label>
        </div>
        <div>
            <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-1/2">
                <label for='note' class="block text-sm Gilroy-Bold font-color-05">{{'Invoice footer note' | translate }}</label>
                <textarea rows="4" id='note' [formControl]='footerNote' placeholder="{{'Enter invoice footer note' | translate }}" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-300" ></textarea>
            </div>
        </div>
        <div class="flex items-center space-x-4 rtl:space-x-reverse ">
            <app-button buttonText='{{"Save" | translate }}' [rounded]='true' type='primary' (handleClick)='updateBusiness()'></app-button>
            <app-button buttonText='{{"Cancel" | translate }}' [rounded]='true' type='third' (handleClick)='cancelUpdate()'></app-button>
        </div>
    </div>
</div>