import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { valueChanges } from 'src/app/shared/utils/formValidator';
import { VendorsService } from '../../vendors.services';
import { getAllCountries, getAllStates } from 'src/app/shared/utils/countryStateData';
// import SlimSelect from 'slim-select';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { select, Store } from '@ngrx/store';
import { RootReducerState } from 'src/app/store/reducers';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AccountingService } from 'src/app/modules/accounting/accounting.service';


@Component({
  selector: 'app-create-vendor',
  templateUrl: './create-vendor.component.html',
  styleUrls: ['./create-vendor.component.scss']
})
export class CreateVendorComponent implements OnInit {

  constructor(private fb: FormBuilder,
              private store: Store<RootReducerState>,
              private vendorService: VendorsService,
              private route: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService,
              private translateService: TranslateService,
              private accountingService: AccountingService,
              private router: Router) {
    this.business$ = this.store.pipe(select(selectBusiness));
  }

  vendorForm: FormGroup;
  business$: Observable<any>;
  unsubscribe$ = new Subject();
  response = '';
  error: '';
  countries = [];
  states = [];
  countrySelect;
  stateSelect;
  update = false;
  businessId = null;
  vendorToUpdate = null;
  formErrors = {
    vendorName: '',
    firstName: '',
    lastName: '',
    email: '',
    telephone: '',
    mobileNumber: '',
    tollFree: '',
    webSite: '',
    fax: '',
    notes: '',
    addressLine1: '',
    addressLine2: '',
    country: '',
    state: '',
    city: '',
    postalCode: '',
    iban: '',
    taxId: ''
  };

  formErrorMessages = {
    vendorName: {
      required: 'Vendor Name is Required'
    },
    firstName: {
      required: 'First Name is Required'
    },
    lastName: {
      required: 'Last Name is Required'
    },
    email: {
      required: 'Email is Required'
    },
    telephone: {
      required: 'Telephone is Required'
    },
    mobileNumber: {
      required: 'Mobile Number is Required'
    },
    tollFree: {
      required: 'Toll Free is Required'
    },
    webSite: {
      required: 'Website is Required'
    },
    fax: {
      required: 'Fax is Required'
    },
    notes: {
      required: 'Notes is Required'
    },
    addressLine1: {
      required: 'Address Line-1 is Required'
    },
    country: {
      required: 'Country is Required'
    },
    state: {
      required: 'State is Required'
    },
    city: {
      required: 'City is Required'
    },
    postalCode: {
      required: 'Postal Code is Required'
    },
    iban: {
      required: 'IBAN is required',
      maxlength: `IBAN can't be of more than 35 characters`
    },
    taxId: {
      required: 'Tax Id is required'
    }
  };
  ngOnInit(): void {
    this.business$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((business) => {
        this.businessId = business.businessId._id;
      });
    this.loadform();
    this.countries = getAllCountries();
    this.route.queryParams.subscribe(({ id }) => {
      if (id) {
        this.vendorToUpdate = id;
        this.update = true;
        this.spinner.show();
        this.vendorService.getVendorId(id).subscribe((resp) => {
          this.spinner.hide();
          if(resp?.success)
          this.loadform(resp?.data);
        }, (error) => {
          this.spinner.hide();
          this.toastr.error(this.translateService.instant('Something went wrong!'));
        });
      }
    });
  }

  loadform(vendor?): void {
    this.vendorForm = this.fb.group({
      vendorName: [vendor?.vendorName || '', [Validators.required]],
      firstName: [vendor?.firstName || '', [Validators.required]],
      lastName: [vendor?.lastName || '', [Validators.required]],
      email: [vendor?.email || '', [Validators.required]],
      telephone: [vendor?.contactDetails?.telephone || ''],
      mobileNumber: [vendor?.contactDetails?.mobileNumber || '', [Validators.required]],
      tollFree: [vendor?.contactDetails?.tollFree || ''],
      webSite: [vendor?.contactDetails?.webSite || ''],
      fax: [vendor?.contactDetails?.fax || ''],
      notes: [vendor?.notes || ''],
      addressLine1: [vendor?.address?.addressLine1 || '', [Validators.required]],
      addressLine2: [vendor?.address?.addressLine2 || ''],
      country: [vendor?.address?.country || null, [Validators.required]],
      state: [vendor?.address?.state || null, [Validators.required]],
      city: [vendor?.address?.city || '', [Validators.required]],
      postalCode: [vendor?.address?.postalCode || '', [Validators.required]],
      iban: [vendor?.iban || '', [Validators.maxLength(35)]],
      taxId: [vendor?.taxId || '']
    });

    this.vendorForm.valueChanges.subscribe(() => {
      this.formErrors = valueChanges(this.vendorForm, { ...this.formErrors }, this.formErrorMessages, this.translateService);
    });

    this.formErrors = valueChanges(this.vendorForm, { ...this.formErrors }, this.formErrorMessages, this.translateService);
  }

  changeEventContry(event): void {
    const result = getAllStates(event?.text || null);
    this.states = result;
  }


  customerDataBody(): object {
    const formValue = this.vendorForm.value;
    const body = {
      vendorName: formValue?.vendorName,
      firstName: formValue?.firstName,
      lastName: formValue?.lastName,
      email: formValue?.email,
      contactDetails: {
        telephone: formValue?.telephone,
        mobileNumber: formValue?.mobileNumber,
        tollFree: formValue?.tollFree,
        fax: formValue?.fax,
        webSite: formValue?.webSite,
      },
      notes: formValue?.notes,
      iban:formValue?.iban,
      taxId:formValue?.taxId,
      address: {
        addressLine1: formValue?.addressLine1,
        addressLine2: formValue?.addressLine2,
        country: formValue?.country,
        state: formValue?.state,
        city: formValue?.city,
        postalCode: formValue?.postalCode,
      }
    };
    return body;
  }
  createVendor(): void {
    if (this.vendorForm.invalid) {
      this.vendorForm.markAllAsTouched();
      this.formErrors = valueChanges(this.vendorForm, { ...this.formErrors }, this.formErrorMessages, this.translateService);
      return
    }
    const body = {
      businessId: this.businessId,
      ...this.customerDataBody(),
    };
    this.spinner.show();
    this.vendorService.createVendor(body).subscribe((resp) => {
      this.spinner.hide();
      if(resp?.success){
        this.accountingService.getAllAccounts(this.businessId).subscribe((resp) => {
          if (resp.success) {
            this.accountingService.setAccountsInStore(resp.data);
          }
        });
        this.vendorService.addVendorInStore(resp.data);
        this.toastr.success(resp.message);
        this.router.navigate(['../purchases/vendors'])
      }
      this.vendorForm.reset();
    }, (error) => {
      this.toastr.error(this.translateService.instant('Something went wrong!'));
      this.spinner.hide();
    });
  }

  UpdateVendor(): void {
    if (this.vendorForm.invalid) {
      this.vendorForm.markAllAsTouched();
      this.formErrors = valueChanges(this.vendorForm, { ...this.formErrors }, this.formErrorMessages, this.translateService);
      return;
    }
    this.spinner.show();
    const body = {
      id: this.vendorToUpdate,
      ...this.customerDataBody(),
    };
    this.vendorService.updateVendor(body).subscribe((resp) => {
      this.spinner.hide();
      if(resp.success){
        this.toastr.success(resp.message);
        this.vendorService.updateVendorInStore(resp.data);
        this.router.navigate(['../purchases/vendors'])
      }
      setTimeout(() => { this.response = ''; }, 2000);
      this.vendorForm.reset();
    }, (error) => {
      this.toastr.error(this.translateService.instant('Something went wrong!'));
      this.spinner.hide();
    });
  }
}
