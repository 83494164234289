<div class="flex flex-col gap-4 p-4">
  <div class="new-design-round-border p-4 text-sm flex-grow w-full">
    <form
      [formGroup]="businessForm"
      [ngClass]="showForm ? '' : 'hidden'"
      novalidate
      class="flex flex-col gap-4"
    >
      <!-- <div class="flex space-x-8 rtl:space-x-reverse">
        <div class="w-1/2">
          <label
            for="profileImage"
            class="block text-md pb-1 Poppins-Bold font-color-05"
            >{{ "Business Logo" | translate }}</label
          >
          <div class="flex items-center space-x-4 rtl:space-x-reverse mb-4">
            <div class="border w-[120px] bg-white rounded-full">
              <img
                [src]="profilePic"
                width="120"
                class="rounded-full"
                alt="Logo"
              />
            </div>
            <div class="flex flex-col space-y-4">
              <label
                class="bg-[#026AA2] text-sm w-28 text-white text-center cursor-pointer flex Poppins-Medium h-10 items-center justify-center px-2 py-4 rounded-[4px]"
                for="file"
              >
                <input
                  type="file"
                  id="file"
                  accept="image/png, image/jpeg, image/jpg"
                  (change)="fileEvent($event)"
                  hidden
                />
                {{
                  profilePic === "assets/images/avatar.jpg"
                    ? "Add Picture"
                    : ("Update" | translate)
                }}
              </label>
              <app-submit
                value="Remove"
                className="bg-[#F6F6F6CC] shadow-btn text-[#1C1C1C66] w-28 h-10"
                (clicked)="openDeleteModal = true"
                *ngIf="profilePic !== 'assets/images/avatar.jpg'"
              ></app-submit>
            </div>
          </div>
        </div>
      </div> -->

      <div
        class="bg-input-light rounded-lg p-4 flex gap-5 items-center h-auto w-1/2"
      >
        <label for="file">
          <div
            class="h-16 w-16 flex justify-center items-center rounded-[80px] bg-[#1C1C1C0D] shadow-btn"
          >
            <img src="assets/images/upload.svg" alt="" class="" />
            <input
              type="file"
              id="file"
              accept="image/png, image/jpeg, image/jpg"
              (change)="fileEvent($event)"
              hidden
            />
          </div>
        </label>
        <div class="flex flex-col justify-between">
          <div class="text-[#1C1C1CCC]">
            {{ "Sidemenu Logo" | translate }}
          </div>
          <div class="flex flex-col text-[13px] text-[#1C1C1C66]">
            <span>{{
              "Upload a quick business switcher logo." | translate
            }}</span>
            <span>{{ "Recommended PNG" | translate }}</span>
            <span>{{ "1:1 | Min 534px by 534px" }}</span>
          </div>
        </div>
        <div class="w-[87px] flex flex-col items-center justify-center gap-1">
          <img
            [src]="profilePic"
            width="87px"
            class="rounded-full"
            alt="Logo"
          />
          <span
            class="text-[13px] text-[#1C1C1C66] underline cursor-pointer"
            *ngIf="profilePic && profilePic !== 'assets/images/avatar.jpg'"
            (click)="openDeleteModal = true"
          >
            {{ "Remove" | translate }}
          </span>
        </div>
      </div>

      <!-- NAME -->
      <div class="flex flex-col gap-2">
        <label for="companyName">{{ "Store Name" | translate }}</label>
        <input
          id="companyName"
          formControlName="companyName"
          type="text"
          class="new-input"
          placeholder="{{ 'Enter Store Name' | translate }}"
        />

        <label *ngIf="formErrors.companyName" class="text-red-400 ms-2">{{
          formErrors.companyName
        }}</label>
      </div>

      <div
        class="flex flex-col gap-2"
        [ngClass]="{
          'w-1/2': !showIndustryCreateNew,
          'w-full': showIndustryCreateNew
        }"
      >
        <label for="businessType" class="">{{
          "Business Type" | translate
        }}</label>
        <ng-select
          *ngIf="!showIndustryCreateNew"
          id="industrytate"
          [items]="industries"
          class="new-dropdown"
          bindLabel="text"
          bindValue="text"
          formControlName="businessType"
          placeholder="{{ 'Select one' | translate }}"
        >
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <div class="px-2 py-1">
              <div
                class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
              >
                {{ item.text }}
              </div>
            </div>
          </ng-template>
          <ng-template ng-footer-tmp class="!p-2">
            <div
              class="p-1 cursor-pointer rounded-lg bg-[#F0F5FF]"
              (click)="showIndustryCreateNew = true"
            >
              {{ "Create New Bussiness Type" | translate }}
            </div>
          </ng-template>
        </ng-select>
        <input
          *ngIf="showIndustryCreateNew"
          formControlName="businessType"
          type="text"
          class="new-input"
          placeholder="{{ 'Enter Business Type' | translate }}"
        />
        <div *ngIf="formErrors.businessType" class="text-red-400 ms-2">
          {{ formErrors.businessType }}
        </div>
      </div>

      <div class="flex flex-col gap-2">
        <label for="addressLine1">{{
          "Enter Address Line 1" | translate
        }}</label>
        <input
          id="addressLine1"
          formControlName="addressLine1"
          type="text"
          class="new-input"
          placeholder="{{ 'Enter Address' | translate }}"
        />
      </div>
      <div class="flex flex-col gap-2">
        <label for="addressLine2">{{
          "Enter Address Line 2" | translate
        }}</label>
        <input
          id="addressLine2"
          formControlName="addressLine2"
          type="text"
          class="new-input"
        />
      </div>
      <div class="flex flex-col gap-2">
        <label for="city">{{ "Enter City" | translate }}</label>
        <input
          id="city"
          formControlName="city"
          type="text"
          class="new-input"
          placeholder="{{ 'Enter Address' | translate }}"
        />
      </div>
      <div class="flex flex-col gap-2 w-1/2">
        <label for="country" class="">{{ "Country" | translate }}</label>
        <ng-select
          id="country-select"
          [items]="countrySelect"
          class="p-0 w-full focus:ring-0 new-dropdown"
          bindLabel="text"
          bindValue="text"
          (change)="changeEventContry($event)"
          formControlName="country"
          placeholder="{{ 'Select one' | translate }}"
        >
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <div class="px-2 py-1">
              <div
                class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
              >
                {{ item.text }}
              </div>
            </div>
          </ng-template>
        </ng-select>
        <div *ngIf="formErrors.country" class="text-red-400 ms-2">
          {{ formErrors.country }}
        </div>
      </div>
      <div class="flex flex-col gap-2 w-1/2">
        <label for="state" class="">{{ "State" | translate }}</label>
        <ng-select
          id="state"
          [items]="states"
          class="p-0 w-full focus:ring-0 new-dropdown"
          bindLabel="text"
          bindValue="text"
          formControlName="state"
          placeholder="{{ 'Select one' | translate }}"
        >
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <div class="px-2 py-1">
              <div
                class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
              >
                {{ item.text }}
              </div>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="flex flex-col gap-2">
        <label for="clientID">{{ "Client Id" | translate }}</label>
        <input
          id="clientID"
          formControlName="clientId"
          type="text"
          class="new-input"
        />
      </div>
      <div class="flex flex-col gap-2">
        <label for="secretCode">{{ "Secret Code" | translate }}</label>
        <input
          id="secretCode"
          formControlName="secretCode"
          type="text"
          class="new-input"
        />
      </div>
      <div class="flex flex-col gap-2">
        <label for="taxID">{{ "Tax Id" | translate }}</label>
        <input
          id="taxID"
          formControlName="taxId"
          type="text"
          class="new-input"
        />
      </div>
      <div class="flex flex-col gap-2">
        <label for="incomeSourceId">{{ "Income Source Id" | translate }}</label>
        <input
          id="incomeSourceId"
          formControlName="incomeSourceId"
          type="text"
          class="new-input"
        />
      </div>
      <div class="flex flex-col gap-2">
        <label for="entity">{{ "Enter Entity Id" | translate }}</label>
        <input
          id="entity"
          formControlName="entityId"
          type="text"
          class="new-input"
        />
      </div>
      <div class="flex gap-4">
        <label for="showTax" class="flex items-center cursor-pointer relative">
          <input
            type="checkbox"
            formControlName="showTax"
            id="showTax"
            class="sr-only peer"
          />
          <div
            [ngClass]="{
              'after:bg-white': businessForm.get('showTax')?.value,
              'after:bg-black bg-[#d9d9d9]': !businessForm.get('showTax')?.value
            }"
            class="toggle-bg peer-checked:bg-black h-6 w-11 rounded-full"
          ></div>
        </label>
        <span>
          {{ "Show tax number on invoices" | translate }}
        </span>
      </div>
      <div class="flex gap-4">
        <label for="isSalesTax" class="flex items-center cursor-pointer relative">
          <input
            type="checkbox"
            formControlName="isSalesTax"
            id="isSalesTax"
            class="sr-only peer"
          />
          <div
            [ngClass]="{
              'after:bg-white': businessForm.get('isSalesTax')?.value,
              'after:bg-black bg-[#d9d9d9]': !businessForm.get('isSalesTax')?.value
            }"
            class="toggle-bg peer-checked:bg-black h-6 w-11 rounded-full"
          ></div>
        </label>
        <span>
          {{ "Are you registered with the Sales Tax for JO-Tax" | translate }}
        </span>
      </div>
      <div class="flex flex-col gap-2 w-1/2">
        <label for="currency" class="">{{
          "Business Currency" | translate
        }}</label>
        <ng-select
          id="currency"
          [items]="currenySelect"
          class="p-0 w-full focus:ring-0 new-dropdown"
          bindLabel="text"
          bindValue="text"
          formControlName="currency"
          placeholder="{{ 'Select one' | translate }}"
        >
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <div class="px-2 py-1">
              <div
                class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
              >
                {{ item.text }}
              </div>
            </div>
          </ng-template>
        </ng-select>
        <div class="text-sm text-red-500 ms-2 mt-1" *ngIf="formErrors.currency">
          {{ formErrors.currency }}
        </div>
      </div>
      <div class="flex flex-col gap-2 w-1/2">
        <label for="organization" class="">{{
          "Type of Organization" | translate
        }}</label>
        <ng-select
          id="organization"
          [items]="organisations"
          class="p-0 w-full focus:ring-0 new-dropdown"
          bindLabel="text"
          bindValue="text"
          formControlName="organization"
          placeholder="{{ 'Select one' | translate }}"
        >
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <div class="px-2 py-1">
              <div
                class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
              >
                {{ item.text }}
              </div>
            </div>
          </ng-template>
        </ng-select>
        <div
          class="text-sm text-red-500 ms-2 mt-1"
          *ngIf="formErrors.organization"
        >
          {{ formErrors.organization }}
        </div>
      </div>
      <div class="flex flex-col gap-2">
        <span>
          {{ "Decimal Places" | translate }}
        </span>
        <div class="flex items-center gap-4 justify-start">
          <label>
            <input
              formControlName="decimalSize"
              type="radio"
              class="me-2"
              name="decimalSize"
              value="2"
            />
            <span>{{"2 Decimal" | translate}}</span>
          </label>
          <label>
            <input
              formControlName="decimalSize"
              type="radio"
              class="me-2"
              name="decimalSize"
              value="3"
            />
            <span>{{"3 Decimal" | translate}}</span>
          </label>
        </div>
      </div>
      <div *ngIf="update" class="mt-4 custom-table-for-business">
        <app-file-upload
          [addedFiles]="addedFiles"
          (emitter)="saveFiles($event)"
          type="compact"
          tableType="new"
          className="w-1/2"
        ></app-file-upload>
      </div>
    </form>
  </div>
  <div class="flex gap-4 self-start">
    <app-submit
      [value]="'Update'"
      className="bg-[#026AA2] text-white w-28 h-10"
      (clicked)="updateBusiness()"
    ></app-submit>
    <app-submit
      value="Discard"
      className="bg-[#F6F6F6CC] shadow-btn text-[#1C1C1C66] w-28 h-10"
      (clicked)="close.emit()"
    ></app-submit>
    <div class="flex flex-wrap">
      <div *ngIf="response" class="text-green-500 py-1 text-xs">
        {{ response }}
      </div>
      <div *ngIf="error" class="text-[#F04438] py-1 text-xs">
        {{ error }}
      </div>
    </div>
  </div>
</div>

<app-image-cropper
  [isOpen]="openModal"
  [imageCropperConfig]="imageCropperConfig"
  (closeImage)="openModal = false"
  (base64Image)="saveImage($event)"
>
</app-image-cropper>

<app-modal-container [isOpen]="openDeleteModal">
  <div header class="border-b pb-2 Poppins-Bold">
    {{ "Remove Profile Image" | translate }}
  </div>
  <div content>{{ "Are you sure?" | translate }}</div>
  <div footer class="flex items-center gap-x-2">
    <app-button
      buttonText="{{ 'Delete' | translate }}"
      type="danger"
      rounded="true"
      (handleClick)="removeLogo()"
    ></app-button>
    <app-button
      buttonText="{{ 'Cancel' | translate }}"
      type="third"
      rounded="true"
      (handleClick)="openDeleteModal = false"
    ></app-button>
  </div>
</app-modal-container>
