import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerStatementComponent } from './components/customer-statement/customer-statement.component';
import { ProductsAndServicesComponent } from './components/products-and-services/products-and-services.component';
import { SalesContainerComponent } from './sales-container.component';

const routes: Routes = [
    {
        path: '',
        component: SalesContainerComponent,
        children: [
            {
                path: 'products-and-services',
                component: ProductsAndServicesComponent
            },
            {
                path: 'customers-statement',
                component: CustomerStatementComponent
            },
            {
                path: 'customers',
                loadChildren: () => import('./components/customers/customers.module').then((m) => m.CustomersModule)
            },
            {
                path: 'invoices',
                loadChildren: () => import('./components/invoices/invoices.module').then((m) => m.InvoicesModule)
            },
            {
                path: 'cash-invoice',
                loadChildren: () => import('./components/invoices/components/cash-invoice/cash-invoice.module').then((m) => m.CashInvoiceModule)
            },
            {
                path: 'estimates',
                loadChildren: () => import('./components/estimates/estimates.module').then((m) => m.EstimatesModule)
            },
            {
                path: 'recurring-invoices',
                loadChildren: () => import('./components/recurring-invoices/recurring-invoices.module').then((m) => m.RecurringInvoicesModule)
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SalesRoutingModule {}
