<app-modal-container [isOpen]='isOpen'>
    <div class="Gilroy-Bold" header>{{'Record Payment' | translate }}</div>
    <my-tabs content >
        <my-tab tabTitle="{{'Payment Details' | translate}}">
            <div [formGroup]='paymentForm'  class="space-y-2 flex flex-col">
                <div>
                    <label for='amount'>{{'Amount' | translate }}</label>
                    <input formControlName='amount' id='amount' type="number" class="w-full bg-input rounded-lg border-0 h-11 me-2">
                    <div class="text-red-500 text-sm ms-2" *ngIf='formErrors.amount'>{{formErrors.amount}}</div>
                    <div class="text-red-500 text-sm ms-2" *ngIf='invalidAmount'>{{'Invalid Amount' | translate }}</div>
                </div>
                <div>
                    <label for='amount'>{{'Date' | translate }}</label>
                    <input formControlName='paymentDate' id='paymentDate' type="date" class="w-full bg-input rounded-lg border-0 h-11 me-2">
                    <div class="text-red-500 text-sm ms-2" *ngIf='formErrors.paymentDate'>{{formErrors.paymentDate}}</div>
                    <div class="text-red-500 text-sm ms-2" *ngIf='!formErrors.paymentDate && invalidDateByMinDate'>{{'Invalid Date, lower than document date' | translate }}</div>
                </div>
                <div>
                    <label for='paymentType'>{{'Payment Method' | translate }}</label>
                    <ng-select [items]="paymentMethods"
                                  [searchable]='false'
                                  [clearable]='false'
                                  class="custom-selector" 
                                  formControlName="paymentMethod"
                                  placeholder='{{"Select one" | translate }}'>
                        </ng-select>
                    <div class="text-red-500 text-sm ms-2" *ngIf='formErrors.paymentMethod'>{{formErrors.paymentMethod}}</div>
                </div>
                <div>
                    <label for='paymentAccount'>{{'Payment Account' | translate }}</label>
                        <ng-select 
                            [items]="paymentAccounts$ | async"
                            [clearable]='false'
                            class="custom-selector" 
                            bindLabel="accountName"
                            groupBy='accountType'
                            formControlName="paymentAccount"
                            placeholder='{{"Select one" | translate }}'>
                        </ng-select>
                    <div class="text-red-500 text-sm ms-2" *ngIf='formErrors.paymentAccount'>{{formErrors.paymentAccount}}</div>
                </div>
                <div>
                    <label for='memo'>{{'Memo' | translate }}</label>
                    <input formControlName='memo' id='memo' type="text" class="w-full bg-input rounded-lg border-0 h-11 me-2">
                </div>
            </div>
        </my-tab>
        <my-tab tabTitle="{{'Files' | translate}}" >
            <app-file-upload [addedFiles]="addedFiles" (emitter)="saveFiles($event)" ></app-file-upload>
        </my-tab>
    </my-tabs>
    <div footer class="flex space-x-2 rtl:space-x-reverse">
        <app-button buttonText='{{"Record" | translate }}' type='primary' rounded='true' (handleClick)='generatePaymentDetails()'></app-button>
        <app-button buttonText='{{"Cancel" | translate }}' type='third' rounded='true' (handleClick)='close()'></app-button>
    </div>
</app-modal-container>