import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { valueChanges } from 'src/app/shared/utils/formValidator';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { AccountingService } from '../../../../accounting.service';

@Component({
  selector: 'app-create-tax',
  templateUrl: './create-tax.component.html',
  styleUrls: ['./create-tax.component.scss']
})
export class CreateTaxComponent implements OnInit {

  constructor(private fb: FormBuilder,
              private store: Store<RootReducerState>,
              private accountingService: AccountingService,
              private router: Router,
              private route: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private toaster: ToastrService) {
              this.business$ = this.store.pipe(select(selectBusiness));
  }

  taxform: FormGroup;
  taxType:string = "General"
  response = '';
  error = '';
  business$: Observable<any>;
  businessId = null;
  taxId = null;
  update = false;
  unsubscribe$ = new Subject();
  formErrors = {
    taxName: '',
    abbreviation: '',
    description: '',
    taxNumber: '',
    taxRate: '',
    account: '',
    taxType:'',
    itemCbcValue: ''
   };
  formErrorMessages = {
    taxName: {
      required: 'Taxname is required'
    },
    abbreviation: {
      required: 'abbreviation is required'
    },
    description: {
      required: 'Description is required'
    },
    taxNumber: {
      required: 'Tax number is required'
    },
    taxRate: {
      required: 'Taxrate is required'
    },
    account: {
      required: 'Account is required'
    },
    taxType:{
      required: "Tax Type is required"
    },
    itemCbcValue:{
      required: "Field is required"
    }
  };
  showIsCbcValueOption = false;

  ngOnInit(): void {
    this.loadForm();
    this.business$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((business) => {
      this.businessId = business.businessId._id;
    });
    this.route.queryParams.subscribe(({id}) => {
      if (id) {
        this.taxId = id;
        this.spinner.show();
        this.accountingService.getTax(id).subscribe((resp) => {
          this.update = true;
          this.spinner.hide();
          console.log("Update data", resp.data)
          this.loadForm(resp.data);
          console.log(resp);
        }, (error) => {
          this.spinner.hide();
          console.log(error);
        });
      }
    });
  }

  loadForm(data?): void {
    console.log("data1234", data);
    
    this.taxform = this.fb.group({
      taxName: [data?.taxName, [Validators.required]],
      abbreviation: [data?.abbreviation, [Validators.required]],
      description: [data?.description, [Validators.required]],
      taxRate: [data?.taxRate, [Validators.required]],
      account:[data?.account, [Validators.required]],
      taxType:[data?.taxType, [Validators.required]],
      itemCbcValue:[data?.itemCbcValue]
    });
    if(data?.taxRate === 0) {
      this.showIsCbcValueOption = true;
    } else {
      this.showIsCbcValueOption = false;
    }
    console.log("data1234", this.taxform);
    this.taxform.valueChanges.subscribe(() => {
      this.formErrors = valueChanges(this.taxform, {...this.formErrors}, this.formErrorMessages);
    });
    this.formErrors = valueChanges(this.taxform, {...this.formErrors}, this.formErrorMessages);
  }

  createTax(): void{
    console.log(this.taxform.valid, this.taxform.value);

    if (this.taxform.invalid) {
      this.taxform.markAllAsTouched();
      this.formErrors = valueChanges(this.taxform, {...this.formErrors}, this.formErrorMessages);
      return;
    }
    const body = {
      businessId: this.businessId,
      ...this.taxform.value
    };
    console.log("Create Tax", body)
    this.spinner.show();
    this.accountingService.createSalesTax(body).subscribe((resp) => {
      this.spinner.hide();
      if (resp.status === 200) {
        this.updateAccountsInStore();
        this.toaster.success('Tax saved sucessfully');
        this.router.navigate(['/settings/accounting/sales-taxes']);
        this.taxform.reset();
      } else {
        this.toaster.error('Something went wrong');
      }
    }, (error) => {
      this.toaster.error(error?.error?.message || 'Internal server error');
      this.spinner.hide();
    });
  }

    updateTax(): void {
      this.error = '';
      this.response = '';
      if (this.taxform.invalid) {
        this.taxform.markAllAsTouched();
        this.formErrors = valueChanges(this.taxform, {...this.formErrors}, this.formErrorMessages);
        return;
      }

      this.spinner.show();
      const body = {
        _id: this.taxId,
        ...this.taxform.value
      };
      this.accountingService.updateSalesTax(body).subscribe((updatedBusiness) => {
        this.spinner.hide();
        this.toaster.success('Tax updated sucessfully');
        this.updateAccountsInStore();
        this.router.navigate(['/settings/accounting/sales-taxes']);
        this.taxform.reset();
      }, (error) => {
        this.toaster.error(error?.error?.message || 'Internal server error');
        this.spinner.hide();
      });
    }

    changeService(event) {
      console.log(event);

    }

    updateAccountsInStore(): void {
      this.accountingService.getAllAccounts(this.businessId).subscribe((resp) => {
        if (resp.success) {
          this.accountingService.setAccountsInStore(resp.data);
        }
      });
    }

    checkIfZero(event): void {
      console.log("changeTaxRate", event);
      if(event === 0){
        this.showIsCbcValueOption = true;
        this.taxform.get("itemCbcValue").setValidators([Validators.required]);
      } else {
        this.showIsCbcValueOption = false;
        this.taxform.get("itemCbcValue").clearValidators();
        this.taxform.get("itemCbcValue").setErrors([]);
        this.taxform.get("itemCbcValue").setValue(null);
      }
    }

    changeTaxRate(event): void {
      console.log("changeTaxRate", event);
      
    }
    
}
