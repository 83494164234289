import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RecurringJvRoutingModule } from './recurring-jv-routing.module';
import { CreateRecurringJvComponent } from './components/create-recurring-jv/create-recurring-jv.component';
import { ListRecurringJvComponent } from './components/list-recurring-jv/list-recurring-jv.component';
import { ViewRecurringJvComponent } from './components/view-recurring-jv/view-recurring-jv.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { DirectivesModule } from 'src/app/directives/directives.module';


@NgModule({
  declarations: [
    CreateRecurringJvComponent,
    ListRecurringJvComponent,
    ViewRecurringJvComponent
  ],
  imports: [
    CommonModule,
    RecurringJvRoutingModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgSelectModule,
    DirectivesModule
  ]
})
export class RecurringJvModule { }
