<div class="mt-[3%] ml-[10%] mr-[10%] space-y-5 p-4">
    <div [formGroup]="onlinePaymentForm" class="flex gap-28">
        <div class="space-y-5 mt-5 bg-light-gray-1 rounded-3xl p-5 w-1/3">
            <div class="">
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-full">
                    <label for='Paytabs Profile ID' class="flex items-center space-x-2 block text-sm Gilroy-Bold font-color-05">
                        {{'Profile ID' | translate }}
                        <span [title]="profileIdConfiguration" >
                            <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 ms-2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                            </svg>
                        </span>
                    </label>
                    <input id='Paytabs Profile ID' formControlName='paytabsProfileId' placeholder="{{'Enter paytabs profile ID' | translate }}" type="text" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-300" />
                </div>
            </div>
            <div class=''>
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-full">
                    <label for='Paytabs Authorization Token' class="flex items-center space-x-2 block text-sm Gilroy-Bold font-color-05">
                        {{'Server Key' | translate }}
                        <span [title]="authorizationToken">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 ms-2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                            </svg>
                        </span>
                    </label>
                    <input id='Paytabs Authorization Token' formControlName='paytabsAuthorizationToken' placeholder="{{'Enter Paytabs Authorization Token' | translate }}" type="text" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-300" />
                </div>
            </div>
        </div>
        <div class="logo-text max-w-[430px]">
            <div class="flex justify-center">
                <img src="assets/images/mepslogo.png" width="200" alt="" srcset="">
            </div>
            <p class="text-center text-sm Gilroy-Medium">{{"Enhance your payment options with MEPS: Contact Middle East Payment Services PSC to set up your account for seamless online transactions. Visit" | translate}}<a href="https://www.mepspay.com/" target="_blank" class="text-[#004b99]"> www.mepspay.com</a> {{"or reach out to them at" | translate}} ecommerce@mepspay.com <span>{{"and" | translate}}</span> +962 6 500 22 50.</p>
        </div>
    </div>
    <div>
        <app-button buttonText='{{"Save" | translate }}' [rounded]='true' type='primary' (handleClick)='saveDetails()'></app-button>
    </div>
</div>