<div class="relative file-upload-style" *ngIf="type === 'full'">
    <div
      appAccessControl
      moduleType="storage"
      accessType="edit"
      [ngClass]="haveStorageSubscription ? '' : 'blur-lg'"
      *ngIf="filesArrToDisplay.length < 3"
      class="flex justify-center items-center w-100 h-28 border border-dashed active-border-invoice bg-white rounded-3xl py-2 relative"
    >
      <input
        [disabled]="!haveStorageSubscription"
        #fileInput
        (change)="addNewFile($event)"
        (click)="$event.target.value = null"
        accept=".jpeg, .jpg, .png, .pdf, .docx, .xlsx, .csv, .xml"
        type="file"
        class="w-full h-full opacity-0 absolute cursor-pointer"
      />
      <label for="fileInput" class="space-y-2 cursor-pointer">
        <p
          (click)="fileInput.click()"
          class="text-center text-xl Poppins-Medium text-blue-600"
        >
          {{ "Select a file to upload" | translate }}
        </p>
        <p (click)="fileInput.click()" class="text-center text-sm Poppins-Medium">
          {{
            "Only PDF, Image(JPEG, PNG), CSV, XLSX, DOCX, XML file types are supported"
              | translate
          }}
        </p>
      </label>
    </div>
  
    <app-table-container
      appAccessControl
      moduleType="storage"
      accessType="view"
      [ngClass]="haveStorageSubscription ? '' : 'blur-lg'"
      [keys]="tableKeys"
      [tableHeadings]="tableHeadings"
      [tableData]="filesArrToDisplay"
      [actionTemplate]="actionTemplate"
    >
      <ng-template let-item #actionTemplate>
        <td class="text-center pe-10">
          <span class="flex w-full justify-center items-center">
            <svg
              (click)="showRemoveFileConfirmation(item)"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
              />
            </svg>
          </span>
        </td>
      </ng-template>
    </app-table-container>
  
    <div
      *ngIf="!haveStorageSubscription"
      class="z-[29] w-96 text-center absolute top-[30%] left-[50%] -translate-x-1/2 -translate-y-1/2"
    >
      You don't have any active storage plan, purchase one to access this feature
    </div>
  </div>
  
  <app-modal-container [isOpen]="showDeleteModal">
    <div header>
      {{ "Are you sure?" | translate }}
    </div>
    <div content>
      <p>{{ "Confirm to delete file" | translate }}</p>
    </div>
    <div footer class="flex gap-x-2">
      <app-button
        type="danger"
        buttonText="{{ 'Delete' | translate }}"
        (handleClick)="removeFile()"
      ></app-button>
      <app-button
        type="third"
        buttonText="{{ 'Cancel' | translate }}"
        (handleClick)="showDeleteModal = false"
      ></app-button>
    </div>
  </app-modal-container>
  
  <div
    class="flex flex-col gap-2 items-start relative"
    appAccessControl
    moduleType="storage"
    accessType="edit"
    *ngIf="type === 'compact'"
  >
    <div
      [ngClass]="{
        'blur-lg': !haveStorageSubscription,
        'cursor-pointer': tableType === 'new'
      }"
      [class]="
        'flex gap-4 p-4 w-full items-center bg-input-light h-[7.5rem] rounded-lg' +
        ' ' +
        className
      "
      (click)="
        haveStorageSubscription &&
        (tableType === 'new' || !filesArrToDisplay.length)
          ? fileInput.click()
          : ''
      "
    >
      <label for="fileCompact" class="cursor-pointer">
        <div
          class="h-16 w-16 flex justify-center items-center rounded-[80px] bg-[#1C1C1C0D] shadow-btn"
        >
          <img src="assets/images/upload.svg" alt="" class="" />
          <input
            id="fileCompact"
            (change)="addNewFile($event)"
            (click)="$event.target.value = null"
            hidden
            [disabled]="!haveStorageSubscription"
            #fileInput
            accept=".jpeg, .jpg, .png, .pdf, .docx, .xlsx, .csv, .xml"
            type="file"
          />
        </div>
      </label>
      <div
        class="flex flex-col overflow-auto max-h-[5rem] w-full"
        *ngIf="tableType !== 'new'"
      >
        <ng-container *ngIf="filesArrToDisplay.length; else noFiles">
          <div
            class="flex gap-2 items-center w-full"
            *ngFor="let file of filesArrToDisplay"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              height="20"
              width="20"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
              />
            </svg>
            <div class="text-xs flex flex-col max-w-[79%] overflow-scroll gap-1">
              <span>
                {{ file?.name }}
              </span>
              <span>
                {{ file?.size }}
              </span>
            </div>
            <div class="ms-auto mr-3" (click)="showRemoveFileConfirmation(file)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#1c1c1ccc"
              >
                <path
                  d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
                />
              </svg>
            </div>
          </div>
        </ng-container>
      </div>
      <ng-container
        *ngIf="tableType === 'new'"
        [ngTemplateOutlet]="noFiles"
      ></ng-container>
    </div>
  
    <ng-container *ngIf="tableType === 'new'">
      <table class="w-full text-xs text-left rtl:text-right mt-2">
        <thead class="text-[#1C1C1C66] border-[#1C1C1C33] border-b">
          <tr>
            <td
              *ngFor="let column of tableHeadings"
              scope="col"
              class="px-4 py-2"
            >
              {{ column | translate }}
            </td>
          </tr>
        </thead>
        <ng-container *ngIf="filesArrToDisplay.length; else noData">
          <tbody>
            <tr
              *ngFor="let row of filesArrToDisplay; trackBy: row?._id"
              class="bg-white"
            >
              <td class="px-6 py-4">
                {{ row?.name | empty : "-" }}
              </td>
              <td class="px-6 py-4">
                {{ row.size | empty : "-" }}
              </td>
              <td class="px-6 py-4">
                {{ row?.type | empty : "-" }}
              </td>
              <td class="px-6 py-4">
                {{ row?.lastModified | empty : "-" }}
              </td>
              <td class="pl-6 py-4">
                <app-dropdown #dropdown [isFromSettings]="true">
                  <div
                    button
                    (click)="dropdown.open = true"
                    (clickOutside)="dropdown.open = false"
                  >
                    <div
                      class="h-8 w-8 rounded-[4px] flex justify-center items-center border-2 border-[#DCDCDC]"
                    >
                      <svg
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <rect
                          x="0.907715"
                          y="0.307617"
                          width="15.3846"
                          height="15.3846"
                          fill="url(#pattern0_30884_4859)"
                        />
                        <defs>
                          <pattern
                            id="pattern0_30884_4859"
                            patternContentUnits="objectBoundingBox"
                            width="1"
                            height="1"
                          >
                            <use
                              xlink:href="#image0_30884_4859"
                              transform="scale(0.0104167)"
                            />
                          </pattern>
                          <image
                            id="image0_30884_4859"
                            width="96"
                            height="96"
                            xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAYKADAAQAAAABAAAAYAAAAACpM19OAAABZklEQVR4Ae3ZzUlDQRiG0blYUcACLEVcpQtjF67EUlKAoB0lzF5k1JuZ94MzcHfze57lbc0gQIAAAQIECBAgQIAAAQIECBAgQIAAAQIECBAgQIAAAQIECBAgQIAAAQIECBAgQIAAAQIECBAgQIAAAQIECBAgQKCawDb7wo9Px9Nla8+zzx05b7u0l/e319PI3L3m3O210eg+X58f58PhfmtbexhdM2PeCvz+rukB+qFpEVbhLwuQFGEl/tIACRFW4y8PsDJCAn5EgBURUvBjAsyMkIQfFWBGhDT8uAC3jJCIHxngFhFS8WMD7BkhGT86wB4R0vHjA/wnQgX8EgH+EqEKfpkAv4lQCb9UgJEI1fDLBfgpQkX8kgG+i1AVv7+l9Oi/N/tX+hEuT4AAAQIECBAgQIAAAQIECBAgQIAAAQIECBAgQIAAAQIECBAgQIAAAQIECBAgQIAAAQIECBAgQIAAAQJlBa5uD5fgrR307gAAAABJRU5ErkJggg=="
                          />
                        </defs>
                      </svg>
                    </div>
                  </div>
                  <div options class="new-shadow p-1">
                    <div
                      (click)="showRemoveFileConfirmation(row)"
                      class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                    >
                      {{ "Delete" | translate }}
                    </div>
                  </div>
                </app-dropdown>
              </td>
            </tr>
          </tbody>
        </ng-container>
      </table>
    </ng-container>
  
    <ng-template #noData class="flex items-center justify-center">
      <tbody>
        <tr>
          <td class="pt-8 text-center" colspan="5">
            {{ "No Files to Show" | translate }}
          </td>
        </tr>
      </tbody>
    </ng-template>
  
    <div
      *ngIf="!haveStorageSubscription"
      class="z-[29] w-96 text-center absolute top-[30%] left-[50%] -translate-x-1/2 -translate-y-1/2"
    >
      You don't have any active storage plan, purchase one to access this feature
    </div>
  </div>
  <ng-template #noFiles>
    <div class="text-xs flex flex-col gap-2 items-start justify-center h-full">
      <div class="font-Medium">
        {{ "Add Files" | translate }}
      </div>
      <div class="text-xs text-[#1C1C1C66]">
        {{ "Allowed file types: png, pdf, jpeg." | translate }}
      </div>
    </div>
  </ng-template>
  