import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectsComponent } from './projects.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule, Routes } from '@angular/router';
import { ListProjectsComponent } from './components/list-projects/list-projects.component';
import { ProjectDetailsComponent } from './components/project-details/project-details.component';
import { ReactiveFormsModule } from "@angular/forms";
import { ClickOutsideModule } from 'ng-click-outside';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from 'src/app/directives/directives.module';


const routes :Routes = [
{
  path:'',
  component: ProjectsComponent,
  children:[
    {
      path:'list',
      component:ListProjectsComponent
    },
    {
      path:':id',
      component: ProjectDetailsComponent
    }
  ]
},
]

@NgModule({
  declarations: [
    ProjectsComponent,
    ListProjectsComponent,
    ProjectDetailsComponent
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    ClickOutsideModule,
    RouterModule.forChild(routes),
    TranslateModule,
    DirectivesModule
  ],
  
})
export class ProjectsModule { }
