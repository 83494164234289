<div class="min-h-screen bg-light-gray-1 flex justify-center p-4">
    <div class="flex flex-col w-full lg:w-1/2">
        <a class="my-12" routerLink='/auth/login'><img src="assets/images/logo.png" width="180" alt="logo"></a>
        <div class="rounded-2xl p-14 bg-white text-center">
            <div *ngIf='verifying'>
                <h2 class="text-3xl Gilroy-Bold mb-2">Verifying Email</h2>
                <p class="text-xl">Please wait...</p>
            </div>
            <div *ngIf='verificationSuccess'>
                <h2 class="text-3xl Gilroy-SemiBold mb-2">Email Verification successfull</h2>
                <p class="text-xl">You can now login to your account with this verified email</p>
                <p class="text-lg">Redirecting to login page in 3 seconds</p>
            </div>
            <div *ngIf="verificationFailed">
                <h2 class="text-3xl Gilroy-Bold mb-2">Verfication Failed</h2>
                <p class="text-red-500 text-xl">{{error ? 'Invalid URL parameters, please verify the URL.' : 'Internal Server Error'}}</p>
            </div>
        </div>
    </div>
</div>
