import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SubscriptionComponent } from './subscription.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { SubscriptionListComponent } from './subscription-list/subscription-list.component';
import { SubcriptionHistoryComponent } from './subcription-history/subcription-history.component';
import { TranslateModule } from '@ngx-translate/core';
import { PayrollSubscriptionComponent } from './payroll-subscription/payroll-subscription.component';
import { PayrollSubscriptionListComponent } from './payroll-subscription-list/payroll-subscription-list.component';
import { PayrollSubscriptionHistoryComponent } from './payroll-subscription-history/payroll-subscription-history.component';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  {
    path: '',
    component: SubscriptionComponent,
    children: [
      {
        path: '',
        component: SubscriptionListComponent
      },
      {
        path: 'subscription-plans',
        component: SubscriptionListComponent
      },
      {
        path: 'subscription-history',
        component: SubcriptionHistoryComponent
      }
    ]
  },
  // {
  //   path: 'payroll',
  //   component: PayrollSubscriptionComponent,
  //   children: [
  //     {
  //       path: '',
  //       component: PayrollSubscriptionListComponent
  //     },
  //     {
  //       path: 'subscription-plans',
  //       component: PayrollSubscriptionListComponent
  //     },
  //     {
  //       path: 'subscription-history',
  //       component: PayrollSubscriptionHistoryComponent
  //     }
  //   ]
  // }
];

@NgModule({
  declarations: [
    SubscriptionComponent,
    SubscriptionListComponent,
    SubcriptionHistoryComponent,
    PayrollSubscriptionComponent,
    PayrollSubscriptionHistoryComponent,
    PayrollSubscriptionListComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    NgxStripeModule.forChild(environment.stripePublishKey),
    RouterModule.forChild(routes)
  ],
  providers: [
    DatePipe
  ]
})
export class SubscriptionModule { }
