<div class="mb-6">
    <h3 class="text-3xl mb-4 Gilroy-Bold inline-flex">{{companyName}} {{'Users' | translate }}</h3>
    <p class="Gilroy-Bold">{{'User invite message' | translate}}
    </p>
</div>
<ul class="space-y-4">
    <li *ngIf='businessOwner.email' class="bg-light-gray-1 rounded-3xl py-4 px-8">
        <div class="flex items-center justify-between">
            <div class="space-y-1">
                <p class="Gilroy-Bold">{{businessOwner.firstName}} {{businessOwner.lastName}} | {{'Owner' | translate }}</p>
                <p class="text-sm Gilroy-Bold">{{businessOwner.email}}</p>
            </div>
        </div>
    </li>
    <li *ngFor='let user of usersList'  class="bg-light-gray-1 rounded-3xl py-4 px-8">
        <div class="flex items-center justify-between">
            <div class="space-y-1">
                <p class="Gilroy-Bold">{{user.firstName}} {{user.lastName}} | {{user.position | titlecase}}</p>
                <p class="text-sm Gilroy-Bold">{{user.email}}</p>
            </div>
            <div class="flex items-center">
                <button *ngIf='businessOwner.email === userFromStore?.email' [routerLink]="['invite-user/', user.position]" [queryParams]="{inviteId: user._id}" class="text-sm Gilroy-Bold px-6 border border-blue-600 bg-white h-9 rounded-full me-2">{{'Edit' | translate }}</button>
                <button (click)='removeInvitedUser(user._id)'>
                    <svg xmlns="http://www.w3.org/2000/svg" name='delete' class="h-5 w-5 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                </button>
            </div>
        </div>
    </li>

</ul>