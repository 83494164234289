<div class="space-y-4 p-4">
    <div class="flex items-center justify-between">
      <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24"
          stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
        </svg>
        {{fieldNames?.type | translate}} # {{receiptData?.recieptNumber}}
      </h1>
    </div>
    <div class="space-x-2 rtl:space-x-reverse flex">
        <app-button (handleClick)="emailModal=true; fetchSendInvoiceEmail()" type='primary' buttonText='{{"Send" | translate }} {{fieldNames?.type | translate }}'></app-button>
        <app-button (handleClick)="downloadPdf()" type='primary' buttonText='{{"Download" | translate }} {{fieldNames?.type | translate }}'></app-button>
        <div class="border rounded-xl flex items-center ps-2">
            <a [href]='shareURL' class="max-w-[250px] overflow-hidden whitespace-nowrap overflow-ellipsis">
              {{shareURL}}
            </a>
            <app-button type='primary' buttonText='{{copyButtonText | translate}}' (handleClick)='copyLink()'></app-button>
          </div>
    </div>
    <div class="flex">
        <div class="w-5/6 mx-auto">
            <table class="w-full d-block overflow-hidden box-shadow-sales rounded-xl">
                <div style="padding: 2rem;">
                  <table class="w-full" style="border-collapse: collapse;">
                    <tr>
                      <td valign='top' class="ps-8">
                        <div>
                          <h1 class="text-2xl font-semibold"> {{fieldNames?.type | translate}} </h1>
                          <table style="border-collapse: collapse;">
                            <tr>
                              <td class="Gilroy-Bold">{{fieldNames?.type | translate }}  {{"Number" | translate}}:</td>
                              <td align="right" class="ps-4">{{receiptData?.recieptNumber}}</td>
                            </tr>
                            <tr>
                              <td class="Gilroy-Bold">{{fieldNames?.type | translate }}  {{ "Date" | translate }}:</td>
                              <td align="right" class="ps-4">{{receiptData?.payDate | date}}</td>
                            </tr>
                            <tr>
                              <td class="Gilroy-Bold">{{'Reference Number:' | translate }}</td>
                              <td align="right" class="ps-4">{{receiptData?.referanceNumber}}</td>
                            </tr>
                            <tr>
                              <td class="Gilroy-Bold">{{'Memo:' | translate }}</td>
                              <td align="right" class="ps-4">{{receiptData?.memo}}</td>
                            </tr>
                          </table>
                        </div>
                      </td>
                      <td valign='top' align="right" class="pe-8 w-1/2" >
                        <img *ngIf="companyLogo" [src]="companyLogo" width="200" alt="">
                        <div class="Gilroy-Bold mt-2">{{receiptData.businessDetails?.businessId?.companyName}}</div>
                        <div>{{receiptData.businessDetails?.businessId?.address?.addressLineFirst}}</div>
                        <div>{{receiptData.businessDetails?.businessId?.address?.addressLine2}}</div>
                        <div>{{receiptData.businessDetails?.businessId?.address?.city ? receiptData.businessDetails?.businessId?.address?.city + ',' : ''}} {{receiptData.businessDetails?.businessId?.address?.state}}</div>
                        <div>{{receiptData.businessDetails?.businessId?.country}}</div>
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="px-16">
                  <table class="w-full border-b" style="border-collapse: collapse;">
                    <tr>
                      <td>
                        <table>
                          <tr>
                            <td>
                              <div class="Gilroy-Bold">{{fieldNames?.billTo | translate}}</div>
                              <div>{{receiptData?.type === 'Receipt' || receiptData?.type === 'Refund' ? receiptData?.customerDetails?.customerId?.customerName : receiptData?.vendorId?.vendorName}}</div>
                              <div>{{receiptData?.type === 'Receipt' || receiptData?.type === 'Refund' ? receiptData?.customerDetails?.customerId?.email : receiptData?.vendorId?.email}}</div>
                              <div>{{receiptData?.type === 'Receipt' || receiptData?.type === 'Refund' ? receiptData?.customerDetails?.customerId?.billingAddress?.addressLine1 : receiptData?.vendorId?.address?.addressLine1}}</div>
                              <div>{{receiptData?.type === 'Receipt' || receiptData?.type === 'Refund' ? receiptData?.customerDetails?.customerId?.billingAddress?.addressLine2 : receiptData?.vendorId?.address?.addressLine2}}</div>
                              <div *ngIf="receiptData?.type === 'Receipt'|| receiptData?.type === 'Refund'">{{receiptData?.customerDetails?.customerId?.billingAddress?.city ? receiptData?.customerDetails?.customerId?.billingAddress?.city + ',' : ''}} {{receiptData?.customerDetails?.customerId?.billingAddress?.state}}</div>
                              <div *ngIf="receiptData?.type !== 'Receipt' || receiptData?.type !== 'Refund'">{{receiptData?.vendorId?.address?.city ? receiptData?.vendorId?.address?.city + ',' : ''}} {{receiptData?.vendorId?.address?.state}}</div>
                              <div>{{receiptData?.type === 'Receipt' || receiptData?.type === 'Refund' ? receiptData?.customerDetails?.customerId?.billingAddress?.postalCode : receiptData?.vendorId?.address?.postalCode}}</div>
                              <div>{{receiptData?.type === 'Receipt' || receiptData?.type === 'Refund' ? receiptData?.customerDetails?.customerId?.billingAddress?.country : receiptData?.vendorId?.address?.country}}</div>
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td align="right">


                      </td>
                    </tr>
                  </table>
                </div>
                <div class="px-16" *ngIf="receiptData.type ==='Refund' || receiptData.type ==='Return'">
                  <span class="text-lg mb-2 Gilroy-Bold">{{'Return Items' | translate }}</span>
                  <table class="w-full">
                    <tr>
                      <td class="bg-gray-100 Gilroy-Bold p-2">{{'Item Name' | translate}}</td>
                      <td class="bg-gray-100 Gilroy-Bold">{{'SKU' | translate}}</td>
                      <td class="bg-gray-100 Gilroy-Bold">{{'HSN/SAC' | translate}}</td>
                      <td class="bg-gray-100 Gilroy-Bold">{{'Quantity' | translate}}</td>
                      <td class="bg-gray-100 Gilroy-Bold">{{'Price' | translate}}</td>
                      <td class="bg-gray-100 Gilroy-Bold">{{'Total' | translate }}</td>
                      <td class="bg-gray-100 Gilroy-Bold">{{'Tax' | translate }}</td>
                    </tr>
                    <tr *ngFor='let itemData of returnItems'>
                      <td class="p-2">{{itemData.item}}</td>
                      <td>{{itemData.sku}}</td>
                      <td>{{itemData.hsn_sac}}</td>
                      <td>{{itemData.unit}}</td>
                      <td>{{itemData.price | currency : currencyDetails?.currency : 'symbol-narrow' : number}}</td>
                      <td>{{itemData.unit * itemData.price | currency : currencyDetails?.currency : 'symbol-narrow' : number}}</td>
                      <td>{{itemData?.tax?.length > 0 && itemData?.tax[0]?.tax ? itemData.tax[0].tax + "%" : 'No Tax'}}</td>
                    </tr>
                  </table>
                </div>
                <div class="px-16" >
                  <table class="w-full mt-2" style="margin-bottom: 2em;">
                    <tr>
                        <td style="width: 50%;" *ngIf="receiptData.type !=='Return'">
                            <table class="">
                                <tr>
                                  <td class="Gilroy-Bold pe-2">{{'Payment Method' | translate }}</td>
                                  <td>{{receiptData?.paymentMethod}}</td>
                                </tr>
                            </table>
                        </td>
                        <td align="right" style="width: 50%;">
                            <table class="">
                                <tr>
                                    <td class="Gilroy-Bold pe-2">{{fieldNames?.type | translate }} {{"Amount" | translate }}</td>
                                    <td>{{receiptData?.paidAmount}}</td>
                                </tr>
                                <tr>
                                    <td class="Gilroy-Bold pe-2">{{fieldNames?.issuer | translate }}:</td>
                                    <td>{{receiptData?.userId?.firstName ? receiptData?.userId?.firstName + ' ' + receiptData?.userId?.lastName : ''}}</td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                  </table>
                  <div class="flex rounded-xl my-5">
                    <div  *ngIf="receiptData?.type === 'Refund'"class="p-5 w-1/4 flex justify-between items-center">
                        <div class="">
                            <img class="" src="{{receiptData?.qrcode}}" />
                        </div>
                    </div>
                  </div>
                </div>

            </table>
        </div>

    </div>
</div>


<app-modal-container [isOpen]='emailModal'>
    <div header class="Gilroy-Bold">{{"Share " + fieldNames?.type + " via email" | translate}}</div>
    <div content class="flex flex-col">
      <label for="">{{'Email' | translate}}</label>
      <input [formControl]='email' type="text" class="w-full rounded-xl bg-gray-100 border-0 p-3">
      <div *ngIf='email.getError("required") && email.dirty' class="text-sm text-red-400 ms-2">{{'Email is required' | translate}}</div>
      <div *ngIf='email.getError("pattern") && email.dirty' class="text-sm text-red-400 ms-2">{{'Email must be in correct format' | translate}}</div>
    </div>
    <div footer class='flex space-x-2 rtl:space-x-reverse'>
      <app-button type='primary' buttonText='{{"Send" | translate}}' (handleClick)='sendEmail()'></app-button>
      <app-button type='third' buttonText='{{"Cancel" | translate}}' (handleClick)='emailModal = false'></app-button>
    </div>
  </app-modal-container>
