export const environment = {
  production: true,
   apiBaseURL: 'https://jo.api.numetric.work',//for production live
  //  apiBaseURL: 'https://api.numetric.co', // for production staging
   frontBaseURL: 'https://jo.numetric.work',// for production live
  //  frontBaseURL: 'https://numetric.co/',// for staging live
  versionCheckUrl: 'https://numetric.work/version.json',
  // stripePublishKey: 'pk_test_51NLpH2FIjrLDlXyw3yRuZl6RkR52H9q4KKx5AaRsC9575wGnjG1r0XB7NrPsXX29WXPhBfTlcnTEiZpShR1pJPz8004lYAOQnj'//test key
  stripePublishKey: 'pk_live_51NLpH2FIjrLDlXywbsgcMF5kzhjdp53lwO5IcqZbIPU8AOkH9NGhRHA2gdDWSXuodujFo1t97xTRqIZMIUskOZ8400jucO7Sra'// live key
};
