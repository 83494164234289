<div class="min-h-screen bg-light-gray-1 flex justify-center p-4">
    <div class="flex flex-col w-full lg:w-1/2">
        <a class="my-12" routerLink='/auth/login'><img src="assets/images/logo.png" width="180" alt="logo"></a>
        <div class="rounded-2xl p-14 bg-white text-center">
            <div *ngIf='verifying'>
                <h2 class="text-3xl Gilroy-Bold mb-2">Verifying Invitation</h2>
                <p class="text-xl">Please wait...</p>
            </div>
            <div *ngIf='verificationSuccess'>
                <h2 class="text-3xl Gilroy-SemiBold mb-2">Invitation Verification successfull</h2>
                <p class="text-xl">Configuring bussiness settings...</p>
                <p class="text-lg">You will be redirected to your account profile</p>
            </div>
            <div *ngIf="verificationFailed">
                <h2 class="text-3xl Gilroy-Bold mb-2">Verfication Failed</h2>
                <p class="text-red-500 text-xl">{{error ?? 'Internal Server Error'}}</p>
            </div>
        </div>
    </div>
</div>