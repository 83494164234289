import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as echarts from 'echarts';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators';
import { CurrencywithspacePipe } from 'src/app/pipes/currencywithspace.pipe';
import { NumberService } from 'src/app/shared/services/number.service';
import { UserService } from 'src/app/shared/services/user.service';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import {
  selectUser,
  selectUserSubscription,
} from 'src/app/store/selectors/user.selectors';
import { ChatsService } from '../../chats/chats.service';
import { DashboardService } from '../dashboard.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import * as moment from 'moment';
import { groupBy, last } from 'lodash';

@Component({
  selector: 'app-dashboard-new',
  templateUrl: './dashboard-new.component.html',
  styleUrls: ['./dashboard-new.component.scss'],
})
export class DashboardNewComponent implements OnInit {
  number = '1.2-2';
  year = moment().format('YYYY');
  previousYear = moment().subtract(1, 'year').format('YYYY');
  public barChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      xAxes: [
        {
          ticks: {
            autoSkip: false, // Ensure all labels are displayed
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            suggestedMax: 500,
            callback: function (value) {
              // Format the value
              if (value >= 100) {
                return value / 100 + 'k';
              }
              return value;
            },
          },
          gridLines: {
            display: false, // Hide vertical grid lines (if any)
          },
          stacked: true,
        },
      ],
    },
    tooltips: {
      enabled: true,
      mode: 'nearest',
      intersect: false, // Show tooltip when hovering near a data point
      padding: 10,
      backgroundColor: 'rgba(0, 0, 0, 1)', // Fully opaque black
      bodyFontColor: 'rgba(255, 255, 255, 1)', // Fully opaque white
      titleFontColor: 'rgba(255, 255, 255, 1)', // Fully opaque white
      bodyFontSize: 12,
      bodyFontStyle: 'normal', // Ensure normal font style
      titleFontSize: 14,
      titleFontStyle: 'normal', // Ensure normal font style
      titleMarginBottom: 10,
    },
    hover: {
      mode: 'nearest',
      intersect: false, // Ensure hover activates near a data point
    },
  };

  public barChartLabels = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ].forEach((item) => this.translateService.instant(item));
  public barChartType = 'bar';
  public barChartLegend = false;
  public barChartData = [
    // {
    //   data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    //   label: this.translateService.instant('Net'),
    //   type: 'line',
    //   fill: false,
    //   // backgroundColor: 'rgba(0, 0, 0, 0)',
    //   // pointBackgroundColor: '#fff',
    //   borderColor: '#181818',
    //   lineTension: 0.4,
    // },
    {
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      label: this.translateService.instant('Outflow'),
      type: 'line',
      fill: false,
      borderColor: '#4E5BA6',
      borderDash: [5, 5],
      borderWidth: 2, // Adjust the border width
      lineTension: 0.4, // Smooth curve
      pointRadius: 0, // Hide points
      pointHoverRadius: 5, // Show points on hover
      showLine: true,
      // backgroundColor: '#4E5BA6'
    },
    {
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      label: this.translateService.instant('Inflow'),
      type: 'line',
      fill: true,
      borderColor: '#0BA5EC',
      backgroundColor: (ctx) => {
        const gradient = this.createVerticalGradient(ctx.chart.ctx, '#f8f7f7');
        return gradient;
      },
      borderWidth: 2, // Adjust the border width
      lineTension: 0.4, // Smooth curve
      pointRadius: 0, // Hide points
      pointHoverRadius: 5, // Show points on hover
      showLine: true,
    },
  ];

  public chartColors: any[] = [];
  public doughnutChartData = [250, 80, 80, 40];
  public doughnutChartType = 'doughnut';
  public chartColors1: any[] = [
    {
      backgroundColor: ['#0B5878', '#01CC9B', '#14A0C0', '#7DE314'],
    },
  ];

  public profitLossChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    borderRadius: 20,
    // devicePixelRatio: window.devicePixelRatio || 1,
    scales: {
      yAxes: [
        {
          stacked: false,
          ticks: {
            beginAtZero: true,
            suggestedMax: 5000, // Adjust to reflect typical data range for testing
            fontFamily: 'Poppins',
            padding: 10,
            callback: function (value) {
              // Format the value
              if (value >= 1000) {
                return value / 1000 + 'k';
              }
              return value;
            },
          },
          gridLines: {
            display: false, // Hide horizontal grid lines
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          ticks: {
            padding: 10,
            fontFamily: 'Poppins',
            fontSize: 10,
            stepSize: 1,
          },
          gridLines: {
            display: false, // Hide vertical grid lines (if any)
          },
        },
      ],
    },
    // tooltips: {
    //   enabled: true,
    //   mode: 'index',
    //   intersect: false,
    //   padding: 15, // Increase padding for more height
    //   backgroundColor: 'rgba(0, 0, 0, 1)', // Fully opaque black
    //   bodyFontColor: 'rgba(255, 255, 255, 1)', // Fully opaque white
    //   titleFontColor: 'rgba(255, 255, 255, 1)', // Fully opaque white
    //   bodyFontSize: 14, // Increase body font size
    //   bodyFontStyle: 'normal',  // Ensure normal font style
    //   titleFontSize: 16, // Increase title font size
    //   titleFontStyle: 'normal', // Ensure normal font style
    //   titleMarginBottom: 12, // Increase margin for better spacing
    //   displayColors: false // Optionally hide color boxes next to tooltip labels
    // }
  };

  public profitLossChartLabels = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ].map((item) => this.translateService.instant(item));
  public profitLossChartType = 'bar';
  public profitLossChartLegend = false;
  public profitLossChartData = [
    {
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      label: this.translateService.instant('Expense'),
      backgroundColor: '#026AA2',
      stack: 'stack1',
      borderColor: 'transparent',
      barThickness: 25,
      borderSkipped: 'start',
      borderRadius: [
        { topLeft: 12, topRight: 12, bottomLeft: 12, bottomRight: 12 },
        { topLeft: 12, topRight: 12, bottomLeft: 12, bottomRight: 12 },
      ],
    },
    {
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      label: this.translateService.instant('Income'),
      backgroundColor: '#36BFFA',
      stack: 'stack1',
      borderColor: 'transparent',
      borderRadius: [
        { topLeft: 12, topRight: 12, bottomLeft: 12, bottomRight: 12 },
        { topLeft: 12, topRight: 12, bottomLeft: 12, bottomRight: 12 },
      ],
      barThickness: 25,
      borderSkipped: 'end',
    },
  ];

  // public payableChartOptions = {
  //   responsive: true,
  //   spacing: 10,
  //   borderRadius: 10,
  //   borderWidth: 20,
  // };

  // public payableChartLabels = ['January', 'February', 'March', 'April'].map(item => this.translateService.instant(item));
  // public payableChartType = 'doughnut';
  // public payableChartLegend = false;
  // public payableChartData = [
  //   {
  //     data: [100, 500, 200, 100],
  //     backgroundColor: ['#36BFFA', '#FF6384', '#FFCE56', '#4BC0C0'],
  //     borderColor: 'transparent',
  //     borderWidth: 20,
  //     borderRadius: 20,
  //   },
  // ];
  notificationsCount: any;
  allNotifications: any;
  userDetails: any;
  user$: Observable<any>;
  selectedQuickAdds = ['customer', 'vendor', 'invoice', 'style'];
  percentages = {
    thirtyDays: 0,
    sixtyDays: 0,
    ninetyDays: 0,
    moreThanNinetyDays: 0,
  };
  unsubscribe$ = new Subject();
  isSafari = false;

  constructor(
    private dashboardService: DashboardService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    private numberService: NumberService,
    private router: Router,
    private chatService: ChatsService,
    private deviceService: DeviceDetectorService,
    private userService: UserService,
    private utlity: UtilityService,
    private store: Store<RootReducerState>
  ) {
    if(this.deviceService.isMobile()) {
      this.router.navigate(['/chats']);
    };
    this.business$ = this.store.pipe(select(selectBusiness));
    this.subscription$ = this.store.pipe(select(selectUserSubscription));
    this.user$ = this.store.pipe(select(selectUser));

    const browser = window.navigator.userAgent.toLowerCase();
    this.isSafari = browser.includes('safari') && !browser.includes('chrome');
    console.log('browserCheck', this.isSafari);
  }
  subscription$: Observable<any>;
  business$: Observable<any>;
  businessId;
  currencyName = 'USD';
  unsubscribe = new Subject();
  dateFilters = {
    currentWeek: {
      fromDate: '',
      endDate: '',
    },
    Last7Days: {
      fromDate: '',
      endDate: '',
    },
    LastMonth: {
      fromDate: '',
      endDate: '',
    },
    Last30Days: {
      fromDate: '',
      endDate: '',
    },
    currentMonth: {
      fromDate: '',
      endDate: '',
    },
    currentYear: {
      fromDate: '',
      endDate: '',
    },
  };
  filters = [];
  selectedFilter = {
    text: this.translateService.instant('Current Week'),
  };
  profitLossSelectedFilter = {
    text: this.translateService.instant('Current Week'),
  };
  overdueInvoices = [];
  overdueBills = [];
  payable = {
    firstPhase: 0,
    secondPhase: 0,
    thirdPhase: 0,
    lastPhase: 0,
  };
  owing = {
    firstPhase: 0,
    secondPhase: 0,
    thirdPhase: 0,
    lastPhase: 0,
  };
  netIncome = {
    currentYear: {
      income: 0,
      outcome: 0,
    },
    previousYear: {
      income: 0,
      outcome: 0,
    },
  };
  chartOption: any;
  selectedCard: any;
  searchText: any;
  subscriptionCards = [
    {
      title: 'Storage',
      content: 'Seamlessly organize and access all your essential files.',
      price: 'Starting from $10 per year for 5GB.',
    },
    {
      title: 'Budget',
      content: 'Effortlessly plan and track your P&L Budget.',
      price: 'Add now for $30 per year.',
    },
    {
      title: 'Recurring',
      content: 'Automate invoices & transactions for consistent cash flow.',
      price: 'Add now for $30 per year.',
    },
    {
      title: 'Inventory',
      content: 'Streamline your stock management with automation.',
      price: 'Add now for $30 per year.',
    },
    {
      title: 'Connect PayTabs',
      content: 'Enhance your payment options with PayTabs.',
      price: 'Free Integration!',
    },
  ];
  isToolTipDisabled = false;
  cashAndBankAmt = 0;
  config: any = {
    tableHeadings: [],
    tableData: [],
    tableKeys: [],
    templateName: '',
    startDate: null,
    endDate: null,
  };

  @ViewChild('textElement') textElement!: ElementRef<HTMLSpanElement>;

  ngOnInit(): void {
    this.loadDates();
    this.loadFilters();
    this.loadInitialData();
    this.loadNumberConfig();

    // const alreadySelected = JSON.parse(localStorage.getItem('selectedQuickAdds'));
    // if(alreadySelected){
    //   this.selectedQuickAdds = alreadySelected;
    // }

    this.chatService.listen('getAllNotifications').subscribe((data) => {
      this.allNotifications = data
        .filter(
          (item) =>
            (item.toUser?.includes(this.userDetails?._id) ||
              item.toUser === this.userDetails?._id) &&
            (item.fromUser !== this.userDetails?._id ||
              (item.fromUser === this.userDetails?._id &&
                item.entityType === 'taskReminder')) &&
            item.businessId === this.businessId
        )
        .slice()
        .reverse();
      this.notificationsCount = this.allNotifications.filter(
        (item) => !item.seen
      ).length;
    });

    this.user$.subscribe((user) => {
      if (user) {
        this.userDetails = user;
        if (user) {
          this.selectRandomCard();
        }
        this.chatService.emit('getAllNotifications', this.userDetails._id);
      }
    });

    this.userService.toolTipDisabled
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.isToolTipDisabled = result;
      });
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.adjustFontSize();
  }

  private adjustFontSize() {
    const parentWidth =
      this.textElement.nativeElement.parentElement?.clientWidth || 0;
    let fontSize = 3;

    this.textElement.nativeElement.style.fontSize = `${fontSize}vw`;

    while (
      this.textElement.nativeElement.scrollWidth > parentWidth &&
      fontSize > 1
    ) {
      fontSize -= 0.1;
      this.textElement.nativeElement.style.fontSize = `${fontSize}vw`;
    }
  }

  selectRandomCard() {
    const allExistingPlans = this.userDetails?.subscription?.subscriptions?.map(
      (item) => item.subHost
    );
    console.log('allExistingPlans', allExistingPlans);
  
    if (allExistingPlans && allExistingPlans.length > 0) {
      this.subscriptionCards = this.subscriptionCards.filter((item) => 
        !allExistingPlans.includes(item.title)
      );
    }

  
    const lastIndex = localStorage.getItem('lastCardIndex');
    let randomIndex = Math.floor(Math.random() * this.subscriptionCards.length);
  
    // Prevent infinite loop by ensuring there are at least two options
    if (lastIndex !== null && this.subscriptionCards.length > 1) {
      while (randomIndex === parseInt(lastIndex)) {
        randomIndex = Math.floor(Math.random() * this.subscriptionCards.length);
      }
    }
  
    this.selectedCard = this.subscriptionCards[randomIndex];
    localStorage.setItem('lastCardIndex', randomIndex.toString());
  }
  

  loadNumberConfig(): void {
    this.numberService.decimalSize
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((number) => {
        this.number = `1.${number}-${number}`;
      });
  }

  loadDates(): void {
    this.dateFilters.currentWeek.fromDate = moment()
      .startOf('week')
      .format('YYYY-MM-DD');
    this.dateFilters.currentWeek.endDate = moment()
      .endOf('week')
      .format('YYYY-MM-DD');
    this.dateFilters.Last7Days.fromDate = moment()
      .subtract(6, 'days')
      .format('YYYY-MM-DD');
    this.dateFilters.Last7Days.endDate = moment().format('YYYY-MM-DD');
    this.dateFilters.currentMonth.fromDate = moment()
      .startOf('month')
      .format('YYYY-MM-DD');
    this.dateFilters.currentMonth.endDate = moment()
      .endOf('month')
      .format('YYYY-MM-DD');
    this.dateFilters.Last30Days.fromDate = moment()
      .subtract(30, 'days')
      .format('YYYY-MM-DD');
    this.dateFilters.Last30Days.endDate = moment().format('YYYY-MM-DD');
    this.dateFilters.LastMonth.fromDate = moment()
      .subtract(1, 'month')
      .startOf('month')
      .format('YYYY-MM-DD');
    this.dateFilters.LastMonth.endDate = moment()
      .subtract(1, 'month')
      .endOf('month')
      .format('YYYY-MM-DD');
    this.dateFilters.currentYear.fromDate = moment()
      .startOf('year')
      .format('YYYY-MM-DD');
    this.dateFilters.currentYear.endDate = moment()
      .endOf('year')
      .format('YYYY-MM-DD');
  }

  loadFilters(): void {
    this.filters = [
      {
        text: `${this.translateService.instant('Current Week')}`,
        type: 'week',
        fromDate: this.dateFilters.currentWeek.fromDate,
        endDate: this.dateFilters.currentWeek.endDate,
      },
      // {
      //   text: `${this.translateService.instant('Last 7 Days')} (${moment(this.dateFilters.Last7Days.fromDate).format('DD-MM-YYYY')} - ${moment(this.dateFilters.Last7Days.endDate).format('DD-MM-YYYY')})`,
      //   type: 'month',
      //   fromDate: this.dateFilters.Last7Days.fromDate,
      //   endDate: this.dateFilters.Last7Days.endDate
      // },
      // {
      //   text: `${this.translateService.instant('Last 30 Days')}`,
      //   type: 'month',
      //   fromDate: this.dateFilters.Last30Days.fromDate,
      //   endDate: this.dateFilters.Last30Days.endDate
      // },
      {
        text: `${this.translateService.instant('Current Month')}`,
        type: 'month',
        fromDate: this.dateFilters.currentMonth.fromDate,
        endDate: this.dateFilters.currentMonth.endDate,
      },
      {
        text: `${this.translateService.instant('Last Month')}`,
        type: 'month',
        fromDate: this.dateFilters.LastMonth.fromDate,
        endDate: this.dateFilters.LastMonth.endDate,
      },
      {
        text: `${this.translateService.instant('Current Year')}`,
        type: 'year',
        fromDate: this.dateFilters.currentYear.fromDate,
        endDate: this.dateFilters.currentYear.endDate,
      },
    ];
    this.selectedFilter = {
      text: `${this.translateService.instant('Current Week')}`,
    };
    this.profitLossSelectedFilter = {
      text: `${this.translateService.instant('Current Week')}`,
    };
  }

  loadInitialData(): void {
    this.spinner.show();
    this.business$.pipe(takeUntil(this.unsubscribe)).subscribe((business) => {
      if (business?.businessId?._id) {
        this.businessId = business?.businessId?._id;
        const accounting = business?.businessId?.accounting;
        this.currencyName = business?.businessId?.currency;
        const year = moment().format('YYYY');
        const prevYear = moment().subtract(1, 'year').format('YYYY');
        const currentFiscalFromDate = moment(
          `${accounting?.date || '01'} ${
            accounting?.month || 'January'
          } ${year}`
        ).format('YYYY-MM-DD');
        const currentFiscalEndDate = moment(
          `${accounting?.date || '01'} ${
            accounting?.month || 'January'
          } ${year}`
        )
          .add(1, 'year')
          .format('YYYY-MM-DD');
        const previousFiscalFromDate = moment(
          `${accounting?.date || '01'} ${
            accounting?.month || 'January'
          } ${prevYear}`
        ).format('YYYY-MM-DD');
        const previousFiscalEndDate = moment(
          `${accounting?.date || '01'} ${
            accounting?.month || 'January'
          } ${prevYear}`
        )
          .add(1, 'year')
          .format('YYYY-MM-DD');
        console.log(currentFiscalFromDate, currentFiscalEndDate);
        const dataForIncomeCurrent = {
          businessId: business.businessId._id,
          startDate: currentFiscalFromDate,
          endDate: currentFiscalEndDate,
        };
        const dataForIncomePrevious = {
          businessId: business.businessId._id,
          startDate: previousFiscalFromDate,
          endDate: previousFiscalEndDate,
        };
        const data = {
          businessId: business.businessId._id,
          fromDate: this.dateFilters.currentWeek.fromDate,
          endDate: this.dateFilters.currentWeek.endDate,
        };
        this.cashFlowApiCaller(data, 'week');
        this.profitLossApiCaller(data, 'week');
        const apiList = [
          this.dashboardService
            .loadOverdueInvoiceData({ businessId: this.businessId })
            .pipe(catchError((error) => of(null))),
          this.dashboardService
            .loadOverdueBillData({ businessId: this.businessId })
            .pipe(catchError((error) => of(null))),
          this.dashboardService
            .loadPayableData({ businessId: this.businessId })
            .pipe(catchError((error) => of(null))),
          this.dashboardService
            .loadOwingData({ businessId: this.businessId })
            .pipe(catchError((error) => of(null))),
          this.dashboardService
            .loadIncomeExpenseByYear(dataForIncomeCurrent)
            .pipe(catchError((error) => of(null))),
          this.dashboardService
            .loadIncomeExpenseByYear(dataForIncomePrevious)
            .pipe(catchError((error) => of(null))),
          this.dashboardService
            .getCashAndBankAmount({ businessId: this.businessId })
            .pipe(catchError((error) => of(null))),
        ];
        this.spinner.show();
        forkJoin(apiList).subscribe(
          (resp) => {
            // this.overdueInvoices = resp[0]?.data?.reverse()?.splice(0, 4);
            // this.overdueBills = resp[1].data.splice(0, 4);

            const overdueInvoices = resp[0]?.data.reduce((acc, curr) => {
              const customerName = curr.customers.customerName;
              if (!acc[customerName]) {
                acc[customerName] = {
                  customerName: customerName,
                  totalDueAmount: 0,
                  overdueDays: curr.overdueDays,
                };
              }
              acc[customerName].totalDueAmount += curr.dueAmount;
              // Update overdueDays to the maximum value
              if (curr.overdueDays > acc[customerName].overdueDays) {
                acc[customerName].overdueDays = curr.overdueDays;
              }
              return acc;
            }, {});

            const overdueBills = resp[1]?.data.reduce((acc, curr) => {
              const vendorName = curr.vendor.vendorName;
              if (!acc[vendorName]) {
                acc[vendorName] = {
                  vendorName: vendorName,
                  totalDueAmount: 0,
                  overdueDays: curr.overdueDays,
                };
              }
              acc[vendorName].totalDueAmount += curr.dueAmount;
              // Update overdueDays to the maximum value
              if (curr.overdueDays > acc[vendorName].overdueDays) {
                acc[vendorName].overdueDays = curr.overdueDays;
              }
              return acc;
            }, {});

            this.overdueInvoices = Object.values(overdueInvoices)
              ?.reverse()
              ?.splice(0, 4);
            this.overdueBills = Object.values(overdueBills)?.splice(0, 4);

            // const allInvoicesBills = [...resp[0]?.data, ...resp[1].data];
            const allInvoicesBills = [...this.overdueBills];

            const listThirtyDays = allInvoicesBills.filter(
              (item) => item.overdueDays > 0 && item.overdueDays <= 30
            );
            const listSixtyDays = allInvoicesBills.filter(
              (item) => item.overdueDays > 30 && item.overdueDays <= 60
            );
            const listNinetyDays = allInvoicesBills.filter(
              (item) => item.overdueDays > 60 && item.overdueDays <= 90
            );
            const listMoreThanNinetyDays = allInvoicesBills.filter(
              (item) => item.overdueDays > 90
            );

            const totalThirtyDays = listThirtyDays.reduce(
              (sum, item) => sum + item.totalDueAmount,
              0
            );
            const totalSixtyDays = listSixtyDays.reduce(
              (sum, item) => sum + item.totalDueAmount,
              0
            );
            const totalNinetyDays = listNinetyDays.reduce(
              (sum, item) => sum + item.totalDueAmount,
              0
            );
            const totalMoreThanNinetyDays = listMoreThanNinetyDays.reduce(
              (sum, item) => sum + item.totalDueAmount,
              0
            );

            this.percentages = {
              thirtyDays: parseFloat(
                (
                  (listThirtyDays.length / allInvoicesBills.length) *
                  100
                ).toFixed(2)
              ),
              sixtyDays: parseFloat(
                (
                  (listSixtyDays.length / allInvoicesBills.length) *
                  100
                ).toFixed(2)
              ),
              ninetyDays: parseFloat(
                (
                  (listNinetyDays.length / allInvoicesBills.length) *
                  100
                ).toFixed(2)
              ),
              moreThanNinetyDays: parseFloat(
                (
                  (listMoreThanNinetyDays.length / allInvoicesBills.length) *
                  100
                ).toFixed(2)
              ),
            };
            console.log(
              'percentages',
              totalThirtyDays,
              totalSixtyDays,
              totalNinetyDays,
              totalMoreThanNinetyDays
            );

            this.chartOption = {
              color: ['#6172F3', '#EE46BC', '#F63D68', '#FB6514', '#D3D3D3'],
              tooltip: {
                show: true,
                trigger: 'item',
                backgroundColor: '#000000',
                textStyle: {
                  color: '#ffffff',
                },
                formatter: (params) => {
                  if (params.data.name === 'No Data') {
                    // Handle tooltip for empty chart state
                    return 'No Payable Overdue Data';
                  }
            
                  // Normal tooltip handling for data points
                  const { name, percent } = params;
                  let overdueAmount = 0;
                  switch (name) {
                    case '1-30 Days Overdue':
                      overdueAmount = totalThirtyDays;
                      break;
                    case '31-60 Days Overdue':
                      overdueAmount = totalSixtyDays;
                      break;
                    case '61-90 Days Overdue':
                      overdueAmount = totalNinetyDays;
                      break;
                    case '> 90 Days Overdue':
                      overdueAmount = totalMoreThanNinetyDays;
                      break;
                  }
            
                  return `${name}: ${percent}%<br/>Overdue Amount: ${this.currencier(overdueAmount.toFixed(2))}`;
                },
              },
              legend: {
                show: false,
                top: '5%',
                left: 'center',
              },
              series: [
                {
                  name: 'Payable Overdue',
                  type: 'pie',
                  radius: ['40%', '80%'],
                  avoidLabelOverlap: false,
                  itemStyle: {
                    borderRadius: 7, // Radius of the corners
                    borderColor: '#fff',
                    borderWidth: 5, // Width of the border to create spacing effect
                  },
                  label: {
                    show: false,
                    position: 'center',
                  },
                  emphasis: {
                    label: {
                      show: false,
                      fontSize: '30',
                      fontWeight: 'bold',
                    },
                  },
                  labelLine: {
                    show: false,
                  },
                  data: this.getChartData(),
                },
              ],
            };

            this.payable = resp[2].data[0];
            this.owing = resp[3].data[0];
            if (resp[4] && resp[5]) {
              this.netIncome.currentYear.income = resp[4]?.data?.income;
              this.netIncome.currentYear.outcome = resp[4]?.data?.expense;
              this.netIncome.previousYear.income = resp[5]?.data?.income;
              this.netIncome.previousYear.outcome = resp[5]?.data?.expense;
            }
            console.log('cashAndBankAmt', resp);

            this.getCashAndBankAmount(resp[6]);
          },
          (error) => {
            this.spinner.hide();
          }
        );
      }
    });
  }

  getChartData() {
    // Check if there is any data in the provided percentages
    const hasData = [
      this.percentages.thirtyDays,
      this.percentages.sixtyDays,
      this.percentages.ninetyDays,
      this.percentages.moreThanNinetyDays,
    ].some((value) => value > 0);
  
    if (hasData) {
      return [
        { value: this.percentages.thirtyDays, name: '1-30 Days Overdue' },
        { value: this.percentages.sixtyDays, name: '31-60 Days Overdue' },
        { value: this.percentages.ninetyDays, name: '61-90 Days Overdue' },
        { value: this.percentages.moreThanNinetyDays, name: '> 90 Days Overdue' },
      ];
    } else {
      // Return a default "empty" data point to render an empty chart with gray color
      return [{ value: 1, name: 'No Data', itemStyle: { color: '#D3D3D3' } }];
    }
  }
  

  currencier(amount): string {
    const currencyWithSpacePipe = new CurrencywithspacePipe();
    return currencyWithSpacePipe.transform(
      amount,
      this.currencyName,
      'narrowSymbol',
      '1.2-2'
    );
  }

  getCashAndBankAmount(resp): void {
    const respData = resp.data;
      respData.Assets = respData.Assets.map((account) => {
        return {
          ...account,
          class: 'border-b',
          transactionsTotal: (account?.transactionsTotal[0]?.debitNoteAmount + account?.transactionsTotal[0]?.totalDebit) -
          (account?.transactionsTotal[0]?.creditNoteAmount + account?.transactionsTotal[0]?.totalCredit)
        };
      })
      .filter((account) => !isNaN(account.transactionsTotal) && account.transactionsTotal !== 0)
      .map((account) => ({...account, transactionsTotal: this.numberService.toFixed(account.transactionsTotal)}));

      this.cashAndBankAmt = respData.Assets.reduce((prev, curr) => {
        if (curr.accountType === 'Cash and Bank') {
          console.log('cash and bank', prev, curr);
          return prev + curr?.transactionsTotal;
        }
        else{
          return prev;
        }
      }, 0);

      // respData.Assets = this.createSectionForBalanceSheet(respData.Assets, 'Assets').newSection;

      console.log("respData.Assets", this.cashAndBankAmt)

      // const finalCalc = (respData.Assets[respData.Assets.length - 2].transactionsTotal) -
      // respData.Liabilities[respData.Liabilities.length - 2].transactionsTotal;

    this.spinner.hide();
    setTimeout(() => {
      this.adjustFontSize();
      this.spinner.hide();
    });
  }


  createSectionForBalanceSheet(data, title): any {
    const section = JSON.parse(JSON.stringify(data));
    const groupedByType = groupBy(section, 'accountType');
    let newSection = [];
    let total = 0;
    Object.keys(groupedByType).forEach((type) => {
      newSection = newSection.concat(...this.createSubSection(groupedByType[type], type));
      total += newSection[newSection.length - 1].transactionsTotal;
    });
    console.log(newSection);
    newSection.push({
      accountName: `Total ${title}`,
      class: 'bg-gray-200 font-bold',
      transactionsTotal: this.numberService.toFixed(total)
    });
    newSection.push({
      accountName: '',
      class: '',
      transactionsTotal: ''
    });
    newSection.unshift({
      accountName: title,
      class: 'bg-gray-200 font-bold',
      transactionsTotal: ''
    });
    newSection = newSection.map((el) => ({ ...el, transactionsTotal: this.currencier(el.transactionsTotal)}));
    return {newSection, total};
  }

  createSubSection(data, type): any {
    const newArray = [];
    newArray.push({
      accountName: type,
      class: 'bg-gray-100 font-bold',
      transactionsTotal: null
    });
    console.log(data);
    data.forEach((account) => {
      newArray.push({
        accountName: account.accountName,
        class: '',
        transactionsTotal: account.transactionsTotal
      });
    });
    newArray.push({
      accountName: `Total ${type}`,
      class: 'bg-gray-100 font-bold',
      transactionsTotal: data.reduce((prev, curr) => prev + curr.transactionsTotal, 0)
    });
    return newArray;
  }

  loadInflowData(data, type): Observable<any> {
    switch (type) {
      case 'year':
        return this.dashboardService.loadInflowDataByYear(data);
      case 'week':
        return this.dashboardService.loadInflowDataByWeek(data);
      case 'month':
        return this.dashboardService.loadInflowDataByMonth(data);
    }
  }

  loadOutFlowData(data, type): Observable<any> {
    switch (type) {
      case 'year':
        return this.dashboardService.loadOutFlowDataByYear(data);
      case 'week':
        return this.dashboardService.loadOutFlowDataByWeek(data);
      case 'month':
        return this.dashboardService.loadOutFlowDataByMonth(data);
    }
  }

  loadProfitData(data, type): Observable<any> {
    switch (type) {
      case 'year':
        return this.dashboardService.loadProfitByYear(data);
      case 'week':
        return this.dashboardService.loadProfitByWeek(data);
      case 'month':
        return this.dashboardService.loadProfitByMonth(data);
    }
  }

  loadLossData(data, type): Observable<any> {
    switch (type) {
      case 'year':
        return this.dashboardService.loadLossByYear(data);
      case 'week':
        return this.dashboardService.loadLossByWeek(data);
      case 'month':
        return this.dashboardService.loadLossByMonth(data);
    }
  }

  cashFlowChange(data, filter): void {
    const filterData = {
      businessId: this.businessId,
      fromDate: data.fromDate,
      endDate: data.endDate,
    };
    this.cashFlowApiCaller(filterData, data.type, filter);
  }

  cashFlowApiCaller(data, type, filter?): void {
    this.spinner.show();
    this.loadInflowData(data, type)
      .pipe(
        mergeMap((inflowData) => {
          if (inflowData.success) {
            return this.loadOutFlowData(data, type).pipe(
              map((outFlowData) => {
                let labels =
                  type == 'year'
                    ? this.getMonthsArray(data)
                    : this.getDatesArray(data);
                let outFlowValues = [];
                let inFlowVales = [];
                if (type == 'year') {
                  outFlowValues = this.mapDataForYear(
                    outFlowData?.data[0]?.data || [],
                    labels
                  );
                  inFlowVales = this.mapDataForYear(
                    inflowData?.data[0]?.data || [],
                    labels
                  );
                } else if (type == 'month') {
                  outFlowValues = this.mapDataForMonth(
                    outFlowData.data,
                    labels
                  );
                  inFlowVales = this.mapDataForMonth(inflowData.data, labels);
                } else {
                  outFlowValues = this.mapDataForWeek(outFlowData.data, labels);
                  inFlowVales = this.mapDataForWeek(inflowData.data, labels);
                }
                return {
                  outFlowData: outFlowValues,
                  inFlowData: inFlowVales,
                  labels,
                };
              })
            );
          }
        })
      )
      .subscribe(
        (resp) => {
          const outflow: Array<any> = Object.values(resp.outFlowData).map(
            (val) => -val
          );
          const inflow: Array<any> = Object.values(resp.inFlowData);
          let line = new Array(outflow.length).fill(0);
          line = line.map((ele, i) => inflow[i] + outflow[i]);
          console.log('finalbarChartData', resp);
          // console.log("Outflow data1:", outflow);
          // console.log("Outflow data2:", inflow);
          // console.log("Outflow data3:", line);

          this.barChartData[0].data = outflow;
          this.barChartData[1].data = inflow;

          this.barChartLabels = resp.labels;
          if(filter){
            this.spinner.hide();
          }
        },
        (error) => {
          this.spinner.hide();
          console.log(error);
        }
      );
  }

  profitLossChange(data, filter): void {
    const filterData = {
      businessId: this.businessId,
      fromDate: data.fromDate,
      endDate: data.endDate,
    };
    this.profitLossApiCaller(filterData, data.type, filter);
  }

  profitLossApiCaller(data, type, filter?): void {
    this.spinner.show();
    this.loadProfitData(data, type)
      .pipe(
        mergeMap((inflowData) => {
          if (inflowData.success) {
            return this.loadLossData(data, type).pipe(
              map((outFlowData) => {
                let labels =
                  type == 'year'
                    ? this.getMonthsArray(data)
                    : this.getDatesArray(data);
                console.log('this.getMonthsArray(data)', labels);
                let outFlowValues = [];
                let inFlowVales = [];
                if (type == 'year') {
                  outFlowValues = this.mapDataForYear(
                    outFlowData?.data[0]?.data || [],
                    labels
                  );
                  inFlowVales = this.mapDataForYear(
                    inflowData?.data[0]?.data || [],
                    labels
                  );
                } else if (type == 'month') {
                  outFlowValues = this.mapDataForMonth(
                    outFlowData?.data,
                    labels
                  );
                  inFlowVales = this.mapDataForMonth(inflowData?.data, labels);
                } else {
                  outFlowValues = this.mapDataForWeek(
                    outFlowData?.data,
                    labels
                  );
                  inFlowVales = this.mapDataForWeek(inflowData?.data, labels);
                }
                return {
                  outFlowData: outFlowValues,
                  inFlowData: inFlowVales,
                  labels,
                };
              })
            );
          }
        })
      )
      .subscribe(
        (resp) => {
          const outflow: Array<any> = Object.values(resp.outFlowData);
          const inflow: Array<any> = Object.values(resp.inFlowData);
          this.profitLossChartData[0].data = outflow;
          this.profitLossChartData[1].data = inflow;
          this.profitLossChartLabels = resp.labels;
          if(filter){
            this.spinner.hide();
          }
        },
        (error) => {
          this.spinner.hide();
          console.log(error);
        }
      );
  }

  mapDataForYear(data, monthsArray): any {
    // Object.keys(data).map((month) => ({_id: month, total: data[month]}))
    const results = Object.keys(data).map((month) => {
      const shortMonth = moment(month, 'MMMM-YYYY').format('MMM'); // Convert to short month format
      return { _id: shortMonth, total: data[month] };
    });
    console.log('...', results);
    const values = new Array(monthsArray.length).fill(0);
    monthsArray.map((month, idx) => {
      results.forEach((dataElement) => {
        if (dataElement._id === month) {
          values[idx] = dataElement.total;
        }
      });
    });
    return values;
  }

  mapDataForMonth(data, labels): any {
    console.log('mapDataForMonth', data);

    const values = new Array(labels.length).fill(0);

    labels.forEach((date, idx) => {
      // Convert the label date from "DD-MM-YYYY" to "YYYY-MM-DD"
      const formattedLabelDate = this.formatDateToYYYYMMDD(date);

      data.forEach((dataElement) => {
        if (dataElement._id === formattedLabelDate) {
          values[idx] = dataElement.total;
        }
      });
    });

    console.log('mapDataForMonth1', values);
    return values;
  }

  formatDateToYYYYMMDD(dateStr) {
    const [day, month, year] = dateStr.split('-');
    return `${year}-${month}-${day}`;
  }

  mapDataForWeek(data, labels): any {
    const array = new Array(labels.length).fill(0);
    data.map((count) => (array[count._id - 1] = count.total));
    return array;
  }

  getDatesArray(filter): any {
    let datesArray = [];
    let startDate: any = moment(filter.fromDate).format('YYYY-MM-DD');
    const endDate = moment(filter.endDate).format('YYYY-MM-DD');
    while (!moment(startDate).isAfter(endDate)) {
      datesArray.push(startDate);
      startDate = moment(startDate).add(1, 'day').format('YYYY-MM-DD');
    }
    datesArray = datesArray.map((el) => {
      return moment(el).format('DD-MM-YYYY');
    });
    console.log(datesArray);

    return datesArray;
  }

  getMonthsArray(filter): any {
    const monthsArray = [];
    let startDate: any = moment(filter.fromDate).format('YYYY-MM-DD');
    const endDate = moment(filter.endDate).format('YYYY-MM-DD');
    while (!moment(startDate).isAfter(endDate)) {
      monthsArray.push(moment(startDate).format('MMM'));
      startDate = moment(startDate).add(1, 'month').format('YYYY-MM-DD');
    }
    monthsArray.forEach((el) => {
      return moment(el).format('DD-MM-YYYY');
    });

    return monthsArray;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  goToNotification(id) {
    // console.log(chat, id);
    const payload = {
      seen: true,
      updateId: id._id,
    };
    this.chatService.emit('updateNotification', payload);
    this.router.navigate(['/chats']);
  }

  createVerticalGradient(
    ctx: CanvasRenderingContext2D,
    color: string
  ): CanvasGradient {
    const chartWidth = ctx.canvas.clientWidth; // Width of the chart area

    // Create a vertical gradient from left to right
    const gradient = ctx.createLinearGradient(0, 0, chartWidth, 0);

    gradient.addColorStop(0, 'rgba(255, 255, 255, 0)'); // Transparent at the left
    gradient.addColorStop(0.1, 'rgba(255, 255, 255, 0)'); // Slight transparency
    gradient.addColorStop(0.2, color); // Start of shaded area
    gradient.addColorStop(0.85, color); // Extend shaded area towards the right
    gradient.addColorStop(1, 'rgba(255, 255, 255, 0)'); // Transparent at the right

    return gradient;
  }

  updateCashFlowChart(dataOne, dataTwo): void {
    const linearGradient = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      { offset: 0, color: 'hsl(0deg 0% 82%)' }, // Lighter shade at the top
      { offset: 0.22, color: 'hsl(0deg 0% 91%)' }, // Slightly darker shade in the middle
      { offset: 0.99, color: 'hsl(0deg 0% 100%)' }, // White at the bottom
    ]);

    // this.chartOption = {
    //   tooltip: {
    //     trigger: 'axis',
    //     axisPointer: {
    //       type: 'cross'
    //     }
    //   },
    //   legend: {
    //     data: ['Plain Line', 'Dotted Line']
    //   },
    //   xAxis: {
    //     type: 'category',
    //     boundaryGap: false,
    //     data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    //   },
    //   yAxis: {
    //     type: 'value'
    //   },
    //   series: [
    //     {
    //       name: 'Plain Line',
    //       type: 'line',
    //       smooth: true,
    //       lineStyle: {
    //         width: 2
    //       },
    //       areaStyle: {
    //         color: linearGradient
    //       },
    //       data: dataOne
    //     },
    //     {
    //       name: 'Dotted Line',
    //       type: 'line',
    //       smooth: true,
    //       lineStyle: {
    //         width: 2,
    //         type: 'dotted'
    //       },
    //       data: dataTwo
    //     }
    //   ]
    // };
  }

  progressBarValue(value): number {
    // console.log("progressBarValue", value);
    if (value > 0 && value <= 30) {
      return 15;
    } else if (value > 30 && value <= 60) {
      return 50;
    } else if (value > 60 && value <= 90) {
      return 75;
    } else if (value > 90) {
      return 100;
    }
  }

  updateQuickAdd(newItem): void {
    // this.selectedQuickAdds.shift();
    // this.selectedQuickAdds.push(newItem);
    // localStorage.setItem("selectedQuickAdds", JSON.stringify(this.selectedQuickAdds));
    if (newItem === 'purchaseOrder') {
      this.router.navigate(['/purchases/purchase-order/create-purchase-order']);
    } else if (newItem === 'estimate') {
      this.router.navigate(['/sales/estimates/create-estimate']);
    } else if (newItem === 'bill') {
      this.router.navigate(['/purchases/bills/create-bill']);
    }
  }

  redirectToSettings(type): void {
    if (type === 'subscription') {
      this.utlity.showHideSettingsModal.next({
        open: true,
        tab: 'subscriptions',
      });
    } else if (type === 'style') {
      this.utlity.showHideSettingsModal.next({ open: true, tab: 'sales' });
    }
  }

  redirect(platformName: string): void {
    let a = document.createElement('a');
    const token = localStorage.getItem('refresh_token');
    if (platformName === 'help') {
      a.href = 'https://learn.numetric.work/pages/support/list';
    } else {
      a.href = `https://learn.numetric.work/autoLogin/${token}/numetric`;
    }
    a.target = 'blank';
    a.click();
  }
}
