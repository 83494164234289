<div class="flex gap-4 p-4 pb-0">
  <div class="new-design-round-border p-4 text-sm flex-grow w-full">
    <form [formGroup]="taxform" novalidate class="flex flex-col gap-4">
      <div class="flex flex-col gap-2">
        <label for="taxname">{{ "Tax Name" | translate }}</label>
        <input
          id="taxname"
          formControlName="taxName"
          type="text"
          class="new-input"
          placeholder="{{ 'Enter Tax Name' | translate }}"
        />
        <label *ngIf="formErrors.taxName" class="text-red-400 ms-2">{{
          formErrors.taxName
        }}</label>
      </div>
      <div class="flex flex-col gap-2">
        <label for="abbreviation">{{ "Abbreviation" | translate }}</label>
        <input
          id="abbreviation"
          formControlName="abbreviation"
          type="text"
          class="new-input"
          placeholder="{{ 'Enter Abbreviation' | translate }}"
        />
        <div *ngIf="formErrors.abbreviation" class="text-red-400 ms-2">
          {{ formErrors.abbreviation }}
        </div>
      </div>
      <div class="flex flex-col gap-2">
        <label for="description">{{ "Description" | translate }}</label>
        <textarea
          id="description"
          formControlName="description"
          class="new-input h-20"
          placeholder="{{ 'Enter Description' | translate }}"
        ></textarea>
        <div *ngIf="formErrors.description" class="text-red-400 ms-2">
          {{ formErrors.description }}
        </div>
      </div>
      <div class="flex flex-col gap-2">
        <div class="flex flex-col">
          <div class="flex flex-col">
            <label for="taxRate">{{ "Tax Rate (%)" | translate }}</label>
            <input
              id="taxRate"
              formControlName="taxRate"
              type="number"
              class="new-input"
              (ngModelChange)="checkIfZero($event)" 
              placeholder="{{ 'Enter Tax Rate' | translate }}"
            />
            <div *ngIf="formErrors.taxRate" class="text-red-400 ms-2">
              {{ formErrors.taxRate }}
            </div>
          </div>
          <div *ngIf="showIsCbcValueOption" class="flex mb-3 mt-2 items-start w-full">
            <fieldset class="flex items-start gap-4" (change)="changeTaxRate($event)">
                <div>
                    <input type="radio" value="Z" formControlName="itemCbcValue"> Exempted
                </div>
                <div>
                    <input type="radio" value="O" formControlName="itemCbcValue"> Zero Percent Tax
                </div>
            </fieldset>
            <div *ngIf='formErrors.itemCbcValue' class="text-sm text-red-400 ms-2">{{formErrors.itemCbcValue}}</div>
        </div>
        </div>
      </div>
      <div class="flex flex-col gap-2 w-1/2">
        <label for="email">Select Accounts</label>
        <fieldset [disabled]="update">
          <ng-select
            [readonly]="update"
            [items]="['Assets', 'Liabilities']"
            class="text-sm bg-white new-dropdown"
            formControlName="account"
            placeholder="Select one category"
            [searchable]="false"
            [clearable]="false"
            [multiple]="false"
            (change)="changeService($event)"
          >
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <div class="px-2 py-1">
                <div
                  class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                >
                  {{ item }}
                </div>
              </div>
            </ng-template>
          </ng-select>
        </fieldset>
        <div *ngIf="formErrors.account" class="text-red-400 ms-2">
          {{ formErrors.account }}
        </div>
      </div>
    </form>
  </div>
  <div class="flex w-[45%] justify-around">
    <app-submit
      [value]="update ? 'Update' : 'Create'"
      className="bg-[#026AA2] text-white w-28 h-10"
      appAccessControl
      moduleType="accounting"
      accessType="edit"
      (clicked)="update ? updateTax() : createTax()"
    ></app-submit>
    <app-submit
      value="Discard"
      className="bg-[#F6F6F6CC] shadow-btn text-[#1C1C1C66] w-28 h-10"
      (clicked)="close.emit()"
    ></app-submit>
  </div>
</div>
