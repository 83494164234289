<div class="flex justify-center p-4">
    <div class="w-11/12 space-y-8">
        <div class="flex space-x-2 items-center">
            <svg (click)="back()" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18" />
            </svg>              
            <h1 class="Gilroy-Bold text-xl">{{'View Discount Code' | translate}}</h1>
        </div>
        <div class="bg-gray-50 rounded-xl w-full p-6  space-y-4">
            <div class="flex gap-x-12 gap-y-8 flex-wrap">
                <div class="flex flex-col space-y-1">
                    <label class="Gilroy-Bold text-lg">{{'Discount Code' | translate}}</label>
                    <p class="Gilroy-Medium">{{codeDetails?.code}}</p>
                </div>
                <div class="flex flex-col space-y-1">
                    <label class="Gilroy-Bold text-lg">{{'Discount Type' | translate}}</label>
                    <p class="Gilroy-Medium">{{codeDetails?.discountType}}</p>
                </div>
                <div class="flex flex-col space-y-1">
                    <label class="Gilroy-Bold text-lg">{{'Discount Value' | translate}}</label>
                    <p class="Gilroy-Medium">{{codeDetails?.discountValue}}</p>
                </div>
                <div class="flex flex-col space-y-1">
                    <label class="Gilroy-Bold text-lg">{{'Usage Limit' | translate}}</label>
                    <p class="Gilroy-Medium">{{codeDetails?.discountCount}}</p>
                </div>
                <div class="flex flex-col space-y-1">
                    <label class="Gilroy-Bold text-lg">{{'Single Customer Usage Limit' | translate}}</label>
                    <p class="Gilroy-Medium">{{codeDetails?.customerUsageCount ?? '-'}}</p>
                </div>
                <div class="flex flex-col space-y-1">
                    <label class="Gilroy-Bold text-lg">{{'Created Date' | translate}}</label>
                    <p class="Gilroy-Medium">{{codeDetails?.createdAt | date}}</p>
                </div>
                <div class="flex flex-col space-y-1">
                    <label class="Gilroy-Bold text-lg">{{'Expiry Date' | translate}}</label>
                    <p class="Gilroy-Medium">{{codeDetails?.expiryDate | date}}</p>
                </div>
                <div class="flex flex-col space-y-1">
                    <label class="Gilroy-Bold text-lg">{{'Code Used' | translate}}</label>
                    <p class="Gilroy-Medium">{{codeDetails?.usedByMobile.length}}</p>
                </div>
                <div class="flex flex-col space-y-1">
                    <label class="Gilroy-Bold text-lg">{{'Status' | translate}}</label>
                    <p class="Gilroy-Medium">{{codeDetails?.isActive ? 'Active' : 'Inactive'}}</p>
                </div>
            </div>
            <div class="flex flex-col space-y-1 w-full mt-4">
                <label class="Gilroy-Bold text-lg">{{'Code Description' | translate}}</label>
                <p class="Gilroy-Medium">{{codeDetails?.description}}</p>
            </div>
        </div>
        <app-table-container [tableHeadings]='tableHeadings' [tableData]='tableData' [keys]='tableKeys'>
        </app-table-container>
    </div>
</div>