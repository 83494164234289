<div class="w-full p-8">
    <div class="bg-white px-14 py-11 rounded-lg flex flex-col">
      <div class="flex items-end justify-between">
        <div class="flex gap-x-2" [formGroup]="filterForm">
          <div class="self-end">
            <label class="Poppins-Medium" for="startDate">{{
              "From" | translate
            }}</label>
            <input
              formControlName="fromDate"
              type="date"
              class="new-date"
            />
            <div
              *ngIf="filterFormErrors.customDateRangefrom"
              class="text-red-500 ms-4"
            >
              {{ filterFormErrors.customDateRangefrom }}
            </div>
          </div>
          <div class="self-end">
            <label class="Poppins-Medium" for="endDate">{{
              "To" | translate
            }}</label>
            <input
              formControlName="endDate"
              type="date"
              class="new-date"
            />
            <div
              *ngIf="filterFormErrors.customDateRangeTo"
              class="text-red-500 ms-4"
            >
              {{ filterFormErrors.customDateRangeTo }}
            </div>
          </div>
          <div class="self-end">
            <app-button
              buttonText="{{ 'Filter Bills' | translate }}"
              type="primary"
              (handleClick)="submitFilterForm()"
            ></app-button>
          </div>
          <div class="self-end">
            <app-button
              buttonText="{{ 'Sort Bills' | translate }}"
              type="primary"
              (handleClick)="revereseList()"
            ></app-button>
          </div>
        </div>
        <app-button
          appAccessControl
          moduleType="purchase"
          accessType="edit"
          routerLink="create-expense"
          type="primary"
          size="large"
          buttonText="{{ 'Create Expense' | translate }}"
        ></app-button>
      </div>
  
      <div class="space-y-0 mt-4">
        <app-table-container
          [tableHeadings]="tableHeadings"
          [tableData]="tableData"
          [keys]="tableKeys"
          [actionTemplate]="actionTemplate"
        >
          <ng-template let-item #actionTemplate>
            <td
              appAccessControl
              moduleType="purchase"
              accessType="edit"
              class="p-2 text-center"
            >
              <app-dropdown #dropdown>
                <div
                  button
                  (click)="dropdown.open = true"
                  (clickOutside)="dropdown.open = false"
                >
                  <div
                    class="h-8 w-8 rounded-lg flex justify-center items-center border-2 border-[#DCDCDC]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                </div>
                <div options class="new-shadow p-1">
                  <div
                    (click)="editTransaction(item)"
                    class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF] text-left"
                  >
                    {{ "Edit" | translate }}
                  </div>
                </div>
              </app-dropdown>
            </td>
          </ng-template>
        </app-table-container>
      </div>
    </div>
  </div>
  
  <app-modal-container [isOpen]="openFilterModal">
    <div header class="border-b pb-2 Gilroy-ExtraBold text-xl">
      {{ "Filter" | translate }}
    </div>
    <div content></div>
    <div footer class="flex items-center gap-x-2">
      <app-button
        buttonText="{{ 'Save' | translate }}"
        type="primary"
        rounded="true"
        (handleClick)="submitFilterForm()"
      ></app-button>
      <app-button
        buttonText="{{ 'Cancel' | translate }}"
        type="third"
        rounded="true"
        (handleClick)="closeFilterForm()"
      ></app-button>
    </div>
  </app-modal-container>
  <!-- 
  <app-modal-container [isOpen]='addTransactionModal.isOpen'>
      <div header class="border-b pb-2 Gilroy-ExtraBold text-xl">{{addTransactionModal.heading | translate}}</div>
      <div content>
          <my-tabs [formGroup]='form'>
              <my-tab tabTitle="{{'Details' | translate}}">
                  <app-add-income-form [invalidAmount]='invalidAmount' [formErrors]='formErrors' [formEditable]='addTransactionModal.formEditable' [form]='form'></app-add-income-form>
              </my-tab>
              <my-tab tabTitle="{{'Notes' | translate}}">
                  <div class="flex mb-3">
                      <div class="bg-proile rounded-xl bg-white px-3 py-2 w-full">
                          <textarea formControlName='notes' name="notes" class="w-full p-0 h-20 border-none bg-proile focus:ring-0" placeholder="{{'Add some notes' | translate}}" ></textarea>
                      </div>
                  </div>
              </my-tab>
              <my-tab appAccessControl moduleType="storage" accessType="view" tabTitle="{{'Files' | translate}}">
                  <app-file-upload #fileUploadComponent [addedFiles]="addedFiles" (emitter)="saveFiles($event)" ></app-file-upload>
              </my-tab>
          </my-tabs>
      </div>
      <div footer class="flex items-center gap-x-2">
          <app-button buttonText='{{"Save" | translate}}' type='primary' rounded=true (handleClick)='saveTransaction(addTransactionModal.type, addTransactionModal.to)'></app-button>
          <app-button buttonText='{{"Cancel" | translate}}' type='third' rounded=true (handleClick)="closeModal()"></app-button>
      </div>
  </app-modal-container>
  
  <app-modal-container [isOpen]='confirmDeleteTransactionModal'>
      <div header class="Gilroy-Bold">
          {{'Delete Transaction' | translate}}
      </div>
      <div content>
          <p>{{'Delete message' | translate}}</p>
      </div>
      <div footer class="gap-x-2">
          <app-button class="me-2" type='danger' buttonText='{{"Delete" | translate}}' (handleClick)='confirmDeleteTransaction()'></app-button>
          <app-button type='third' buttonText='{{"Cancel" | translate}}' (handleClick)='confirmDeleteTransactionModal=false'></app-button>
      </div>
  </app-modal-container>
     -->
  