import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RecurringInvoicesService } from '../recurring-invoices.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { DateValidator } from 'src/app/shared/utils/formValidator';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NumberService } from 'src/app/shared/services/number.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';

@Component({
  selector: 'app-view-invoice-template',
  templateUrl: './view-invoice-template.component.html',
  styleUrls: ['./view-invoice-template.component.scss']
})
export class ViewInvoiceTemplateComponent implements OnInit {

  unsubscribe = new Subject();
  showFiles = false;
  customizationSettings$: Observable<any>;
  business$: Observable<any>;

  minDate = moment().format('YYYY-MM-DD')
  updatedDate = new FormControl(moment().format('YYYY-MM-DD'), [Validators.required, DateValidator()]);

  customizationSettings;
  invoiceData = null;
  number;
  decimalSize;
  createdInvoices = [];
  files: File[] = [];
  addedFiles = [];
  showExtendModal: boolean = false;
  showStopModal: boolean = false;

  constructor(
    private route: ActivatedRoute, private router: Router,
    private invoiceService: RecurringInvoicesService,
    private spinner: NgxSpinnerService, private toastr: ToastrService,
    private numberService: NumberService,
    private fileUploadService: FileUploadService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      console.log(params);
      if(params?.id){
        this.loadInvoiceData(params?.id);
        this.loadNumberConfig();
      }
      else {
        this.router.navigate(['/sales/recurring-invoices']);
      }
    })
  }

  loadNumberConfig(): void {
    this.numberService.number
    .pipe((takeUntil(this.unsubscribe)))
    .subscribe((number) => {
      this.number = number
    })

    this.numberService.decimalSize
    .pipe((takeUntil(this.unsubscribe)))
    .subscribe((decimalSize) => {
      this.decimalSize = decimalSize
    })
  }

  loadInvoiceData(id): void {
    this.spinner.show();
    this.invoiceService.getInvoiceById(id)
    .pipe(
      takeUntil(
        this.unsubscribe
      )
    )
    .subscribe(resp => {
      if(resp?.success){
        this.invoiceData = resp?.data;
        this.createdInvoices = resp?.recurringInvoices;
        this.updatedDate.setValue(moment(this.invoiceData?.startDate).isAfter(moment()) ? moment(this.invoiceData.startDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'))
        this.minDate = moment(this.invoiceData?.startDate).isAfter(moment()) ? moment(this.invoiceData.startDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
      }
      else{
        this.toastr.error(resp?.message);
        this.router.navigate(['/sales/recurring-invoices']);
      }
      this.spinner.hide()
    }, (err) => {
      this.toastr.error(err?.error?.message);
      this.router.navigate(['/sales/recurring-invoices']);
    })
  }

  stopRecurringInvoice(): void {
    this.spinner.show();
    this.invoiceService.stopRecurringInvoice(this.invoiceData?._id)
    .subscribe(resp => {
      if(resp?.success){
        this.invoiceData.isCanceled = true;
        this.invoiceData.cancelDate = moment().format('YYYY-MM-DD');
        this.toastr.success(resp?.message);
        this.showStopModal = false;
      }
      else{
        this.toastr.error(resp?.message);
      }
      this.spinner.hide();
    }, (err) => {
      this.toastr.error(err?.error?.message);
      this.spinner.hide();
    })
  }

  showUpdateModate(): void {
    this.updatedDate.setValue(moment(this.invoiceData?.endDate).format('YYYY-MM-DD'));
    this.showExtendModal = true;
  }

  updateInvoiceDate(): void {
    if(this.updatedDate.invalid){
      this.updatedDate.markAllAsTouched();
      return;
    }
    this.spinner.show();
    this.invoiceService.updateInvoice(this.updatedDate.value, this.invoiceData?._id)
    .subscribe(resp => {
      if(resp?.success){
        this.invoiceData.endDate = this.updatedDate.value;
        this.invoiceData.isEnded = resp?.data?.isEnded;
        this.showExtendModal = false;
        this.toastr.success(resp?.message);
      }
      else {
        this.toastr.error(resp?.message);
      }
      this.spinner.hide();
    }, (err) => {
      this.toastr.error(err?.error?.message);
      this.spinner.hide();
    })
  }

  viewInvoice(invoiceId): void {
    this.router.navigate(['/sales/invoices/view-invoice'], {queryParams: {id: invoiceId}});
  }

  uploadFiles(): void {
    this.fileUploadService.emitFiles.next(true);
    this.fileUploadService.emitFiles.next(false);
    if(!this.files.length){
      this.toastr.error('Select files to upload');
      return;
    }
    this.spinner.show();
    const body = {
      businessId: this.invoiceData?.businessDetails?.businessId?._id,
      referenceId: this.invoiceData?._id,
      documentType: 'Recurring-Invoice',
      parentDocType: 'Sales'
    }
    const formData = new FormData();
    this.files.forEach((file, i) => {
      formData.append(`file${i}`, file);
    })
    formData.append('payload', JSON.stringify(body));
    this.fileUploadService.uploadFileForDocument(formData)
    .subscribe(resp => {
      if(resp?.success){
        console.log(resp?.data, 'view recurring invoice');
        this.addedFiles = resp?.data?.files ?? [];
        this.toastr.success(resp?.message);
      }
      else{
        this.toastr.error(resp?.message)
      }
      this.spinner.hide();
    },
    (err) => {
      this.toastr.error(err?.error?.message);
      this.spinner.hide();
      this.showFiles = false;
      setTimeout(() => {
        this.showFiles = true;
      }, 100);
    }
  );
}

  saveFiles(files: File[]){
    this.files = files;
  }


}
