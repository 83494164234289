<div class="w-full p-8">
  <div class="bg-white px-14 py-11 rounded-lg flex flex-col">
    <!-- <div class="flex justify-center p-4">
  <div class="w-full flex flex-col space-y-4"> -->
    <div>
      <div class="flex items-center justify-between">
        <div class="">
          <!-- <h1 *ngIf="!update" class="text-2xl Poppins-Medium inline-flex items-center">
            <svg _ngcontent-hbd-c18="" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke="currentColor" class="h-6 w-6 me-2">
              <path _ngcontent-hbd-c18="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z">
              </path>
            </svg>
            {{'Add Vendor' | translate}}
          </h1>

          <h1 *ngIf="update" class="text-2xl Poppins-Medium inline-flex items-center">
            <svg _ngcontent-hbd-c18="" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke="currentColor" class="h-6 w-6 me-2">
              <path _ngcontent-hbd-c18="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z">
              </path>
            </svg>
            {{'Update Vendor' | translate}}
          </h1> -->
        </div>
        <div
          appAccessControl
          moduleType="purchase"
          accessType="edit"
          class="flex gap-x-2"
        >
          <app-button
            *ngIf="!update"
            buttonText="{{ 'Save' | translate }}"
            type="primary"
            (handleClick)="createVendor()"
          ></app-button>
          <app-button
            *ngIf="update"
            buttonText="{{ 'Update' | translate }}"
            type="primary"
            (handleClick)="UpdateVendor()"
          ></app-button>
          <app-button
            routerLink="../"
            buttonText="{{ 'Discard' | translate }}"
            type="border"
          ></app-button>
        </div>
      </div>
    </div>
    <form [formGroup]="vendorForm">
      <div class="flex justify-between">
        <div class="flex mb-2 gap-2 flex-col">
          <label class="block text-md Poppins-Medium">{{
            "Vendor Name" | translate
          }}</label>
          <input
            formControlName="vendorName"
            type="text"
            class="new-input"
            placeholder="{{ 'Vendor Name' | translate }}"
          />
          <div
            class="text-sm text-red-500 ms-2 mt-1"
            *ngIf="formErrors.vendorName"
          >
            {{ formErrors.vendorName }}
          </div>
        </div>
      </div>
      <div class="flex mt-2 gap-x-5">
        <div class="flex gap-2 flex-col">
          <label class="block text-sm Poppins-Medium">{{
            "First Name" | translate
          }}</label>
          <input
            id="firstName"
            formControlName="firstName"
            type="text"
            class="new-input"
            placeholder="{{ 'First Name' | translate }}"
          />
          <div
            class="text-sm text-red-500 ms-2 mt-1"
            *ngIf="formErrors.firstName"
          >
            {{ formErrors.firstName }}
          </div>
        </div>
        <div class="flex gap-2 flex-col">
          <label class="block text-sm Poppins-Medium">{{
            "Last Name" | translate
          }}</label>
          <input
            id="lastName"
            formControlName="lastName"
            type="text"
            class="new-input"
            placeholder="{{ 'Last Name' | translate }}"
          />
          <div
            class="text-sm text-red-500 ms-2 mt-1"
            *ngIf="formErrors.lastName"
          >
            {{ formErrors.lastName }}
          </div>
        </div>
        <div class="flex gap-2 flex-col">
          <label class="block text-sm Poppins-Medium">{{
            "Email" | translate
          }}</label>
          <input
            id="email"
            formControlName="email"
            type="email"
            class="new-input"
            placeholder="{{ 'Email' | translate }}"
          />
          <div class="text-sm text-red-500 ms-2 mt-1" *ngIf="formErrors.email">
            {{ formErrors.email }}
          </div>
        </div>
      </div>
      <h2 class="text-lg font-Medium mt-8 mb-4 Poppins-Medium">
        {{ "Contact Details" | translate }}
      </h2>
      <div class="flex mt-2 gap-x-5">
        <div class="flex gap-2 flex-col">
          <label class="block text-sm Poppins-Medium">{{
            "Telephone" | translate
          }}</label>
          <input
            id="telephone"
            formControlName="telephone"
            type="number"
            class="new-input"
            placeholder="{{ 'Telephone' | translate }}"
          />
          <div
            class="text-sm text-red-500 ms-2 mt-1"
            *ngIf="formErrors.telephone"
          >
            {{ formErrors.telephone }}
          </div>
        </div>
        <div class="flex gap-2 flex-col">
          <label class="block text-sm Poppins-Medium">{{
            "Mobile Number" | translate
          }}</label>
          <input
            id="mobileNumber"
            formControlName="mobileNumber"
            type="number"
            class="new-input"
            placeholder="{{ 'Mobile Number' | translate }}"
          />
          <div
            class="text-sm text-red-500 ms-2 mt-1"
            *ngIf="formErrors.mobileNumber"
          >
            {{ formErrors.mobileNumber }}
          </div>
        </div>
        <div class="flex gap-2 flex-col">
          <label class="block text-sm Poppins-Medium">{{
            "Landline" | translate
          }}</label>
          <input
            id="tollFree"
            formControlName="tollFree"
            type="number"
            class="new-input"
            placeholder="Landline"
          />
          <div
            class="text-sm text-red-500 ms-2 mt-1"
            *ngIf="formErrors.tollFree"
          >
            {{ formErrors.tollFree }}
          </div>
        </div>
        <div class="flex gap-2 flex-col">
          <label class="block text-sm Poppins-Medium">{{
            "Fax" | translate
          }}</label>
          <input
            id="fax"
            formControlName="fax"
            type="number"
            class="new-input"
            placeholder="{{ 'Fax' | translate }}"
          />
          <div class="text-sm text-red-500 ms-2 mt-1" *ngIf="formErrors.fax">
            {{ formErrors.fax }}
          </div>
        </div>
      </div>
      <div class="flex mt-2 gap-x-5">
        <div class="flex gap-2 flex-col">
          <label class="block text-sm Poppins-Medium">{{
            "Tax ID" | translate
          }}</label>
          <input
            id="taxId"
            formControlName="taxId"
            type="text"
            class="new-input"
            placeholder="{{ 'Tax ID' | translate }}"
          />
          <div class="text-sm text-red-500 ms-2 mt-1" *ngIf="formErrors.taxId">
            {{ formErrors.taxId }}
          </div>
        </div>
        <div class="flex gap-2 flex-col">
          <label class="block text-sm Poppins-Medium">{{
            "IBAN" | translate
          }}</label>
          <input
            id="iban"
            formControlName="iban"
            type="text"
            class="new-input"
            placeholder="{{ 'IBAN' | translate }}"
          />
          <div class="text-sm text-red-500 ms-2 mt-1" *ngIf="formErrors.iban">
            {{ formErrors.iban }}
          </div>
        </div>
      </div>
      <div class="flex mt-2 gap-x-5">
        <div class="flex gap-2 flex-col">
          <label class="block text-sm Poppins-Medium">{{
            "Website" | translate
          }}</label>
          <input
            id="webSite"
            formControlName="webSite"
            type="text"
            class="new-input"
            placeholder="{{ 'Website' | translate }}"
          />
          <div
            class="text-sm text-red-500 ms-2 mt-1"
            *ngIf="formErrors.webSite"
          >
            {{ formErrors.webSite }}
          </div>
        </div>
      </div>
      <div class="flex mt-4">
        <div class="w-8/12">
          <label class="block text-sm Poppins-Medium">{{
            "Notes" | translate
          }}</label>
          <textarea
            formControlName="notes"
            id="notes"
            class="new-input !h-[7rem]"
            placeholder="{{ 'Add some notes' | translate }}"
          ></textarea>
          <div class="text-sm text-red-500 ms-2 mt-1" *ngIf="formErrors.notes">
            {{ formErrors.notes }}
          </div>
        </div>
      </div>

      <h3 class="text-lg mt-6 font-Medium Poppins-Medium">
        {{ "Vendor Address" | translate }}
      </h3>
      <div class="flex mt-4">
        <div class="w-8/12">
          <label class="block text-sm Poppins-Medium">{{
            "Address Line 1" | translate
          }}</label>
          <input
            id="addressLine1"
            formControlName="addressLine1"
            type="text"
            class="new-input"
            placeholder="{{ 'Address Line 1' | translate }}"
          />
          <div
            class="text-sm text-red-500 ms-2 mt-1"
            *ngIf="formErrors.addressLine1"
          >
            {{ formErrors.addressLine1 }}
          </div>
        </div>
      </div>

      <div class="flex mt-4">
        <div class="w-8/12">
          <label class="block text-sm Poppins-Medium">{{
            "Address Line 2" | translate
          }}</label>
          <input
            id="addressLine2"
            formControlName="addressLine2"
            type="text"
            class="new-input"
            placeholder="{{ 'Address Line 2' | translate }}"
          />
          <div
            class="text-sm text-red-500 ms-2 mt-1"
            *ngIf="formErrors.addressLine2"
          >
            {{ formErrors.addressLine2 }}
          </div>
        </div>
      </div>

      <div class="flex mt-4 gap-x-5">
        <div class="flex gap-2 flex-col">
          <label class="block text-sm Poppins-Medium mb-2">{{
            "Country" | translate
          }}</label>
          <ng-select
            (change)="changeEventContry($event)"
            id="country-select1"
            [items]="countries"
            class="p-0 w-56 text-sm focus:ring-0 new-dropdown"
            bindLabel="text"
            bindValue="text"
            formControlName="country"
            placeholder="{{ 'Select one' | translate }}"
          >
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <div class="px-2 py-1">
                <div
                  class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                >
                  {{ item.text }}
                </div>
              </div>
            </ng-template>
          </ng-select>
          <div
            class="text-sm text-red-500 ms-2 mt-1"
            *ngIf="formErrors.country"
          >
            {{ formErrors.country }}
          </div>
        </div>
        <div class="flex gap-2 flex-col">
          <label class="block text-sm Poppins-Medium mb-2">{{
            "State" | translate
          }}</label>
          <ng-select
            id="state-select"
            [items]="states"
            class="p-0 w-56 text-sm focus:ring-0 new-dropdown"
            bindLabel="text"
            bindValue="text"
            formControlName="state"
            placeholder="{{ 'Select one' | translate }}"
          >
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <div class="px-2 py-1">
                <div
                  class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                >
                  {{ item.text }}
                </div>
              </div>
            </ng-template>
          </ng-select>
          <div class="text-sm text-red-500 ms-2 mt-1" *ngIf="formErrors.state">
            {{ formErrors.state }}
          </div>
        </div>
        <div class="flex gap-2 flex-col">
          <label class="block text-sm Poppins-Medium">{{
            "City" | translate
          }}</label>
          <input
            id="city"
            formControlName="city"
            type="text"
            class="new-input"
            placeholder="{{ 'City' | translate }}"
          />
          <div class="text-sm text-red-500 ms-2 mt-1" *ngIf="formErrors.city">
            {{ formErrors.city }}
          </div>
        </div>

        <div class="flex gap-2 flex-col">
          <label class="block text-sm Poppins-Medium">{{
            "Postal Code" | translate
          }}</label>
          <input
            id="postalCode"
            formControlName="postalCode"
            type="text"
            class="new-input"
            placeholder="{{ 'Postal Code' | translate }}"
          />
          <div
            class="text-sm text-red-500 ms-2 mt-1"
            *ngIf="formErrors.postalCode"
          >
            {{ formErrors.postalCode }}
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
