<div class="flex">
  <div class="w-5/6 mx-auto">
    <div class="pb-3" > 
      <app-button  (click)='downloadPdf()' type='primary' buttonText='{{"Download" | translate }}' ></app-button>
    </div>
        <table class="w-full d-block overflow-hidden box-shadow-sales rounded-xl">
            <div style="padding: 2rem;">
              <table class="w-full" style="border-collapse: collapse;">
                <tr>
                  <td valign='top' class="ps-8">
                    <div>
                      <h1 class="text-2xl font-semibold"> {{receiptData?.type | translate}} </h1>
                      <table style="border-collapse: collapse;">
                        <tr>
                          <td class="Gilroy-Bold">{{'Receipt Number:' | translate}}</td>
                          <td align="right" class="ps-4">{{receiptData?.recieptNumber}}</td>
                        </tr>
                        <tr>
                          <td class="Gilroy-Bold">{{'Receipt Date:' | translate }}</td>
                          <td align="right" class="ps-4">{{receiptData?.payDate | date}}</td>
                        </tr>
                        <tr>
                          <td class="Gilroy-Bold">{{'Reference Number:' | translate }}</td>
                          <td align="right" class="ps-4">{{receiptData?.referanceNumber}}</td>
                        </tr>
                      </table>
                    </div>
                  </td>
                  <td valign='top' align="right" class="pe-8 w-1/2" >
                    <img *ngIf="companyLogo" [src]="companyLogo" width="280" alt="Logo">
                    <div class="mt-2 Gilroy-Bold">{{receiptData.businessDetails?.businessId?.companyName}}</div>
                    <div>{{receiptData.businessDetails?.businessId?.address?.addressLineFirst}}</div>
                    <div>{{receiptData.businessDetails?.businessId?.address?.addressLine2}}</div>
                    <div>{{receiptData.businessDetails?.businessId?.address?.city ? receiptData.businessDetails?.businessId?.address?.city + ',' : ''}} {{receiptData.businessDetails?.businessId?.address?.state}}</div>
                    <div>{{receiptData.businessDetails?.businessId?.country}}</div>
                  </td>
                </tr>
              </table>
            </div>
            <div class="px-16" >
              <table class="w-full border-b" style="border-collapse: collapse;">
                <tr>
                  <td>
                    <table>
                      <tr>
                        <td>
                          <div class="Gilroy-Bold">{{receiptData?.type === 'Receipt' ? 'Customer Details' : 'Vendor Details' | translate}}</div>
                          <div>{{receiptData?.type === 'Receipt' ? receiptData?.customerDetails?.customerId?.customerName : receiptData?.vendorId.vendorName}}</div>
                          <div>{{receiptData?.type === 'Receipt' ? receiptData?.customerDetails?.customerId?.email : receiptData?.vendorId.email}}</div> 
                          <div>{{receiptData?.type === 'Receipt' ? receiptData?.customerDetails?.customerId?.billingAddress?.addressLine1 : receiptData?.vendorId?.address?.addressLine1}}</div> 
                          <div>{{receiptData?.type === 'Receipt' ? receiptData?.customerDetails?.customerId?.billingAddress?.addressLine2 : receiptData?.vendorId?.address?.addressLine2}}</div> 
                          <div *ngIf="receiptData?.type === 'Receipt'">{{receiptData?.customerDetails?.customerId?.billingAddress?.city ? receiptData?.customerDetails?.customerId?.billingAddress?.city + ',' : ''}} {{receiptData?.customerDetails?.customerId?.billingAddress?.state}}</div> 
                          <div *ngIf="receiptData?.type !== 'Receipt'">{{receiptData?.vendorId?.address?.city ? receiptData?.vendorId?.address?.city + ',' : ''}} {{receiptData?.vendorId?.address?.state}}</div> 
                          <div>{{receiptData?.type === 'Receipt' ? receiptData?.customerDetails?.customerId?.billingAddress?.postalCode : receiptData?.vendorId?.address?.postalCode}}</div> 
                          <div>{{receiptData?.type === 'Receipt' ? receiptData?.customerDetails?.customerId?.billingAddress?.country : receiptData?.vendorId?.address?.country}}</div> 
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td align="right">
                    
                  </td>
                </tr>
              </table>
              <table class="w-full mt-2" style="margin-bottom: 2em;">
                <tr>
                    <td style="width: 50%;">
                        <table class="">
                            <tr>
                              <td class="Gilroy-Bold pe-2">{{'Payment Type' | translate }}</td>
                              <td>{{receiptData?.paymentMethod}}</td>
                            </tr>
                        </table>
                    </td>
                    <td align="right" style="width: 50%;">
                        <table class="">
                            <tr>
                                <td class="Gilroy-Bold pe-2">{{'Receipt Amount' | translate }}</td>
                                <td>{{receiptData?.paidAmount | number: number}}</td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                  <td colspan="2" class="text-end">
                    {{ receiptData?.userId?.firstName ? 'Issued by:'+receiptData?.userId?.firstName+' '+receiptData?.userId?.lastName : '' }}
                  </td>
                </tr>
              </table>
            </div>
        </table>
    </div>

</div>