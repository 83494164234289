<div class="p-7  rounded-3xl bg-light-gray-1 mt-2 mb-2">
    <h3 class="text-xl Gilroy-Bold">{{'Invoice settings' | translate }}</h3>
    <form [formGroup]='invoiceSettingsForm'>
        <div class="flex mt-5 space-x-8 rtl:space-x-reverse">
            <div class="flex-1">
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2">
                    <label for='title' class="block text-sm Gilroy-Bold font-color-05">{{'Default Title' | translate }}</label>
                    <input id='title' formControlName='title' type="text" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-200" placeholder="{{'Invoice' | translate }}" />
                </div>
                <p class="text-sm mt-1 ms-1">{{'You can change this on each invoice.' | translate }}</p>
            </div>
            <div class="flex-1">
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2">
                    <label for='footer' class="block text-sm Gilroy-Bold font-color-05">{{'Default Footers' | translate }}</label>
                    <input id='footer' formControlName='footer' type="text" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-200" placeholder="{{'Thank you for your business!' | translate }}" />
                </div>
                <p class="text-sm mt-1 ms-1">{{'You can change this on each invoice.' | translate }}</p>
            </div>
        </div>
        <div class="mt-5 mb-5">
            <div class="flex-1">
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2">
                    <label for='terms' class="block text-sm Gilroy-Bold font-color-05">{{'Default notes / terms' | translate }}</label>
                    <textarea id='terms' formControlName='terms' type="text" class="w-full p-0 h-36 border-0 focus:ring-0 placeholder-gray-200" placeholder="{{'Enter notes / terms' | translate }}"></textarea>
                </div>
                <p class="text-sm mt-1 ms-1">{{'Default notes message' | translate}}</p>
            </div>
        </div>
        <div class="flex items-center space-x-2">
            <app-button buttonText='{{"Save Changes" | translate }}' type='primary' rounded='true' (handleClick)='saveChanges()'></app-button>
            <span *ngIf='response' class="text-green-500">{{response}}</span>
            <span *ngIf='error' class="text-red-500">{{error}}</span>
        </div>
    </form>
</div>