import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SubscriptionService } from 'src/app/modules/settings/settings-modules/subscription/subscription.service';
import { setUsersSubscription } from 'src/app/store/actions/subscription.actions';
import { RootReducerState } from 'src/app/store/reducers';
import { selectSubscriptionPlans } from 'src/app/store/selectors/subscription.selectors';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-send-to-sub',
  templateUrl: './send-to-sub.component.html',
  styleUrls: ['./send-to-sub.component.scss']
})
export class SendToSubComponent implements OnInit, OnDestroy {

  subscription$: Observable<any>;
  unsubscribe$ = new Subject();
  subscriptionPlans = [];

  showConfirmationDialog: boolean = false;

  @Input() from: string = '';

  features = {
    'recurring': "Recurring Subscription Description",
    'budget': "P/L Budget Subscription Description",
    'inventory': "Inventory Subscription Description",
    'payroll': "Payroll Subscription Description",
  }

  constructor(
    private store: Store<RootReducerState>,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private utility: UtilityService,
    private subscriptionService: SubscriptionService,
    private router: Router
  ) {
    this.subscription$ = store.pipe(select(selectSubscriptionPlans))
  }

  ngOnInit(): void {
    this.getPlans();
  }

  getPlans(): void {
    this.subscription$.
    pipe(takeUntil(this.unsubscribe$))
    .subscribe((sub:any) => {
      this.subscriptionPlans = sub;
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getSubscriptionLink(): void {
    let planId = this.subscriptionPlans?.find(
      (sub) => this.from === sub?.planName?.toLowerCase()
    )?._id;
    this.spinner.show();
    this.subscriptionService.getSubscriptionLink({ planId }).subscribe(
      (resp) => {
        if (resp?.success) {
          if (!resp?.data?.isCard) {
            this.utility.showHideSettingsModal.next({
              open: true,
              tab: 'subscriptions',
            });
            this.showConfirmationDialog = false;
          } else {
            this.toastr.success(resp?.message);
            this.store.dispatch(
              setUsersSubscription({
                subscription: resp?.data?.currentSubscription,
              })
            );
            this.subscriptionService.setCurrentSubscription(
              resp?.data?.currentSubscription
            );
            if (this.from === 'payroll') {
              let a = document.createElement('a');
              const token = localStorage.getItem('refresh_token');
              a.href = `https://payroll.numetric.work/auth/login?redirect=${token}&db=numetric`;
              // a.target = 'blank';
              a.click();
            }
          }
          this.spinner.hide();
        } else {
          this.toastr.error(resp?.message);
          this.spinner.hide();
        }
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(error?.error?.message);
      }
    );
  }

}
