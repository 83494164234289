import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BusinessesManagementComponent } from './businesses-management/businesses-management.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { StoreManagementComponent } from './store-management/store-management.component';

@NgModule({
  declarations: [
      BusinessesManagementComponent,
      StoreManagementComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgxSpinnerModule,
    TranslateModule,
    DirectivesModule
  ],
  exports: [
      BusinessesManagementComponent,
      StoreManagementComponent
  ]
})
export class SettingsSharedModule { }
