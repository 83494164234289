import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BusinessService } from 'src/app/shared/services/business.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { NumberService } from 'src/app/shared/services/number.service';
import { ReceiptService } from 'src/app/shared/services/receipt.service';
import { EstimatesService } from '../../sales/components/estimates/estimates.service';
import { OpenService } from '../open.service';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss'],
  providers: [CurrencyPipe]
})
export class ReceiptComponent implements OnInit {

  constructor(private receiptService: ReceiptService,
              private route: ActivatedRoute,
              private openService: OpenService,
              private spinnerService: NgxSpinnerService,
              private router: Router,
              private translateServic: TranslateService,
              private numberService: NumberService,
              private languageService: LanguageService,
              private businessService: BusinessService,
              private currencyPipe: CurrencyPipe) { }

  receiptData = {
    _id: '',
    recieptNumber: '',
    referanceId: '',
    recordId: '',
    referanceNumber: '',
    paidAmount: null,
    payDate: null,
    currencyDetails: {
      currencySymbol: '$'
    },
    paymentMethod: '',
    memo: '',
    type: this.translateServic.instant('Receipt'),
    businessDetails: {
      businessId: {
        companyLogo: '',
        companyName: '',
        address: {
          addressLineFirst: '',
          addressLine2: '',
          city: '',
          state: ''
        },
        country: '',
        currency: ''
      },
    },
    customerDetails: {
      customerId: {
        customerName: '',
        email: '',
        billingAddress: {
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        mobileNumber: ''
      },
    },
    vendorId: {
      vendorName: '',
      email: '',
      address: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
      contactDetails: {
        mobileNumber: ''
      }
    },
    userId: {
      firstName: '',
      lastName: ''
    }
  };
  fieldNames = {
    type: 'Invoice',
    receiptNumber: 'Receipt Number',
    payDate: 'Receipt Date',
    billingAddress: 'Billing Address',
    shippingAddress: 'Shipping Address',
    total: 'Total',
    referanceNumber: 'Reference Number',
    paymentType: 'Payment Method',
    receiptAmount: 'Receipt Amount',
    subtotal: 'Subtotal',
    tax: 'Tax',
    terms: 'Memo',
    itemName: 'Item Name',
    quantity: 'Quantity',
    unitPrice: 'Unit Price',
    totalAmount: 'Total Amount',
    billTo: 'Bill To',
    eSign: 'Digitally signed document',
    discount: 'Discount',
    discountSubtotal: 'Discount Subtotal',
    issuer: 'Issued By'
  };

  companyLogo = null;

  unsubscribe = new Subject();
  number = '1.2-2';
  decimalSize = 2;

  ngOnInit(): void {
    this.route.params.subscribe(({businessId, receiptId}) => {
      if (businessId && receiptId) {
        this.loadData(businessId, receiptId);
      } else {
        this.router.navigate(['/']);
      }
    });

    this.loadNumberConfig();
  }

  loadNumberConfig(): void {
    this.numberService.number
    .pipe((takeUntil(this.unsubscribe)))
    .subscribe((number) => {
      this.number = number
    })
  }

  loadData(businessId, receiptId): void {
    this.spinnerService.show();
    this.receiptService.getReceiptById(receiptId).subscribe((resp) => {
      this.spinnerService.hide();
      this.receiptData = resp.data;
      this.receiptData = {
        ...this.receiptData,
        paidAmount: this.currencyPipe.transform(this.receiptData.paidAmount, this.receiptData.businessDetails.businessId.currency, 'symbol-narrow', this.number)
      }      
    }, error => {
      this.spinnerService.hide();
      console.log(error);
    });
    this.businessService.getBusinessDetailsById(businessId).subscribe((resp) => {
      if (resp?.success) {
        const businessData = resp.data;
        const decimalSize = businessData?.decimalSize ?? 2;
        this.decimalSize = businessData?.decimalSize;
        this.numberService.decimalSize.next(decimalSize);
        this.numberService.number.next(`1.${decimalSize}-${decimalSize}`);
        this.number = `1.${decimalSize}-${decimalSize}`;
      }
    })
    this.openService.getInvoiceCustomizationSettings(businessId).subscribe((resp) => {
      if (resp.success) {
        this.companyLogo = resp.data.companyLogo;
        this.receiptData.businessDetails.businessId.companyLogo = this.companyLogo;
      }
    })
  }

  

  downloadPdf(): void {
    const body = {
      config: {
        data: {
          ...this.receiptData,
          payDate: moment(this.receiptData.payDate).format('DD-MM-YYYY'),
          currencyDetails: { currencySymbol: this.receiptData.businessDetails.businessId.currency },
          companylogo: this.companyLogo
        },
        direction: localStorage.getItem('NuMetric|lang') === 'ar'? 'rtl' : 'ltr',
        showTax: true,
        decimalSize: this.decimalSize,
        fieldNames: this.languageService.translate(this.fieldNames)
      }
    };
    this.spinnerService.show();
    this.receiptService.createReceiptPdf(body).subscribe((resp) => {
      this.spinnerService.hide();
      const a = document.createElement('a');
      const blob = new Blob([resp], {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `${this.receiptData.type} ${this.receiptData.recieptNumber}.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
    }, (error) => {
      this.spinnerService.hide();
    });
  }

}
