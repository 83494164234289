<div class="p-8 rounded-3xl bg-light-gray-1">
  <h3 class="Gilroy-Bold text-lg">{{'Jordan Invoice API Credentials' | translate }}</h3>
  <form [formGroup]='invoiceCredentialsForm' class="space-y-6">
      <div class="flex flex-col">
          <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-1/2">
              <label class="text-sm Gilroy-Bold font-color-05">{{'Client Id' | translate }}</label>
              <input formControlName='clientId' type="text" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-200" placeholder="{{'Enter Client Id' | translate }}" />
          </div>
          <div class="text-red-500 ms-2 text-sm" *ngIf='formErrors.clientId'>{{formErrors.clientId}}</div>
      </div>
      <div class="flex flex-col">
          <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-1/2">
              <label class="text-sm Gilroy-Bold font-color-05">{{'Secret Code' | translate }}</label>
              <input formControlName='secretCode' type="text" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-200" placeholder="{{'Enter Secret Code' | translate }}" />
          </div>
          <div class="text-red-500 ms-2 text-sm" *ngIf='formErrors.secretCode'>{{formErrors.secretCode}}</div>
      </div>
      <div class="flex flex-col">
        <div>
            <label class="inline-flex mt-3 items-center">
              <input formControlName='isSalesTax' type="checkbox" class="me-2">
              <!-- <span class="block Gilroy-SemiBold">{{'Remind customers 3, 7, and 14 days after due date' | translate}}</span> -->
              <p class="">{{'Are you compliant with the Sales Tax for JO-Tax' | translate }}</p>
          </label>
          </div>
          <div class="text-red-500 ms-2 text-sm" *ngIf='formErrors.secretCode'>{{formErrors.secretCode}}</div>
      </div>
      <div class='flex items-center'>
          <app-button type='primary' (handleClick)="saveDetails()" [rounded]='true' buttonText='{{"Update" | translate }}'></app-button>
          <div class='text-green-500 ms-2' *ngIf='respMessage'>{{respMessage}}</div>
          <div class='text-red-500 ms-2' *ngIf='error'>{{error}}</div>
      </div>
  </form>
</div>
