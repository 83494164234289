<div class="flex justify-center px-14 py-11 bg-white rounded-lg">
    <div class="w-full flex flex-col space-y-4">
      <div class="grid grid-cols-3 gap-6 mt-5 leading-0 text-[#202020CC]">
        <div class="bg-input-light shadow-card rounded-lg p-5">
          <p class="text-base Poppins-Medium">
            {{ "Total Invoiced amount" | translate }}
          </p>
          <h3 class="text-[30px] Poppins-SemiBold text-[#030723B2]">
            {{
              invoiceStats?.totalInvoice?.total
                | currency
                  : currencyDetails?.currencySymbol
                  : "symbol-narrow"
                  : number
            }}
          </h3>
          <p class="text-sm">
            {{ "from" | translate }} {{ invoiceStats?.totalInvoice?.count }}
            {{ "invoices" | translate }}
          </p>
        </div>
        <div class="bg-input-light shadow-card rounded-7 p-5">
          <p class="text-base Poppins-Medium">
            {{ "Total Invoices Paid" | translate }}
          </p>
          <h3 class="text-[30px] Poppins-SemiBold text-[#030723B2]">
            {{
              invoiceStats?.paidInvoice?.total
                | currency
                  : currencyDetails?.currencySymbol
                  : "symbol-narrow"
                  : number
            }}
          </h3>
          <p class="text-sm">
            {{ "from" | translate }} {{ invoiceStats?.paidInvoice?.count }}
            {{ "invoices" | translate }}
          </p>
        </div>
        <div class="bg-input-light shadow-card rounded-7 p-5">
          <p class="text-base Poppins-Medium">
            {{ "Total Invoice overdue" | translate }}
          </p>
          <h3 class="text-[30px] Poppins-SemiBold text-[#030723B2]">
            {{
              invoiceStats?.overdueInvoice?.total
                | currency
                  : currencyDetails?.currencySymbol
                  : "symbol-narrow"
                  : number
            }}
          </h3>
          <p class="text-sm">
            {{ "from" | translate }} {{ invoiceStats?.overdueInvoice?.count }}
            {{ "invoices" | translate }}
          </p>
        </div>
      </div>
      <div class="flex items-center justify-between ms-auto py-4">
        <app-button
          appAccessControl
          moduleType="sales"
          accessType="edit"
          size="large"
          buttonText="{{ 'Create Invoice' | translate }}"
          type="primary"
          routerLink="create-cash-invoice"
        ></app-button>
      </div>
      <h3
        class="text-base text-[#1C1C1C66] leading-0 inline-flex items-center space-y-3"
      >
        {{ "Filters" | translate }}
      </h3>
      <div
        class="grid grid-cols-5 items-center gap-10 rtl:space-x-reverse"
        [formGroup]="filterForm"
      >
        <div class="flex flex-col items-start gap-[10px]">
          <label class="text-base leading-5 Poppins-Medium">{{
            "Select Customer" | translate
          }}</label>
          <ng-select
            [items]="customers"
            class="new-dropdown w-full"
            bindLabel="customerName"
            bindValue="_id"
            formControlName="customerId"
            placeholder="{{ 'Type Customer Name' | translate }}"
          >
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <div class="px-2 py-1">
              <div
                class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
              >
                {{ item.customerName }}
              </div>
            </div>
          </ng-template>
          </ng-select>
        </div>
        <div class="flex flex-col items-start gap-[10px]">
          <label class="text-base leading-5 Poppins-Medium">{{
            "Select Project" | translate
          }}</label>
          <ng-select
            [items]="projects"
            class="new-dropdown w-full"
            bindLabel="projectName"
            bindValue="_id"
            formControlName="projectId"
            placeholder="{{ 'Type Project Name' | translate }}"
          >
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <div class="px-2 py-1">
              <div
                class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
              >
                {{ item.projectName }}
              </div>
            </div>
          </ng-template>
          </ng-select>
        </div>
        <div class="flex flex-col items-start gap-[10px]">
          <label class="text-base leading-5 Poppins-Medium" for="startDate">{{
            "From" | translate
          }}</label>
          <input
            formControlName="fromDate"
            type="date"
            class="new-date"
          />
        </div>
        <div class="flex flex-col items-start gap-[10px]">
          <label class="text-base leading-5 Poppins-Medium" for="endDate">{{
            "To" | translate
          }}</label>
          <input
            formControlName="endDate"
            type="date"
            class="new-date"
          />
        </div>
        <app-button
          buttonText="{{ 'Filter Invoices' | translate }}"
          size="large"
          type="primary"
          class="self-end"
          (handleClick)="filterData()"
        ></app-button>
      </div>
      <div class="flex space-x-3 rtl:space-x-reverse">
        <div class="w-1/4"></div>
        <div class="w-1/4"></div>
        <div class="w-1/4">
          <div *ngIf="formErrors.endDate" class="text-red-500 ms-4">
            {{ formErrors.endDate }}
          </div>
        </div>
        <div class="w-1/4"></div>
        <div class="w-1/4"></div>
      </div>
      <div class="px-5 mt-10">
        <ul
          class="hidden mb-4 h-12 w-1/3 text-sm font-medium text-center text-gray-500 rounded-lg shadow sm:flex"
        >
          <li
            class="w-full flex items-center focus-within:z-10 hover:text-gray-700 hover:bg-gray-50 cursor-pointer border-r border-gray-200"
            (click)="filterInvoicesByStatus('all')"
            [ngClass]="{
              'bg-[#F0F5FF] text-black hover:!bg-[#F0F5FF]': selectedTab === 'all'
            }"
            tabindex="1"
          >
            <div
              class="inline-block w-full p-4 rounded-s-lg focus:ring-4 focus:ring-blue-300 focus:outline-none whitespace-nowrap"
            >
              {{ "All Invoices" | translate }}
            </div>
          </li>
          <li
            class="w-full flex items-center focus-within:z-10 hover:text-gray-700 hover:bg-gray-50 cursor-pointer border-r border-gray-200"
            (click)="filterInvoicesByStatus('paid')"
            [ngClass]="{
              'bg-[#F0F5FF] text-black hover:!bg-[#F0F5FF]':
                selectedTab === 'paid'
            }"
            tabindex="2"
          >
            <div
              class="inline-block w-full p-4 focus:ring-4 focus:ring-blue-300 focus:outline-none"
            >
              {{ "Paid" | translate }}
            </div>
          </li>
          <li
            class="w-full flex items-center focus-within:z-10 hover:text-gray-700 hover:bg-gray-50 cursor-pointer"
            (click)="filterInvoicesByStatus('unpaid')"
            [ngClass]="{
              'bg-[#F0F5FF] text-black hover:!bg-[#F0F5FF] ':
                selectedTab === 'unpaid'
            }"
            tabindex="3"
          >
            <div
              class="inline-block w-full p-4 border-s-0 border-gray-200 rounded-e-lg focus:ring-4 focus:outline-none focus:ring-blue-300"
            >
              {{ "Unpaid" | translate }}
            </div>
          </li>
          <li
            class="w-full flex items-center focus-within:z-10 hover:text-gray-700 hover:bg-gray-50 cursor-pointer"
            (click)="filterInvoicesByStatus('draft')"
            [ngClass]="{
              'bg-[#F0F5FF] text-black hover:!bg-[#F0F5FF] ':
                selectedTab === 'draft'
            }"
            tabindex="3"
          >
            <div
              class="inline-block w-full p-4 border-s-0 border-gray-200 rounded-e-lg focus:ring-4 focus:outline-none focus:ring-blue-300"
            >
              {{ "Draft" | translate }}
            </div>
          </li>
        </ul>
        <app-table-container
          [tableHeadings]="tableHeadings"
          [tableData]="tableData"
          [keys]="tableKeys"
          [actionTemplate]="actionTemplate"
        >
          <ng-template let-item #actionTemplate>
            <td class="p-2 text-center last:pe-10">
              <app-dropdown [isFromSetting]="true" #dropdown>
                <div
                  button
                  (click)="dropdown.open = true"
                  (clickOutside)="dropdown.open = false"
                >
                  <div
                    class="h-8 w-8 rounded-[4px] flex justify-center items-center border-2 border-[#DCDCDC]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                </div>
                <div options class="new-shadow p-1 text-start">
                  <div
                    (click)="viewInvoice(item._id)"
                    class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                  >
                    {{ "View" | translate }}
                  </div>
                  <div
                    appAccessControl
                    moduleType="sales"
                    accessType="edit"
                    *ngIf="item?.status?.primaryStatus !== 'draft'"
                    (click)="openRecordPayment(item)"
                    class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                  >
                    {{ "Record Payment" | translate }}
                  </div>
                  <div
                    appAccessControl
                    moduleType="sales"
                    accessType="edit"
                    *ngIf="item?.status?.primaryStatus === 'draft'"
                    (click)="deleteInvoice(item._id)"
                    class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                  >
                    {{ "Delete" | translate }}
                  </div>
                </div>
              </app-dropdown>
            </td>
          </ng-template>
        </app-table-container>
      </div>
    </div>
  </div>
  