<div class="p-8 rounded-3xl bg-light-gray-1">
    <h3 class="Gilroy-Bold text-lg">{{'Security & Password' | translate }}</h3>
    <p class="mt-1 mb-2">{{'Set Password' | translate }}</p>
    <form [formGroup]='changePasswordForm' class="space-y-6">
        <div class="flex flex-col">
            <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-1/2">
                <label class="text-sm Gilroy-Bold font-color-05">{{'Current Password' | translate }}</label>
                <input formControlName='currentPassword' type="password" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-200" placeholder="{{'Enter current password' | translate }}" />
            </div>
            <div class="text-red-500 ms-2 text-sm" *ngIf='formErrors.currentPassword'>{{formErrors.currentPassword}}</div>
        </div>
        <div class="flex flex-col">
            <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-1/2">
                <label class="text-sm Gilroy-Bold font-color-05">{{'New Password' | translate }}</label>
                <input formControlName='newPassword' type="password" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-200" placeholder="{{'Enter new password' | translate }}" />
            </div>
            <div class="text-red-500 ms-2 text-sm" *ngIf='formErrors.newPassword'>{{formErrors.newPassword}}</div>
        </div>
        <div class="flex flex-col">
            <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-1/2">
                <label class="text-sm Gilroy-Bold font-color-05">{{'Confirm Password' | translate }}</label>
                <input formControlName='confirmPassword' type="password" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-200" placeholder="{{'Confirm your password' | translate }}" />
            </div>
            <div class="text-red-500 ms-2 text-sm" *ngIf='formErrors.confirmPassword'>{{formErrors.confirmPassword}}</div>
        </div>
        <div class='flex items-center'>
            <app-button type='primary' [rounded]='true' buttonText='{{"Change Password" | translate }}' (handleClick)='changePassword()'></app-button>
            <div class='text-green-500 ms-2' *ngIf='respMessage'>{{respMessage}}</div>
            <div class='text-red-500 ms-2' *ngIf='error'>{{error}}</div>
        </div>
    </form>
</div>