<div class="w-full p-8">
    <div class="bg-white px-14 py-11 rounded-xl space-y-6">
      <div class="flex items-center space-x-4">
        <!-- <svg routerLink="/storage" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-12 h-12">
              <path fill-rule="evenodd" d="M11.03 3.97a.75.75 0 010 1.06l-6.22 6.22H21a.75.75 0 010 1.5H4.81l6.22 6.22a.75.75 0 11-1.06 1.06l-7.5-7.5a.75.75 0 010-1.06l7.5-7.5a.75.75 0 011.06 0z" clip-rule="evenodd" />
          </svg> -->
        <span
          class="rounded-full bg-proile p-2 cursor-pointer"
          routerLink="/storage"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-8 h-8"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="#000000"
          >
            <path
              d="M360-240 120-480l240-240 56 56-144 144h568v80H272l144 144-56 56Z"
            />
          </svg>
        </span>
        <p class="Poppins-Medium text-3xl">{{ documentType ?? "" }}</p>
      </div>
      <div class="mt-8 space-y-3">
        <p class="Poppins-Medium text-xl">
          {{ documentType + " " + "Name" | translate }}
        </p>
        <div class="space-y-3">
          <div>
            <div class="accordion w-11/12">
              <div
                *ngFor="let item of filesData; let i = index"
                class="accordion-item rounded-xl bg-[#F0F5FF] bg-opacity-50 p-3 my-2"
              >
                <input
                  type="checkbox"
                  id="accordion-item-{{ i }}"
                  class="accordion-toggle"
                  [checked]="isAccordionOpen(i)"
                />
                <label
                  for="accordion-item-{{ i }}"
                  class="accordion-label flex justify-between items-center"
                  [ngClass]="
                    isAccordionOpen(i)
                      ? 'border-b pb-4 border-gray-300'
                      : 'border-0'
                  "
                >
                  <div class="flex items-center space-x-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#47494C"
                      class="w-6 h-6"
                    >
                      <path
                        d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h240l80 80h320q33 0 56.5 23.5T880-640H447l-80-80H160v480l96-320h684L837-217q-8 26-29.5 41.5T760-160H160Zm84-80h516l72-240H316l-72 240Zm0 0 72-240-72 240Zm-84-400v-80 80Z"
                      />
                    </svg>
                    <p class="Poppins-Medium">{{ item.referenceNumber }}</p>
                  </div>
                  <div class="flex items-center space-x-2 rtl:space-x-2">
                    <span *ngIf="isAccordionOpen(i)">
                      <app-dropdown #dropdown>
                        <div
                          button
                          (click)="dropdown.open = !dropdown.open"
                          class="p-2"
                        >
                          <div 
                            class="h-8 w-8 rounded-full flex justify-center items-center cursor-pointer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </div>
                        </div>
                        <div options class="new-shadow p-1">
                          <div
                            (click)="downloadZip(item)"
                            class="cursor-pointer px-4 py-2 text-sm Poppins-Medium hover:bg-gray-50 text-center"
                          >
                            {{ "Download as Zip" | translate }}
                          </div>
                        </div>
                      </app-dropdown>
                    </span>
                    <span
                      (click)="toggleAccordion(i)"
                      class="arrow"
                      *ngIf="isAccordionOpen(i)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M4.5 15.75l7.5-7.5 7.5 7.5"
                        />
                      </svg>
                    </span>
                    <span
                      (click)="toggleAccordion(i)"
                      class="arrow"
                      *ngIf="!isAccordionOpen(i)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    </span>
                  </div>
                </label>
                <div class="accordion-content" *ngIf="isAccordionOpen(i)">
                  <app-table-container
                    [keys]="tableKeys"
                    [tableHeadings]="tableHeadings"
                    [tableData]="item?.files"
                    [actionTemplate]="actionTemplate"
                  >
                    <ng-template let-item #actionTemplate>
                      <td class="p-2 flex justify-center items-center last:pe-10">
                        <svg
                          (click)="viewOrDownloadFile(item.fileId, 'Download')"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12.75l3 3m0 0l3-3m-3 3v-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        <!-- <app-dropdown #dropdown>
                                              <div button (click)='dropdown.open = true' (clickOutside)='dropdown.open = false'>
                                                  <div class="h-8 w-8 rounded-full flex justify-center items-center bg-gray-50 border border-gray-200">
                                                      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                                                      </svg>
                                                  </div>
                                              </div>
                                              <div options>
                                                  <div (click)='viewOrDownloadFile(item.fileId, "View")' class="cursor-pointer px-4 py-2 text-sm Poppins-Medium hover:bg-gray-50 text-center">{{'View File' | translate}}</div>
                                                  <div (click)='viewOrDownloadFile(item.fileId, "Download")' class="cursor-pointer px-4 py-2 text-sm Poppins-Medium hover:bg-gray-50 text-center">{{'Download File' | translate}}</div>
                                              </div>
                                          </app-dropdown>              -->
                      </td>
                    </ng-template>
                  </app-table-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  