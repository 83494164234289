import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/http.service';
import { PurchasesService } from '../../purchases.service';
// import * as VendorActions from '../../state/actions/vendors.action';
import { Store } from '@ngrx/store';
import { PurchaseReducerState } from '../../state/reducers';



@Injectable({
  providedIn: 'root'
})
export class BillsService {


  constructor(private httpService: HttpService,
              private store: Store<PurchaseReducerState>,
              private purchaseServices: PurchasesService) {}

    createBill(data): Observable<any> {
        return this.purchaseServices.createBill(data);
    }

    convertBill(data): Observable<any> {
      return this.purchaseServices.convertBill(data);
  }

    getAllBills(id): Observable<any> {
      return this.purchaseServices.getAllBills(id);
    }

    getBillId(id): Observable<any> {
      return this.purchaseServices.getBillDetails(id);
    }
    deleteBill(id): Observable<any> {
      return this.purchaseServices.deleteBill(id);
    }
    // addVendorInStore(vendor): void {
    //   this.store.dispatch(VendorActions.AddVendor({vendor}));
    // }
    updateBill(data): Observable<any> {
      return this.purchaseServices.updateBill(data);
    }

    recordPayment(data): Observable<any> {
      return this.purchaseServices.recordPayment(data);
    }

    refundPayment(data): Observable<any> {
      return this.purchaseServices.refundPayment(data);
    }

    updateRecord(data): Observable<any> {
      return this.purchaseServices.updateRecord(data);
    }

    deleteRecord(data): Observable<any> {
      return this.purchaseServices.deleteRecord(data);
    }

    filterBills(id,data): Observable<any> {
      return this.purchaseServices.filterBills(id,data)
    }

    getBillNumber(data): Observable<any> {
      return this.purchaseServices.getBillNumber(data);
    }

    createBillPdf(data): Observable<any> {
      return this.purchaseServices.createBillPdf(data);
    }

    sendEmail(data): Observable<any> {
      return this.purchaseServices.sendBill(data);
    }

}
