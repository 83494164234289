<div class="p-7 rounded-3xl bg-light-gray-1 mt-5 mb-2">
    <h3 class="Gilroy-Bold text-xl">{{'Estimate Settings' | translate }}</h3>
    <form [formGroup]='estimateSettingsForm'>
        <div class="flex mt-5 space-x-8 rtl:space-x-reverse">
            
            <div class="flex-1">
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2">
                    <label for='title2' class="block text-sm Gilroy-Bold font-color-05">{{'Default Title' | translate }}</label>
                    <input id='title2' formControlName='title' type="text" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-200" placeholder="{{'Estimate' | translate }}" />
                </div>
                <p class="text-sm mt-1 ms-1">{{'You can change this on each estimate.' | translate }}</p>
            </div>
            <div class="flex-1">
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2">
                    <label for='footer2' class="block text-sm Gilroy-Bold font-color-05">{{'Default Footers' | translate }}</label>
                    <input id='footer2' formControlName='footer' type="text" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-200" placeholder="{{'Thank you for your business!' | translate }}" />
                </div>
                <p class="text-sm mt-1 ms-1">{{'You can change this on each estimate.' | translate }}</p>
            </div>
        </div>
        <div class="mt-5 mb-5">
            <div class="flex-1">
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2">
                    <label for='memo' class="block text-sm Gilroy-Bold font-color-05">{{'Standard memo for new estimates' | translate }}</label>
                    <textarea id='memo' formControlName='memo' type="text" class="w-full p-0 h-36 border-0 focus:ring-0 placeholder-gray-200" placeholder="{{'Enter Memo' | translate }}"></textarea>
                </div>
                <p class="text-sm mt-1 ms-1">{{'Memo message' | translate }}</p>
            </div>
        </div>
        <div class="flex items-center space-x-2 rtl:space-x-reverse">
            <app-button buttonText='{{"Save changes" | translate }}' rounded='true' type='primary' (handleClick)='saveChanges()'></app-button>
            <span *ngIf='response' class="text-green-500">{{response}}</span>
            <span *ngIf='error' class="text-red-500">{{error}}</span>
        </div>
    </form>
</div>