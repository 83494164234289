<h3 class="text-3xl Gilroy-Bold">{{'Invite a new user to access' | translate }} {{companyName}}.</h3>
<p class="mb-5 Gilroy-SemiBold text-lg text-gray-800">{{'Choose a user role to get started:' | translate }}</p>

<div class="space-y-8">
    <div routerLink='/settings/users-management/invite-user/admin' class="cursor-pointer custom-input-box-shadow rounded-2xl p-6 px-10 flex items-center">
        <div class="bg-gradient p-4 rounded-full text-white">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />
            </svg>
        </div>
        <div class="ms-4">
            <p class="text-lg Gilroy-Bold font-bold">{{'Admin' | translate }}</p>
            <p class="Gilroy-Medium">{{'Best for a business partner, family member, or trusted accountant' | translate }}</p>
        </div>
        <button routerLink='/settings/users-management/invite-user/admin' class="text-blue-700 border border-blue-700 p-2 rounded-full ms-auto">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
            </svg>
        </button>      
    </div> 
    <div routerLink='/settings/users-management/invite-user/viewer' class="cursor-pointer custom-input-box-shadow rounded-2xl p-6 px-10 flex items-center">
        <div class="bg-gradient p-4 rounded-full text-white">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7" viewBox="0 0 20 20" fill="currentColor">
                <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                <path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
            </svg>
        </div>
        <div class="ms-4">
            <p class="text-lg Gilroy-Bold font-bold">{{'Viewer' | translate }}</p>
            <p class="Gilroy-Medium">{{'Best for employees' | translate }}</p>
        </div>
        <button routerLink='/settings/users-management/invite-user/viewer' class="text-blue-700 border border-blue-700 p-2 rounded-full ms-auto">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
            </svg>
        </button>      
    </div> 
    <div routerLink='/settings/users-management/invite-user/custom' class="cursor-pointer custom-input-box-shadow rounded-2xl p-6 px-10 flex items-center">
        <div class="bg-gradient p-4 rounded-full text-white">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
              </svg>
        </div>
        <div class="ms-4">
            <p class="text-lg Gilroy-Bold font-bold">{{'Custom' | translate }}</p>
            <p class="Gilroy-Medium">{{'Customize permissions according your need' | translate }}</p>
        </div>
        <button routerLink='/settings/users-management/invite-user/custom' class="text-blue-700 border border-blue-700 p-2 rounded-full ms-auto">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
            </svg>
        </button>      
    </div> 
</div>