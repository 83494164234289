<div class="w-full p-8">
  <div class="bg-white px-14 py-11 rounded-lg flex flex-col gap-y-4">
    <!-- <h1 class="text-2xl Poppins-Medium inline-flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
          </svg>
          <span class="text-2xl Poppins-Medium inline-flex items-center" >{{'New Recurring Bill' | translate}}</span>
        </h1> -->
    <div class="w-full m-auto">
      <form class="space-y-4 mb-4" [formGroup]="billForm">
        <div class="w-full space-y-8">
          <div class="flex gap-5">
            <div class="w-1/3 pe-1 gap-y-2">
              <label class="block text-sm Poppins-Medium font-color-05 mb-2">{{
                "Add a Vendor" | translate
              }}</label>
              <fieldset [disabled]="!isFieldEditable">
                <ng-select
                  [items]="vendors"
                  class="new-dropdown"
                  bindLabel="vendorName"
                  bindValue="_id"
                  formControlName="vendor"
                  [readonly]="!isFieldEditable"
                  placeholder="{{ 'Type Vendor Name' | translate }}"
                >
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div class="px-2 py-1">
                      <div
                        class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                      >
                        {{ item.vendorName }}
                      </div>
                    </div>
                  </ng-template>
                </ng-select>
                <div class="text-red-500" *ngIf="formErrors.vendor">
                  {{ formErrors.vendor }}
                </div>
              </fieldset>
            </div>
            <div class="w-1/3 px-1 gap-y-2">
              <label class="block text-sm Poppins-Medium font-color-05 mb-2">{{
                "Add a Project" | translate
              }}</label>
              <fieldset [disabled]="!isFieldEditable">
                <ng-select
                  [items]="projects"
                  class="new-dropdown"
                  bindLabel="projectName"
                  bindValue="_id"
                  formControlName="projectId"
                  [readonly]="!isFieldEditable"
                  placeholder="{{ 'Select Project Name' | translate }}"
                >
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div class="px-2 py-1">
                      <div
                        class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                      >
                        {{ item.projectName }}
                      </div>
                    </div>
                  </ng-template>
                </ng-select>
              </fieldset>
            </div>
            <div class="w-1/3 pe-1 gap-y-2">
              <label class="block text-sm Poppins-Medium font-color-05 mb-2">{{
                "Vendor Invoice" | translate
              }}</label>
              <fieldset [disabled]="!isFieldEditable">
                <input
                  formControlName="purchaseOrder"
                  type="text"
                  class="new-input"
                />
                <div class="text-red-500" *ngIf="formErrors.purchaseOrder">
                  {{ formErrors.purchaseOrder }}
                </div>
              </fieldset>
            </div>
          </div>
          <div class="flex gap-x-5 rtl:space-x-reverse">
            <!-- <div class="flex flex-col w-1/4">
                  <label class="Poppins-Medium">{{"Invoice #" | translate}}</label>
                  <fieldset [disabled]='!isFieldEditable' >  
                    <input formControlName='invoiceNumber' type="text" class="new-input " />
                    <div class="text-red-500" *ngIf='formErrors.invoiceNumber'>{{formErrors.invoiceNumber}}</div>
                  </fieldset>
                </div> -->
            <div class="flex flex-col w-1/4 gap-y-2">
              <label class="Poppins-Medium">{{
                "Frequency" | translate
              }}</label>
              <fieldset [disabled]="!isFieldEditable">
                <ng-select
                  [items]="frequency"
                  class="new-dropdown"
                  formControlName="frequency"
                  [readonly]="!isFieldEditable"
                  [clearable]="false"
                  placeholder="{{ 'Select Frequency' | translate }}"
                >
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div class="px-2 py-1">
                      <div
                        class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                      >
                        {{ item }}
                      </div>
                    </div>
                  </ng-template>
                </ng-select>
                <div class="text-red-500" *ngIf="formErrors.frequency">
                  {{ formErrors.frequency }}
                </div>
                <!-- <input formControlName='purchaseOrder' type="text" class="new-input " /> -->
              </fieldset>
            </div>
            <div class="flex flex-col w-1/4 gap-y-2">
              <label class="Poppins-Medium">{{
                "Payment Terms" | translate
              }}</label>
              <fieldset [disabled]="!isFieldEditable">
                <ng-select
                  #select
                  [items]="paymentTerms"
                  class="new-dropdown"
                  formControlName="paymentTerms"
                  bindLabel="paymentTerm"
                  bindValue="_id"
                  [readonly]="!isFieldEditable"
                  [clearable]="false"
                  placeholder="{{ 'Select Payment Terms' | translate }}"
                >
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div class="px-2 py-1">
                      <div
                        class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                      >
                        {{ item.paymentTerm }}
                      </div>
                    </div>
                  </ng-template>
                  <ng-template ng-footer-tmp>
                    <p
                      class="create-new cursor-pointer"
                      (click)="showCustomPaymentTermsModal(select)"
                    >
                      Create New
                    </p>
                  </ng-template>
                </ng-select>
                <div class="text-red-500" *ngIf="formErrors.paymentTerms">
                  {{ formErrors.paymentTerms }}
                </div>
                <!-- <input formControlName='purchaseOrder' type="text" class="new-input " /> -->
              </fieldset>
            </div>
            <div class="flex flex-col w-1/4 gap-y-2">
              <label class="Poppins-Medium">{{
                "Start Date" | translate
              }}</label>
              <fieldset [disabled]="!isFieldEditable">
                <input
                  formControlName="startDate"
                  [min]="today"
                  type="date"
                  class="new-date"
                />
                <div class="text-red-500" *ngIf="formErrors.startDate">
                  {{ formErrors.startDate }}
                </div>
              </fieldset>
            </div>
            <div class="flex flex-col w-1/4 gap-y-2">
              <label class="Poppins-Medium">{{ "End Date" | translate }}</label>
              <input
                formControlName="endDate"
                [min]="billForm.get('startDate').value"
                type="date"
                class="new-date"
              />
              <div class="text-red-500" *ngIf="formErrors.endDate">
                {{ formErrors.endDate }}
              </div>
            </div>
          </div>
          <table class="w-full">
            <tr>
              <td
                class="Poppins border-b text-[#1C1C1C66] border-[#1C1C1C33] font-light p-2 px-3 text-left w-3/12"
              >
                {{ tableFields.items | translate }}
              </td>
              <td
                class="Poppins border-b text-[#1C1C1C66] border-[#1C1C1C33] font-light p-2 px-3 text-left w-3/12"
              >
                {{ tableFields.units | translate }}
              </td>
              <td
                class="Poppins border-b text-[#1C1C1C66] border-[#1C1C1C33] font-light p-2 px-3 text-left w-2/12"
              >
                {{ tableFields.price | translate }}
              </td>
              <td
                class="Poppins border-b text-[#1C1C1C66] border-[#1C1C1C33] font-light p-2 px-3 text-left w-1/6"
              >
                {{ "Tax:" | translate }}
              </td>
              <td
                class="Poppins border-b text-[#1C1C1C66] border-[#1C1C1C33] font-light p-2 px-3 text-left"
              >
                {{ tableFields.amount | translate }}
              </td>
              <td
                class="Poppins border-b text-[#1C1C1C66] border-[#1C1C1C33] font-light p-2 px-3 text-left rounded-e-xl"
              ></td>
            </tr>
            <ng-container formArrayName="items">
              <ng-container
                *ngFor="let control of items?.controls; let i = index"
              >
                <tr [formGroup]="control" class="">
                  <td class="w-1/6 p-2">
                    <ng-select
                      [items]="avaialbleProducts"
                      class="new-dropdown"
                      bindLabel="name"
                      bindValue="name"
                      formControlName="item"
                      [readonly]="!isFieldEditable"
                      [hideSelected]="true"
                      [clearable]="false"
                      placeholder="{{ 'Select one' | translate }}"
                      (change)="changeEvent($event, i)"
                    >
                      <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-index="index"
                      >
                        <div class="px-2 py-1">
                          <div
                            class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                          >
                            {{ item.name }}
                          </div>
                        </div>
                      </ng-template>
                    </ng-select>
                  </td>
                  <td class="p-2 w-1/12">
                    <fieldset [disabled]="!isFieldEditable">
                      <input
                        id="quantity"
                        formControlName="unit"
                        type="number"
                        min="0"
                        class="new-input"
                      />
                    </fieldset>
                  </td>
                  <td class="p-2 w-2/12">
                    <fieldset [disabled]="!isFieldEditable">
                      <input
                        id="price"
                        formControlName="price"
                        type="number"
                        min="0"
                        class="new-input"
                      />
                      <div class="text-red-500" *ngIf="formErrors.price">
                        {{ formErrors.price }}
                      </div>
                    </fieldset>
                  </td>
                  <td class="p-2 w-1/6">
                    <ng-select
                      class="p-0 w-full text-sm focus:ring-0 border-0 new-dropdown"
                      [readonly]="!isFieldEditable"
                      [items]="taxes"
                      bindLabel="name"
                      formControlName="tax"
                      (change)="changeTax($event, i)"
                      placeholder="{{ 'Select Tax' | translate }}"
                    >
                      <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-index="index"
                      >
                        <div class="px-2 py-1">
                          <div
                            class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                          >
                            {{ item.name }}
                          </div>
                        </div>
                      </ng-template>
                      <ng-template
                        ng-label-tmp
                        let-item="item"
                        let-clear="clear"
                      >
                        <span class="ng-value-label"
                          >{{ item.taxName }}
                          {{ item.tax | number : number }}%</span
                        >
                        <span
                          class="ng-value-icon right"
                          (click)="clear(item)"
                          aria-hidden="true"
                          >×</span
                        >
                      </ng-template>
                    </ng-select>
                  </td>
                  <td class="p-2">
                    <p>
                      {{
                        items.controls[i].get("unit").value *
                          items.controls[i].get("price").value
                          | currency : currencySymbol : "symbol-narrow" : number
                      }}
                    </p>
                  </td>
                  <td *ngIf="isFieldEditable" class="w-1/12">
                    <svg
                      (click)="removeItem(i)"
                      xmlns="http://www.w3.org/2000/svg"
                      name="delete"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      class="mx-auto h-5 w-5 text-red-500"
                    >
                      <path
                        _ngcontent-hsm-c58=""
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      ></path>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <td colspan="2"></td>
                  <td>
                    <p
                      class="text-red-500 whitespace-nowrap"
                      *ngIf="control.get('unit').hasError('min')"
                    >
                      {{ "Quantity should be more than 0" }}
                    </p>
                  </td>
                  <td colspan="5"></td>
                </tr>
              </ng-container>
              <ng-container *ngIf="items.controls.length === 0">
                <tr>
                  <td colspan="8" class="text-center p-2">
                    {{ "No items added" | translate }}
                  </td>
                </tr>
              </ng-container>
            </ng-container>
            <tr>
              <td vAlign="top" colspan="3">
                <div *ngIf="isFieldEditable" class="ps-2">
                  <app-button (handleClick)="addNewItem()" type="add-line">
                    <img
                      src="assets/images/delete-icon.svg"
                      style="transform: rotate(45deg)"
                      alt=""
                    />
                    {{ "Add a Line" | translate }}
                  </app-button>
                </div>
              </td>
              <td colspan="4">
                <div class="Poppins-Medium flex justify-between py-2">
                  <p>{{ "Subtotal:" | translate }}</p>
                  <p>
                    {{
                      subTotal
                        | currency : currencySymbol : "symbol-narrow" : number
                    }}
                  </p>
                </div>
                <div class="Poppins-Medium flex justify-between py-2">
                  <p>{{ "Tax:" | translate }}</p>
                  <p>
                    {{
                      tax | currency : currencySymbol : "symbol-narrow" : number
                    }}
                  </p>
                </div>
                <div class="Poppins-Medium flex justify-between py-2 border-t">
                  <p>{{ "Total Amount:" | translate }}</p>
                  <p>
                    {{
                      totalAmount
                        | currency : currencySymbol : "symbol-narrow" : number
                    }}
                  </p>
                </div>
              </td>
            </tr>
          </table>
          <!-- File Upload Component -->
          <div
            class="mt-4 flex flex-col gap-2 max-h-[7.5rem] h-[7.5rem] relative"
          >
            <label class="block text-sm Poppins-Medium">{{
              "Notes & Terms" | translate
            }}</label>
            <div class="flex gap-28">
              <div class="w-1/3 gap-y-2">
                <textarea
                  [disabled]="!isFieldEditable"
                  formControlName="notesTerms"
                  class="new-input !h-[7rem]"
                  placeholder="{{ 'Add some notes' | translate }}"
                ></textarea>
              </div>
              <div class="w-1/3 gap-y-2">
                <app-file-upload
                  appAccessControl
                  moduleType="storage"
                  accessType="edit"
                  (emitter)="saveFiles($event)"
                  [type]="'compact'"
                ></app-file-upload>
              </div>
              <div
                appAccessControl
                moduleType="purchase"
                accessType="edit"
                class="flex flex-col items-start ms-auto justify-start"
              >
                <div class="flex flex-col gap-4">
                  <!-- <app-submit
                    [value]="update ? 'Update' : 'Save'"
                    className="bg-[#026AA2] text-white w-44"
                    (clicked)="update ? updatebill() : createBill()"
                  ></app-submit>
                  <app-submit
                    value="Discard"
                    className="bg-[#F6F6F6CC] text-[#1C1C1C66] w-44 shadow-btn"
                    routerLink="../"
                  ></app-submit> -->
                  <app-button
                    type="primary"
                    buttonText="Create Recurring Bill"
                    (handleClick)="createBill()"
                  ></app-button>
                  <app-button
                    fullWidth="true"
                    buttonText="Cancel"
                    routerLink="../"
                  ></app-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<app-modal-container [isOpen]="showCustomPaymentTerms">
  <div header class="text-start border-b pb-2 Poppins-Medium">
    {{ "Add New Payment Term" | translate }}
  </div>
  <div class="text-start" [formGroup]="paymentTermForm" content>
    <div class="flex flex-col w-1/2">
      <label class="Poppins-Medium">{{ "New Payment Term" | translate }}</label>
      <fieldset>
        <input
          id="paymentTerm"
          formControlName="paymentTerm"
          type="text"
          class="new-input"
        />
        <div class="text-red-500" *ngIf="paymentTermFormErrors.paymentTerm">
          {{ paymentTermFormErrors.paymentTerm }}
        </div>
      </fieldset>
    </div>
    <div class="flex flex-col w-1/2">
      <label class="Poppins-Medium">{{
        "New Payment Term Days" | translate
      }}</label>
      <fieldset>
        <input formControlName="days" type="number" class="new-input" />
        <div class="text-red-500" *ngIf="paymentTermFormErrors.days">
          {{ paymentTermFormErrors.days }}
        </div>
      </fieldset>
    </div>
  </div>
  <div footer class="flex items-center ms-auto gap-x-2">
    <app-button
      buttonText="{{ 'Create' | translate }}"
      type="primary"
      rounded="true"
      (handleClick)="createPaymentTerms()"
    ></app-button>
    <app-button
      buttonText="{{ 'Close' | translate }}"
      type="third"
      rounded="true"
      (handleClick)="showCustomPaymentTerms = false"
    ></app-button>
  </div>
</app-modal-container>
<!-- <app-image-cropper [imageData]='imageDataEsign' (base64Image)='loadEsign($event)'></app-image-cropper> -->
