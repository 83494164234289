import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
type buttonSize = 'small' | 'medium' | 'large';
type buttonType = 'primary' | 'secondary' | 'third' | 'danger' | 'add-line';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  constructor() {}

  @Input() primary = false;
  // @Input() buttonText;
  @Input() fullWidth = false;
  @Input() rounded = false;
  @Input() size: buttonSize = 'large';
  @Input() type: buttonType = 'secondary';
  @Input() disabled = false;
  @Input() formId = '';
  @Input('buttonText') label = '';
  @Input() customClass = '';

  @Output() handleClick = new EventEmitter();

  ngOnInit(): void {
    // this.customClassCreator();
  }

  // private customClassCreator(): void {
  //   switch (this.size) {
  //     case 'small':
  //       this.customClass = this.customClass.concat('py-1 px-5 text-sm ');
  //       break;
  //     case 'medium':
  //       this.customClass = this.customClass.concat('py-2 px-6 text-sm ');
  //       break;
  //     case 'large':
  //       this.customClass = this.customClass.concat(
  //         'py-2 px-6 Poppins-SemiBold '
  //       );
  //       break;
  //     default:
  //       this.customClass = this.customClass.concat('py-2 px-5 text-sm ');
  //   }

  //   switch (this.type) {
  //     case 'primary':
  //       this.customClass = this.customClass.concat('bg-gradient text-white ');
  //       break;
  //     case 'secondary':
  //       this.customClass = this.customClass.concat('bg-white text-blue-500 ');
  //       break;
  //     case 'third':
  //       this.customClass = this.customClass.concat(
  //         'bg-gray-200 text-gray-800 '
  //       );
  //       break;
  //     case 'danger':
  //       this.customClass = this.customClass.concat('bg-red-500 text-white ');
  //       break;
  //     case 'border':
  //       this.customClass = this.customClass.concat('border text-gray-800 ');
  //       break;
  //     default:
  //       this.customClass = this.customClass.concat('bg-white text-blue-500 ');
  //   }
  // }
}
