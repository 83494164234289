<!-- <button
    class="inline-block disabled:cursor-not-allowed"
    [ngClass] = 'customClass'
    [class.w-full] = 'fullWidth'
    [class.rounded-full] = 'rounded'
    [class.rounded-xl] = '!rounded'
    (click)='handleClick.emit()'
    [disabled]='disabled'
    >
    {{buttonText | translate}}
</button> -->



<button
  [class]="
    'h-[44px] px-4 whitespace-nowrap Poppins-Medium text-base disabled:cursor-not-allowed rounded-md shadow-md leading-4 hover:shadow-lg flex gap-2 items-center justify-center' +
    ' ' +
    customClass
  "
  [disabled]="disabled"
  (click)="handleClick.emit()"
  [attr.form]="formId || null"
  [ngClass]="{
    'bg-[#026AA2] text-white': type === 'primary',
    'text-[#A0A0A0] !bg-[#F8F8F8] hover:text-black': type === 'secondary',
    'bg-white border-2 border-[#DCDCDC] rounded-lg': type === 'third',
    'bg-white text-red-600': type === 'danger',
    'border border-[#1C1C1C0D] bg-[#f6f6f6c9] px-10': type === 'add-line',
    'w-full': fullWidth
  }"
>
  <ng-container *ngIf="label !== ''; else elseBlock">
    {{ label | translate }}
  </ng-container>
  <ng-template #elseBlock>
    <ng-content></ng-content>
  </ng-template>
</button>