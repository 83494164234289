import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectUserSubscription } from 'src/app/store/selectors/subscription.selectors';
import { ReportsService } from './reports.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  constructor(private reportsService: ReportsService,
    private router: Router,
    private store: Store<RootReducerState>) {
    this.business$ = this.store.select(selectBusiness);
    this.subscription$ = this.store.select(selectUserSubscription)
  }

  sections = [
    {
      name: 'Get the big picture',
      summary: 'How much profit are you making? Are your assets growing faster than your liabilities? Is cash flowing, or getting stuck?',
      subSection: [
        {
          name: 'Profit & Loss (Income Statement)',
          summary: 'Summary of your revenue and expenses that determine the profit you made.',
          routerLink: '/reports/profit-and-loss'
        },
        {
          name: 'Balance Sheet',
          summary: `Snapshot of what your business owns or is due to receive from others (assets), what it owes to others (liabilities), and what you've invested or retained in your company (equity).`,
          routerLink: '/reports/balance-sheet'
        },
        {
          name: 'Cash Flow',
          summary: 'Cash coming in and going out of your business. Includes items not included in Profit & Loss such as repayment of loan principal and owner drawings (paying yourself).',
          routerLink: '/reports/cash-flow'
        }
      ]
    },
    {
      name: 'Stay on top of taxes',
      summary: `Find out how much tax you've collected and how much tax you owe back to tax agencies.`,
      subSection: [
        {
          name: 'Sales Tax',
          summary: 'Taxes collected from sales and paid on purchases to help you file sales tax returns.',
          routerLink: '/reports/sales-tax'
        },
        {
          name: 'Sales Tax Transaction Report',
          summary: 'Taxes collected from sales and paid on purchases to help you file sales tax returns.',
          routerLink: '/reports/sales-tax-transaction'
        }
      ]
    },
    {
      name: 'Keep track of Assets',
      summary: 'Find out assets values after applying depreciation.',
      subSection: [
        {
          name: 'Assets Report',
          summary: 'This report will help you understand the current value of your assets',
          routerLink: '/reports/assets'
        }
      ]
    },
    {
      name: 'Focus on customers',
      summary: 'See which customers contribute most of your revenue, and keep on top of overdue balances.',
      subSection: [
        {
          name: 'Aged Receivables',
          summary: 'Unpaid and overdue invoices for the last 30, 60, and 90+ days.',
          routerLink: '/reports/aged-recievables'
        }
      ]
    },
    {
      name: 'Focus on vendors',
      summary: 'Understand business spending, where you spend, and how much you owe to your vendors.',
      subSection: [
        {
          name: 'Aged Payables',
          summary: 'Unpaid and overdue bills for the last 30, 60, and 90+ days.',
          routerLink: '/reports/aged-payables'
        }
      ]
    },
    {
      name: 'Dig deeper',
      summary: 'Drill into the detail of financial transactions over the life of your company.',
      subSection: [
        {
          name: 'Trail Balances',
          summary: 'Summary view of balances and activity for all accounts.',
          routerLink: '/reports/account-balances'
        },
        {
          name: 'Partial Trail Balances',
          summary: 'Detailed list of all transactions and their total by account—everything in your Chart of Accounts.',
          routerLink: '/reports/partial-trial-balances'
        },
        // {
        //   name: 'Trial Balance',
        //   summary: 'Balance of all your accounts on a specified date.',
        //   routerLink: '/reports/trail-balance'
        // },
        {
          name: 'Account Transactions (General Ledger)',
          summary: 'Detailed list of all transactions and their total by account—everything in your Chart of Accounts.',
          routerLink: '/reports/account-transactions'
        }
      ]
    },
    {
      name: 'POS',
      summary: 'Drill into the detail of financial transactions over the life of your company.',
      subSection: [
        {
          name: 'Cashier Attendance',
          summary: 'Summary view of cashier attendance for all cashier.',
          routerLink: '/reports/cashier-attendance'
        },
        {
          name: 'Cashier Sales - Items',
          summary: 'Summary view of cashier sales items for all cashier.',
          routerLink: '/reports/cashier-sales-items'
        },
        {
          name: 'Cashier Sales - Invoices',
          summary: 'Summary view of cashier sales invoices for all cashier.',
          routerLink: '/reports/cashier-sales-invoices'
        }
      ]
    }
  ];

  inventorySection = {
    name: 'Manage Inventory',
    summary: 'Get a easy view on your inventory and manage with no hassles.',
    subSection: [
      {
        name: 'Stock Summary',
        summary: 'Summary view of all the products and serivces.',
        routerLink: '/reports/stock-summary'
      },
      {
        name: 'Low Stock Summary',
        summary: 'Listing of all the inventory items reached low stock value.',
        routerLink: '/reports/low-stock'
      },
      {
        name: 'Item Summary',
        summary: 'Summary of particular item based on transactions',
        routerLink: '/reports/item-summary'
      }
    ]
  }

  routes = {
    'Profit & Loss (Income Statement)': 'profit-loss',
    'Balance Sheet': 'balance-sheet',
    'Cash Flow': 'cash-flow',
    'Sales Tax': 'sales-tax',
    'Sales Tax Transaction Report': 'sales-tax-transaction',
    'Income by Customer': 'income-by-customer',
    'Aged Receivables': 'aged-receivables',
    'Purchases by Vendor': 'purchases-by-vendor',
    'Aged Payables': 'aged-payables',
    'Trail Balances': 'trial-balances',
    'Partial Trail Balances': 'partial-trial-balances',
    // 'Trial Balance': 'trial-balance',
    'Account Transactions (General Ledger)': 'account-transactions',
    'Assets Report': 'assets',
    'Stock Summary': 'stock-summary',
    'Low Stock Summary': 'low-stock',
    'Item Summary': 'item-summary',
    'Cashier Attendance' : 'cashier-attendance',
    'Cashier Sales - Items' : 'cashier-sales-items',
    'Cashier Sales - Invoices' : 'cashier-sales-invoices'
  };

  business$: Observable<any>;
  subscription$: Observable<any>;
  direction;
  ngOnInit(): void {
    this.direction = localStorage.getItem('NuMetric|lang');
    this.business$.subscribe((business) => {
      if (business?.businessId?._id) {
        this.reportsService.setBusinessId(business.businessId._id);
        const currencyDetails = (({ currency, currencySymbol }) => ({ currency, currencySymbol }))(business?.businessId);
        this.reportsService.setCurrencyDetails(currencyDetails);
      }
    });

    this.subscription$.subscribe(({subscriptions}) => {
      if (subscriptions?.find(sub => sub?.subHost === 'Inventory')) {
        console.log(this.sections);
        
        if(!this.sections.includes(this.inventorySection)){
          this.sections.push(this.inventorySection)
        }
      }
    })
  }

  viewReport(subSection): void {
    this.router.navigate([`/reports/view/${this.routes[subSection.name]}`]);
  }

}
