import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/http.service';
import * as ProductsActions from './state/actions/products.action';
import * as SalesActions from './state/actions/salesTaxes.action';
import * as CustomerActions from './state/actions/customers.action';
import * as CustomizationSetttings from './state/actions/customizationSetttings.actions';
import { SalesReducerState } from './state/reducers';

@Injectable({
    providedIn: 'root'
  })
export class SalesService {
    constructor(private rootStore: Store<SalesReducerState>,
                private httpService: HttpService) {
    }

    currencyDetail = new BehaviorSubject(null);
    private businessId = new BehaviorSubject(null);
    businessId$ = this.businessId.asObservable();

    setCurrencyDetails(details): void{
        if(details){
            this.currencyDetail.next(details);
        }
    }

    setBusinessId(id): void {
        this.businessId.next(id);
    }

    setProducts(products): void {
        this.rootStore.dispatch(ProductsActions.SetProducts({products}));
    }

    setSalesTaxes(salesTaxes): void {
        this.rootStore.dispatch(SalesActions.SetSalesTaxes({salesTaxes}));
    }

    getInvoiceCustomizationSettings(businessId?): Observable<any> {
        return this.httpService.get(`/setting/getTemplateConfiguration/${this.businessId.getValue() || businessId}`);
    }

    setCustomizationSettings(customizationSettings): void {
        this.rootStore.dispatch(CustomizationSetttings.SetSettings({customizationSettings}));
    }

    // Estimate
    // APIs
    getEstimateNumber(): Observable<any> {
        return this.httpService.get(`/sales/generateEstimateNumber/${this.businessId.getValue()}`);
    }

    getEstimateList(): Observable<any> {
        return this.httpService.get(`/sales/getEstimates/${this.businessId.getValue()}`);
    }

    createEstimate(data): Observable<any> {
        return this.httpService.postMultipart(`/sales/createEstimate/${this.businessId.getValue()}`, data);
    }

    updateEstimate(data): Observable<any> {
        return this.httpService.putMultipart(`/sales/updateEstimate/${data.estimateId}`, data?.formData);
    }

    getEstimate(id): Observable<any> {
        return this.httpService.get(`/sales/viewEstimate/${id}`);
    }

    deleteEstimate(id): Observable<any> {
        return this.httpService.delete(`/sales/deleteEstimate/${id}`);
    }

    sendEstimateEmail(data): Observable<any> {
        return this.httpService.post(`/sales/sendEstimate/${data.id}`, data);
    }

    createEstimatePdf(data): Observable<any> {
        return this.httpService.postPdf('/pdf/estimate', data);
    }

    // Customer

    // Store
    setCustomers(customers): void {
        this.rootStore.dispatch(CustomerActions.SetCustomers({customers}));
    }

    // APIs
    customerList(id): Observable<any> {
        return this.httpService.get(`/sales/getCustomers/${id}`);
    }

    createCustomer(body): Observable<any> {
        return this.httpService.post(`/sales/createCustomer/${this.businessId.getValue()}`, body);
    }

    updateCustomer(data): Observable<any> {
        return this.httpService.put(`/sales/updateCustomer/${data.id}`, data);
    }

    getCustomer(id): Observable<any> {
        return this.httpService.get(`/sales/getCustomerById/${id}`);
    }

    getCustomerDetails(id): Observable<any> {
        return this.httpService.get(`/sales/getCustomerDetails/${id}`);
    }

    deleteCustomer(id): Observable<any> {
        return this.httpService.delete(`/sales/deleteCustomer/${id}`);
    }

    getCustomerActivity(data): Observable<any> {
        return this.httpService.post(`/sales/customerStatement/${data._id}`, data);
    }

    sendStatementEmail(data): Observable<any> {
        return this.httpService.post(`/sales/sendStatement`, data);
    }

    createCustomerStatementPdf(data): Observable<any> {
        return this.httpService.postPdf('/pdf/customerStatement', data);
    }

    getCustomerStats(customerId): Observable<any> {
        return this.httpService.get(`/sales/getCustomerStatics/${customerId}`)
    }

    getCustomerByPhoneNumber(data): Observable<any> {
        return this.httpService.post(`/sales/searchCustomerByNumber/${this.businessId.getValue()}`, data)
    }

    // Invoice
    // APIs

    getInvoiceStats(body): Observable<any> {
        return this.httpService.get(`/sales/getInvoiceStatics/${this.businessId.getValue()}`)
    }

    getCashInvoiceStats(): Observable<any> {
        return this.httpService.get(`/sales/getCashInvoiceStatics/${this.businessId.getValue()}`);
    }

    getInvoiceNumber(data): Observable<any> {
        return this.httpService.post(`/sales/generateInvoiceNumber/${this.businessId.getValue()}`, data);
    }

    getInvoiceList(currentPage, invoiceType): Observable<any> {
        return this.httpService.get(`/sales/getInvoices/${this.businessId.getValue()}?page=${currentPage}&limit=20&type=${invoiceType}`);
    }

    getCashInovoiceList(): Observable<any> {
        return this.httpService.get(`/sales/getCashInvoices/${this.businessId.getValue()}`);
    }

    getReccurringInoviceList(): Observable<any> {
        return this.httpService.get(`/sales/getRecurringInvoices/${this.businessId.getValue()}`)
    }

    createInvoice(data): Observable<any> {
        return this.httpService.postMultipart(`/sales/createInvoice/${this.businessId.getValue()}`, data);
    }

    createCashinvoice(data): Observable<any> {
        return this.httpService.postMultipart(`/sales/createCashInvoice/${this.businessId.getValue()}`, data)
    }

    createRecurringInvoice(data): Observable<any> {
        return this.httpService.postMultipart(`/sales/createRecurringInvoice/${this.businessId.getValue()}`, data)
    }

    updateInvoice(data): Observable<any> {
        return this.httpService.putMultipart(`/sales/updateInvoice/${data.invoiceData._id}`, data?.formData);
    }

    updateRecurringInvoice(id, data): Observable<any> {
        return this.httpService.put(`/sales/updateRecurringInvoice/${id}`, data)
    }

    deleteInvoice(id): Observable<any> {
        return this.httpService.delete(`/sales/deleteInvoice/${id}`);
    }

    cancelRecurringInvoice(id): Observable<any> {
        return this.httpService.get(`/sales/cancelRecurringInvoice/${id}`);
    }

    getInvoice(id): Observable<any> {
        return this.httpService.get(`/sales/viewInvoice/${id}`);
    }

    getRecurringInvoice(id): Observable<any> {
        return this.httpService.get(`/sales/viewRecurringInvoice/${id}`);
    }

    approveInvoice(data): Observable<any> {
        return this.httpService.put(`/sales/approveInvoice/${data.invoiceId}`, data);
    }

    markAsSent(id): Observable<any> {
        return this.httpService.put(`/sales/markAsSent/${id}`, id);
    }

    sendInvoiceEmail(data): Observable<any> {
        return this.httpService.post(`/sales/sendInvoice/${data._id}`, data);
    }

    recordPayment(data): Observable<any> {
        return this.httpService.postMultipart(`/sales/recordPayment/${data._id}`, data?.formData);
    }

    refundPayment(data): Observable<any> {
        return this.httpService.postMultipart(`/sales/refundPayment/${data._id}`, data?.formData);
    }
    
    cashInvoiceRefundPayment(data): Observable<any> {
        return this.httpService.post(`/sales/cashInvoiceRefundXML/${data._id}`, data);
    }

    filterInvoice(data): Observable<any> {
        return this.httpService.post(`/sales/filterInvoice/${this.businessId.getValue()}`, data);
    }

    filterCashInvoice(data): Observable<any> {
        return this.httpService.post(`/sales/filterCashInvoice/${this.businessId.getValue()}`, data);
    }

    updateRecordPayment(data): Observable<any> {
        return this.httpService.put(`/sales/updateRecord/${data.invoiceId}`, data);
    }

    deleteRecordPayment(data): Observable<any> {
        return this.httpService.delete(`/sales/deleteInvoiceRecord/${data.invoiceId}`, data);
    }

    convertCurrency(data): Observable<any> {
        return this.httpService.post('/paypal/convertCurrency', data);
    }

    generatePaymentLink(data): Observable<any> {
        return this.httpService.post(`/sales/recordOnlinePayment/${data?.invoiceId}`, data);
    }

    checkPaymentStatus(transactionId): Observable<any> {
        return this.httpService.get(`/sales/checkPaymentStatus/${transactionId}`);
    }

    createInvoicePdf(data): Observable<any> {
        return this.httpService.postPdf('/pdf/invoice/', data);
    }

    updatePaypalInvoice(data): Observable<any> {
        return this.httpService.post(`/paypal/updateInvoice/${data.businessId}`, data);
    }
}
