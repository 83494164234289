import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule,ReactiveFormsModule} from '@angular/forms';
import { BudgetListComponent } from './budget-list/budget-list.component';
import { CreateBudgetComponent } from './create-budget/create-budget.component';
import { BudgetViewComponent } from './budget-view/budget-view.component';

const routes: Routes = [
  {
    path: '',
    component: BudgetListComponent
  },
  {
    path:'create-budget',
    component:CreateBudgetComponent
  },
  {
    path:'edit-budget/:id',
    component:CreateBudgetComponent
  },
  {
    path:'view-budget/:id',
    component:BudgetViewComponent
  }
];



@NgModule({
  declarations: [
    BudgetListComponent,
    CreateBudgetComponent,
    BudgetViewComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    DirectivesModule,
    RouterModule.forChild(routes)
  ]
})
export class BudgetModule { }
