import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { RootReducerState } from 'src/app/store/reducers';
import { selectUser } from 'src/app/store/selectors/user.selectors';
import { getAllCountries, getAllStates } from 'src/app/shared/utils/countryStateData';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { DateValidator } from 'src/app/shared/utils/formValidator';

@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss']
})

export class PersonalInformationComponent implements OnInit {

  constructor(private fb: FormBuilder,
              private store: Store<RootReducerState>,
              private userService: UserService,
              private spinner: NgxSpinnerService) {
    this.userInfo$ = this.store.pipe(select(selectUser));
  }

  personalInformationForm: FormGroup;
  userInfo$: Observable<any>;
  error = '';
  respMessage = '';
  countries = [];
  states = [];
  countrySelect;
  stateSelect;
  profilePic = 'assets/images/avatar.jpg';
  openModal = false;
  openDeleteModal = false;
  openDeleteAccountModal = false;
  imageCropperConfig = {
    event: '',
    config: {
      maintainAspectRatio: true,
      roundCropper: true,
      aspectRatio: 1 / 1,
      title: 'Upload'
    }
  };
  openPreviewModal = false;
  selectedPreview = '';
  userDetails = null;
  ngOnInit(): void {
    this.loadForm();
    this.countries = getAllCountries();
    this.userInfo$.subscribe((user) => {
      this.loadForm(user);
      this.userDetails = {...user, DOB: user?.DOB?.split('T')[0]};
      if (user.profileImageName) {
        this.profilePic = `${environment.apiBaseURL}/${user.profileImageName}`;
      }
    });
  }

  loadForm(user?): void {
    console.log('[Personal Info] Form', user);
    this.personalInformationForm = this.fb.group({
      firstName: [user?.firstName ?? null],
      lastName: [user?.lastName ?? null],
      country: [user?.address?.country ?? null],
      state: [user?.address?.state ?? null],
      city: [user?.address?.city ?? null],
      zipcode: [user?.address?.zipcode ?? null],
      DOB: [user?.DOB?.split('T')[0] ?? null, DateValidator()]
    });
  }

  savePersonalInfo(): void {
    this.error = '';
    this.respMessage = '';
    this.spinner.show();
    this.userService.updateUserDetails(this.personalInformationForm.value).subscribe((resp) => {
      this.spinner.hide();
      const updatedUser = {...resp.data,DOB:resp?.data?.DOB?.split('T')[0]};
      this.userService.onUpdateUserDetailsSuccess(updatedUser);
      this.respMessage = 'Personal information updated successfully';
      setTimeout(() => this.respMessage = '', 3000);
    }, (error) => {
      this.error = error?.error?.message || 'Internal Server Error';
      this.spinner.hide();
    });
  }
  changeEventContry(event): void {
    const {text} = event;
    const result = getAllStates(text);
    this.states = result;
    console.log('huss', this.states );
  }

  openPreview(url): void {
    this.selectedPreview = url;
    this.openPreviewModal  = true;
  }

  fileEvent(event): void {
    this.openModal = true;
    this.imageCropperConfig = {
      event,
      config: {
        maintainAspectRatio: true,
        roundCropper: true,
        aspectRatio: 1 / 1,
        title: 'Upload'
      }
    };
  }


  saveImage(image): void {
    this.openModal = false;
    this.spinner.show();
    this.userService.uploadProfile({ data: image })
    .subscribe((resp) => {
      this.spinner.hide();
      if (resp.success) {
        this.profilePic = image;
        this.userService.setUserDetails(resp.data);
      }
    }, (error) => {
      this.spinner.hide();
      console.log(error);
    });
  }

  removeLogo(): void {
    this.spinner.show();
    this.openDeleteModal = true;
    this.userService.uploadProfile({ data: '' })
    .subscribe((resp) => {
      this.spinner.hide();
      if (resp.success) {
        this.profilePic = 'assets/images/avatar.jpg';
        this.userService.setUserDetails(resp.data);
        this.openDeleteModal = false;
      }
    }, (error) => {
      this.spinner.hide();
      console.log(error);
    });
  }
}
