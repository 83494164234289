import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SalesService } from 'src/app/modules/sales/sales.service';

@Injectable({
  providedIn: 'root'
})
export class CashInvoiceService {

  constructor(private salesService: SalesService) { }

  createCashInvoice(invoiceData): Observable<any> {
    return this.salesService.createCashinvoice(invoiceData);
  }

  getCashInvoices(): Observable<any> {
    return this.salesService.getCashInovoiceList();
  }

  getCashInvoice(invoiceId): Observable<any> {
    return this.salesService.getInvoice(invoiceId);
  }

  filterCashInvoices(filterData): Observable<any> {
    return this.salesService.filterCashInvoice(filterData);
  }

  updateCustomersInStore(customers): void {
    this.salesService.setCustomers(customers);
  }

  refundPayment(data): Observable<any> {
    return this.salesService.cashInvoiceRefundPayment(data);
  }
}
