<div class='flex justify-center w-full fixed top-0 z-10 bg-white ' id='navbar'>
  <nav class='w-full lg:w-5/6 h-16  flex md:flex-row flex-col justify-between max-w-[1440px] mx-auto'>
    <div class="flex justify-between p-4 md:px-2">
      <img src="assets/images/logo.png" width='130' alt="" srcset="">
      <div class="block lg:hidden">
        <button id="nav-toggle" (click)='toggle()' class="flex items-center px-3 py-2 border rounded">
          <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
        </button>
      </div>
    </div>
    <!-- <div id="nav-content" class="hidden md:flex md:flex-row bg-white flex-col justify-between" >
    </div> -->
    <span id="nav-content" class='p-2 bg-white flex md:flex-row flex-col space-y-2 md:space-y-0 md:items-center items-start transition-all duration-200'>
      <a class='me-6  flex-col current' href="#home">
        <div class="Gilroy-Bold text-sm font-mider">{{'Home' | translate}}</div>
        <div class="dot opacity-0 dark-blue mx-auto"></div>
      </a>

      <a class='me-6 Gilroy-Bold' href="#solution">
        <div class="Gilroy-Bold text-sm font-mider">{{'Solutions' | translate}}</div>
        <div class="dot opacity-0 dark-blue mx-auto"></div>
      </a>
      <a class='me-6 Gilroy-Bold' href="#about">
        <div class="Gilroy-Bold text-sm font-mider">{{'About Us' | translate}}</div>
        <div class="dot opacity-0 dark-blue mx-auto"></div>
      </a>
      <a class='me-6 Gilroy-Bold' href="#pricing">
        <div class="Gilroy-Bold text-sm font-mider">{{'Pricing' | translate}}</div>
        <div class="dot opacity-0 dark-blue mx-auto"></div>
      </a>
      <a class='me-6 Gilroy-Bold' href="https://learn.numetric.work">
        <div class="Gilroy-Bold text-sm font-mider">{{'Learn' | translate}}</div>
        <div class="dot opacity-0 dark-blue mx-auto"></div>
      </a>
      <a routerLink="contact" class='me-6 Gilroy-Bold'>
        <div class="Gilroy-Bold text-sm font-mider">{{'Contact Us' | translate}}</div>
        <div class="dot opacity-0 dark-blue mx-auto"></div>
      </a>
    </span>
    <div id="nav-content1" class="p-2 flex bg-white flex-row transition-all md:items-center items-start duration-200">

      <app-dropdown #dropdown>
        <div (click)='dropdown.open=true' (clickOutside)='dropdown.open=false' button class="relative inline-block text-start group ms-4">
          <div id="menu-button" class="inline-flex  cursor-pointer items-center justify-center w-full rounded-md px-4 py-2 bg-white text-sm font-mider font-medium text-gray-700 hover:bg-gray-50 focus:outline-none">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-5 w-5"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"></path></svg>
            <span class="uppercase ms-1">{{currentLanguage}}</span>
          </div>
        </div>
        <div options class="absolute mt-2 md:w-32 w-48 rounded-xl shadow-lg bg-gray-50 focus:outline-none">
          <a (click)='changeLanguage("en")' id="menu-item-0" class="text-dark-700 cursor-pointer block px-4 py-2 text-sm font-mider hover:bg-gray-200 text-center rounded-t-xl">English</a>
          <a (click)='changeLanguage("ar")' id="menu-item-1" class="text-dark-700 cursor-pointer block px-4 py-2 text-sm font-mider hover:bg-gray-200 text-center rounded-b-xl">Arabic</a>
        </div>
      </app-dropdown>

      <span>
        <a [attr.href]="baseURL + '/auth/login'" class='px-6 py-2  text-gray-700 Gilroy-Bold text-sm me-4 flex'>
        {{'Login' | translate}}
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-4 ml-2 pt-1">
          <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
        </svg>
        </a>
      </span>

      <span>
        <a [attr.href]="baseURL + '/auth/signup'" class='px-3 py-2 background-landing rounded-full text-white border Gilroy-Bold text-xs me-4 flex justify-center w-full'>
        {{'Sign Up For Free' | translate}}
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 pt-1">
          <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
        </svg>
        </a>
      </span>

    </div>


  </nav>
  <!-- <nav class="flex items-center justify-between flex-wrap bg-grey-darkest p-6 fixed w-full z-10 pin-t">
    <div class="flex items-center flex-no-shrink me-6">
      <img src="assets/images/logo.png" width='120' alt="" srcset="">
    </div>

    <div class="block lg:hidden">
      <button id="nav-toggle" (click)='toggle()' class="flex items-center px-3 py-2 border rounded">
        <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
      </button>
    </div>

    <div class="lg:mx-autow-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block pt-6 lg:pt-0" id="nav-content">
      <a class='me-6  flex-col current' href="#home">
        <div class="Gilroy-Bold text-sm">{{'Home' | translate}}</div>
        <div class="dot opacity-0 h-2 w-2 mt-1 rounded-full dark-blue mx-auto"></div>
      </a>

      <a class='me-6 Gilroy-Bold' href="#solution">
        <div class="Gilroy-Bold text-sm">{{'Solutions' | translate}}</div>
        <div class="dot opacity-0 h-2 w-2 mt-0 rounded-full dark-blue mx-auto"></div>
      </a>
      <a class='me-6 Gilroy-Bold' href="#about">
        <div class="Gilroy-Bold text-sm">{{'About Us' | translate}}</div>
        <div class="dot opacity-0 h-2 w-2 mt-0 rounded-full dark-blue mx-auto"></div>
      </a>
      <a class='me-6 Gilroy-Bold' href="#pricing">
        <div class="Gilroy-Bold text-sm">{{'Pricing' | translate}}</div>
        <div class="dot opacity-0 h-2 w-2 mt-0 rounded-full dark-blue mx-auto"></div>
      </a>
      <a routerLink="contact" class='me-6 Gilroy-Bold'>
        <div class="Gilroy-Bold text-sm">{{'Contact Us' | translate}}</div>
        <div class="dot opacity-0 h-2 w-2 mt-0 rounded-full dark-blue mx-auto"></div>
      </a>
    </div>
  </nav> -->
</div>
<div class="w-full">
  <router-outlet></router-outlet>
</div>
<footer class="bg-white w-full md:p-0 px-2 ">
  <div class="w-full md:w-5/6 max-w-[1280px] mx-auto ">
    <div class="lg:flex grid grid-cols-1 gap-4 py-12">

       <div class="flex-1 sm:justify-center">
        <div class="flex justify-between p-2 md:px-2">
          <img src="assets/images/logo.png" width='80' alt="" srcset="">
        </div>
        <p class="text-start text-gray-500 mb-4 Gilroy-Light text-xs">{{'invoice description' | translate}}</p>
      </div>

      <div class="flex-1 flex lg:pl-8">
        <div class="flex flex-col space-y-1 md:mt-1 mt-1">
          <h3 class="Gilroy-Bold text-base"> {{'Helpful Links' | translate }}</h3>
          <a class="Gilroy-Light text-gray-500 text-sm pt-2" href="#faq">{{"FAQ's" | translate }}</a>
          <a class="Gilroy-Light text-gray-500 text-sm pt-2" href="https://learn.numetric.work">{{"Learn and Support" | translate }}</a>
          <!-- <a class="cursor-pointer Gilroy-Bold " href="https://numetric.tapfiliate.com" target="_blank">{{"Join our Affiliate Program" | translate }}</a> -->
          <a href="https://zapier.com/apps/numetric/integrations" target="_blank"  class="cursor-pointer Gilroy-Light text-gray-500 text-sm">{{'Zapier Integration' | translate }}</a>
          <a routerLink="termandconditions" target="_blank" class="cursor-pointer Gilroy-Light text-gray-500 text-sm">{{'Terms and Conditions' | translate }}</a>
          <a routerLink="privacyandpolicy" target="_blank" class="cursor-pointer Gilroy-Light text-gray-500 text-sm">{{'Privacy Policy' | translate }}</a>
          <a routerLink="eula" target="_blank" class="cursor-pointer Gilroy-Light text-gray-500 text-sm">{{'EULA' | translate }}</a>
        </div>
      </div>

      <div class="flex-1 flex-col">
        <div class="flex flex-col p-1">
          <h3 class="Gilroy-Bold text-base"> {{'Ready to Start?' | translate }}</h3>
          <span>
            <button routerLink='auth/login'
            class='px-4 py-1 background-landing rounded-full text-white border Gilroy-Light text-sm me-4 mt-2'>
            {{'Get Started' | translate}}</button>
          </span>
        </div>
        <div class="flex flex-col md:mt-4 mt-2 justify-start">
          <h6 class="Gilroy-Bold text-base ml-[3px]"> {{'Get the App' | translate }}</h6>
          <span class="flex w-[80%] lg:w-[100%] mt-[6px] ml-[4px]">
            <a routerLink='https://play.google.com/store/apps/details?id=com.numetric'>
             <img src="assets/images/google-play-png-logo-3802.png" class="w-[90%]" /></a>

             <a routerLink='https://apps.apple.com/in/app/numetric/id1639065837'>
              <img src="assets/images/app-store-png-logo-33116.png" class="w-[90%]"/></a>
              <!-- <img src="assets/images/app-store-png-logo-33116.png" class="appStore object-cover h-[60px] w-28 mt-[-2px]  block"/></a> -->
          </span>
        </div>
      </div>

      <div class="md:w-2/5 w-full flex  lg:justify-center justify-start">
        <div>
          <h3 class="md:text-start lg:text-center text-left text-black Gilroy-Bold text-lg pb-4">{{'Subscribe to our Newsletter' | translate}}</h3>
          <form action="https://gmail.us20.list-manage.com/subscribe/post?u=237e8e668e2bea3d62d9cfd14&amp;id=99423f7a5b" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
              <div class="flex md:flex-row flex-col items-center md:space-y-0 space-y-2" id="mc_embed_signup_scroll">

              <div class="">
                  <input [formControl]='email' type="email" value="" name="EMAIL" placeholder='{{"Email Address" | translate}}' class="md:h-12 h-10 w-80 border-1 border-gray-600 md:rounded-ts-md md:rounded-bs-md rounded-md" id="mce-EMAIL">
                  <div class="text-red-400 ms-2 mb-4" *ngIf='email.getError("required") && email.dirty'>{{"Errors.Field is required" | translate}}</div>
                  <div class="text-red-400 ms-2 mb-4" *ngIf='email.getError("pattern") && email.dirty'>{{"Errors.Email must be in correct format" | translate}}</div>
              </div>
              <div id="" class="clear"></div>
              <div class="absolute left-[-5000px]" aria-hidden="true"><input type="text" name="b_237e8e668e2bea3d62d9cfd14_99423f7a5b" tabindex="-1" value=""></div>
              <input type="submit" value="{{'Submit'|translate}}" name="subscribe" id="mc-embedded-subscribe" class="background-landing rounded-xl md:h-10 h-8 px-auto text-white text-sm mr-auto md:-ms-24 w-[90px]">
              </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-black w-full h-12 text-white flex gap-6 justify-center py-4 text-xs Gilroy-Light">
    <div>
      <p class="">{{'Numetric.work © Virifi Technologies LTD'}}</p>
    </div>
    <!-- <div>
      <p class="">{{'Private Policy'}}</p>
    </div>
    <div>
      <p class="">{{'Term and Conditions'}}</p>
    </div>
    <div>
      <p class="">{{'EULA'}}</p>
    </div> -->
  </div>
</footer>
