<div class="w-[90%] mx-auto flex flex-row space-x-2 my-4" [ngClass]="{'w-3/4' : newTemplate}">
    <app-button (click)='downloadPdf("regular")' buttonText='{{"Download PDF" | translate }}' type='primary'></app-button>
    <app-button *ngIf="invoiceData?.isCashInvoice" (click)='downloadPdf("pos")' buttonText='{{"Download POS PDF" | translate }}' type='primary'></app-button>
    <app-button *ngIf="businessData?.paytabsAuthorizationToken && businessData?.paytabsProfileId && invoiceData?.dueAmount > 0 && invoiceData?.acceptOnlinePayment" (click)='payByPaytabsModal = true' buttonText='{{"Pay Online" | translate }}' type='primary'></app-button>
</div>
<div class="w-[90%] mx-auto mb-4">
    <app-template-one *ngIf='customizationSettings?.selectedTemplate === "modern"' [invoiceData]='invoiceData'></app-template-one>
    <app-template-two *ngIf='customizationSettings?.selectedTemplate === "classic"' [invoiceData]='invoiceData'></app-template-two>
    <app-template-three *ngIf='customizationSettings?.selectedTemplate === "contemporary"' [invoiceData]='invoiceData'></app-template-three>
    <app-template-four *ngIf='customizationSettings?.selectedTemplate === "templateOption1"'  [invoiceData]='invoiceData'></app-template-four>
    <app-template-five (paymentClick)="paymentClick($event)" [businessData]="businessData" *ngIf='customizationSettings?.selectedTemplate === "templateOption2"'  [invoiceData]='invoiceData'></app-template-five>
    <app-template-six *ngIf='customizationSettings?.selectedTemplate === "jordan"'  [invoiceData]='invoiceData'></app-template-six>
    <app-template-seven *ngIf='customizationSettings?.selectedTemplate === "saudi"'  [invoiceData]='invoiceData'></app-template-seven>
</div>

<app-modal-container [isOpen]="payByPaytabsModal" >
    <div header>{{'Payment Details' | translate}}</div>
    <div content [formGroup]="paytabsForm" class="space-y-4">
        <div>
          <label>{{ 'Payment Type' | translate }}</label>
          <div class="flex items-center space-x-4">
            <label class="inline-flex items-center">
              <input type="radio" formControlName="fullAmount" [value]="true" class="form-radio">
              <span class="ml-2">{{ 'Full Amount' | translate }}</span>
            </label>
            <label class="inline-flex items-center">
              <input type="radio" formControlName="fullAmount" [value]="false" class="form-radio">
              <span class="ml-2">{{ 'Partial Amount' | translate }}</span>
            </label>
          </div>
        </div>
      
        <div class="flex items-center space-x-4">
          <div>
            <label for="amount">{{ 'Amount' | translate }}</label>
            <input formControlName="amount" id="amount" type="number" class="w-full bg-input rounded-lg border-0 h-11 me-2"
                   [attr.disabled]="paytabsForm.get('fullAmount').value ? true : null">
            <div class="text-red-500 text-sm ms-2" *ngIf="paytabsFormErrors.amount">{{ paytabsFormErrors.amount | translate }}</div>
          </div>
      
          <div>
            <label for="memo">{{ 'Memo' | translate }}</label>
            <input formControlName="memo" id="memo" type="text" class="w-full bg-input rounded-lg border-0 h-11 me-2">
          </div>
        </div>
      </div>
      
    <div footer class="flex space-x-2 rtl:space-x-reverse">
      <app-button [disabled]="paytabsForm?.invalid" buttonText='{{"Proceed" | translate }}' type='primary' rounded='true' (click)="generatePaymentLink()"></app-button>
      <app-button buttonText='{{"Cancel" | translate }}' type='third' rounded='true' (click)="payByPaytabsModal = false"></app-button>
    </div>
</app-modal-container>