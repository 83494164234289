import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { SalesReducerState } from 'src/app/modules/sales/state/reducers';
import { RootReducerState } from 'src/app/store/reducers';
import { InvoicesService } from '../../../invoices.servcies';
import { TranslateService } from '@ngx-translate/core';
import { NumberService } from 'src/app/shared/services/number.service';
import { ToastrService } from 'ngx-toastr';
import { selectProducts } from 'src/app/modules/sales/state/selectors/products.selector';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { selectCustomers } from 'src/app/modules/sales/state/selectors/customers.selector';
import { selectCustomizationSettings } from 'src/app/modules/sales/state/selectors/customizationSettings.selector';
import { selectSalesTaxes } from 'src/app/modules/sales/state/selectors/salesTaxes.selector';
import {
  DateValidator,
  dateLessThan,
  valueChanges,
} from 'src/app/shared/utils/formValidator';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectProjects } from 'src/app/store/selectors/project.selector';
import { selectUserSubscription } from 'src/app/store/selectors/user.selectors';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import * as htmlToImage from 'html-to-image';
import { selectAccounts } from 'src/app/store/selectors/account.selector';
import { CashInvoiceService } from '../cash-invoice.service';
import { getCountryPhoneCode } from 'src/app/shared/utils/countryStateData';
import { CustomerService } from '../../../../customers/customer.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
// import { selectAccounts } from 'src/app/store/selectors/account.selector';

interface StockCheck {
  item: string;
  openingStock: number;
  quantityAdded: number;
}

@Component({
  selector: 'app-create-cash-invoice',
  templateUrl: './create-cash-invoice.component.html',
  styleUrls: ['./create-cash-invoice.component.scss'],
  providers: [DatePipe],
})
export class CreateCashInvoiceComponent implements OnInit, OnDestroy {
  constructor(
    private fb: FormBuilder,
    private store: Store<SalesReducerState>,
    private rootStore: Store<RootReducerState>,
    private router: Router,
    private spinner: NgxSpinnerService,
    private cashInvoiceService: CashInvoiceService,
    private customerService: CustomerService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private numberService: NumberService,
    private toaster: ToastrService,
    private fileUploadService: FileUploadService
  ) {
    this.products$ = this.store.pipe(select(selectProducts));
    this.customers$ = this.store.pipe(select(selectCustomers));
    this.taxes$ = this.store.pipe(select(selectSalesTaxes));
    this.business$ = this.rootStore.pipe(select(selectBusiness));
    this.projects$ = this.rootStore.pipe(select(selectProjects));
    this.subscription$ = rootStore.pipe(select(selectUserSubscription));
    this.customizationSettings$ = this.store.pipe(
      select(selectCustomizationSettings)
    );
    this.paymentAccounts$ = this.rootStore.pipe(
      select(selectAccounts),
      map((accounts: any[]) => accounts.filter((account) => !account.isArchive && !account.isDeleted && ( account.accountType === 'Cash and Bank' || account.accountType === 'Money in Transit' )))
    );
  }

  invoiceForm: FormGroup;
  products$: Observable<any>;
  customers$: Observable<any>;
  taxes$: Observable<any>;
  business$: Observable<any>;
  paymentAccounts$: Observable<any>;
  projects$: Observable<any>;
  subscription$: Observable<any>;
  customizationSettings$: Observable<any>;

  products = [];
  availableProducts = [];
  projects = [];
  taxes = [];
  customers = [];
  accounts = [];
  countryWithCode = [];
  files: File[] = [];
  business = null;
  subscription = null;
  subTotal = 0;
  totalAmount = 0;
  averageTotalAmount = 0;
  tax = 0;
  discount = 0;
  invoiceToUpdate = null;
  unsubscribe$ = new Subject();

  customizationSettings = {
    companyLogo: '',
  };
  tableFields = {
    items: 'Items',
    itemDescription: 'Description',
    units: 'Units',
    sku: 'SKU',
    hsn_sac: 'HSN/SAC',
    price: 'Price',
    amount: 'Amount',
  };
  invoiceData = {
    _id: '',
    qrCode: '',
    eSign: '',
    currencySymbol: '',
    discount: null,
    discountValue: null,
    qrHeading: '',
    currencyDetails: {
      currencySymbol: null,
      currencyValue: null,
      convertedAmount: null,
    },
    dueAmount: null,
    paymentLink: null,
    paypalInvoiceId: null,
    businessDetails: null,
    customerDetails: null,
    paymentHistory: [],
  };
  imageDataEsign = {
    event: '',
    config: {
      maintainAspectRatio: false,
      roundCropper: false,
      aspectRatio: 16 / 9,
      title: 'Upload',
    },
  };

  openCreateEsign = false;
  openModal = false;
  openModalForEsign = false;
  openDeleteModal = false;
  removeQRCodeModal = false;
  disableFields = false;
  removeESignModal = false;
  eSignSettingModal = false;
  previewSignatureForDoc = false;
  isFieldEditable: boolean = true;

  eSignValue = '';
  eSignSelected = '';
  discountType = 'percent';
  discountValue = 0;

  imageCropperConfig = {
    event: '',
    config: {
      maintainAspectRatio: true,
      roundCropper: true,
      aspectRatio: 1 / 1,
      title: 'Upload',
    },
  };
  imageDataQR = {
    event: new Event(''),
    openModal: false,
    config: {
      maintainAspectRatio: false,
      roundCropper: false,
      aspectRatio: 16 / 9,
      title: 'Upload',
    },
  };

  formErrors = {
    countryCode: '',
    customerNumber: '',
    projectId: '',
    invoiceDate: '',
    purchaseOrder: '',
    discount: '',
    title: '',
    unit: '',
    price: '',
    paymentMethod: ''
  };

  formErrorMessages = {
    countryCode: {
      required: 'Country Code is Required',
    },
    customerNumber: {
      required: 'Customer Mobile Number is Required',
      min: 'Customer Mobile Number must be of atleast 4 digits'
    },
    projectId: {
      required: 'Project is Required',
    },
    invoiceDate: {
      required: 'Date is Required',
      pattern: 'Invalid Date',
      max: 'Invalid Date',
      min: 'Invalid Date',
      invalidDate: 'Invalid Date',
    },
    purchaseOrder: {
      required: 'Purchase Order is Required',
    },
    title: {
      required: 'Title is required',
    },
    discount: {
      min: "Discount can't be less than 0%",
      max: "Discount can't be more than 100%",
    },
    items: {
      price: {
        min: "Price can't be negative",
      },
    },
    unit: {
      min: "Quantity can't be negative",
    },
    paymentMethod: {
      required: 'Payment Method is required'
    }
  };

  outOfStock: boolean = false;
  isBusinessEligible: boolean = false;
  checkQuantityInStock: Array<StockCheck | null> = [];

  number = '1.2-2';
  customerBalanceUpdatedAt = moment();

  currencySymbol = null;
  minDate = moment('01/01/1000').format('YYYY-MM-DD');
  maxDate = moment('31/12/3000').format('YYYY-MM-DD');

  ngOnInit(): void {
    this.loadInvoiceForm();
    this.loadSubscription();
    this.loadCustomers();
    this.loadProjects();
    this.loadTaxes();
    this.loadBusiness();
    this.loadCountryCodeData();
    this.loadCustomizationSettings();
    this.loadNumberConfig();
  }

  loadSubscription(): void {
    this.subscription$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((subscription) => {
        this.subscription = subscription;
        this.products$
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((products) => {
            if (
              this.subscription?.subscriptions?.find(sub => sub.subHost === 'Inventory')
            ) {
              products = products?.filter((product) => product?.isReviewed);
            }
            this.products = products.filter((product) => product?.isSale);
            this.availableProducts = this.products;
          });
      });
  }

  loadCountryCodeData(): void {
    this.countryWithCode = getCountryPhoneCode()
  }

  loadNumberConfig(): void {
    this.numberService.number
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((number) => {
        this.number = number;
      });
  }

  createSignature(): void {
    htmlToImage
      .toPng(document.getElementById(this.eSignSelected))
      .then((dataUrl) => {
        this.updateEsign(dataUrl);
      })
      .catch((error) => {
        console.error(this.translateService.instant('Something went wrong!'));
      });
  }

  updateEsign(base64): void {
    this.invoiceData.eSign = base64;
    this.eSignSettingModal = false;
    this.openCreateEsign = false;
    this.eSignValue = null;
    this.previewSignatureForDoc = true;
  }

  appendInvoiceData(invoiceData): void {
    const {
      invoiceNumber,
      customerDetails: {
        customerId: { _id },
      },
      invoiceDate,
      dueDate,
      purchaseOrder,
      Footer,
      notesTerms,
      subTotal,
      tax,
      totalAmount,
      items,
      title,
      subHeading,
      discount,
      discountValue,
      reminder = false,
      qrHeading,
    } = invoiceData;
    const mappedItems = items.map(
      ({
        item,
        itemId,
        itemDescription,
        unit,
        tax,
        sku = '',
        type,
        hsn_sac = '',
        isSale = false,
        cogsAccountDetails = null,
        inventoryAccountDetails = null,
        price,
        totalCost,
        accountDetails,
        averagePrice,
        productNature
      }) => {
        return {
          item,
          itemId,
          itemDescription,
          unit,
          sku,
          type,
          hsn_sac,
          tax,
          price,
          totalCost,
          accountDetails,
          isSale,
          inventoryAccountDetails,
          cogsAccountDetails,
          averagePrice: averagePrice || price,
          productNature
        };
      }
    );
    const data = {
      invoiceNumber,
      invoiceDate: invoiceDate.split('T')[0],
      dueDate: dueDate.split('T')[0],
      purchaseOrder: purchaseOrder ?? null,
      subHeading: subHeading ?? null,
      Footer: Footer ?? null,
      title: title ?? 'Invoice',
      notesTerms: notesTerms ?? null,
      items: mappedItems,
      customerId: _id,
      projectId: invoiceData?.projectDetails?.projectId ?? null,
      discount,
      reminder,
      qrHeading,
    };
    if (
      invoiceData?.paymentHistory.length > 0 ||
      invoiceData?.refundHistory.length > 0
    ) {
      this.isFieldEditable = false;
    }
    this.discountValue = discountValue;
    this.subTotal = subTotal;
    this.tax = tax;
    this.totalAmount = totalAmount;
    mappedItems.forEach(() => this.items.push(this.createItem()));
    this.invoiceForm.setValue(data);
  }

  fileEventForESign(event): void {
    this.openCreateEsign = false;
    this.openModalForEsign = true;
    this.imageCropperConfig = {
      event,
      config: {
        maintainAspectRatio: true,
        roundCropper: false,
        aspectRatio: 16 / 9,
        title: 'Add E-Sign',
      },
    };
  }

  fileEventForQR(event): void {
    if (!this.isFieldEditable) return;
    (this.openModal = true),
      (this.imageDataQR = {
        event,
        openModal: true,
        config: {
          maintainAspectRatio: true,
          roundCropper: false,
          aspectRatio: 1 / 1,
          title: 'Add QR Code',
        },
      });
  }

  openEsignModal(): void {
    if (!this.isFieldEditable) return;
    this.openCreateEsign = true;
    this.previewSignatureForDoc = true;
  }

  loadInvoiceNumber(): void {
    this.spinner.show();
    const from = moment().startOf('year').format('YYYY-MM-DD');
    const to = moment().endOf('year').format('YYYY-MM-DD');
    const year = moment().format('YYYY');
    const body = {
      from,
      to,
      year,
    };
  }


  loadProducts(): void {}

  loadProjects(): void {
    this.projects$.pipe(takeUntil(this.unsubscribe$)).subscribe((projects) => {
      this.projects = projects;
    });
  }

  loadTaxes(): void {
    this.taxes$.pipe(takeUntil(this.unsubscribe$)).subscribe((taxes) => {
      this.taxes = taxes.map((tax) => ({
        ...tax,
        name: tax.taxName,
        tax: tax.taxRate,
        accountDetails: tax.accountDetails,
        itemCbcValue: tax.itemCbcValue
      }));
    });
  }

  loadCustomers(): void {
    this.customers$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((customers) => {
        this.customers = customers;
      });
  }

  loadBusiness(): void {
    this.business$.subscribe((business) => {
      if (business?.businessId) {
        this.business = business?.businessId;
        this.currencySymbol = this.business?.currency;
        if(this.business?.clientId && this.business?.secretCode && this.business?.taxId && this.business?.incomeSourceId  ){
          this.isBusinessEligible = true; 
        }
      }
    });
  }

  loadCustomizationSettings(): void {
    this.customizationSettings$.subscribe((settings) => {
      if (settings) {
        console.log(settings);

        this.customizationSettings = settings;
        this.tableFields = {
          ...this.tableFields,
          items: settings?.items || this.tableFields.items,
          amount: settings?.amount || this.tableFields.amount,
          units: settings?.units || this.tableFields.units,
          price: settings?.price || this.tableFields.price,
        };
        this.invoiceForm
          .get('title')
          .setValue(settings?.invoiceSettings?.title ?? 'Invoice');
        this.invoiceForm
          .get('Footer')
          .setValue(settings?.invoiceSettings?.footer);
      }
    });
  }

  loadInvoiceForm(): void {
    this.invoiceForm = this.fb.group(
      {
        countryCode: [null, Validators.required],
        customerNumber: ['', [Validators.required, Validators.min(5999)]],
        customerFirstName: [''],
        customerLastName: [''],
        customerEmail: [''],
        customerCompanyName: [''],
        customerBalance: [''],
        projectId: [null],
        purchaseOrder: [null],
        invoiceDate: [
          new Date().toISOString().split('T')[0],
          [Validators.required, DateValidator()],
        ],
        title: [{ value: null, disabled: true }, [Validators.required]],
        discount: [0, [Validators.min(0), Validators.max(100)]],
        Footer: [null],
        items: this.fb.array([]),
        paymentMethod: [null],
        useCustomerBalance: [false]
      }
    );
    // this.invoiceForm.get('customerNumber').valueChanges.pipe(debounceTime())
    this.invoiceForm.valueChanges.subscribe(({items, customerBalance, useCustomerBalance}) => {
      this.calculateTotal(items);
      if(customerBalance && Number(customerBalance) >= this.totalAmount && useCustomerBalance){
        this.invoiceForm.get('paymentMethod').setValue(null, {emitEvent: false});
        this.invoiceForm.get('paymentMethod').clearValidators();
        this.invoiceForm.get('paymentMethod').updateValueAndValidity({emitEvent: false});
      }
      else{
        this.invoiceForm.get('paymentMethod').setValidators(Validators.required);
        this.invoiceForm.get('paymentMethod').updateValueAndValidity({emitEvent: false});
      }
      this.formErrors = valueChanges(
        this.invoiceForm,
        { ...this.formErrors },
        this.formErrorMessages,
        this.translateService
      );
    });
    this.formErrors = valueChanges(
      this.invoiceForm,
      { ...this.formErrors },
      this.formErrorMessages,
      this.translateService
    );
  }

  searchCustomerByMobileNumber(): void {
    if(this.invoiceForm.get('customerNumber').valid ){
      this.spinner.show(); 
      this.customerService.getCustomerByPhoneNumber({mobileNumber: this.invoiceForm.get('customerNumber').value})
      .subscribe(resp => {
        if(resp?.success){
          let customer = resp?.data;
          if(customer){
            this.invoiceForm.patchValue({
              customerFirstName: customer?.firstName ?? '',
              customerLastName: customer?.lastName ?? '',
              customerEmail: customer?.email ?? '',
              customerCompanyName: customer?.companyName ?? '',
              customerBalance: customer?.balance ? Number(customer?.balance)*-1 : '',
            })
            this.customerBalanceUpdatedAt = moment();
          }
          else{
            this.invoiceForm.patchValue({
              customerBalance: ''
            })
          }
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
      })
    }
  }

  showPaymentMethod(): boolean {
    let formValue = this.invoiceForm.value;
    if(formValue?.customerBalance && formValue?.useCustomerBalance){
      return this.totalAmount > Number(formValue?.customerBalance);
    }
    else {
      return true;
    }
  }

  changeTax(event, i) {
    console.log(event, i);
    if (this.items.length > 0) {
      if (event.length > 1) {
        event.shift();
        this.items.controls[i].get('tax').setValue(event);
      }
    }
  }

  createInvoice(): void {
    console.log(this.invoiceForm);
    if (this.invoiceForm.invalid) {
      this.invoiceForm.markAllAsTouched();
      this.formErrors = valueChanges(
        this.invoiceForm,
        { ...this.formErrors },
        this.formErrorMessages,
        this.translateService
      );
      return;
    }

    if(this.business?.isSalesTax && this.items.value?.some(el => el?.tax?.length === 0)){
      this.toaster.error(this.translateService.instant('All item must contain taxes to proceed'));
      return
    }

    const invoiceData: any = this.invoiceDataMapper();

    if (invoiceData.items.length === 0) {
      return;
    }

    if (invoiceData.items[0].item === null) {
      return;
    }

    if (invoiceData?.items?.some((item) => !item.itemId)) {
      this.toaster.error(
        'Please select item for each line item before proceeding'
      );
      return;
    }

    if (this.subscription?.subscriptions?.find(sub => sub.subHost === 'Inventory')) {
      let stockCheck: Array<StockCheck | null> = [];
      invoiceData.items?.forEach((item) => {
        const product = this.products.find(
          (product) => product?._id == item?.itemId
        );
        if (product?.itemType === 'Product' && product.productNature === 'normal' && product?.stockDetails?.openingStock < item?.unit) {
          stockCheck.push({
            item: item?.item,
            openingStock: product?.stockDetails?.openingStock,
            quantityAdded: item?.unit,
          });
        }
      });
      this.checkQuantityInStock = stockCheck;
      if (this.checkQuantityInStock.length) {
        this.outOfStock = true;
        return;
      }
    }
    this.fileUploadService.emitFiles.next(true);
    this.fileUploadService.emitFiles.next(false);
    let formData = new FormData();
    this.files.forEach((file, i) => {
      formData.append(`file${i}`, file)
    })
    formData.append('payload', JSON.stringify(invoiceData));
    this.spinner.show();
    this.cashInvoiceService.createCashInvoice(formData).subscribe(
      (resp) => {
        if(resp?.success){
          this.toaster.success(resp?.message || 'Invoice created successfully');
          this.cashInvoiceService.updateCustomersInStore([...this.customers, {...resp?.data?.customerDetails?.customerId}])
          this.router.navigate(['/sales/cash-invoice']);
        }
        else{
          this.toaster.error(resp?.message);
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error(error?.error?.message);
      }
    );
  }


  invoiceDataMapper(): object {
    const body = {
      ...this.invoiceForm.getRawValue(),
      businessDetails: {
        businessId: this.business._id,
      },
      customerDetails: {
        firstName: this.invoiceForm.value.customerFirstName,
        lastName: this.invoiceForm.value.customerLastName,
        mobileNumber: this.invoiceForm.value.customerNumber,
        email: this.invoiceForm.value.customerEmail,
        companyName: this.invoiceForm.value.customerCompanyName,
      },
      projectDetails: {
        projectId: this.invoiceForm.value.projectId,
      },
      currencyDetails: {
        currencySymbol:
          this.invoiceData?.currencyDetails?.currencySymbol ??
          this.currencySymbol,
        currencyValue: this.invoiceData?.currencyDetails?.currencyValue ?? null,
        convertedAmount:
          this.invoiceData?.currencyDetails?.convertedAmount ?? null,
      },
      companylogo: this.customizationSettings.companyLogo || '',
      totalAmount: this.numberService.toFixed(this.totalAmount),
      averageTotalAmount: this.numberService.toFixed(this.averageTotalAmount),
      dueAmount: this.numberService.toFixed(this.totalAmount),
      tax: this.numberService.toFixed(this.tax),
      subtotal: this.numberService.toFixed(this.subTotal),
      discountValue: this.numberService.toFixed(this.discountValue),
      discount: this.numberService.toFixed(this.invoiceForm.value?.discount),
      paymentMethod: {...this.invoiceForm?.value?.paymentMethod},
      isJOValid: this.isBusinessEligible
    };

    return body;
  }

  get items(): FormArray {
    return this.invoiceForm.get('items') as FormArray;
  }

  createItem(): FormGroup {
    const itemForm = this.fb.group({
      item: null,
      itemId: '',
      itemDescription: '',
      sku: '',
      hsn_sac: '',
      price: [null, [Validators.min(0)]],
      unit: [1, [Validators.min(0.01)]],
      tax: null,
      type: '',
      totalCost: [null, [Validators.min(0)]],
      accountDetails: null,
      cogsAccountDetails: null,
      isSale: false,
      inventoryAccountDetails: null,
      productNature: null,
      averagePrice: 0,
    });
    return itemForm;
  }

  addNewItem(): void {
    this.items.push(this.createItem());
    this.invoiceForm.controls.items.valueChanges.subscribe((items) => {});
  }
  removeItem(index): void {
    this.items.removeAt(index);
    this.updateAvailableItems();
  }
  updateAvailableItems(){
    const allIds = this.items.value.map(item=>item.itemId)
    this.availableProducts = this.products.filter(el => !allIds.includes(el?._id));
  }


  changeEvent(event, index): void {
    const {
      _id,
      name,
      itemDescription = '',
      sellPrice,
      itemType,
      tax,
      accountDetails,
      sku,
      hsn,
      sac,
      isSale = false,
      inventoryAccountDetails = null,
      cogsAccountDetails = null,
      averagePrice,
      productNature
    } = event;
    const taxToBeSaved = this.business?.isSalesTax ? tax : null;
    this.items.controls[index].setValue({
      itemId: _id,
      item: name,
      itemDescription,
      type: itemType,
      sku,
      hsn_sac: hsn || sac,
      price: sellPrice,
      tax: taxToBeSaved,
      isSale,
      cogsAccountDetails,
      inventoryAccountDetails,
      accountDetails,
      unit: 1,
      totalCost: sellPrice * 1,
      averagePrice: this.numberService.toFixed(averagePrice || sellPrice),
      productNature
    });
    this.updateAvailableItems()
  }

  calculateTotal(estimateItems): void {
    let subTotal = 0;
    let total = 0;
    let totalTax = 0;
    let discountPercent = 0;
    let averageTotalAmount = 0;
    const discountFormValue = this.invoiceForm.get('discount').value;
    const discountFormField = this.invoiceForm.get('discount');
    if (discountFormField.valid) {
      discountPercent = this.numberService.toFixed(discountFormValue);
    }
    estimateItems.forEach((item) => {
      const { price, unit, tax, averagePrice } = item;
      if (price > 0 && unit > 0) {
        let itemTotal = price * unit;
        let averageItemTotal = averagePrice * unit;
        subTotal += itemTotal;
        if (discountFormValue > 0) {
          const discount = itemTotal * (discountPercent / 100);
          itemTotal -= discount;
          averageItemTotal -= discount;
        }
        total += itemTotal;
        averageTotalAmount += averageItemTotal;
        if (tax) {
          totalTax += this.calculateItemTax(itemTotal, this.business?.isSalesTax ? tax : null);
        }
      }
    });
    this.tax = this.numberService.toFixed(totalTax);
    this.subTotal = this.numberService.toFixed(subTotal);
    this.totalAmount = this.numberService.toFixed(total + totalTax);
    this.averageTotalAmount = this.numberService.toFixed(averageTotalAmount);
    this.discount = this.numberService.toFixed(subTotal - total);
    this.discountValue = this.numberService.toFixed(total);
    console.log('total', this.totalAmount, this.subTotal, estimateItems);
  }

  calculateItemTax(price, taxes): any {
    return [taxes].reduce((a, b) => (a += price * (b.tax / 100)), 0);
  }

  calculateDiscountPercent(total, discountValue): number {
    return this.numberService.toFixed(
      (discountValue * 100) / this.numberService.toFixed(total)
    );
  }
  removeQRCode(): void {
    this.invoiceData.qrCode = '';
    this.removeQRCodeModal = false;
  }

  removeEsign(): void {
    this.invoiceData.eSign = '';
  }

  // updateEsign(base64): void {
  //   this.invoiceData.eSign = base64;
  //   this.eSignSettingModal = false;
  // }

  loadEsign(event): void {
    this.openModalForEsign = false;
    this.invoiceData.eSign = event;
  }

  loadQR(event): void {
    this.openModal = false;
    this.invoiceData.qrCode = event;
  }

  saveFiles(event): void {
    this.files = event;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
