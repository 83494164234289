import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PurchasesService } from 'src/app/modules/purchases/purchases.service';
import { SalesService } from 'src/app/modules/sales/sales.service';
import { selectCustomizationSettings } from 'src/app/modules/sales/state/selectors/customizationSettings.selector';
import { LanguageService } from 'src/app/shared/services/language.service';
import { NumberService } from 'src/app/shared/services/number.service';
import { copyToClipBoard } from 'src/app/shared/utils/copyToClipBoard';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { environment } from 'src/environments/environment';
import { BillsService } from '../../../bills/bills.services';
import { PurchaseOrderService } from '../../PurchaseOrder.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';

@Component({
  selector: 'app-view-purchase-order',
  templateUrl: './view-purchase-order.component.html',
  styleUrls: ['./view-purchase-order.component.scss']
})
export class ViewPurchaseOrderComponent implements OnInit {

  constructor(
    private store: Store<RootReducerState>,
    private router: Router,
    private route: ActivatedRoute,
    private purchaseOrderService: PurchaseOrderService,
    private purchasesService: PurchasesService,
    private salesService: SalesService,
    private billService: BillsService,
    private spinner: NgxSpinnerService,
    private languageService: LanguageService,
    private toaster: ToastrService,
    private translateService: TranslateService,
    private numberService: NumberService,
    private fileUploadService: FileUploadService
  ) {
    this.business$ = this.store.pipe(select(selectBusiness));
  }
  business$: Observable<any>;
  customizationSettings = null;
  businessId = null;
  business = null;
  currencyDetails = {
    currency: '',
    currencySymbol: ''
  };
  billNumber = ''
  unsubscribe$ = new Subject();
  purchaseOrderDetail;
  emailModal = false;
  error = '';
  response = '';
  shareURL = '';
  copyButtonText = 'Copy';
  number = '1.2-2';
  decimalSize = 2;
  email = new FormControl(null, [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]);
  fieldNames = {
    purchase: 'Purchase Order',
    purchaseOrder: 'P.O. Number',
    date: 'P.O. Date',
    dueDate: 'Due Date',
    vendorDetails: 'Sent To',
    notes: 'Notes',
    itemName: 'Item Name',
    quantity: 'Unit',
    price: 'Unit Price',
    tax: 'Tax',
    totalAmount: 'Total Amount',
    total: 'Total',
    subtotal: 'Subtotal',
    issuer: 'Issued By'
  };
  baseURL = environment.frontBaseURL;
  files: File[] = [];
  addedFiles = [];

  ngOnInit(): void {
    this.business$.pipe(takeUntil(this.unsubscribe$)).subscribe((business) => {
      this.businessId = business.businessId._id;
      this.business = business.businessId;
      const { currency, currencySymbol } = business?.businessId;
      this.currencyDetails = {
        currency: currency,
        currencySymbol: currencySymbol
      }
    });
    this.loadCustomSettings();
    this.route.queryParams.subscribe(({ id }) => {
      if (id) { this.loadPO(id); }
      else {
        this.router.navigate(['../']);
      }
    });

    this.loadNumberConfig();
  }

  loadNumberConfig(): void {
    this.numberService.number
    .pipe((takeUntil(this.unsubscribe$)))
    .subscribe((number) => {
      this.number = number
    })

    this.numberService.decimalSize
    .pipe((takeUntil(this.unsubscribe$)))
    .subscribe((decimalSize) => {
      this.decimalSize = decimalSize
    })
  }


  loadPO(id): void {
    this.spinner.show();
    
    this.purchaseOrderService.OrderDetails(id).subscribe(
      (resp) => {
        this.spinner.hide();
        this.purchaseOrderDetail = resp.data;
        this.addedFiles = this.purchaseOrderDetail?.files ?? []
        this.shareURL = `${this.baseURL}/open/purchase-order/${resp.data.businessId._id}/${resp.data._id}`;
      },
      (error) => {
        this.spinner.hide();
        this.router.navigate(['../']);
        console.log(error);
      }
    );
  }

  loadCustomSettings(): void {
    this.salesService.getInvoiceCustomizationSettings(this.businessId).pipe(takeUntil(this.unsubscribe$)).subscribe((resp) => {
      if(resp?.success)
      this.customizationSettings = resp?.data;
    });
  }

  convertToBill(): void {
    this.spinner.show();
    this.createBillData(this.purchaseOrderDetail);
  }

  loadBillNumber(): Observable<any> {
    const from = moment().startOf('year').format('YYYY-MM-DD');
    const to = moment().endOf('year').format('YYYY-MM-DD');
    const year = moment().format('YYYY');
    const body = {
      from,
      to,
      year,
      businessId: this.businessId
    };
    return this.billService.getBillNumber(body)
  }

  createBillData(data): void {
    const items = data.items.map(item => ({
      accountDetails: item.accountDetails,
      itemId: item.itemId,
      item: item.item,
      hsn_sac: item?.hsn_sac,
      sku: item?.sku, 
      unit: item.unit,
      price: item.price,
      tax: item.tax,
      totalCost: item.unit * item.price,
      type: item.type,
      isSale: item?.isSale ?? false,
      cogsAccountDetails: item?.cogsAccountDetails ?? null,
      inventoryAccountDetails: item?.inventoryAccountDetails ?? null,
      depreciationAccount: item.depreciationAccount
    }));
    let itemTotal = 0;
    data.items.forEach((el) => {
      if (el.type === 'Product') {
        return itemTotal += el.price * el.unit;
      }
    });
    this.loadBillNumber()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        if (resp.success) {
          const billData = {
            vendor: data?.vendor,
            billNumber: resp?.data,
            date: data.date,
            dueDate: data.dueDate,
            purchaseOrder: data.purchaseOrder,
            businessId:this.businessId,
            items: [...items],
            dueAmount: data.toatalAmount,
            totalAmount: data.toatalAmount,
            tax: data.tax,
            itemTotal,
            subtotal: data.subtotal,
            userId: data?.userId?._id,
            currencyDetails: {
              currencySymbol: this.currencyDetails?.currencySymbol,
              currencyValue: null,
              convertedAmount: null
            },
          };
          this.createBill(billData);
        }
      })
  }

  createBill(billData): void {
    this.billService.convertBill(billData).subscribe((resp) => {
      this.spinner.hide();
      console.log(resp);
      this.router.navigate(['/purchases/bills/view-bill'], { queryParams: { id: resp.data._id } });
    }, (error) => {
      this.spinner.hide();
      console.log(error);
    });
  }

  copyLink(): void {
    copyToClipBoard(this.shareURL);
    this.copyButtonText = 'Copied!';
    setTimeout(() => {
      this.copyButtonText = 'Copy';
    }, 1500);
  }

  sendEmail(): void {
    if (this.email.invalid) {
      this.email.markAsDirty();
      return;
    }
    const data = {
      email: this.email.value,
      id: this.purchaseOrderDetail._id,
      ownerId: this.business.userId
    };
    this.spinner.show();
    this.purchaseOrderService.sendEmail(data).subscribe((resp) => {
      this.spinner.hide();
      this.email.reset();
      this.toaster.success('Email sent successfully');
      this.emailModal = false;
    }, (error) => {
      this.spinner.hide();
      this.toaster.error('Email not sent');
      console.log(error);
    });
  }

  downloadPdf(): void {
    const body = {
      config: {
        data: {
          ...this.purchaseOrderDetail,
          items: this.purchaseOrderDetail.items.map((item) => {
            return {
              ...item,
              price: this.numberService.currencier(item.price),
              totalPrice: this.numberService.currencier(item.price * item.unit)
            };
          }),
          date: moment(this.purchaseOrderDetail.date).format('DD-MM-YYYY'),
          dueDate: moment(this.purchaseOrderDetail.dueDate).format('DD-MM-YYYY'),
          subtotal: this.numberService.currencier(this.purchaseOrderDetail.subtotal),
          tax: this.numberService.currencier(this.purchaseOrderDetail.tax),
          toatalAmount: this.numberService.currencier(this.purchaseOrderDetail.toatalAmount),
          currencyDetails: { currencySymbol: this.currencyDetails.currencySymbol },
          companylogo: this.customizationSettings.companyLogo
        },
        direction: localStorage.getItem('NuMetric|lang') === 'ar'? 'rtl' : 'ltr',
        showTax: this.purchaseOrderDetail.tax ? true : false,
        decimalSize: this.decimalSize,
        fieldNames: this.languageService.translate(this.fieldNames)
      }
    };
    this.spinner.show();
    this.purchaseOrderService.generatePdf(body).subscribe((resp) => {
      this.spinner.hide();
      const a = document.createElement('a');
      const blob = new Blob([resp], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `${this.purchaseOrderDetail.purchaseOrder}.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
      this.toaster.success(this.translateService.instant('PDF downloaded'));
    }, (error) => {
      this.spinner.hide();
      const errorMessage = error?.error?.message || this.translateService.instant('Something went wrong!');
      this.toaster.error(errorMessage);
    });
  }

  uploadFiles(): void {
    this.fileUploadService.emitFiles.next(true);
    this.fileUploadService.emitFiles.next(false);
    if(!this.files.length){
      this.toaster.error('Select files to upload');
      return;
    }
    this.spinner.show();
    const body = {
      businessId: this.purchaseOrderDetail?.businessId?._id,
      referenceId: this.purchaseOrderDetail?._id,
      documentType: 'Purchase Order',
      parentDocType: 'Purchase'
    }
    const formData = new FormData();
    this.files.forEach((file, i) => {
      formData.append(`file${i}`, file);
    })
    formData.append('payload', JSON.stringify(body));
    this.fileUploadService.uploadFileForDocument(formData)
    .subscribe(resp => {
      if(resp?.success){
        console.log(resp?.data, 'view po');
        this.addedFiles = resp?.data?.files ?? [];
        this.toaster.success(resp?.message);
      }
      else{
        this.toaster.error(resp?.message)
      }
      this.spinner.hide();
    }, (err) => {
      this.toaster.error(err?.error?.message);
      this.spinner.hide();
    })
  }

  saveFiles(files: File[]){
    this.files = files;
  }

}
