import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ReferrralService {

  constructor(private http: HttpService) { }

  getReferralCode(referralCode): Observable<any> {
    return this.http.post('/admin/getReferralbyCode', {referralCode});
  }
}
