import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PurchasesService } from 'src/app/modules/purchases/purchases.service';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { VendorsService } from '../../vendors.services';
import { NumberService } from 'src/app/shared/services/number.service';


@Component({
  selector: 'app-vendors-list',
  templateUrl: './vendors-list.component.html',
  styleUrls: ['./vendors-list.component.scss']
})
export class VendorsListComponent implements OnInit {
  tableHeadings = [
    'Vendor Name',
    'Email',
    'Mobile Number',
    'Vendor Location',
    'Balance',
    'Actions'
  ];
  tableKeys = ['vendorName', 'email', 'mobileNumber', 'location', 'availableBalance'];
  tableData = [];
  deleteConfirmationModal = false;
  searchText;
  vendorToDelete = null;

  constructor(private fb: FormBuilder,
              private rootStore: Store<RootReducerState>,
              private route: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private vendorService: VendorsService,
              private PurchaseService: PurchasesService,
              private toastr: ToastrService,
              private translateService: TranslateService,
              private router: Router,
              private numberService: NumberService,){
    }
    business$: Observable<any>;
    unsubscribe$ = new Subject();
    error = '';
    ngOnInit(): void {
      this.loadvendors();
    }

  loadvendors(): void {
    this.PurchaseService.businessId$.subscribe((id) => {
      if (id) {
        this.vendorService.getAllVendors(id).subscribe(resp => {
          if (resp?.success) {
            this.tableData = resp.data.map(vendors =>
              ({
                ...vendors,
                location: `${vendors?.address?.state}, ${vendors?.address?.country}`,
                customerName: vendors?.customerDetails?.customerId?.customerName,
                mobileNumber: vendors?.contactDetails?.mobileNumber,
                availableBalance: vendors?.availableBalance ? this.numberService.toFixed(vendors?.availableBalance) : "0",
              }));
          }
        }, (error) => {
            this.toastr.error(this.translateService.instant('Something went wrong!'));
        });
      }
    });
  }

  editVendor(id): void{
    this.router.navigate(['/purchases/vendors/create-vendor'], { queryParams: { id } });
  }

  deleteVendor(): void{
    this.spinner.show();
    this.vendorService.deleteVendor(this.vendorToDelete).subscribe((resp) => {
      this.spinner.hide();
      this.deleteConfirmationModal = false;
      if(resp.success){
        this.tableData = this.tableData.filter(item=>item._id != this.vendorToDelete);
        this.toastr.success(resp.message);
      }
    }, (error) => {
      if(error.status === 400){
        this.toastr.error('Cannot delete vendor');
      }
      else{
        this.spinner.hide();
        this.toastr.error(this.translateService.instant('Something went wrong!'));
      }
    });
  }

  deleteConfirmation(id): void {
    this.vendorToDelete = id;
    this.deleteConfirmationModal = true;
  }
}
