<div class="banner-landing h-screen flex items-center justify-center">
  <div class="md:w-5/6 mx-auto block max-w-[1440px]">

    <h3 class="text-center text-white Gilroy-Bold text-4xl pt-8 pb-8">{{'Find the right way to get in touch' | translate }}</h3>

    <form [formGroup]='contactForm' (ngSubmit)="submit()">
      <div class="flex md:flex-row flex-col space-y-3 md:space-y-0">
        <div class="flex-1 md:me-4">
          <label class="block text-white Gilroy-Bold text-sm mb-1">*{{'First Name' | translate }}</label>
          <input class="h-10 w-full rounded-lg bg-contact pl-2 placeholder-gray-100 text-white" formControlName='firstName' placeholder="{{'Enter First Name' | translate }}" />
          <span *ngIf='formErrors?.firstName' class="text-red-500 text-sm">{{formErrors?.firstName}}</span>
        </div>
        <div class="flex-1 md:me-4">
          <label class="block text-white Gilroy-Bold text-sm mb-1">*{{'Last Name' | translate}}</label>
          <input class="h-10 w-full  rounded-lg bg-contact pl-2 placeholder-gray-100 text-white" formControlName='lastName' placeholder="{{'Enter Last Name' | translate }}" />
          <span *ngIf='formErrors?.lastName' class="text-red-500 text-sm">{{formErrors?.lastName}}</span>
        </div>
        <div class="flex-1">
          <label class="block text-white Gilroy-Bold text-sm mb-1">*{{'Email Address' | translate }}</label>
          <input type="email" class="h-10 w-full  rounded-lg bg-contact pl-2 placeholder-gray-100 text-white" formControlName='email' placeholder="{{'Enter Email' | translate }}" />
          <span *ngIf='formErrors?.email' class="text-red-500 text-sm">{{formErrors?.email}}</span>
        </div>
      </div>
      <div class="mt-4">
        <div>
          <label class="block text-white Gilroy-Bold text-sm mb-1 ">{{'Message' | translate }}</label>
          <textarea formControlName='message' class="h-40 w-full rounded-lg bg-contact ps-2 placeholder-gray-100 text-white" placeholder="" ></textarea>
        </div>
      </div>
      <button type="submit" class="w-36 h-12 mx-auto block mt-4 mb-4 text-sm Gilroy-Bold bg-white rounded-2xl">{{'Send' | translate }}</button>
    </form>
 <!-- <p>By Submitting the form you agree to your terms. View our privacy policy to learn about how we use your information.</p> -->
  </div>
</div>

