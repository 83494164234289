<div class="flex justify-center p-4">
  <div class="w-full flex flex-col space-y-4">
    <div class="flex items-center justify-between">
      <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
        </svg>
        {{'Products & Services (Purchase)' | translate}}
      </h1>
      <app-button appAccessControl moduleType='purchase' accessType='edit' (click)='this.openAddProductModal = true' buttonText='{{"Add a product or service" | translate}}' type='primary' size='large'>
      </app-button>
    </div>
    <app-table-container [tableHeadings]='tableHeadings' [tableData]='tableData' [keys]='tableDataKeys' [actionTemplate]='actionTemplate'>
      <ng-template let-item #actionTemplate>
        <td appAccessControl moduleType='purchase' accessType='edit' class="py-4 last:pr-10 ">
          <div class="flex justify-center">
            <svg (click)='modalProductUpdate(item._id)'  xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 cursor-pointer mr-1" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
            </svg>
            <svg (click)='openDeleteConfirmation(item._id)' xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 cursor-pointer" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>
          </div>
        </td>
      </ng-template>
    </app-table-container>
  </div>
</div>

<app-modal-container [isOpen]='openAddProductModal'>
  <div *ngIf='!update' header class="border-b pb-2 Gilroy-Bold">{{'Add a Product or Service' | translate}}</div>
  <div *ngIf='update'  header class="border-b pb-2 Gilroy-Bold">{{'Update a Product or Service' | translate}}</div>
  <div content>
    <p class="text-sm text-start Gilroy-Bold">{{'Products Info' | translate}}
    </p>
    <form [formGroup]="createProductForm" class="mt-5 text-start">
      <div class="flex mb-3">
        <div class="w-1/5">
          <p>{{'Name:' | translate}}</p>
        </div>
        <div class="">
          <input type="text" formControlName="name" class="bg-input pl-2 h-10 border-0 rounded-xl" placeholder="" />
          <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.name'>
            {{formErrors.name}}</div>
        </div>
      </div>
      <div class="flex mb-3 w-full">
        <div class="w-1/5">
          <p>{{'Description:' | translate}}</p>
        </div>
        <div class="w-full">
          <textarea formControlName="description" class="bg-input pl-2 h-44 border-0 w-9/12 rounded-xl ml-5 mr-5"></textarea>
          <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.description'>
            {{formErrors.description}}</div>
        </div>
      </div>
      <div class="flex mb-3">
        <div class="w-1/5">
          <p>{{'Price:' | translate}}</p>
        </div>
        <div class="">
          <input type="number" formControlName="price" class="ps-2 border-0 bg-input h-10 rounded-xl" placeholder="" />
          <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.price'>
            {{formErrors.price }}</div>
        </div>
      </div>
      <div class="flex mb-3">
        <div class="w-1/5">
          <p>{{'Sales Tax' | translate}}</p>
        </div>
        <div class="flex-1">
          <ng-select class="custom-selector border-0 p-0" [items]="salesTaxes" 
            bindLabel="taxName"
            formControlName='tax'
            multiple='true'
            (change)='taxChange($event)'>
          </ng-select>
        </div>
      </div>
      <div class="flex mb-3 items-center">
        <div class="w-1/5">
          <p>{{'Expense Account' | translate }}</p>
        </div>
        <div class="flex-1">
          <ng-select class="custom-selector border-0 p-0" [items]="expenseAccounts" 
            bindLabel="accountName"
            formControlName = 'accountDetails'>
          </ng-select>
          <div  class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.accountDetails'>
            {{formErrors.accountDetails}}
          </div>
        </div>
      </div>


    </form>
  </div>
  <div footer class="flex w-full">
    <div class="w-1/5">
    </div>
    <div appAccessControl moduleType='purchase' accessType='edit' class="">
      <app-button *ngIf='!update' buttonText="{{'Save' | translate}}" (handleClick)="createProduct()" type="primary" rounded="full"></app-button>
      <app-button *ngIf='update'  buttonText="{{'Update' | translate}}"(handleClick)="updateProduct()" type="primary" rounded="full"></app-button>
      <app-button buttonText="{{'Cancel' | translate}}" type="third" (handleClick)='closeProductUpdateModal()' class="ms-2" rounded="full"></app-button>
    </div>
  </div>
</app-modal-container>


<app-modal-container [isOpen]='openDeleteConfirmModal'>
  <div header class="border-b pb-2 Gilroy-Bold">{{'Delete Product' | translate}}</div>
  <div content>{{'Are you sure?' | translate}}</div>
  <div footer class="flex items-center space-x-2">
      <app-button buttonText='{{"Delete" | translate}}' type='danger' rounded=true (handleClick)='deleteProduct()'></app-button>
      <app-button buttonText='{{"Cancel" | translate}}' type='third' rounded=true (handleClick)='openDeleteConfirmModal = false'></app-button>
  </div>
</app-modal-container>
