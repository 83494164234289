import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { ChatsService } from 'src/app/modules/chats/chats.service';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectUser } from 'src/app/store/selectors/user.selectors';
import { UserService } from '../../services/user.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DropdownComponent } from '../utils/dropdown/dropdown.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('dropdown', { static: false }) dropdown: DropdownComponent;
  notificationsCount: any;
  allNotifications: any;
  userDetails: any;
  user$: Observable<any>;
  business$: Observable<any>;
  businessId;
  searchText: any;
  searchTerms = new Subject<string>();
  searchResult: any;
  pageTitle = 'Dashboard';
  currencyDetails: any;
  isTooltipDisabled = false;
  reports = [
    "Balance Sheet",
    "Profit & Loss (Income Statement)",
    "Income by Customer",
    "Purchases by Vendor",
    "Aged Receivables",
    "Aged Payables",
    "Trial Balances",
    "Partial Trial Balances",
    "Account Transactions",
    "Sales Tax",
    "Sales Tax Transaction Report",
    "Assets Report",
    "Cash Flow",
    "Stock Summary",
    "Low Stock Summary",
    "Item Summary",
    "Cashier Attendance",
    "Cashier Sales-Items",
    "Cashier Sales-Invoices"
  ];
  routes = {
    'Profit & Loss (Income Statement)': 'profit-loss',
    'Balance Sheet': 'balance-sheet',
    'Cash Flow': 'cash-flow',
    'Sales Tax': 'sales-tax',
    'Sales Tax Transaction Report': 'sales-tax-transaction',
    'Income by Customer': 'income-by-customer',
    'Aged Receivables': 'aged-receivables',
    'Purchases by Vendor': 'purchases-by-vendor',
    'Aged Payables': 'aged-payables',
    'Trail Balances': 'trial-balances',
    // 'Trial Balance': 'trial-balance',
    'Partial Trial Balances': 'partial-trial-balances',
    'Account Transactions (General Ledger)': 'account-transactions',
    'Assets Report': 'assets',
    'Stock Summary': 'stock-summary',
    'Low Stock Summary': 'low-stock',
    'Item Summary': 'item-summary',
    'Cashier Attendance' : 'cashier-attendance',
    'Cashier Sales - Items' : 'cashier-sales-items',
    'Cashier Sales - Invoices' : 'cashier-sales-invoices'
  };

  constructor(
    private spinner: NgxSpinnerService,
    private userService: UserService,
    private translateService: TranslateService,
    private router: Router,
    private chatService: ChatsService,
    private activatedRoute: ActivatedRoute,
    private route: Router,
    private deviceService: DeviceDetectorService,
    private store: Store<RootReducerState>
  ) {
    this.user$ = this.store.pipe(select(selectUser));
    this.business$ = this.store.pipe(select(selectBusiness));
    this.setPageTitle();
  }

  ngOnInit(): void {
    this.setTitle({ url: this.router.url });
    this.chatService.listen('getAllNotifications').subscribe((data) => {
      this.allNotifications = data
        .filter(
          (item) =>
            (item.toUser?.includes(this.userDetails?._id) ||
              item.toUser === this.userDetails?._id) &&
            (item.fromUser !== this.userDetails?._id ||
              (item.fromUser === this.userDetails?._id &&
                item.entityType === 'taskReminder')) &&
            item.businessId === this.businessId
        )
        .slice()
        .reverse();
      this.notificationsCount = this.allNotifications.filter(
        (item) => !item.seen
      ).length;
    });

    this.user$.subscribe((user) => {
      if (user) {
        this.userDetails = user;
        this.chatService.emit('getAllNotifications', this.userDetails._id);
      }
    });

    this.business$.subscribe((business) => {
      this.businessId = business?.businessId?._id;
      this.currencyDetails = {
        currencySymbol: business?.businessId?.currencySymbol,
        decimal: business?.businessId?.decimalSize,
      };
    });

    this.searchTerms
      .pipe(
        debounceTime(300), // Wait for 300ms pause in events
        distinctUntilChanged() // Ignore if the next search term is same as previous
      )
      .subscribe((term) => {
        console.log('distinctUntilChanged', term);
        if (term.length > 3) {
          this.getFilteredMessages(term);
        } else {
          this.searchResult = [];
        }
      });
  }

  onSearch(term: string): void {
    console.log('onSearch called with term:', term);
    this.searchTerms.next(term);
    console.log('searchTerms emitted term:', term);
  }

  getFilteredMessages(value): void {
    this.userService
      .getGlobalSearchResult(this.businessId, value)
      .subscribe((result) => {
        if (result.status === 200 && result.data && result.data.length > 0) {
          this.searchResult = result.data;
        } else {
          const matchingReports = this.reports.filter(title =>
            title.toLowerCase().includes(value.toLowerCase())
          );
          const result = [{
            name: matchingReports[0],
            type: 'report'
          }]
          this.searchResult = result;
        }
        console.log("matchingReports", this.searchResult)
      });
  }

  setPageTitle(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // this.currentRoute = event.urlAfterRedirects;
        console.log('setPageTitle', event.url);
        this.setTitle(event);
      }
    });
  }
  setTitle(event) {
    if (event.url === '/dashboard') {
      this.pageTitle = 'Dashboard';
    } else if (event.url === '/purchases/expenses') {
      this.pageTitle = 'Expenses';
    } else if (event.url === '/purchases/expenses/create-expense') {
      this.pageTitle = 'Create Expense';
    } else if (event.url.includes('/purchases/expenses/create-expense?id=')) {
      this.pageTitle = 'Update Expense';
    } else if (event.url === '/purchases/purchase-order') {
      this.pageTitle = 'Purchase Order';
    } else if (event.url === '/purchases/bills') {
      this.pageTitle = 'Bills';
    } else if (event.url === '/purchases/bills/create-bill') {
      this.pageTitle = 'Add Bill';
    } else if (event.url?.includes('/purchases/bills/create-bill?')) {
      this.pageTitle = 'Update Bill';
    } else if (event.url === '/purchases/recurring-bill') {
      this.pageTitle = 'Recurring-Bills';
    } else if (event.url === '/purchases/recurring-bill/create-bill') {
      this.pageTitle = 'New Recurring Bill';
    } else if (
      event.url.includes('/purchases/recurring-bill/create-bill?id=')
    ) {
      this.pageTitle = 'Update Recurring Bill';
    } else if (event.url === '/purchases/vendors') {
      this.pageTitle = 'Vendors';
    } else if (event.url === '/purchases/vendors/create-vendor') {
      this.pageTitle = 'Add Vendor';
    } else if (event.url.includes('/purchases/vendors/create-vendor?id=')) {
      this.pageTitle = 'Update Vendor';
    } else if (
      event.url.includes('/purchases/purchase-order/create-purchase-order?id=')
    ) {
      this.pageTitle = 'Update Purchase Order';
    } else if (
      event.url === '/purchases/purchase-order/create-purchase-order'
    ) {
      this.pageTitle = 'Add New Purchase Order';
    } else if (
      event.url.includes('/purchases/purchase-order/view-purchase-order?id=')
    ) {
      this.pageTitle = 'View Purchase Order';
    } else if (event.url === '/purchases/assets') {
      this.pageTitle = 'Assets';
    } else if (event.url === '/sales/estimates') {
      this.pageTitle = 'Estimates';
    } else if (event.url === '/sales/estimates/create-estimate') {
      this.pageTitle = 'Add a Estimate';
    } else if (event?.url?.includes('/sales/estimates/create-estimate?id=')) {
      this.pageTitle = 'Update a Estimate';
    } else if (event.url === '/sales/invoices') {
      this.pageTitle = 'Invoices';
    } else if (event.url === '/sales/invoices/create-invoice') {
      this.pageTitle = 'Add a Invoice';
    } else if (event.url.includes('/sales/invoices/create-invoice?id=')) {
      this.pageTitle = 'Update Invoice';
    } else if (event.url === '/sales/recurring-invoices') {
      this.pageTitle = 'Recurring-Invoices';
    } else if (event.url === '/sales/recurring-invoices/create-invoice') {
      this.pageTitle = 'New Recurring Invoice';
    } else if (event.url === '/sales/cash-invoice') {
      this.pageTitle = 'Cash-Invoices';
    } else if (event.url === '/sales/cash-invoice/create-cash-invoice') {
      this.pageTitle = 'Add a Invoice';
    } else if (event.url === '/sales/customers') {
      this.pageTitle = 'Customers';
    } else if (event.url === '/sales/customers/create-customer') {
      this.pageTitle = 'Add New Customer';
    } else if (event.url?.includes('/sales/customers/create-customer?id=')) {
      this.pageTitle = 'Update Customer';
    } else if (event.url === '/sales/customers-statement') {
      this.pageTitle = 'Customers Statement';
    } else if (event.url === '/inventory/single-product') {
      this.pageTitle = 'Inventory';
    } else if (event.url === '/inventory/add-item') {
      this.pageTitle = 'Add a Product or Service';
    } else if (event.url.includes('/inventory/add-item?productId=')) {
      this.pageTitle = 'Update a Product or Service';
    } else if (event.url === '/inventory/bundled-products') {
      this.pageTitle = 'Complex Products Inventory';
    } else if (event.url === '/inventory/add-bundled-item') {
      this.pageTitle = 'Complex Products Inventory';
    } else if (event.url.includes('/inventory/add-bundled-item?productId=')) {
      this.pageTitle = 'Update a Product or Service';
    } else if (event.url === '/inventory/production-run') {
      this.pageTitle = 'Production Run';
    } else if (event.url === '/inventory/write-off') {
      this.pageTitle = 'Write Off Products';
    } else if (event.url === '/accounting/transaction') {
      this.pageTitle = 'Transactions';
    } else if (event.url === '/accounting/journal-entry') {
      this.pageTitle = 'Journal Voucher';
    } else if (event.url === '/accounting/recurring-jv') {
      this.pageTitle = 'Recurring-JV';
    } else if (event.url === '/accounting/recurring-jv/create-recurring-jv') {
      this.pageTitle = 'Create Recurring-JV';
    } else if (event.url === '/accounting/charts-of-account') {
      this.pageTitle = 'Charts of Accounts';
    } else if (event.url === '/projects/list') {
      this.pageTitle = 'Projects';
    } else if (event.url === '/plus/budget') {
      this.pageTitle = 'P/L Budget';
    } else if (event.url === '/plus/financedashboard') {
      this.pageTitle = 'P/L Analysis';
    } else if (event.url === '/plus/salesdashboard') {
      this.pageTitle = 'Sales Dashboard';
    } else if (event.url === '/reports') {
      this.pageTitle = 'Reports';
    } else if (event.url === '/experts/hire-expert') {
      this.pageTitle = 'Hire an Expert';
    } else if (event.url === '/experts/enquiry-history') {
      this.pageTitle = 'Enquiry History';
    } else if (event.url === '/storage') {
      this.pageTitle = 'Cloud Storage';
    } else if (event.url === '/chats') {
      this.pageTitle = 'Chats';
    } else if (event.url === '/task') {
      this.pageTitle = 'Task Manager';
    } else if (event.url === '/files') {
      this.pageTitle = 'File Manager';
    } else if (event.url.includes('/time-sheet')) {
      this.pageTitle = 'Time Sheet';
    }else if (event.url.includes('/view-cash-invoice')) {
      this.pageTitle = 'View Cash Invoice';
    }else if (event.url.includes('/view-customer')) {
      this.pageTitle = 'View Customer';
    }
  }

  disableTootip(): void {
    this.isTooltipDisabled = !this.isTooltipDisabled;
    this.userService.toolTipDisabled.next(this.isTooltipDisabled);
  }

  redirectToResult(item): void {
    console.log('redirectToResult', item);
    if (item.type === 'invoice') {
      // window.open(`/sales/invoices/view-invoice?id=${item._id}`, "_self")
      // this.router.navigate([`/sales/invoices/view-invoice?id=${item._id}`])
      this.router.navigate(['/sales/invoices/view-invoice'], {
        queryParams: { id: item._id },
      });
      setTimeout(() => {
        this.dropdown.open = false;
        this.searchText = '';
        this.searchResult = [];
      });
    }
    if (item.type === 'customer') {
      this.router.navigate(['/sales/customers/view-customer'], {
        queryParams: { id: item._id },
      });
      setTimeout(() => {
        this.dropdown.open = false;
        this.searchText = '';
        this.searchResult = [];
      });
    }
    if (item.type === 'vendor') {
      this.router.navigate(['/purchases/vendors/create-vendor'], {
        queryParams: { id: item._id },
      });
      setTimeout(() => {
        this.dropdown.open = false;
        this.searchText = '';
        this.searchResult = [];
      });
    }
    if (item.type === 'purchase') {
      this.router.navigate(['/purchases/purchase-order/view-purchase-order'], {
        queryParams: { id: item._id },
      });
      setTimeout(() => {
        this.dropdown.open = false;
        this.searchText = '';
        this.searchResult = [];
      });
    }
    if (item.type === 'recurringInvoice') {
      this.router.navigate(['/sales/recurring-invoices/view-template'], {
        queryParams: { id: item._id },
      });
      setTimeout(() => {
        this.dropdown.open = false;
        this.searchText = '';
        this.searchResult = [];
      });
    }
    if (item.type === 'cashInvoice') {
      this.router.navigate(['/sales/cash-invoice/view-cash-invoice'], {
        queryParams: { id: item._id },
      });
      setTimeout(() => {
        this.dropdown.open = false;
        this.searchText = '';
        this.searchResult = [];
      });
    }
    if (item.type === 'complex') {
      this.router.navigate(['/inventory/add-bundled-item'], {
        queryParams: { productId: item._id },
      });
      setTimeout(() => {
        this.dropdown.open = false;
        this.searchText = '';
        this.searchResult = [];
      });
    }
    if (item.type === 'normal') {
      this.router.navigate(['/inventory/add-item'], {
        queryParams: { productId: item._id },
      });
      setTimeout(() => {
        this.dropdown.open = false;
        this.searchText = '';
        this.searchResult = [];
      });
    }
    if (item.type === 'project') {
      this.router.navigate([`/projects/${item._id}`]);
      setTimeout(() => {
        this.dropdown.open = false;
        this.searchText = '';
        this.searchResult = [];
      });
    }
    if (item.type === 'document') {
      this.router.navigate([`/storage/folder/${this.capitalize(item.documentType)}`]);
      setTimeout(() => {
        this.dropdown.open = false;
        this.searchText = '';
        this.searchResult = [];
      });
    }
    if (item.type === 'report') {
      this.router.navigate([`/reports/view/${this.routes[item.name]}`]);
      setTimeout(() => {
        this.dropdown.open = false;
        this.searchText = '';
        this.searchResult = [];
      });
    }
  }

  capitalize(s) {
    return s[0]?.toUpperCase() + s?.slice(1);
  }

  clearSearch(): void {
    setTimeout(() => {
      this.dropdown.open = false;
      this.searchText = '';
      this.searchResult = [];
    });
  }

  goToNotification(item){
    console.log(item);
    switch (item.entityType) {
      case "taskAssigned":
        const payload = {
          seen: true,
          updateId: item._id
        }
        this.chatService.emit('updateNotification', payload);
        this.router.navigate([`/task/view-task/${item.entityId}`]);
        break;
      case "sharedFile":
        const payloadFour = {
          seen: true,
          updateId: item._id
        }
        this.chatService.emit('updateNotification', payloadFour);
        this.router.navigate([`/files/shared`]);
      break;
      case "sharedFile":
        const payloadThree = {
          seen: true,
          updateId: item._id
        }
        this.chatService.emit('updateNotification', payloadThree);
        this.router.navigate([`/files/shared`]);
        break;
      case "taskReminder":
        const payloadTwo = {
          seen: true,
          updateId: item._id
        }
        this.chatService.emit('updateNotification', payloadTwo);
        this.router.navigate([`/task/view-task/${item.entityId}`]);
      break;
      case "mention":
        const payloadOne = {
          seen: true,
          updateId: item._id
        }
        this.chatService.emit('updateNotification', payloadOne);
        this.chatService.setredirectionChatId(item.entityId);
        this.router.navigate([`/chats`]);
        break;
    }

  }
}
