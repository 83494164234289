import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/shared/services/user.service';
import { RootReducerState } from 'src/app/store/reducers';
import { selectUsage } from 'src/app/store/selectors/usage.selector';
import { SubscriptionService } from '../subscription.service';
import { setUsersSubscription } from 'src/app/store/actions/subscription.actions';
import { StripePaymentElementComponent, StripeService } from 'ngx-stripe';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-subscription-list',
  templateUrl: './subscription-list.component.html',
  styleUrls: ['./subscription-list.component.scss']
})
export class SubscriptionListComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(private subscriptionService: SubscriptionService,
              private spinner: NgxSpinnerService,
              private toastr:ToastrService,
              private stripeService: StripeService,
              private store: Store<RootReducerState>,
              private cdr: ChangeDetectorRef,
              private route: ActivatedRoute,
              private userService: UserService) {
                this.usage$ = store.pipe(select(selectUsage))
               }
  @ViewChild('Budget') Budget: TemplateRef<any>;
  @ViewChild('Payroll') Payroll: TemplateRef<any>;
  @ViewChild('Inventory') Inventory: TemplateRef<any>;
  @ViewChild('Recurring') Recurring: TemplateRef<any>;
  @ViewChild('Storage') Storage: TemplateRef<any>;
  @ViewChild(StripePaymentElementComponent) paymentElement: StripePaymentElementComponent;
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: localStorage.getItem('NuMetric|lang') === 'en' ? 'en' : 'ar',
  };

  usage$: Observable<any>;
  subscriptionPlans = [];
  transactionPlans = [];
  storagePlans = [];
  storagePlan;
  allSubscriptionPlans = [];
  unsubscribe$ = new Subject();
  currentSubscription = null;
  currentTransactionSubscription =  null;
  currentStorageSubscription =  null;
  cancelSubscriptionPlanModal = false;
  confirmSubscriptionPlanModal: boolean = false;
  starterPlanModal = false;
  showDowngradeModal = false;
  showCardModal: boolean = false;
  downgradeCheck = {
    businessLimit: true,
    transactionLimit: true,
    adminLimit: true,
    supportEmail: true
  }
  newPlan;
  currentUsage;
  clientSecret: string = '';
  planToSubscribe: string = '';
  planToCancel: string = '';

  ngOnInit(): void {
    this.cdr.detectChanges();
    this.loadSubscriptionPlans();
    this.loadCurrentSubscription();
    this.route.queryParams.subscribe(params => {
      if(params?.planId){
        this.planToSubscribe = params?.planId;
        this.getSubscriptionLink();
      }
    })
  }

  loadSubscriptionPlans(): void {
    this.subscriptionService.subscriptionPlans$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((plans) => {
      plans = plans.filter(plan => plan?.planName !== 'Starter')     
      this.subscriptionPlans = plans?.filter(plan => !plan?.planName?.startsWith('Transaction')&& !plan?.planName?.startsWith('Files')).sort((a, b) => a.planPrice - b.planPrice);
      this.transactionPlans = plans?.filter(plan => plan?.planName?.startsWith('Transaction'));
      this.storagePlans = plans.filter(plan => plan?.planName?.startsWith('Files'));
      this.allSubscriptionPlans = [...plans];
      this.cdr.detectChanges();
      this.loadCurrentSubscription();
    }, (error) => {
      console.log(error);
    });
  }

  ngAfterViewInit(): void {
    
  }

  loadCurrentSubscription(): void {
    this.subscriptionService.currentSubscription$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((subscription: any) => {
      if (subscription?.subscriptionId) {
        this.currentSubscription = subscription;
        this.currentTransactionSubscription = subscription?.subscriptions?.find(sub => sub.subHost === 'Transaction');
        this.currentStorageSubscription = subscription?.subscriptions?.find(sub => sub.subHost === 'Storage');
      }
    });
  }

  // subscribePlan(plan): void {    
  //   this.spinner.show()
  //   if(this.currentSubscription?.planPrice > plan?.planPrice ){
  //     this.newPlan = plan;
  //     this.usage$.subscribe(resp => {
  //         this.currentUsage = resp?.currentUsage;
  //         Object.keys(this.downgradeCheck).forEach(key => {
  //           this.downgradeCheck[key] = plan?.features[key]  >= this.currentUsage[key];
  //         });
  //         Object.keys(this.downgradeCheck).forEach(key => {
  //           if(!this.downgradeCheck[key]){
  //             this.showDowngradeModal = true;
  //             return;
  //           }
  //         })
  //         this.spinner.hide()
  //         if(!this.showDowngradeModal){
  //           console.log(plan,'plan');
  //           debugger
  //           this.getSubscriptionLink(plan);
  //         }
  //        })
  //     }
  //   else if (this.currentSubscription?.planPrice !== 0 && this.currentSubscription?.planPrice < plan.planPrice ) {
  //     this.allSubscriptionPlans = this.allSubscriptionPlans.filter(({isDiscount}) => !isDiscount);
  //     plan = this.allSubscriptionPlans.find(({planName, isHidden, reference = ''}) => (isHidden && planName === plan.planName && (reference).toLowerCase() === (this.currentSubscription?.planName).toLowerCase()));
  //     this.spinner.hide();
  //     this.getSubscriptionLink(plan);
  //   }
  //   else {
  //     this.getSubscriptionLink(plan);
  //   }
  // }

  subscribeFreePlan(): void {
    const freePlan = this.allSubscriptionPlans.find(({planPrice}) => planPrice === 0);
    const body = {
      subscriptionId: freePlan._id,
      planName: freePlan.planName,
      planPrice: freePlan.planPrice,
    }
    this.spinner.show();
    this.subscriptionService.subscribeFreePlan(body).subscribe((resp) => {
      this.spinner.hide();
      if(resp.success) {
        this.userService.setUserDetails(resp.data);
      }
    }, (error) => {
      console.log(error);
      this.spinner.hide();
    })
  }

  // getSubscriptionLink(plan): void {
  //   let body = {
  //     id: plan._id,
  //     planId: plan.paypalData.planId,
  //     expiryDate: this.currentSubscription.expiryDate
  //   };
  //   this.spinner.show();
  //   this.subscriptionService.getSubscriptionLink(body).subscribe(resp => {
  //     this.spinner.hide();
  //     const url = resp.data.links[0].href;
  //     window.location.href = url;
  //   }, error => {
  //     this.spinner.hide();
  //     console.log(error);
  //   });
  // }

  showConfiramtionDialoge(plan): void {
    this.confirmSubscriptionPlanModal = true;
    this.planToSubscribe = plan?._id;
  }

  getSubscriptionLink(): void {
    let body = {
      planId: this.planToSubscribe
    };
    this.spinner.show();
    this.subscriptionService.getSubscriptionLink(body).subscribe(resp => {
      if(resp?.success){
        if(!resp?.data?.isCard){
          this.elementsOptions.clientSecret = resp?.data?.clientSecret;
          this.clientSecret = resp?.data?.clientSecret;
          this.showCardModal = true;
        }
        else{
          this.toastr.success(resp?.message);
          this.store.dispatch(setUsersSubscription({subscription: resp?.data?.currentSubscription}));
          this.subscriptionService.setCurrentSubscription(resp?.data?.currentSubscription);
        }
        this.confirmSubscriptionPlanModal = false;
      }
      else{
        this.toastr.error(resp?.message);
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.message);
    });
  }

  pay() {
    this.spinner.show();
    this.stripeService.confirmSetup({
      elements: this.paymentElement.elements,
      redirect: 'if_required'
    }).subscribe((result: any) => {
      if (result.error) {
        this.toastr.error(result?.error?.message);
        this.spinner.hide();
      } else {
        // The payment has been processed!
        if (result.setupIntent.status === 'succeeded') {
          const payload = {
            paymentId: result?.setupIntent?.payment_method,
            planId: this.planToSubscribe
          }
          this.subscriptionService.createPaymentMethod(payload)
          .subscribe(resp => {
            if(resp?.success){
              this.spinner.hide();
              this.toastr.success(resp?.message);
              this.showCardModal = false;
              this.planToSubscribe = '';
              this.store.dispatch(setUsersSubscription({subscription: resp?.data?.currentSubscription}))
              this.subscriptionService.setCurrentSubscription(resp?.data?.currentSubscription);
            }
            else{
              this.spinner.hide();
              this.toastr.error(resp?.message);
            }
          }, (err) => {
            this.spinner.hide();
            this.toastr.error(err?.error?.message)
          })
        }
      }
    });
}

confirmCancelPlan(plan): void {
  this.planToCancel = plan?._id;
  this.cancelSubscriptionPlanModal = true;
}
  
  cancelPlan(): void {
    const body ={
      subId: this.currentSubscription?.subId
    }
    this.spinner.show();
    let planId = this.currentSubscription?.subscriptions?.find(sub => sub?.subId === this.planToCancel)?._id;
    if(!planId){
      this.toastr.error('Plan does not exist');
      return
    }
    this.subscriptionService.cancelPlan(planId)
    .subscribe(resp => {
      if(resp?.success){
        this.toastr.success(resp?.message);
        this.cancelSubscriptionPlanModal = false;
        this.planToCancel = '';
        this.store.dispatch(setUsersSubscription({subscription: resp?.data?.currentSubscription}))
        this.subscriptionService.setCurrentSubscription(resp?.data?.currentSubscription);
      }
      else{
        this.toastr.error(resp?.message);
      }
      this.spinner.hide();
    },(err) =>{
      this.spinner.hide();
      this.toastr.error(err?.error?.message || 'Something went wrong');
    })
  }

  injectPlan(){
    const plan = this.allSubscriptionPlans.find(({planPrice}) => planPrice === 0);
    let body = {
      planDetails: plan,
     }
    this.spinner.show();
    this.subscriptionService.subscribeStarter(body).subscribe(resp => {
      this.starterPlanModal = false;
      this.loadCurrentSubscription();
      this.spinner.hide();


    }, error => {
      this.spinner.hide();
      this.starterPlanModal = false;
      this.toastr.error('Something went wrong');
      console.log(error);
    });

  }

  isSubscribed(planId){
    let plan = this.currentSubscription?.subscriptions?.find(sub => sub?.subId === planId );
    return plan ? true : false;
  }

  isCanceled(planId){
    let plan = this.currentSubscription?.subscriptions?.find(sub => sub?.subId === planId );
    console.log(plan?.isCanceled, 'cancel plan');
    
    return plan?.isCanceled ?? false;
  }


  getLastDate(planId){
    let plan = this.currentSubscription?.subscriptions?.find(sub => sub?.subId === planId );
    console.log(plan);
    
    return plan?.expiryDate
  }



  updatePlan(plan, isUpgrade: boolean): void {
    if(!plan){
      this.toastr.error('Plan not exist');
      return;
    }
    let payload = {
      planId: plan?._id,
      isUpgrade
    }
    this.spinner.show();
    this.subscriptionService.updateSubscriptionPlan(payload)
    .subscribe(resp => {
      if(resp?.success){
        this.toastr.success(resp?.message);
        this.store.dispatch(setUsersSubscription({subscription: resp?.data?.currentSubscription}))
        this.subscriptionService.setCurrentSubscription(resp?.data?.currentSubscription);
      }
      else{
        this.toastr.error(resp?.message);
      }
      this.spinner.hide();
    }, (err) => {
      this.toastr.error(err?.error?.message);
      this.spinner.hide();
    })
  }

  updateQuantity(plan): void {
    const subId = this.currentSubscription?.subscriptions?.find(sub => sub?.subId === plan?._id)?._id
    let payload = {
      subId
    }
    this.spinner.show();
    this.subscriptionService.updateQuantity(payload)
    .subscribe(resp => {
      if(resp?.success){
        this.toastr.success(resp?.message);
        this.store.dispatch(setUsersSubscription({subscription: resp?.data?.currentSubscription}))
        this.subscriptionService.setCurrentSubscription(resp?.data?.currentSubscription);
      }
      else{
        this.toastr.error(resp?.message);
      }
      this.spinner.hide();
    }, (err) => {
      this.toastr.error(err?.error?.message);
      this.spinner.hide();
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
