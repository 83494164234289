import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { toFixed } from 'src/app/shared/utils/numberFormatting';
import { ReportsService } from '../../reports.service';
import { groupBy, last } from 'lodash';
import { select, Store } from '@ngrx/store';
import { RootReducerState } from 'src/app/store/reducers';
import { Observable, Subject } from 'rxjs';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { CurrencyPipe } from '@angular/common';
import { selectProjects } from 'src/app/store/selectors/project.selector';
import { selectAccounts } from 'src/app/store/selectors/account.selector';
import { selectUser } from 'src/app/store/selectors/user.selectors';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectCustomizationSettings } from 'src/app/modules/sales/state/selectors/customizationSettings.selector';
import { NumberService } from 'src/app/shared/services/number.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { selectProducts } from 'src/app/modules/sales/state/selectors/products.selector';

interface IConfig {
  tableHeadings: Array<any>,
  tableData: Array<any>,
  tableKeys: Array<any>,
  templateName: string,
  startDate: string,
  endDate: string,
  data?: any
}
@Component({
  selector: 'app-reports-container',
  templateUrl: './reports-container.component.html',
  styleUrls: ['./reports-container.component.scss'],
  providers: [CurrencyPipe]
})


export class ReportsContainerComponent implements OnInit, OnDestroy {

  constructor(private route: ActivatedRoute,
              private reportsService: ReportsService,
              private spinner: NgxSpinnerService,
              private toaster: ToastrService,
              private store: Store<RootReducerState>,
              private languageService: LanguageService,
              private translateService: TranslateService,
              private numberService: NumberService,
              private currenyPipe: CurrencyPipe) {
                this.projects$ = this.store.pipe(select(selectProjects));
                this.accounts$ = this.store.pipe(select(selectAccounts));
                this.user$ = this.store.pipe(select(selectUser));
                this.business$ = this.store.pipe(select(selectBusiness));
                this.customizationSettings$ = this.store.pipe(select(selectCustomizationSettings));
                this.products$ = store.pipe(select(selectProducts));
              }
  reportType = {
    title: null,
    method: null,
    templateName: null
  };

  @ViewChild('FilterForm') FilterForm;
  methodToCall;
  businessId = null;
  projectId = null
  unsubscribe = new Subject();
  currencyDetails = null;
  number = '1.2-2';
  selectedProduct = '';
  lastDate = true;
  asOfDate = true;
  projectsFilter = false;
  accountsFilter = false;
  contactsFilter = false;
  itemsFilter = false;
  depreciationFilter = false;
  assetsAccountFilter = false;
  partialTrialFilter = false;
  salesTaxTransaction = false;
  cashierFilter = false;
  projects$: Observable<any>;
  accounts$: Observable<any>;
  user$: Observable<any>;
  business$: Observable<any>;
  products$: Observable<any>;
  customizationSettings$: Observable<any>;

  user = null;

  projects = [];
  accounts = [];
  contacts = [];
  products = [];
  cashiers = [];
  assetAccounts = [];

  fieldNames;

  routes = {
    'balance-sheet': {
      title: 'Balance Sheet',
      method: this.getBalanceSheet,
      templateName: 'normal',
    },
    'profit-loss': {
      title: 'Profit & Loss (Income Statement)',
      method: this.getProfitLoss,
      templateName: 'normal',
    },
    'income-by-customer': {
      title: 'Income by Customer',
      method: this.getIncomeByCustomer,
      templateName: 'normal',
    },
    'purchases-by-vendor': {
      title: 'Purchases by Vendor',
      method: this.getPurchasesByVendor,
      templateName: 'normal',
    },
    'aged-receivables': {
      title: 'Aged Receivables',
      method: this.getAgedRecieveables,
      templateName: 'normal',
    },
    'aged-payables': {
      title: 'Aged Payables',
      method: this.getAgedPayable,
      templateName: 'normal',
    },
    'trial-balances': {
      title: 'Trial Balances',
      method: this.getAccountBalances,
      templateName: 'normal',
    },
    'partial-trial-balances': {
      title: 'Partial Trial Balances',
      method: this.getPartialAccountBalances,
      templateName: 'normal',
    },
    // 'trial-balance': {
    //   title: 'Trial Balance',
    //   method: this.getTrialBalance,
    //   templateName: 'normal',
    // },
    'account-transactions': {
      title: 'Account Transactions',
      method: this.getAccountTransactions,
      templateName: 'accountTransactions',
    },
    'sales-tax': {
      title: 'Sales Tax',
      method: this.getSalesTax,
      templateName: 'salesTaxes'
    },
    'sales-tax-transaction': {
      title: 'Sales Tax Transaction Report',
      method: this.getSalesTransactionTax,
      templateName: 'normal'
    },
    'assets': {
      title: 'Assets Report',
      method: this.getAssets,
      templateName: 'assets'
    },
    'cash-flow': {
      title: 'Cash Flow',
      method: this.getCashFlow,
      templateName: 'cash'
    },
    'stock-summary': {
      title: 'Stock Summary',
      method: this.getStockSummary,
      templateName: 'normal'
    },
    'low-stock': {
      title: 'Low Stock Summary',
      method: this.getLowStockSummary,
      templateName: 'normal'
    },
    'item-summary': {
      title: 'Item Summary',
      method: this.getItemDetails,
      templateName: 'normal'
    },
    'cashier-attendance': {
      title: 'Cashier Attendance',
      method: this.getCashierAttendance,
      templateName: 'normal'
    },
    'cashier-sales-items': {
      title: 'Cashier Sales-Items',
      method: this.getCashierSalesItems,
      templateName: 'normal'
    },
    'cashier-sales-invoices': {
      title: 'Cashier Sales-Invoices',
      method: this.getCashierSalesInvoices,
      templateName: 'normal'
    },
  };

  config: any = {
    tableHeadings: [],
    tableData: [],
    tableKeys: [],
    templateName: '',
    startDate: null,
    endDate: null,
  };


  fiscalDate = null;

  stats = [];
  allAssetsSection;
  allTaxes;
  business = null;
  customizationSettings = null;
  cashierActivity:any;
  activities:any = []
  ngOnInit(): void {
    this.spinner.show();
    this.route.params
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(({type}) => {
      if (type) {
        const asOfReports = ['balance-sheet','aged-payables', 'aged-receivables', 'stock-summary', 'low-stock'];
        if (asOfReports.includes(type)) {
          this.lastDate = false;
        }
        if(type === 'trial-balance'){
          this.lastDate =true
        }
        if(type === 'stock-summary' || type === 'low-stock'){
          this.asOfDate = false;
        }
        if (type === 'profit-loss') {
          this.projectsFilter = true;
        }
        if (type === 'account-transactions') {
          this.accountsFilter = true;
        }
        if (type === 'item-summary') {
          this.itemsFilter = true;
        }
        if (type === 'assets') {
          this.depreciationFilter = true;
          this.assetsAccountFilter = true;
        }
        if(type === 'partial-trial-balances') {
          this.partialTrialFilter = true
        }
        if(type === 'sales-tax-transaction') {
          this.salesTaxTransaction = true
        }
        if(type === 'cashier-attendance') {
          this.cashierFilter = true
        }
        if(type === 'cashier-sales-items') {
          this.cashierFilter = true
        }
        if(type === 'cashier-sales-invoices') {
          this.cashierFilter = true
        }

        this.reportType = this.routes[type];
        this.methodToCall = this.routes[type].method;
        console.log("........",this.routes[type])
      }
    });
    this.route.queryParams.pipe(takeUntil(this.unsubscribe))
    .subscribe(params => {
      if(params?.itemId){
        this.selectedProduct = params?.itemId;
      }
    })

    this.business$
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((business) => {
      if(business?.businessId?._id){
        this.reportsService.setBusinessId(business?.businessId?._id)
        this.business = business.businessId;
        this.businessId = business.businessId?._id;
        if(this.businessId){
          this.reportsService.getAccountSection(this.businessId).subscribe((result)=>{
            console.log("resultgetAccountSection", result);
            this.allAssetsSection = result.data;
            // this.allAssetsSection.accountDetails = Object.values(this.allAssetsSection.accountDetails.reduce((acc, curr) => {
            //   // Use accountType as key to check uniqueness
            //   acc[curr.accountType] = curr;
            //   return acc;
            // }, {}));
          })
          this.reportsService.getAllTaxes(this.businessId).subscribe((result)=>{
            console.log("allTaxes", result);
            this.allTaxes = result.data;
          })
        }
        const currencyDetails = (({ currency, currencySymbol }) => ({ currency, currencySymbol }))(business?.businessId);
        this.reportsService.setCurrencyDetails(currencyDetails);
        this.loadProducts();
        this.loadCashiers();
        this.fiscalDate = moment(`${this.business.accounting.date} ${this.business.accounting.month} 2022`).format('YYYY-MM-DD')
        this.FilterForm?.emitFormData();
        this.reportsService.getInvoiceCustomizationSettings().subscribe((resp) => {
          if (resp.success) {
            this.customizationSettings = resp.data;
          }
        });

      }
    });


    this.reportsService.currencyDetails$
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((details) => {
      if (details) {
        this.currencyDetails = details;
      }
    });
    this.projects$
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((projects) => {
      this.projects = projects;
    });
    this.user$
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((user) => {
      this.user = user;
    });
    this.accounts$
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((accounts) => {
      this.accounts = accounts;
      const contactsAcc = ['Accounts Receivables', 'Accounts Payables'];
      this.contacts = accounts.filter((account) => contactsAcc.includes(account.accountType));
      const assetsAcc = ['Property, Plant, Equipment'];
      this.assetAccounts = accounts.filter((account) => assetsAcc.includes(account.accountType));
    });
    // this.business$
    // .pipe(takeUntil(this.unsubscribe))
    // .subscribe((business) => {
    //   if(business?.businessId?._id){
    //     this.reportsService.setBusinessId(business?.businessId?._id)
    //     this.business = business.businessId;
    //     this.businessId = business.businessId?._id;
    //     this.loadProducts();
    //     this.fiscalDate = moment(`${this.business.accounting.date} ${this.business.accounting.month} 2022`).format('YYYY-MM-DD')
    //     this.FilterForm?.emitFormData();
    //     this.reportsService.getInvoiceCustomizationSettings().subscribe((resp) => {
    //       if (resp.success) {
    //         this.customizationSettings = resp.data;
    //       }
    //     });

    //   }
    // });

    this.loadNumberConfig();
  }

  loadProducts(): void {
    this.spinner.show();
    this.reportsService.getInventoryItems(this.businessId)
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(resp => {
      if(resp?.success){
        this.products = resp?.data?.filter(item => (item?.itemType === 'Product'))?.map(item => ({name:item?.name,_id:item?._id}));
      }
    })
  }

  loadCashiers() {
    this.reportsService.getAllCashierByBusiness(this.businessId).subscribe({
      next : (response)=>{
        this.cashiers = response.data.map((cashier:any)=>{
          {
            return {
              name : cashier?.firstName + ' ' + cashier?.lastName,
              email : cashier?.email
            }
          }
        })
        console.log(response)
      },
      error : (error)=>{
        console.log(error)
      }
    })
  }


  loadReport(data): void {
    if (this.businessId) {
      this.spinner.show();

      const fiscalDate = moment(`${this.business.accounting.date} ${this.business.accounting.month} ${moment(data.endDate).format('YYYY')}`).format('YYYY-MM-DD')
      data = {
        ...data,
        fiscalDate
      }
      this.methodToCall(data);
    }
  }

  loadNumberConfig(): void {
    this.numberService.number
    .pipe((takeUntil(this.unsubscribe)))
    .subscribe((number) => {
      this.number = number
    })
  }

  downloadPdf(): void {
    let projectDetails =this.projectId? this.projects.find(project =>  project._id == this.projectId):null;
    const projectName = projectDetails?projectDetails.projectName:null

    const data = {
      cashierEmail : this.config.cashierEmail,
      cashierName : this.config.cashierName,
      paymentTypeObj : this.config.paymentTypeObj,
      countTotalAmount : this.config.countTotalAmount,
      countTotalTax : this.config.countTotalTax,
      countSubTotal : this.config.countSubTotal,
      countDiscount : this.config.countDiscount,
      totalTax : this.config.totalTax,
      totalSales : this.config.totalSales,
      fieldNames:this.fieldNames !== {} ? this.languageService.translate(this.fieldNames) : null,
      tableData: this.config.tableData,
      tableKeys: this.config.tableKeys,
      tableHeadings: (this.reportType.templateName === 'normal' && this.config.tableHeadings )?  this.languageService.translateArray(this.config.tableHeadings) : this.config.tableHeadings,
      reportTitle: this.translateService.instant(this.reportType.title),
      direction: localStorage.getItem('NuMetric|lang') === 'en'? 'ltr' : 'rtl',
      type: this.reportType.templateName,
      startDate: this.config.startDate,
      endDate: this.config.endDate,
      lastDate: this.lastDate,
      userName: `${this.user.firstName} ${this.user.lastName}`,
      companyLogo: this.customizationSettings?.companyLogo,
      business: this.business,
      projectName:projectName,
      data: this.config.data ?? null
    };
    this.spinner.show();
    this.reportsService.downloadReport({config: data}).subscribe((resp) => {
      this.spinner.hide();
      const a = document.createElement('a');
      const blob = new Blob([resp], {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `Report.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
      this.toaster.success('PDF downloaded');
    }, error => {
      this.spinner.hide();
    });
  }

  getProfitLoss(datesData): void {
    this.spinner.show();
    this.projectId = datesData.projectId
    const body = {
      businessId: this.businessId,
      startDate: datesData.startDate,
      endDate: datesData.endDate,
      projectId: datesData.projectId
    };
    this.reportsService.getProfitLoss(body)
    .subscribe((resp) => {
      this.spinner.hide();
      const respData = resp.data;
      // const prevResult = resp?.previousResult ? resp?.previousResult : 0;
      console.log("profit and loss",respData)
      respData.income = respData.income.map((account) => {
        return {
          ...account,
          class: 'border-b',
          transactionsTotal: (account?.transactionsTotal[0]?.creditNoteAmount + account?.transactionsTotal[0]?.totalCredit) -
          (account?.transactionsTotal[0]?.debitNoteAmount + account?.transactionsTotal[0]?.totalDebit)
        };
      })
      .filter((account) => !isNaN(account.transactionsTotal));

      respData.expense = respData.expense.map((account) => {
        return {
          ...account,
          class: '',
          transactionsTotal: (account?.transactionsTotal[0]?.debitNoteAmount + account?.transactionsTotal[0]?.totalDebit) -
          (account?.transactionsTotal[0]?.creditNoteAmount + account?.transactionsTotal[0]?.totalCredit)
        };
      })
      .filter((account) => !isNaN(account.transactionsTotal));

      respData.income = this.createSection(respData.income, 'Income');
      let costOfGS = respData.expense.filter((account) => account.accountType === 'Cost of Goods Sold');
      costOfGS = this.createSection(costOfGS, 'Cost of Goods Sold');
      let operatingExpense = respData.expense.filter((account) => account.accountType !== 'Cost of Goods Sold');
      operatingExpense = this.createSection(operatingExpense, 'Expenses');
      let grossProfit = [
        {
          accountName: 'Gross Profit',
          class: 'bg-gray-200 font-bold',
          transactionsTotal: respData.income[respData.income.length - 2].transactionsTotal - costOfGS[costOfGS.length - 2].transactionsTotal
        },
        {
          accountName: '',
          class: '',
          transactionsTotal: null
        }
      ];
      let netProfit = [
        {
          accountName: 'Net Profit',
          class: 'bg-gray-200 font-bold',
          transactionsTotal: grossProfit[0].transactionsTotal -
          operatingExpense[operatingExpense.length - 2].transactionsTotal
          // + prevResult
        },
        {
          accountName: '',
          class: '',
          transactionsTotal: ''
        }
      ];
      // let equity = [
      //   {
      //     accountName: 'Equity',
      //     class: 'bg-gray-200 font-bold',
      //     transactionsTotal: ""
      //   },
      //   {
      //     accountName: 'Retained Earning:Profit/Loss',
      //     class: '',
      //     transactionsTotal:prevResult
      //   },
      //   {
      //     accountName: '',
      //     class: '',
      //     transactionsTotal:''
      //   }
      // ];
      const netProfitClass = netProfit[netProfit.length - 2].transactionsTotal < 0 ? 'text-red-500' : 'text-green-500';
      respData.income = respData.income.map((el) => ({ ...el, transactionsTotal: this.currencier(el.transactionsTotal)}));
      costOfGS = costOfGS.map((el) => ({ ...el, transactionsTotal: this.currencier(el.transactionsTotal)}));
      operatingExpense = operatingExpense.map((el) => ({ ...el, transactionsTotal: this.currencier(el.transactionsTotal)}));
      grossProfit = grossProfit.map((el) => ({ ...el, transactionsTotal: this.currencier(el.transactionsTotal)})) as any;
      netProfit = netProfit.map((el) => ({ ...el, transactionsTotal: this.currencier(el.transactionsTotal)}));
      // equity = equity.map((el) =>({ ...el, transactionsTotal: this.currencier(el.transactionsTotal)}))
      this.config = {
        tableHeadings: ['Account', `${moment(datesData.startDate).format('DD-MM-YYYY')} to ${moment(datesData.endDate).format('DD-MM-YYYY')}`],
        tableData: [
          ...respData.income,
          ...costOfGS,
          ...grossProfit,
          ...operatingExpense,
          // ...equity,
          ...netProfit,

        ],
        tableKeys: ['accountName', 'transactionsTotal'],
        templateName: 'normal',
        startDate: moment(datesData.startDate).format('DD-MM-YYYY'),
        endDate: moment(datesData.endDate).format('DD-MM-YYYY'),
      };
      this.stats = [
        {
          name: 'Income',
          value: respData.income[respData.income.length - 2].transactionsTotal,
          show: true,
          class: ''
        },
        {
          name: 'sign',
          value: '-',
          show: false,
          class: ''
        },
        {
          name: 'Cost of Goods Sold',
          value: costOfGS[costOfGS.length - 2].transactionsTotal,
          show: true
        },
        {
          name: 'sign',
          value: '-',
          show: false,
          class: ''
        },
        {
          name: 'Operating Expenses',
          value: operatingExpense[operatingExpense.length - 2].transactionsTotal,
          show: true
        },
        // {
        //   name: 'sign',
        //   value: '+',
        //   show: false,
        //   class: ''
        // },
        // {
        //   name: 'Equity',
        //   value:equity[1].transactionsTotal,
        //   show: true
        // },
        {
          name: 'sign',
          value: '=',
          show: false,
          class: ''
        },
        {
          name: 'final',
          value: netProfit[netProfit.length - 2].transactionsTotal,
          show: false,
          class: netProfitClass
        }
      ];
    }, error => {
      this.spinner.hide();
      console.log(error);
    });
  }

  createSection(data, title): any {
    const section = JSON.parse(JSON.stringify(data));
    section.push({
      accountName: `Total ${title}`,
      class: 'bg-gray-200 font-bold',
      transactionsTotal: section.reduce((prev, curr) => prev + curr?.transactionsTotal, 0)
    });
    section.push({
      accountName: '',
      class: '',
      transactionsTotal: ''
    });
    section.unshift({
      accountName: title,
      class: 'bg-gray-200 font-bold',
      transactionsTotal: ''
    });
    return section;
  }

  createSectionForBalanceSheet(data, title): any {
    const section = JSON.parse(JSON.stringify(data));
    const groupedByType = groupBy(section, 'accountType');
    let newSection = [];
    let total = 0;
    Object.keys(groupedByType).forEach((type) => {
      newSection = newSection.concat(...this.createSubSection(groupedByType[type], type));
      total += newSection[newSection.length - 1].transactionsTotal;
    });
    console.log(newSection);
    newSection.push({
      accountName: `Total ${title}`,
      class: 'bg-gray-200 font-bold',
      transactionsTotal: this.numberService.toFixed(total)
    });
    newSection.push({
      accountName: '',
      class: '',
      transactionsTotal: ''
    });
    newSection.unshift({
      accountName: title,
      class: 'bg-gray-200 font-bold',
      transactionsTotal: ''
    });
    newSection = newSection.map((el) => ({ ...el, transactionsTotal: this.currencier(el.transactionsTotal)}));
    return {newSection, total};
  }

  createSubSection(data, type): any {
    const newArray = [];
    newArray.push({
      accountName: type,
      class: 'bg-gray-100 font-bold',
      transactionsTotal: null
    });
    console.log(data);
    data.forEach((account) => {
      newArray.push({
        accountName: account.accountName,
        class: '',
        transactionsTotal: account.transactionsTotal
      });
    });
    newArray.push({
      accountName: `Total ${type}`,
      class: 'bg-gray-100 font-bold',
      transactionsTotal: data.reduce((prev, curr) => prev + curr.transactionsTotal, 0)
    });
    return newArray;
  }

  getBalanceSheet(datesData): void {
    this.spinner.show();
    const body = {
      businessId: this.businessId,
      startDate: datesData.startDate,
      endDate: datesData.endDate
    };
    this.reportsService.getBalanceSheet(body)
    .subscribe((resp) => {
      this.spinner.hide();
      const respData = resp.data;
      respData.Assets = respData.Assets.map((account) => {
        return {
          ...account,
          class: 'border-b',
          transactionsTotal: (account?.transactionsTotal[0]?.debitNoteAmount + account?.transactionsTotal[0]?.totalDebit) -
          (account?.transactionsTotal[0]?.creditNoteAmount + account?.transactionsTotal[0]?.totalCredit)
        };
      })
      .filter((account) => !isNaN(account.transactionsTotal) && account.transactionsTotal !== 0)
      .map((account) => ({...account, transactionsTotal: this.numberService.toFixed(account.transactionsTotal)}));

      const cashAndBank = respData.Assets.reduce((prev, curr) => {
        if (curr.accountType === 'Cash and Bank') {
          console.log('cash and bank', prev, curr);
          return prev + curr?.transactionsTotal;
        }
        else{
          return prev;
        }
      }, 0);

      respData.Liabilities = respData.Liabilities.map((account) => {
        return {
          ...account,
          class: 'border-b',
          transactionsTotal: (account?.transactionsTotal[0]?.creditNoteAmount + account?.transactionsTotal[0]?.totalCredit) -
          (account?.transactionsTotal[0]?.debitNoteAmount + account?.transactionsTotal[0]?.totalDebit)
        };
      })
      .filter((account) => !isNaN(account.transactionsTotal) && account.transactionsTotal !== 0);

      respData.Equity = respData.Equity.map((account) => {
        return {
          ...account,
          class: 'border-b',
          transactionsTotal: (account?.transactionsTotal[0]?.creditNoteAmount + account?.transactionsTotal[0]?.totalCredit) -
          (account?.transactionsTotal[0]?.debitNoteAmount + account?.transactionsTotal[0]?.totalDebit)
        };
      })
      .filter((account) => !isNaN(account.transactionsTotal) && account.transactionsTotal !== 0);

      respData.Equity.push({
        accountName: 'Profit',
        accountType: 'Retained Earnings',
        accountSection: 'Equity',
        class: '',
        transactionsTotal: respData.finalProfitLoss
      });

      const totalEquityAndLiabitity = this.createSectionForBalanceSheet(respData.Equity, 'Equity').total
        + this.createSectionForBalanceSheet(respData.Liabilities, 'Liabilities').total;
      respData.Assets = this.createSectionForBalanceSheet(respData.Assets, 'Assets').newSection;
      respData.Liabilities = this.createSectionForBalanceSheet(respData.Liabilities, 'Liabilities').newSection;
      respData.Equity = this.createSectionForBalanceSheet(respData.Equity, 'Equity').newSection;


      const finalArray = [
        {
          accountName: 'Liability and Equity',
          transactionsTotal: this.currencier(this.numberService.toFixed(totalEquityAndLiabitity)),
          class: 'bg-gray-200 font-bold'
        }
      ]
      this.config = {
        startDate: moment(datesData.startDate).format('DD-MM-YYYY'),
        endDate: moment(datesData.endDate).format('DD-MM-YYYY'),
        tableHeadings: ['Accounts', `As of ${moment(datesData.endDate).format('DD-MM-YYYY')}`],
        tableData: [
          ...respData.Assets,
          ...respData.Liabilities,
          ...respData.Equity,
          ...finalArray
        ],
        tableKeys: ['accountName', 'transactionsTotal'],
        templateName: 'normal'
      };

      const finalCalc = (respData.Assets[respData.Assets.length - 2].transactionsTotal) -
      respData.Liabilities[respData.Liabilities.length - 2].transactionsTotal;
    }, error => {
      this.spinner.hide();
      console.log(error);
    });
  }

  getIncomeByCustomer(datesData): void {
    this.spinner.show();
    const body = {
      businessId: this.businessId
    };

    this.reportsService.getIncomeByCustomer(body).subscribe((resp) => {
      this.spinner.hide();
      console.log(resp);
      const respData = resp.data;
      respData.income = respData.income.map((account) => {
        return {
          accountName: account.accountName,
          class: 'border-b',
          totalDebit: account.transactionsTotal.totalDebit + account.transactionsTotal.debitNoteAmount,
          totalCredit: account.transactionsTotal.totalCredit + account.transactionsTotal.creditNoteAmount,
        };
      });
      const totalIncome = {
        accountName: 'Total Income',
        class: 'bg-gray-200 font-bold',
        totalDebit: respData.income.reduce((prev, curr) => prev + curr.totalDebit, 0),
        totalCredit: respData.income.reduce((prev, curr) => prev + curr.totalCredit, 0)
      };
      respData.income.push(totalIncome);
      this.config = {
        startDate: moment(datesData.startDate).format('DD-MM-YYYY'),
        endDate: moment(datesData.endDate).format('DD-MM-YYYY'),
        tableHeadings: ['Customers', 'All Income', 'Paid Income'],
        tableKeys: ['accountName', 'totalDebit', 'totalCredit'],
        tableData: [
          ...respData.income
        ],
        templateName: 'normal'
      };
    }, error => {
      this.spinner.hide();
    });
  }

  getPurchasesByVendor(datesData): void {
    this.spinner.show();
    const body = {
      businessId: this.businessId
    };

    this.reportsService.getPurchasesByVendor(body).subscribe((resp) => {
      this.spinner.hide();
      console.log(resp);
      const respData = resp.data;
      respData.expense = respData.expense.map((account) => {
        return {
          accountName: account.accountName,
          class: 'border-b',
          totalDebit: account.transactionsTotal.totalDebit + account.transactionsTotal.debitNoteAmount,
          totalCredit: account.transactionsTotal.totalCredit + account.transactionsTotal.creditNoteAmount,
        };
      });
      const totalExpense = {
        accountName: 'Total Purchases',
        class: 'bg-gray-200 font-bold',
        totalDebit: respData.expense.reduce((prev, curr) => prev + curr.totalDebit, 0),
        totalCredit: respData.expense.reduce((prev, curr) => prev + curr.totalCredit, 0)
      };
      respData.expense.push(totalExpense);
      this.config = {
        startDate: moment(datesData.startDate).format('DD-MM-YYYY'),
        endDate: moment(datesData.endDate).format('DD-MM-YYYY'),
        tableHeadings: ['Vendors', 'All Purchases', 'Paid Purchases'],
        tableKeys: ['accountName', 'totalDebit', 'totalCredit'],
        tableData: [
          ...respData.expense
        ],
        templateName: 'normal'
      };
    }, error => {
      this.spinner.hide();
    });
  }

  getAgedRecieveables(datesData): void {
    this.spinner.show();
    const data = {
      businessId: this.businessId,
      endDate: datesData.endDate,
    };
    this.reportsService.getAgedRecieveables(data).subscribe((resp) => {
      console.log(resp);
      this.spinner.hide();
      const respData = resp.data.recievables[0];
      const totals = {
        '0-30 Days': null,
        '31-60 Days': null,
        '61-90 Days': null,
        '90-More Days': null,
        name: 'Total',
        total: null,
        class: 'bg-gray-200 font-bold'
      };
      Object.keys(respData).map((o) => totals[o] = respData[o].reduce((prev, curr) => prev + curr.totalAmount, 0));
      totals.total = totals['0-30 Days'] + totals['31-60 Days'] + totals['61-90 Days'] + totals['90-More Days'];
      totals.total = this.numberService.toFixed(totals.total);
      const myData2 = Object.keys(respData).map((o) => ({[o]: respData[o]}));
      const myData3 = [];
      myData2.forEach((data) =>
        data[Object.keys(data)[0]].map((el) =>
          myData3.push({ type: Object.keys(data)[0], totalAmount: el.totalAmount, name: el.customerName })));
      const myData4 = groupBy(myData3, 'name');
      console.log(myData4);
      let myData5 = Object.values(myData4).map((o: any) => ({...o}));
      myData5 = myData5.map((data) => {
        const obj = {
          name: data['0'].name,
          '0-30 Days': null,
          '31-60 Days': null,
          '61-90 Days': null,
          '90-More Days': null,
          class: 'border-b',
          total: 0
        };
        Object.values(data).map((el: any) => {
          if (el.type === '0-30 Days') {
            obj['0-30 Days'] = el.totalAmount;
          }
          if (el.type === '31-60 Days') {
            obj['31-60 Days'] = el.totalAmount;
          }
          if (el.type === '61-90 Days') {
            obj['61-90 Days'] = el.totalAmount;
          }
          if (el.type === '90-More Days') {
            obj['90-More Days'] = el.totalAmount;
          }
        });
        obj.total = obj['0-30 Days'] + obj['31-60 Days'] + obj['61-90 Days'] + obj['90-More Days'];
        return obj;
      });
      myData5.push(totals);
      myData5 = myData5.map((el) => ({
        ...el,
        '0-30 Days': this.currencier(el['0-30 Days']),
        '31-60 Days': this.currencier(el['31-60 Days']),
        '61-90 Days': this.currencier(el['61-90 Days']),
        '90-More Days': this.currencier(el['90-More Days']),
        total: this.currencier(el.total)
      }));
      this.config = {
        startDate: moment(datesData.startDate).format('DD-MM-YYYY'),
        endDate: moment(datesData.endDate).format('DD-MM-YYYY'),
        tableHeadings: ['Customer Name', '0-30 Days', '31-60 Days', '61-90 Days', '90-More Days', 'Total'],
        tableKeys: ['name', '0-30 Days', '31-60 Days', '61-90 Days', '90-More Days', 'total'],
        tableData: [
          ...myData5
        ],
        templateName: 'normal'
      };
    }, error => {
      this.spinner.hide();
    });
  }

  getAgedPayable(datesData): void {
    this.spinner.show();
    const data = {
      businessId: this.businessId,
      endDate: datesData.endDate
    };
    this.reportsService.getAgedPayable(data).subscribe((resp) => {
      console.log(resp);
      this.spinner.hide();
      const respData = resp.data.payables[0];
      const totals = {
        '0-30 Days': null,
        '31-60 Days': null,
        '61-90 Days': null,
        '90-More Days': null,
        name: 'Total',
        total: null,
        class: 'bg-gray-200 font-bold'
      };
      Object.keys(respData).map((o) => totals[o] = respData[o].reduce((prev, curr) => prev + curr.totalAmount, 0));
      totals.total = totals['0-30 Days'] + totals['31-60 Days'] + totals['61-90 Days'] + totals['90-More Days'];
      totals.total = this.numberService.toFixed(totals.total);
      const myData2 = Object.keys(respData).map((o) => ({[o]: respData[o]}));
      const myData3 = [];
      myData2.forEach((data) =>
        data[Object.keys(data)[0]].map((el) =>
          myData3.push({ type: Object.keys(data)[0], totalAmount: el.totalAmount, name: el.name })));
      const myData4 = groupBy(myData3, 'name');
      console.log(myData4);
      let myData5 = Object.values(myData4).map((o: any) => ({...o}));
      myData5 = myData5.map((data) => {
        const obj = {
          name: data['0'].name,
          '0-30 Days': null,
          '31-60 Days': null,
          '61-90 Days': null,
          '90-More Days': null,
          class: 'border-b',
          total: 0
        };
        Object.values(data).map((el: any) => {
          console.log('da', el);
          if (el.type === '0-30 Days') {
            obj['0-30 Days'] = el.totalAmount;
          }
          if (el.type === '31-60 Days') {
            obj['31-60 Days'] = el.totalAmount;
          }
          if (el.type === '61-90 Days') {
            obj['61-90 Days'] = el.totalAmount;
          }
          if (el.type === '90-More Days') {
            obj['90-More Days'] = el.totalAmount;
          }
        });
        obj.total = obj['0-30 Days'] + obj['31-60 Days'] + obj['61-90 Days'] + obj['90-More Days'];
        return obj;
      });
      myData5.push(totals);
      myData5 = myData5.map((el) => ({
        ...el,
        '0-30 Days': this.currencier(el['0-30 Days']),
        '31-60 Days': this.currencier(el['31-60 Days']),
        '61-90 Days': this.currencier(el['61-90 Days']),
        '90-More Days': this.currencier(el['90-More Days']),
        total: this.currencier(el.total)
      }));
      this.config = {
        startDate: moment(datesData.startDate).format('DD-MM-YYYY'),
        endDate: moment(datesData.endDate).format('DD-MM-YYYY'),
        tableHeadings: ['Vendor Name', '0-30 Days', '31-60 Days', '61-90 Days', '90-More Days', 'Total'],
        tableKeys: ['name', '0-30 Days', '31-60 Days', '61-90 Days', '90-More Days', 'total'],
        tableData: [
          ...myData5
        ],
        templateName: 'normal'
      };
    }, error => {
      this.spinner.hide();
    });
  }

  getAccountBalances(datesData): void {
    this.spinner.show();
    const data = {
      businessId: this.businessId,
      startDate: datesData.startDate,
      endDate: datesData.endDate,
      fiscalDate: datesData.fiscalDate
    };

    this.reportsService.getAccountBalances(data).subscribe((resp) => {
      this.spinner.hide();
      const respData = resp.data;
      const balances = [...respData.balances, ...respData.incomeExpense];
      const current = respData.current;
      const retained = {
        income: 0,
        expense: 0,
        finalCredit: 0,
        finalDebit: 0
      }
      console.log('current1234', balances, current, retained);
      
      // console.log(respData.retained.find(el => el._id === 'Income').data);

      retained.income = respData.retained.find(el => el._id === 'Income')?.data ?? 0;
      retained.expense = respData.retained.find(el => el._id === 'Expenses')?.data ?? 0;
      (-retained.income - retained.expense) > 0
      ? retained.finalCredit = -retained.income - retained.expense
      : retained.finalDebit = -retained.income - retained.expense;

      let result = current.map((el) => ({...el, ...balances.find(el2 => el._id === el2._id)}))
      .map((el) => {
        if (el.accountName === 'Retained Earnings: Profit/Loss' && (retained.finalDebit || retained.finalCredit)) {
          console.log(el);
          let newEl = {
            ...el
          }
          if (newEl.balanceTotal) {
            newEl.balanceTotal.balanceCredit += retained.finalCredit;
            newEl.balanceTotal.balanceDebit += retained.finalDebit;
          } else {
            newEl = {
              ...el,
              balanceTotal: {
                balanceCredit: retained.finalCredit,
                balanceDebit: retained.finalDebit,
                balance: 0
              }
            }
          }
          console.log('returningNewEl', newEl);
          
          return newEl;
        }
        return el;
      });
      console.log('check1234', result);
      
      result = result.filter((el) => el.transactionsTotal.length > 0 || el.balanceTotal)
        .map((el) => {
          return {
            accountName: el.accountName,
            accountSubName: el.accountName,
            accountType: el.accountType,
            accountSection: el.accountSection,
            balanceCredit: el.balanceTotal?.balanceCredit ? el.balanceTotal?.balanceCredit : 0,
            balanceDebit: el.balanceTotal?.balanceDebit ? el.balanceTotal?.balanceDebit : 0,
            balance: el.balanceTotal ? this.numberService.toFixed(el.balanceTotal.balance) : 0,
            credit: el.transactionsTotal[0] ? this.numberService.toFixed(el.transactionsTotal[0].totalCredit + el.transactionsTotal[0].creditNoteAmount) : 0,
            debit: el.transactionsTotal[0] ? this.numberService.toFixed(el.transactionsTotal[0].totalDebit + el.transactionsTotal[0].debitNoteAmount) : 0,
          };
        })
        .map(el => {
          let balanceCredit = 0;
          let balanceDebit = 0;
          if (el.balanceCredit > el.balanceDebit) {
            balanceCredit = el.balanceCredit - el.balanceDebit;
            balanceDebit = 0;
          } else {
            balanceDebit = el.balanceDebit - el.balanceCredit;
            balanceCredit = 0;
          }

          return {
            ...el,
            balanceCredit: balanceCredit,
            balanceDebit: balanceDebit
          }
        })
        .map(el => {
          let finalBalanceCredit = 0;
          let finalBalanceDebit = 0;
          const totalCredit = el.balanceCredit + el.credit;
          const totalDebit = el.balanceDebit + el.debit;
          if (totalCredit > totalDebit) {
            finalBalanceCredit = totalCredit - totalDebit;
            finalBalanceDebit = 0;
          } else {
            finalBalanceDebit = totalDebit - totalCredit;
            finalBalanceCredit = 0;
          }

          return {
            ...el,
            net: this.numberService.toFixed(el.debit - el.credit),
            finalBalanceCredit: this.numberService.toFixed(finalBalanceCredit),
            finalBalanceDebit: this.numberService.toFixed(finalBalanceDebit),
            finalBalance: this.numberService.toFixed((el.balance + el.debit) - el.credit),
            class: ''
          };
        });

      console.log("result1234", result);
      
      const grouped = groupBy(result, 'accountSection');
      console.log("result1234", grouped);
      
      let newArray = [];
      const finalTotal = {
        accountName: 'Total',
        class: 'bg-gray-200 font-bold',
        balanceCredit: 0,
        balanceDebit: 0,
        credit: 0,
        debit: 0,
        finalBalance: 0,
        finalBalanceCredit: 0,
        finalBalanceDebit: 0,
      };
      Object.keys(grouped).forEach((key) => {
        let section = JSON.parse(JSON.stringify(grouped[key]));
        const newSectionFinalBalanceCreditTotal = section.reduce((prev, curr) => this.numberService.toFixed(prev + curr?.finalBalanceCredit), 0);
        const newSectionFinalBalanceDebitTotal = section.reduce((prev, curr) => this.numberService.toFixed(prev + curr?.finalBalanceDebit), 0);

        const newSectionBalanceCreditTotal = section.reduce((prev, curr) => this.numberService.toFixed(prev + curr?.balanceCredit), 0);
        const newSectionBalanceDebitTotal = section.reduce((prev, curr) => this.numberService.toFixed(prev + curr?.balanceDebit), 0);

        // console.log("section1234", section);
        section = section.sort((a, b) => {
          return (a.accountType ?? '').localeCompare(b.accountType ?? '');
        });

        let groupedSection = groupBy(section, 'accountType');
        let singleArray = [];
        Object.keys(groupedSection).forEach((key) => {
          console.log("singleKey", key);
          
          let singleSection = JSON.parse(JSON.stringify(groupedSection[key]));
          // singleSection.map
          console.log('singleSection', singleSection);
          
          // singleSection.push({
          //   accountName: '',
          //   class: '',
          //   transactionsTotal: ''
          // });
          singleSection.forEach(item=>item.accountName = '');
          singleSection.unshift({
            accountName: key,
            class: '',
            transactionsTotal: '',
            credit: null,
            debit: null
          });
          singleArray.push(...singleSection)
        });
        console.log("singleSection", [...singleArray]);

        section = singleArray;
        
        section.push({
          accountName: `Total ${key}`,
          class: 'bg-gray-200 font-bold',
          credit: section.reduce((prev, curr) => this.numberService.toFixed(prev + curr?.credit), 0),
          debit: section.reduce((prev, curr) => this.numberService.toFixed(prev + curr?.debit), 0),
          balanceCredit: newSectionBalanceCreditTotal,
          balanceDebit: newSectionBalanceDebitTotal,
          finalBalanceCredit: newSectionFinalBalanceCreditTotal,
          finalBalanceDebit: newSectionFinalBalanceDebitTotal,
          finalBalance: section.reduce((prev, curr) => this.numberService.toFixed(prev + curr?.finalBalance), 0)
        });
        console.log("section12345", section.reduce((prev, curr) => this.numberService.toFixed(prev + curr?.credit), 0));
        finalTotal.credit += last(section).credit;
        finalTotal.debit += last(section).debit;
        finalTotal.finalBalance += last(section).finalBalance;
        finalTotal.balanceCredit += last(section).balanceCredit;
        finalTotal.balanceDebit += last(section).balanceDebit;
        finalTotal.finalBalanceCredit += last(section).finalBalanceCredit;
        finalTotal.finalBalanceDebit += last(section).finalBalanceDebit;
        section.push({
          accountName: '',
          class: '',
          transactionsTotal: ''
        });
        section.unshift({
          accountName: key,
          class: 'bg-gray-200 font-bold',
          transactionsTotal: ''
        });
        newArray.push(...section);
      });
      newArray.push(finalTotal);
      console.log('finalLast', newArray);
      
      newArray = newArray.map((el) =>{  

       return {
        ...el,
        balance: this.currencier(el.balance),
        debit: this.currencier(el.debit),
        credit: this.currencier(el.credit),
        net: this.currencier(el.net),
        finalBalance: this.currencier(el.finalBalance),
        balanceCredit: this.currencier(el.balanceCredit),
        balanceDebit: this.currencier(el.balanceDebit),
        finalBalanceCredit: this.currencier(el.finalBalanceCredit),
        finalBalanceDebit: this.currencier(el.finalBalanceDebit),
      }
    });
      newArray.unshift({
        balanceDebit: this.translateService.instant('Debit'),
        balanceCredit: this.translateService.instant('Credit'),
        debit: this.translateService.instant('Debit'),
        credit: this.translateService.instant('Credit'),
        finalBalanceDebit: this.translateService.instant('Debit'),
        finalBalanceCredit: this.translateService.instant('Credit'),
        class: 'bg-gray-100 font-bold'
      })
      console.log(newArray);
      // newArray = newArray.sort((a, b) => {
      //   return (a.accountType ?? '').localeCompare(b.accountType ?? '');
      //     // return a.accountType.localeCompare(b.accountType);
      // });
      // newArray[0] = this.languageService.translate(newArray[0]);
      this.config = {
        startDate: moment(datesData.startDate).format('DD-MM-YYYY'),
        endDate: moment(datesData.endDate).format('DD-MM-YYYY'),
        tableHeadings: ['Account', '', 'Balance', '', 'Transactions', '', 'Final Balance', ''],
        tableKeys: ['accountName', 'accountSubName', 'balanceDebit', 'balanceCredit', 'debit', 'credit', 'finalBalanceDebit', 'finalBalanceCredit'],
        tableData: [
          ...newArray
        ],
        templateName: 'normal'
      };
      console.log("trial-balances");
      
    }, (error) => {
      this.spinner.hide();
    });
  }

  getPartialAccountBalances(datesData): void {
    this.spinner.show();
    console.log("datesData", datesData);
    
    const data = {
      businessId: this.businessId,
      startDate: datesData.startDate,
      endDate: datesData.endDate,
      fiscalDate: datesData.fiscalDate,
    };
    // accountId: datesData.subAssetValue

    this.reportsService.getAccountBalances(data).subscribe((resp) => {
      this.spinner.hide();
      const respData = resp.data;
      const balances = [...respData.balances, ...respData.incomeExpense];
      const current = respData.current;
      const retained = {
        income: 0,
        expense: 0,
        finalCredit: 0,
        finalDebit: 0
      }
      console.log('current1234', balances, current, retained);
      
      // console.log(respData.retained.find(el => el._id === 'Income').data);

      retained.income = respData.retained.find(el => el._id === 'Income')?.data ?? 0;
      retained.expense = respData.retained.find(el => el._id === 'Expenses')?.data ?? 0;
      (-retained.income - retained.expense) > 0
      ? retained.finalCredit = -retained.income - retained.expense
      : retained.finalDebit = -retained.income - retained.expense;

      let result = current.map((el) => ({...el, ...balances.find(el2 => el._id === el2._id)}))
      .map((el) => {
        if (el.accountName === 'Retained Earnings: Profit/Loss' && (retained.finalDebit || retained.finalCredit)) {
          console.log(el);
          let newEl = {
            ...el
          }
          if (newEl.balanceTotal) {
            newEl.balanceTotal.balanceCredit += retained.finalCredit;
            newEl.balanceTotal.balanceDebit += retained.finalDebit;
          } else {
            newEl = {
              ...el,
              balanceTotal: {
                balanceCredit: retained.finalCredit,
                balanceDebit: retained.finalDebit,
                balance: 0
              }
            }
          }
          console.log('returningNewEl', newEl);
          
          return newEl;
        }
        return el;
      });
      console.log('check1234', result);
      if(datesData.assetValue && !datesData.subAssetValue) {
        console.log("1");
        
        result = result.filter((item)=>item.accountSection === datesData.assetValue);
      } else if (datesData.assetValue && datesData.subAssetValue) {
        console.log("2");
        // result = result.filter((item)=>item.accountSection === datesData.assetValue && item.accountName === datesData.subAssetValue);
        result = result.filter((item)=>item.accountSection === datesData.assetValue && item.accountType === datesData.subAssetValue);
      }
      
      result = result.filter((el) => el.transactionsTotal.length > 0 || el.balanceTotal)
        .map((el) => {
          return {
            accountName: el.accountName,
            accountSubName: el.accountName,
            accountType: el.accountType,
            accountSection: el.accountSection,
            balanceCredit: el.balanceTotal?.balanceCredit ? el.balanceTotal?.balanceCredit : 0,
            balanceDebit: el.balanceTotal?.balanceDebit ? el.balanceTotal?.balanceDebit : 0,
            balance: el.balanceTotal ? this.numberService.toFixed(el.balanceTotal.balance) : 0,
            credit: el.transactionsTotal[0] ? this.numberService.toFixed(el.transactionsTotal[0].totalCredit + el.transactionsTotal[0].creditNoteAmount) : 0,
            debit: el.transactionsTotal[0] ? this.numberService.toFixed(el.transactionsTotal[0].totalDebit + el.transactionsTotal[0].debitNoteAmount) : 0,
          };
        })
        .map(el => {
          let balanceCredit = 0;
          let balanceDebit = 0;
          if (el.balanceCredit > el.balanceDebit) {
            balanceCredit = el.balanceCredit - el.balanceDebit;
            balanceDebit = 0;
          } else {
            balanceDebit = el.balanceDebit - el.balanceCredit;
            balanceCredit = 0;
          }

          return {
            ...el,
            balanceCredit: balanceCredit,
            balanceDebit: balanceDebit
          }
        })
        .map(el => {
          let finalBalanceCredit = 0;
          let finalBalanceDebit = 0;
          const totalCredit = el.balanceCredit + el.credit;
          const totalDebit = el.balanceDebit + el.debit;
          if (totalCredit > totalDebit) {
            finalBalanceCredit = totalCredit - totalDebit;
            finalBalanceDebit = 0;
          } else {
            finalBalanceDebit = totalDebit - totalCredit;
            finalBalanceCredit = 0;
          }

          return {
            ...el,
            net: this.numberService.toFixed(el.debit - el.credit),
            finalBalanceCredit: this.numberService.toFixed(finalBalanceCredit),
            finalBalanceDebit: this.numberService.toFixed(finalBalanceDebit),
            finalBalance: this.numberService.toFixed((el.balance + el.debit) - el.credit),
            class: ''
          };
        });

      console.log("result1234", result);
      
      const grouped = groupBy(result, 'accountSection');
      
      let newArray = [];
      const finalTotal = {
        accountName: 'Total',
        class: 'bg-gray-200 font-bold',
        balanceCredit: 0,
        balanceDebit: 0,
        credit: 0,
        debit: 0,
        finalBalance: 0,
        finalBalanceCredit: 0,
        finalBalanceDebit: 0,
      };
      Object.keys(grouped).forEach((key) => {
        let section = JSON.parse(JSON.stringify(grouped[key]));
        const newSectionFinalBalanceCreditTotal = section.reduce((prev, curr) => this.numberService.toFixed(prev + curr?.finalBalanceCredit), 0);
        const newSectionFinalBalanceDebitTotal = section.reduce((prev, curr) => this.numberService.toFixed(prev + curr?.finalBalanceDebit), 0);

        const newSectionBalanceCreditTotal = section.reduce((prev, curr) => this.numberService.toFixed(prev + curr?.balanceCredit), 0);
        const newSectionBalanceDebitTotal = section.reduce((prev, curr) => this.numberService.toFixed(prev + curr?.balanceDebit), 0);

        // console.log("section1234", section);
        section = section.sort((a, b) => {
          return (a.accountType ?? '').localeCompare(b.accountType ?? '');
        });

        let groupedSection = groupBy(section, 'accountType');
        let singleArray = [];
        Object.keys(groupedSection).forEach((key) => {
          console.log("singleKey", key);
          
          let singleSection = JSON.parse(JSON.stringify(groupedSection[key]));
          // singleSection.map
          console.log('singleSection', singleSection);
          
          // singleSection.push({
          //   accountName: '',
          //   class: '',
          //   transactionsTotal: ''
          // });
          singleSection.forEach(item=>item.accountName = '');
          singleSection.unshift({
            accountName: key,
            class: '',
            transactionsTotal: '',
            credit: null,
            debit: null
          });
          singleArray.push(...singleSection)
        });
        console.log("singleSection", [...singleArray]);

        section = singleArray;
        
        section.push({
          accountName: `Total ${key}`,
          class: 'bg-gray-200 font-bold',
          credit: section.reduce((prev, curr) => this.numberService.toFixed(prev + curr?.credit), 0),
          debit: section.reduce((prev, curr) => this.numberService.toFixed(prev + curr?.debit), 0),
          balanceCredit: newSectionBalanceCreditTotal,
          balanceDebit: newSectionBalanceDebitTotal,
          finalBalanceCredit: newSectionFinalBalanceCreditTotal,
          finalBalanceDebit: newSectionFinalBalanceDebitTotal,
          finalBalance: section.reduce((prev, curr) => this.numberService.toFixed(prev + curr?.finalBalance), 0)
        });
        console.log("section12345", section.reduce((prev, curr) => this.numberService.toFixed(prev + curr?.credit), 0));
        finalTotal.credit += last(section).credit;
        finalTotal.debit += last(section).debit;
        finalTotal.finalBalance += last(section).finalBalance;
        finalTotal.balanceCredit += last(section).balanceCredit;
        finalTotal.balanceDebit += last(section).balanceDebit;
        finalTotal.finalBalanceCredit += last(section).finalBalanceCredit;
        finalTotal.finalBalanceDebit += last(section).finalBalanceDebit;
        section.push({
          accountName: '',
          class: '',
          transactionsTotal: ''
        });
        section.unshift({
          accountName: key,
          class: 'bg-gray-200 font-bold',
          transactionsTotal: ''
        });
        newArray.push(...section);
      });
      if((!datesData.assetValue && !datesData.subAssetValue)) {
        newArray.push(finalTotal);
      }
      console.log('finalLast', newArray);
      
      newArray = newArray.map((el) =>{  

       return {
        ...el,
        balance: this.currencier(el.balance),
        debit: this.currencier(el.debit),
        credit: this.currencier(el.credit),
        net: this.currencier(el.net),
        finalBalance: this.currencier(el.finalBalance),
        balanceCredit: this.currencier(el.balanceCredit),
        balanceDebit: this.currencier(el.balanceDebit),
        finalBalanceCredit: this.currencier(el.finalBalanceCredit),
        finalBalanceDebit: this.currencier(el.finalBalanceDebit),
      }
    });
      newArray.unshift({
        balanceDebit: this.translateService.instant('Debit'),
        balanceCredit: this.translateService.instant('Credit'),
        debit: this.translateService.instant('Debit'),
        credit: this.translateService.instant('Credit'),
        finalBalanceDebit: this.translateService.instant('Debit'),
        finalBalanceCredit: this.translateService.instant('Credit'),
        class: 'bg-gray-100 font-bold'
      });
      console.log("partial-trial-balances", newArray);
      // newArray = newArray.sort((a, b) => {
      //   return (a.accountType ?? '').localeCompare(b.accountType ?? '');
      //     // return a.accountType.localeCompare(b.accountType);
      // });
      // newArray[0] = this.languageService.translate(newArray[0]);
      this.config = {
        startDate: moment(datesData.startDate).format('DD-MM-YYYY'),
        endDate: moment(datesData.endDate).format('DD-MM-YYYY'),
        tableHeadings: ['Account', '', 'Balance', '', 'Transactions', '', 'Final Balance', ''],
        tableKeys: ['accountName', 'accountSubName', 'balanceDebit', 'balanceCredit', 'debit', 'credit', 'finalBalanceDebit', 'finalBalanceCredit'],
        tableData: [
          ...newArray
        ],
        templateName: 'normal'
      };
      
    }, (error) => {
      this.spinner.hide();
    });
  }


  getTrialBalance(datesData): void {
    this.spinner.show();
    const data = {
      businessId: this.businessId,
      // endDate: datesData.endDate,
      startDate: datesData.startDate,
      endDate: datesData.endDate,
    };
    this.reportsService.getTrialBalance(data).subscribe((resp) => {
      this.spinner.hide();
      const prevResult = resp?.previousResult ? resp?.previousResult : 0;
      const result = resp.data.filter((el) => el.transactionsTotal.length > 0)
        .map((el) => {
          let finalCredit = 0;
          let finalDebit = 0;
          if (el.transactionsTotal[0].credit > el.transactionsTotal[0].debit) {
            finalCredit = el.transactionsTotal[0].credit - el.transactionsTotal[0].debit;
            finalDebit = 0;
          } else {
            finalDebit = el.transactionsTotal[0].debit - el.transactionsTotal[0].credit;
            finalCredit = 0;
          }
          return {
            accountName: el.accountName,
            accountSection: el.accountSection,
            accountType: el.accountType,
            credit: this.numberService.toFixed(el.transactionsTotal[0].credit),
            debit: this.numberService.toFixed(el.transactionsTotal[0].debit),
            finalDebit: this.numberService.toFixed(finalDebit),
            finalCredit: this.numberService.toFixed(finalCredit),
            class: 'border-b'
          };
        });

      const grouped = groupBy(result, 'accountSection');
      let newArray = [];
      const finalTotal = {
        accountName: 'Total',
        class: 'bg-gray-200 font-bold',
        credit: 0,
        debit: 0,
        finalCredit: prevResult <= 0 ? prevResult: 0,
        finalDebit: prevResult >= 0 ? prevResult: 0,
      };
      Object.keys(grouped).forEach((key) => {
        const section = JSON.parse(JSON.stringify(grouped[key]));
        let totalFinalDebit = 0;
        let totalFinalCredit = 0;
        const finalDebitT = section.reduce((prev, curr) => this.numberService.toFixed(prev + curr?.finalDebit), 0);
        const finalCreditT = section.reduce((prev, curr) => this.numberService.toFixed(prev + curr?.finalCredit), 0);
        if (finalDebitT > finalCreditT) {
          totalFinalDebit = finalDebitT - finalCreditT;
          totalFinalCredit = 0;
        } else {
          totalFinalDebit = 0;
          totalFinalCredit = finalCreditT - finalDebitT;
        }
        section.push({
          accountName: `Total ${key}`,
          class: 'bg-gray-200 font-bold',
          credit: section.reduce((prev, curr) => this.numberService.toFixed(prev + curr?.credit), 0),
          debit: section.reduce((prev, curr) => this.numberService.toFixed(prev + curr?.debit), 0),
          finalDebit: finalDebitT,
          finalCredit: finalCreditT,
        });
        finalTotal.credit += last(section).credit;
        finalTotal.debit += last(section).debit;
        finalTotal.finalCredit += last(section).finalCredit;
        finalTotal.finalDebit += last(section).finalDebit;
        section.push({
          accountName: '',
          class: '',
          transactionsTotal: ''
        });
        section.unshift({
          accountName: key,
          class: 'bg-gray-200 font-bold',
          transactionsTotal: ''
        });

        newArray.push(...section);
      });

      newArray.push(
          {
            accountName: "Equity",
            class: 'bg-gray-200 font-bold',
            transactionsTotal: ''
          },
          {
            accountName: 'Retained Earnings: Profit/Loss',
            class: '',
            // credit: 10,
            // debit:12,
            finalCredit:prevResult <= 0 ? prevResult: 0,
            finalDebit:prevResult >= 0 ? prevResult: 0
          },
          {
            accountName: '',
            class: '',
            transactionsTotal: ''
          }

        )

      // if (finalTotal.credit > finalTotal.debit) {
      //   finalTotal.finalCredit = finalTotal.credit - finalTotal.debit;
      // } else {
      //   finalTotal.finalDebit = finalTotal.debit - finalTotal.credit;
      // }

      newArray.push(finalTotal);
      console.log("tril Balance", newArray)
      newArray = newArray.map((el) => ({
        ...el,
        debit: this.currencier(el.debit),
        credit: this.currencier(el.credit),
        finalCredit: this.currencier(el.finalCredit),
        finalDebit: this.currencier(el.finalDebit)
      }));
      // newArray.unshift({
      //   accountName: 'Account',
      //   debit: 'Debit',
      //   credit: 'Credit',
      //   finalDebit: 'Debit',
      //   finalCredit: 'Credit',
      //   class: 'bg-gray-100 font-bold'
      // })
      this.config = {
        startDate: moment(datesData.startDate).format('DD-MM-YYYY'),
        endDate: moment(datesData.endDate).format('DD-MM-YYYY'),
        tableHeadings: ['Account', 'Debit', 'Credit'],
        tableKeys: ['accountName', 'finalDebit', 'finalCredit'],
        tableData: [
          ...newArray
        ],
        templateName: 'normal'
      };
    }, error => {
      this.spinner.hide();
    });
  }

  getAccountTransactions(datesData): void {
    this.spinner.show();
    this.fieldNames = {
      startingBalance: 'Starting Balance',
      totalsAndEndingBalance: 'Totals and Ending Balance',
      date: 'Date',
      transactionId: 'Transaction ID',
      description: 'Description',
      debit: 'Debit',
      credit: 'Credit',
      balance: 'Balance'
    }
    const data = {
      businessId: this.businessId,
      startDate: moment(datesData.startDate).format('YYYY-MM-DD'),
      endDate: moment(datesData.endDate).format('YYYY-MM-DD'),
      customerAcId: datesData?.customerId?._id ?? null,
      accountId: datesData?.accountId?._id ?? null,
      fiscalDate: datesData.fiscalDate
    };

    this.reportsService.getAccountTransactions(data).subscribe((resp) => {
      this.spinner.hide();
      const respData = resp.data;
      const balances = [...respData.balances, ...respData.incomeExpense];
      const current = respData.current;
      const retained = {
        income: 0,
        expense: 0,
        finalCredit: 0,
        finalDebit: 0
      }
      // console.log(respData.retained.find(el => el._id === 'Income').data);

      retained.income = respData.retained.find(el => el._id === 'Income')?.data ?? 0;
      retained.expense = respData.retained.find(el => el._id === 'Expenses')?.data ?? 0;
      let netBalanceInMap = 0;
      let result = current.map((el) => ({...el, ...balances.find(el2 => el._id === el2._id)}))
      .map((el) => {

        if (el.accountName === 'Retained Earnings: Profit/Loss' && (retained.income || retained.expense)) {
          console.log('in retained', el);
          // console.log(el);
          let newEl = {
            ...el
          }
          retained.income = (-retained.income)
          let balance =  retained.income > retained.expense ? -(retained.income - retained.expense) : retained.income - retained.expense;
          if (newEl.balanceTotal) {
            newEl.balanceTotal.balanceCredit += retained.finalCredit;
            newEl.balanceTotal.balanceDebit += retained.finalDebit;
            newEl.balance = balance
          } else {
            newEl = {
              ...el,
              balanceTotal: {
                balanceCredit: retained.finalCredit,
                balanceDebit: retained.finalDebit,
                balance: balance
              }
            }
          }
          return newEl;
        }
        return el;
      });
      console.log(result, 'before filter');

      result = result
      .map((el) => {
        console.table(el);
        netBalanceInMap = el?.balanceTotal?.balance ?? 0;
        const entry =  {
          ...el,
          balance: el?.balanceTotal?.balance ?? 0,
          transactions: el.transactions.map((el2) => {
            if (el2?.type === 'journal' || el2?.type === 'journal voucher'){
              if (el2?.journal?.credit){
                netBalanceInMap -= this.numberService.toFixed(el2?.journal?.credit) ?? 0;
              }
              else if(el2?.journal?.debit) {
                netBalanceInMap += this.numberService.toFixed(el2?.journal?.debit) ?? 0;
              }
            }
            else{
              if (el2.debitNoteAmount !== 0){
                netBalanceInMap += this.numberService.toFixed(el2?.debitNoteAmount ) ?? 0;
              }
              else {
                netBalanceInMap -= this.numberService.toFixed(el2?.creditNoteAmount) ?? 0;
              }
            }
            return {
              ...el2,
              credit: (el2.type === 'journal' || el2?.type === 'journal voucher') ? (this.numberService.toFixed(el2.journal?.credit) ?? null) : this.numberService.toFixed(el2.creditNoteAmount),
              debit: (el2.type === 'journal' || el2?.type === 'journal voucher') ? (this.numberService.toFixed(el2.journal?.debit) ?? null) : this.numberService.toFixed(el2.debitNoteAmount),
              balance: this.numberService.toFixed(netBalanceInMap),
              class: 'border-b'
            };
          }),
        };
        console.log(entry);
        return entry;
      })
      .map((el) => {
        return {
          ...el,
          total: {
            debit: el.transactions.reduce((prev, curr) => this.numberService.toFixed(prev + curr.debit), 0),
            credit: el.transactions.reduce((prev, curr) => this.numberService.toFixed(prev + curr.credit), 0),
          }
        };
      })
      .map((el) => {
        return {
          ...el,
          balance: this.currencier(el?.balance),
          transactions: el.transactions.map((el2) => ({
            ...el2,
            balance: this.currencier(el2?.balance),
            credit: this.currencier(el2?.credit),
            debit: this.currencier(el2?.debit),
          })),
          total: {
            debit: this.currencier(el?.total?.debit),
            credit: this.currencier(el?.total?.credit),
            net: this.currencier((el?.total?.debit - el?.total?.credit) + el?.balance )
          }
        };
      });

      if (data.accountId) {
        result = result.filter((el) => el._id === data.accountId);
      }
      console.log(result);
      let finalArr = []
      const grouped = groupBy(result, 'accountSection')
      for( const key in grouped){
        finalArr.push({title: key, value: grouped[key]})
      }
      console.log(finalArr);

      this.config = {
        tableHeadings: ['Date', 'Transaction ID', 'Description', 'Debit', 'Credit', 'Balance'],
        tableData: finalArr,
        tableKeys: [],
        templateName: 'accountTransactions',
        startDate: moment(datesData.startDate).format('DD-MM-YYYY'),
        endDate: moment(datesData.endDate).format('DD-MM-YYYY'),
      };
    }, error => {
      this.spinner.hide();
    });
  }

  getSalesTax(datesData): void {
    this.spinner.show();
    this.fieldNames = {
      salesAndPurchases: 'Sales and Purchases',
      tax: 'Tax',
      salesSubjectToTax: 'Sales Subject to Tax',
      taxAmountOnSales: 'Tax Amount on Sales',
      purchaseSubjectToTax: 'Purchases Subject to Tax',
      taxAmountOnPurchases: 'Tax Amount on Purchases',
      newOwing: 'Net Owing',
      total: 'Total',
      balancesAndOwing: 'Balances and Owing',
      startingBalance: 'Starting Balance',
      netTaxOwing: 'Net Tax Owing',
      endingBalance: 'Ending Balance'
    }
    const data = {
      businessId: this.businessId,
      startDate: datesData.startDate,
      endDate: datesData.endDate
    };
    this.reportsService.getSalesTaxes(data).subscribe((resp) => {
      this.spinner.hide();
      console.log(resp);
      const respData = resp.data[0];
      let mapped = respData.invoiceAccounts.map(el => ({ ...el, ...respData.purchaseAccounts.find((el2 => el2._id === el._id)) }));
      mapped = mapped.map(el => {
        return {
          _id: el._id,
          accountName: el.accountName,
          salesSubjectToTax: this.numberService.toFixed(el.invoiceTaxTotals?.totalTaxableItemCost ?? 0) ?? 0,
          taxAmountOnSales: this.numberService.toFixed(el.invoiceTaxTotals?.totalTax ?? 0) ?? 0,
          purchaseSubjectToTax: this.numberService.toFixed(el.purchaseTaxTotals?.totalTaxableItemCost ?? 0) ?? 0,
          taxAmountOnPurchases: this.numberService.toFixed(el.purchaseTaxTotals?.totalTax ?? 0) ?? 0,
        };
      })
      .map((el) => {
        return {
          ...el,
          netOwing: this.numberService.toFixed(el.taxAmountOnPurchases - el.taxAmountOnSales) ?? 0
        };
      });
      const salesAndPurchase = {
        tableHeadings: ['Tax', 'Sales Subject to Tax', 'Tax Amount on Sales', 'Purchases Subject to Tax', 'Tax Amount on Purchases', 'Net Tax Owning'],
        tableData: [...mapped],
        totals: {
          sales: mapped.reduce((prev, curr) => prev + curr.taxAmountOnSales, 0),
          purchase: mapped.reduce((prev, curr) => prev + curr.taxAmountOnPurchases, 0),
          owing: mapped.reduce((prev, curr) => prev + curr.netOwing, 0)
        }
      };

      let mapped2 = respData.invoiceAccountsForBalance
      .map(el => ({ ...el, ...respData.purchaseAccountsForBalance.find((el2 => el2._id === el._id)) }));
      mapped2 = mapped2.map(el => {
        return {
          _id: el._id,
          accountName: el.accountName,
          taxAmountOnSales: this.numberService.toFixed(el.invoiceTaxTotals?.totalTax ?? 0) ?? 0,
          taxAmountOnPurchases: this.numberService.toFixed(el.purchaseTaxTotals?.totalTax ?? 0) ?? 0,
        };
      })
      .map((el) => {
        return {
          ...el,
          balance: this.numberService.toFixed(el.taxAmountOnPurchases - el.taxAmountOnSales) ?? 0,
          netOwing: this.numberService.toFixed(mapped.find(el2 => el2._id === el._id).netOwing ?? 0)
        };
      })
      .map(el => {
        return {
          ...el,
          endBalance: el.balance - el.netOwing
        };
      });

      const balances = {
        tableData: [...mapped2],
        totals: {
          balance: mapped2.reduce(((prev, curr) => prev + curr.balance), 0),
          netOwing: mapped2.reduce(((prev, curr) => prev + curr.netOwing), 0),
          endBalance: mapped2.reduce(((prev, curr) => prev + curr.endBalance), 0)
        }
      };

      salesAndPurchase.tableData = salesAndPurchase.tableData.map((el) => ({
        ...el,
        salesSubjectToTax: this.currencier(el.salesSubjectToTax ?? 0),
        taxAmountOnSales: this.currencier(el.taxAmountOnSales ?? 0),
        purchaseSubjectToTax: this.currencier(el.purchaseSubjectToTax ?? 0),
        taxAmountOnPurchases: this.currencier(el.taxAmountOnPurchases ?? 0),
        netOwing: this.currencier(el.netOwing ?? 0)
      }));
      salesAndPurchase.totals.owing = this.currencier(salesAndPurchase.totals.owing);
      salesAndPurchase.totals.purchase = this.currencier(salesAndPurchase.totals.purchase);
      salesAndPurchase.totals.sales = this.currencier(salesAndPurchase.totals.sales);
      balances.tableData = balances.tableData.map((el) => ({
        ...el,
        taxAmountOnSales: this.currencier(el.taxAmountOnSales ?? 0),
        taxAmountOnPurchases: this.currencier(el.taxAmountOnPurchases ?? 0),
        netOwing: this.currencier(el.netOwing ?? 0),
        balance: this.currencier(el.balance ?? 0),
        endBalance: this.currencier(el.endBalance ?? 0)
      }));
      balances.totals.balance = this.currencier(balances.totals.balance);
      balances.totals.netOwing = this.currencier(balances.totals.netOwing);
      balances.totals.endBalance = this.currencier(balances.totals.endBalance);


      this.config = {
        startDate: datesData.startDate,
        endDate: datesData.endDate,
        tableKeys: [],
        tableData: [{
          salesAndPurchase,
          balances
        }],
        tableHeadings: [],
        templateName: 'salesTaxes'
      };
    }, error => {
      this.spinner.hide();
    });
  }
  getSalesTransactionTax(datesData): void {
    this.spinner.show();
    const data = {
      businessId: this.businessId,
      accountId: datesData.selectedTax,
      startDate: datesData.startDate,
      endDate: datesData.endDate,
    };
    this.reportsService.getSalesTaxTransactionReport(data)
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(resp => {
      this.spinner.hide();
      if(resp?.success){
        let stockValue = 0;
        resp.data.sort((a, b) => {
          return new Date(a.transactionDate).getTime() - new Date(b.transactionDate).getTime();
        });

        const totals = {
          'transactionDate': null,
          'type': null,
          'transactionNumber': null,
          'amount': null,
          'taxAmount': null,
          'totalAmountWithTax': null,
          name: 'Total',
          total: null,
          class: 'bg-gray-200 font-bold'
        };
        
        Object.keys(totals).forEach(key => {
          console.log("checkingkeys", key);
          if(key === 'amount' || key === 'taxAmount' || key === 'totalAmountWithTax') {
            totals[key] = 0;
          } else if (key === 'transactionDate') {
            totals[key] = 'Total'
          }
        });

        resp?.data.forEach(product => {
          Object.keys(product).forEach(key => {
            if (typeof product[key] === 'number') {
              totals[key] += product[key];
            }
          });
        });

        totals.total = Object.values(totals).reduce((acc, curr) => acc + curr, 0);

        resp?.data.push(totals);

        const products = resp?.data.map(item => {
          return {
            ...item,
            transactionDate: item.transactionDate === 'Total' ? 'Total' : moment(item.transactionDate).format('DD-MM-YYYY'),
            amount: this.currencier(item.amount),
            taxAmount: this.currencier(item.taxAmount),
            totalAmountWithTax: this.currencier(item.totalAmountWithTax),
          }
        })

        this.config = {
          startDate: datesData.startDate,
          endDate: datesData.endDate,
          tableHeadings: [
            'Transaction Date',
            'Transaction Type',
            'Transaction Number',
            'Amount',
            'Tax Amount',
            'Total Amount with Tax',
          ],
          tableKeys:  [
            'transactionDate',
            'type',
            'transactionNumber',
            'amount',
            'taxAmount',
            'totalAmountWithTax',
          ],
          tableData: products,
          templateName: 'normal'
        }
        // this.stats = [
        //   {
        //     name: 'Total Stock Value',
        //     value: this.currencier(stockValue),
        //     show: true,
        //     class: ''
        //   }
        // ]
      }
    }, (err) => {
      this.spinner.hide()

    })
  }

  getAssets(datesData): void {
    console.log(datesData);
    this.spinner.show();

    this.fieldNames = {
      assetName: 'Asset Name',
      assetType: 'Asset Type',
      purchaseDate: 'Purchase Date',
      purchaseValue: 'Purchase Value',
      depreciation: 'Depreciation',
      depreciationValue: 'Depreciation Value',
      assetValue: 'Asset Value',
      currentDepreciationValue: 'Current Depreciation Value',
      total: 'Total'
    }
    const data = {
      businessId: this.businessId,
      startDate: datesData.startDate,
      endDate: datesData.endDate,
      assetAcId: datesData.assetAccountId
    };
    this.reportsService.getAssetsReport({assetAcId: data.assetAcId}).subscribe((resp) => {
      this.spinner.hide();
      let respData = resp.data;
      const getDepreciationValue = (assetData, endDate) => {
        console.log("..............",assetData,endDate)
        const el = JSON.parse(JSON.stringify(assetData));
        console.log(el.amount);
        const yearEndDate = moment(el.purchaseDate).endOf('year');
        const currentDate = moment(endDate);
        const yearDiff = Number(moment(endDate).format('YYYY')) - Number(moment(el.purchaseDate).format('YYYY'));
        console.log(yearDiff)
        let finalAmount = el.amount;
        if (yearDiff > 0) {
          const startOfCurrentYear = moment(endDate).startOf('year');
          const lastYearDays = currentDate.diff(startOfCurrentYear, 'days');
          const firstYearDays = yearEndDate.diff(moment(el.purchaseDate), 'days');
          finalAmount -= depre(finalAmount, el.depreciation, firstYearDays);
          if (yearDiff > 1) {
            let i = yearDiff - 1;
            while (i) {
              finalAmount -= depre(finalAmount, el.depreciation);
              i--;
            }
          }
          if (lastYearDays > 0) {
            finalAmount -= depre(finalAmount, el.depreciation, lastYearDays);
          }
        } else {
          const firstYearDays = moment(endDate).diff(moment(el.purchaseDate), 'days');
          if (firstYearDays >= 0) {
            finalAmount -= depre(finalAmount, el.depreciation, firstYearDays);
          }
        }
        console.log(finalAmount);
        return finalAmount;
      };

      const depre = (amount, depreciation, days = 365) => {
        return this.numberService.toFixed(amount * (depreciation / 100) * (days / 365));
      };
      if (data.assetAcId) {
        respData = respData.filter(el => el.assetAccount.accountId === data.assetAcId)
      }
      respData = respData.map(el => {
        if (el.calDepreciation === undefined) {
          el.calDepreciation = false;
        }
        return el;
      })
      console.log(respData);

      if (datesData.withDepreciation) {
        respData = respData.filter(el => el.calDepreciation === true);
        console.log(respData);
      } else {
        respData = respData.filter(el => el.calDepreciation === false || el.calDepreciation === undefined)
      }

      respData
       = respData.filter(el => moment(el.purchaseDate).isSameOrBefore(moment(data.endDate).add(1, 'day')))
      respData = respData.map(el => {
        return {
          ...el,
          depreciationValue: el.amount - getDepreciationValue(el, data.endDate),
          amountValue: getDepreciationValue(el, data.endDate),
          currentDepreciationValue: getDepreciationValue(el, data.startDate) - getDepreciationValue(el, data.endDate)
        };
      })

      const totalCurrentDepreciationValue = respData.reduce((prev, curr) => prev + curr.currentDepreciationValue, 0);
      const totalAssetValue = respData.reduce((prev, curr) => prev + curr.amountValue, 0);
      const totalDepreciationValue = respData.reduce((prev, curr) => prev + curr.depreciationValue, 0);

      respData = respData
      .map((el) => ({
        ...el,
        accountName: el.assetAccount.accountName,
        purchaseDate: moment(el.purchaseDate).format('ll'),
        depreciationValue: this.currencier(el.depreciationValue),
        amountValue: this.currencier(el.amountValue),
        amount: this.currencier(el.amount),
        currentDepreciationValue: this.currencier(el.currentDepreciationValue)
      }));


      this.config = {
        startDate: datesData.startDate,
        endDate: datesData.endDate,
        tableHeadings: [],
        tableKeys: [],
        templateName: 'assets',
        tableData: [...respData],
        data: {
          withDepreciation: datesData.withDepreciation,
          totalCurrentDepreciationValue: this.currencier(totalCurrentDepreciationValue),
          totalAssetValue: this.currencier(totalAssetValue),
          totalDepreciationValue: this.currencier(totalDepreciationValue)
        }

      };
      console.log(this.config);
    }, error => {
      this.spinner.hide();
    });
  }

  getCashFlow(datesData): void {
    this.spinner.show();
    this.fieldNames = {
      cashInflowAndOutflow: 'Cash inflow and outflow',
      operatingActivities: 'Operating Activites',
      sales: 'Sales',
      salesTotal: 'Sales Total',
      purchases: 'Purchases',
      purchasesTotal: 'Purchases Total',
      salesTaxes: 'Sales Taxes',
      salesTaxesTotal: 'Sales Taxes Total',
      others: 'Others',
      othersTotal: 'Others Total',
      netOperatingActivities: 'Net Operating Activities',
      investingActivities: 'Investing Activites',
      propertyPlantEquipments: 'Property, Plants, and Equipments',
      propertyTotal: 'Property Total',
      netInvestingActivities: 'Net Investing Activities',
      financialActivites: 'Financial Activites',
      loanAndLinesOfCredit: 'Loans and Lines of Credit',
      owners: 'Owners',
      netFinancialActivites: 'Net Financial Activities',
      overview: 'Overview',
      startingBalance: 'Starting Balance',
      totalStartingBalance: 'Total Starting Balance',
      grossInflow: 'Gross Inflow',
      grossOutflow: 'Gross Outflow',
      netChange: 'Net Change',
      endingBalance: 'Ending Balance',
      totalEndingBalance: 'Total Ending Balance',
    }
    const data = {
      businessId: this.businessId,
      startDate: moment(datesData.startDate).format('YYYY-MM-DD'),
      endDate: moment(datesData.endDate).format('YYYY-MM-DD')
    };

    this.reportsService.getAccountBalances(data).subscribe((resp) => {
      this.spinner.hide();
      const respData = resp.data;
      const balances = respData.balances;
      const current = respData.current;
      let inflow: (string | number) = 0;
      let outflow = 0;
      let result = current.map((el) => ({...el, ...balances.find(el2 => el._id === el2._id)}));
      const cashAccounts = [];
      result = result.filter((el) => el.transactionsTotal.length > 0 || el.balanceTotal)
        .map((el) => {
          return {
            accountName: el.accountName,
            accountSection: el.accountSection,
            accountType: el.accountType,
            balance: el.balanceTotal ? this.numberService.toFixed(el.balanceTotal.balance) : 0,
            credit: el.transactionsTotal[0] ? this.numberService.toFixed(el.transactionsTotal[0].totalCredit + el.transactionsTotal[0].creditNoteAmount) : 0,
            debit: el.transactionsTotal[0] ? this.numberService.toFixed(el.transactionsTotal[0].totalDebit + el.transactionsTotal[0].debitNoteAmount) : 0,
          };
        })
        .map(el => {
          return {
            ...el,
            net: this.numberService.toFixed(el.debit - el.credit),
            finalBalance: el.credit - (el.balance + el.debit),
          };
        })
        .map((el) => {
          if (el?.accountType !== 'Cash and Bank' && el?.accountType !== 'Money in Transit'){
            el?.finalBalance >= 0 ?
            inflow += el.finalBalance
            : outflow -= el.finalBalance;
          } else {
            cashAccounts.push({...el, finalBalance: -el.finalBalance});
          }
          return el;
        });

      const grouped = groupBy(result, 'accountType');
      const otherForOper = ['Accounts Receivables', 'Accounts Payables'];
      const purchaseTableData = ['Operating Expense', 'Cost of Goods Sold'];
      const categorizerForOthers = (included) => {
        const filteredKeys = Object.keys(grouped).filter(el => included.includes(el));
        return filteredKeys.map((el) => ({
          accountName: el,
          finalBalance: grouped[el].reduce((prev, curr) => prev + curr.finalBalance, 0)
        }));
      };
      const categorizer = (included) => {
        const filteredKeys = Object.keys(grouped).filter(el => included.includes(el));
        let arr = [];
        filteredKeys.map((el) => arr = arr.concat(grouped[el]));
        return arr;
      };
      const activities = {
        operating: {
          sales: {
            tableData: categorizer(['Income']),
            total: categorizer(['Income']).reduce((prev, curr) => prev + curr.finalBalance, 0)
          },
          purchase: {
            tableData: categorizer(purchaseTableData),
            total: categorizer(purchaseTableData).reduce((prev, curr) => prev + curr.finalBalance, 0)
          },
          salesTax: {
            tableData: categorizer(['Sales Taxes']),
            total: categorizer(['Sales Taxes']).reduce((prev, curr) => prev + curr.finalBalance, 0)
          },
          others: {
            tableData: categorizerForOthers(otherForOper),
            total: categorizerForOthers(otherForOper).reduce((prev, curr) => prev + curr.finalBalance, 0)
          },
          total: null,
        },
        investing: {
          property: {
            tableData: categorizer(['Property, Plant, Equipment']),
            total: categorizer(['Property, Plant, Equipment']).reduce((prev, curr) => prev + curr.finalBalance, 0)
          },
          others: {
            tableData: categorizerForOthers([]),
            total: categorizerForOthers([]).reduce((prev, curr) => prev + curr.finalBalance, 0)
          },
          total: null
        },
        financial: {
          loans: {
            tableData: categorizerForOthers(['Short Term Loans', 'Long Term Loans']),
            total: categorizerForOthers(['Short Term Loans', 'Long Term Loans']).reduce((prev, curr) => prev + curr.finalBalance, 0)
          },
          owners: {
            tableData: categorizerForOthers(['Business Owner Contribution']),
            total: categorizerForOthers(['Business Owner Contribution']).reduce((prev, curr) => prev + curr.finalBalance, 0)
          },
          others: {
            tableData: categorizerForOthers([]),
            total: categorizerForOthers([]).reduce((prev, curr) => prev + curr.finalBalance, 0)
          },
          total: null
        },
        balances: {
          inflow,
          outflow,
          startTotal: cashAccounts.reduce((prev, curr) => prev + curr.balance, 0),
          net: inflow - outflow,
          endTotal: cashAccounts.reduce((prev, curr) => prev + curr.finalBalance, 0),
          tableData: cashAccounts
        }
      };

      activities.operating.total = activities.operating.sales.total +
                                   activities.operating.purchase.total +
                                   activities.operating.salesTax.total +
                                   activities.operating.others.total;

      activities.investing.total = activities.investing.property.total + activities.investing.others.total;
      activities.financial.total = activities.financial.owners.total +
                                   activities.financial.others.total +
                                   activities.financial.loans.total;

      activities.operating.sales.tableData = activities.operating.sales.tableData
      .map((el) => ({ ...el, finalBalance: this.currencier(el.finalBalance)}));
      activities.operating.sales.total = this.currencier(activities.operating.sales.total);
      activities.operating.purchase.tableData = activities.operating.purchase.tableData
      .map((el) => ({ ...el, finalBalance: this.currencier(el.finalBalance)}));
      activities.operating.purchase.total = this.currencier(activities.operating.purchase.total);
      activities.operating.salesTax.tableData = activities.operating.salesTax.tableData
      .map((el) => ({ ...el, finalBalance: this.currencier(el.finalBalance)}));
      activities.operating.salesTax.total = this.currencier(activities.operating.salesTax.total);
      activities.operating.others.tableData = activities.operating.others.tableData
      .map((el) => ({ ...el, finalBalance: this.currencier(el.finalBalance)}));
      activities.operating.others.total = this.currencier(activities.operating.others.total);
      activities.operating.total = this.currencier(activities.operating.total);


      activities.investing.property.tableData = activities.investing.property.tableData
      .map((el) => ({ ...el, finalBalance: this.currencier(el.finalBalance)}));
      activities.investing.others.tableData = activities.investing.others.tableData
      .map((el) => ({ ...el, finalBalance: this.currencier(el.finalBalance)}));
      activities.investing.property.total = this.currencier(activities.investing.property.total);
      activities.investing.others.total = this.currencier(activities.investing.others.total);
      activities.investing.total = this.currencier(activities.investing.total);

      activities.financial.loans.tableData = activities.financial.loans.tableData
      .map((el) => ({ ...el, finalBalance: this.currencier(el.finalBalance)}));
      activities.financial.owners.tableData = activities.financial.owners.tableData
      .map((el) => ({ ...el, finalBalance: this.currencier(el.finalBalance)}));
      activities.financial.others.tableData = activities.financial.others.tableData
      .map((el) => ({ ...el, finalBalance: this.currencier(el.finalBalance)}));
      activities.financial.loans.total = this.currencier(activities.financial.loans.total);
      activities.financial.owners.total = this.currencier(activities.financial.owners.total);
      activities.financial.others.total = this.currencier(activities.financial.others.total);
      activities.financial.total = this.currencier(activities.financial.total);


      activities.balances.tableData = activities.balances.tableData
      .map((el) => ({ ...el, finalBalance: this.currencier(el.finalBalance), balance: this.currencier(el.balance)}));
      activities.balances.endTotal = this.currencier(activities.balances.endTotal);
      activities.balances.inflow = this.currencier(activities.balances.inflow) as any;
      activities.balances.outflow = this.currencier(activities.balances.outflow) as any;
      activities.balances.net = this.currencier(activities.balances.net) as any;
      activities.balances.startTotal = this.currencier(activities.balances.startTotal);

      this.stats = [
        {
          name: 'Gross Inflow',
          value: activities.balances.inflow,
          show: true,
          class: ''
        },
        {
          name: 'sign',
          value: '-',
          show: false,
          class: ''
        },
        {
          name: 'Gross Outflow',
          value: activities.balances.outflow,
          show: true
        },
        {
          name: 'sign',
          value: '=',
          show: false,
          class: ''
        },
        {
          name: 'final',
          value: activities.balances.net,
          show: false,
          class: ''
        }
      ];

      this.config = {
        startDate: datesData.startDate,
        endDate: datesData.endDate,
        tableKeys: [],
        tableHeadings: [],
        templateName: 'cash',
        tableData: [{activities}]
      };
      console.log(activities, grouped);
      }, (error) => {
      this.spinner.hide();
    });
  }

  getStockSummary(): void {
    this.spinner.show();
    this.reportsService.getInventoryItems(this.businessId)
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(resp => {
      this.spinner.hide();
      if(resp?.success){
        let stockValue = 0;
        const products = resp?.data.map(item => {
          stockValue += (item?.price * item?.stockDetails?.openingStock);
          return {
            ...item,
            price: this.currencier(item.price),
            sellPrice: this.currencier(item.sellPrice),
            quantity: (item?.stockDetails?.openingStock)?.toFixed(2),
            averagePrice: this.currencier(item.averagePrice) ?? '-',
            stockValue: this.currencier((item.averagePrice || item?.price) * item?.stockDetails?.openingStock)
          }
        })

        this.config = {
          startDate: '',
          endDate: '',
          tableHeadings: [
            'Name',
            'SKU',
            'Purchase Price',
            'Selling Price',
            'Weighted Average Price',
            'Quantity',
            'Stock Value',
          ],
          tableKeys:  [
            'name',
            'sku',
            'price',
            'sellPrice',
            'averagePrice',
            'quantity',
            'stockValue'
          ],
          tableData: products,
          templateName: 'normal'
        }
        this.stats = [
          {
            name: 'Total Stock Value',
            value: this.currencier(stockValue),
            show: true,
            class: ''
          }
        ]
      }
    }, (err) => {
      this.spinner.hide()

    })
  }

  getLowStockSummary(): void {
    this.spinner.show();
    this.reportsService.getInventoryItems(this.businessId)
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(resp => {
      this.spinner.hide();
      if(resp?.success){
        let stockValue = 0;
        const products = resp?.data.filter(item => item?.stockDetails?.openingStock < item?.stockDetails?.lowStock).map(item => {
          stockValue += (item?.price * item?.stockDetails?.openingStock);
          return {...item, quantity: (item?.stockDetails?.openingStock).toFixed(2), stockValue: (item?.price * item?.stockDetails?.openingStock)}
        })

        this.config = {
          startDate: '',
          endDate: '',
          tableHeadings: [
            'Name',
            'SKU',
            'Purchase Price',
            'Selling Price',
            'Quantity',
            'Stock Value',
          ],
          tableKeys:  [
            'name',
            'sku',
            'price',
            'sellPrice',
            'quantity',
            'stockValue'
          ],
          tableData: products,
          templateName: 'normal'
        }
        this.stats = [
          {
            name: 'Total Low Stock Value',
            value: stockValue,
            show: true,
            class: ''
          }
        ]
      }
    },(err) => {
      this.spinner.hide()
    })
  }

  getItemDetails(datesData): void {
    let items = [];
    this.spinner.show();
    if(datesData?.itemId) {
      this.reportsService.getItemSummary(datesData?.itemId, datesData)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(resp => {
        if(resp?.success){
          this.spinner.hide()
          console.log(resp?.data,'item summary');
          items = resp?.data?.map(item => ({
            ...item,
            name: item?.itemId?.name,
            sku: item?.itemId?.sku,
            date: item?.date?.split('T')[0],
            averagePrice: this.currencier(item?.averagePrice),
            totalPrice: this.currencier(item?.price * item?.quantity)
          }));
          this.config = {
            startDate: datesData?.fromDate,
            endDate: datesData?.endDate,
            tableHeadings: [
              'Date',
              'Name',
              'SKU',
              'Transaction Type',
              'Transaction ID',
              'Quantity',
              'Total Price',
              'Closing Stock',
              'Weighted Average Price'
            ],
            tableKeys:  [
              'date',
              'name',
              'sku',
              'transactionType',
              'referenceNumber',
              'quantity',
              'totalPrice',
              'closingStock',
              'averagePrice'
            ],
            tableData: items,
            templateName: 'normal'
          }
      }
      else {
        this.spinner.hide();
        items = [];
        this.config = {
          startDate: datesData?.startDate,
          endDate: datesData?.endDate,
          tableHeadings: [
            'Name',
            'Transaction Type',
            'Quantity',
            'Date',
            'Closing Stock',
          ],
          tableKeys:  [
            'name',
            'transactionType',
            'quantity',
            'date',
            'closingStock',
          ],
          tableData: items,
          templateName: 'normal'
        }
      }
      }, (err) => {
        this.spinner.hide();
        this.toaster.error(err?.message)
      })
    }
    else {
      this.spinner.hide()
      items = [];
      this.config = {
        startDate: datesData?.fromDate,
        endDate: datesData?.endDate,
        tableHeadings: [
          'Name',
          'Transaction Type',
          'Quantity',
          'Date',
          'Closing Stock',
        ],
        tableKeys:  [
          'name',
          'transactionType',
          'quantity',
          'date',
          'closingStock',
        ],
        tableData: items,
        templateName: 'normal'
      }
    }
  }

  getCashierAttendance(datesData): void {
    console.log('datesData',datesData)
      const payload = {
          businessId : this.businessId,
          startDate: datesData.startDate,
          endDate: datesData.endDate,
          cashierEmail : datesData.cashierEmail?.email
      }
      this.spinner.show();
      this.activities = []
    this.reportsService.getCashierAttendanceReport(this.businessId,payload).subscribe({
      next : (response)=>{
        this.spinner.hide()
        this.cashierActivity = response.data
        this.cashierActivity.sort((a: any, b: any) => new Date(a?.date).getTime() - new Date(b?.date).getTime());
        const activityDates = this.cashierActivity.map((activity: any) => {
          return {
            ...activity,
            newDate: new Date(activity?.date).toDateString()
          }
        });

        const firstActivityDate = new Date(this.cashierActivity[0]?.date);
        const lastActivityDate = new Date(this.cashierActivity[this.cashierActivity.length - 1]?.date);
        const currentDate = new Date(firstActivityDate);

        while (currentDate <= lastActivityDate) {
          const dateString = currentDate.toDateString();
          const matchedActivity = activityDates.find((activity: any) => new Date(activity?.date).toDateString() === dateString);

          let loginTime = '-';
          let logoutTime = '-';
          let totalTimeSpent = '-';

          if (matchedActivity) {
            loginTime = moment(matchedActivity.loginTime).format('hh:mm a');
            logoutTime = matchedActivity.logoutTime ? moment(matchedActivity.logoutTime).format('hh:mm a') : '-';
            totalTimeSpent = matchedActivity.totalTimeSpent ? ((matchedActivity.totalTimeSpent)/(1000*60*60)).toFixed(2) +' '+'Hours' : '-'
          }

          const color = matchedActivity ? '#008000' : '#FF0000';
          const status = matchedActivity ? 'Present' : 'Absent';

          this.activities.push({
            date: moment(currentDate).format('DD-MM-YYYY'),
            status: status,
            color: color,
            loginTime: loginTime,
            logoutTime: logoutTime,
            totalTimeSpent: totalTimeSpent
          });

          currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
        }
        this.config = {
          cashierEmail : datesData.cashierEmail?.email,
          cashierName : datesData.cashierEmail?.name,
          startDate: moment(datesData.startDate).format('DD-MM-YYYY'),
          endDate: moment(datesData.endDate).format('DD-MM-YYYY'),
          tableHeadings: ['Date', 'Status','Check In','Check Out', 'Hour Consume'],
          tableKeys: ['date', 'status' ,'loginTime','logoutTime','totalTimeSpent'],
          tableData: [
            ...this.activities
          ],
          templateName: 'normal'
        };
      },
      error : (error)=>{
        this.spinner.hide()
        console.log(error)
      }
    })
  }
  getCashierSalesItems(datesData): void{
    const payload = {
      businessId : this.businessId,
      startDate: datesData.startDate,
      endDate: datesData.endDate,
      cashierEmail : datesData.cashierEmail?.email
  }
  this.spinner.show();
  this.reportsService.getCashierSalesReport(this.businessId,payload).subscribe({
    next : (response)=>{
      let items = []
      let totalSales = 0;
      let totalTax = 0;
      this.spinner.hide();
      response.data?.forEach((item:any)=>{
        console.log(item)
        items = [...items, ...item?.items]
        totalSales += item.totalAmount
        totalTax += item.tax
      })
      console.log(totalTax)
      items = items.map((item)=>{
        return {
          item:item.item,
          price:item.price.toFixed(this.business.decimalSize),
          quantity : item.unit,
          total : item.price * item.unit,
          tax : item.tax.length ? item.tax[0].taxName+'('+item.tax[0].tax+'%)' : 'No Tax'
        }
      })
      let newItems = []
      let itemExist;
      for(let i=0; i<items.length; i++) {
         itemExist = newItems.find((item)=> {
          if((item?.item == items[i]?.item) && (item.tax == items[i].tax)) {
            return {
              ...item,
              quantity : item.quantity+= items[i]?.quantity,
              total : item.total+=items[i]?.total
            }
          }
        })
        if(!itemExist) {
          newItems.push(items[i])
        }
      }
      console.log(newItems)
      this.config = {
        cashierEmail : datesData.cashierEmail?.email,
        cashierName : datesData.cashierEmail?.name,
        totalSales : totalSales.toFixed(this.business.decimalSize),
        totalTax : totalTax.toFixed(this.business.decimalSize),
        currency : response.data[0]?.currencyDetails.currencySymbol ?? '',
        startDate: moment(datesData.startDate).format('DD-MM-YYYY'),
        endDate: moment(datesData.endDate).format('DD-MM-YYYY'),
        // invoiceData : response.data,
        tableHeadings: ['Item', 'Quantity','Unit Price ','Tax','Total'],
        tableKeys: ['item', 'quantity' ,'price','tax','total'],
        tableData: [
          ...newItems
        ],
        templateName: 'normal'
      };
      console.log(this.config)
    },
    error : (error)=> {
      this.spinner.hide();
      console.log(error)
    }
  })
  }
  getCashierSalesInvoices(datesData): void{
    const payload = {
      businessId : this.businessId,
      startDate: datesData.startDate,
      endDate: datesData.endDate,
      cashierEmail : datesData.cashierEmail?.email
  }
  this.spinner.show();
  this.reportsService.getCashierSalesReport(this.businessId,payload).subscribe({
    next : (response)=>{
      this.spinner.hide();
      let countTotalAmount = 0
      let countTotalTax = 0;
      let countSubTotal = 0;
      let countDiscount = 0;
      response.data?.forEach((invoice)=>{
        console.log('invoice',invoice)
         countTotalAmount += invoice.totalAmount
         countTotalTax += invoice.tax
         countSubTotal += invoice.subtotal
         countDiscount += Math.abs(invoice.averageTotalAmount ?? 0)
      })
      const currency = response.data[0]?.currencyDetails.currencySymbol ?? ''
      let paymentTypeObj = {
        'On Delivery' : 1,
        'Wallet' : 1,
        'Card' : 1,
        'Cash' : 1,
        'Credit Balance' : 1,
      }
      const data = response.data.map((invoice)=>{
        console.log(invoice)
        paymentTypeObj[invoice.posPaymentType] ? paymentTypeObj[invoice.posPaymentType]+=invoice.totalAmount : ''
        return {
          invoiceNumber : invoice.invoiceNumber,
          subtotal : invoice.subtotal.toFixed(this.business.decimalSize),
          tax : invoice.tax.toFixed(this.business.decimalSize),
          totalAmount : invoice.totalAmount.toFixed(this.business.decimalSize),
          posPaymentType : invoice.posPaymentType,
          gotDiscount : Math.abs(invoice.averageTotalAmount ?? 0).toFixed(this.business.decimalSize)
        }
      })
      console.log(paymentTypeObj)
      this.config = {
        cashierEmail : datesData.cashierEmail?.email,
        cashierName : datesData.cashierEmail?.name,
        countTotalAmount : countTotalAmount.toFixed(this.business.decimalSize),
        countSubTotal : countSubTotal.toFixed(this.business.decimalSize),
        countDiscount : countDiscount.toFixed(this.business.decimalSize),
        paymentTypeObj,
        currency : currency,
        startDate: moment(datesData.startDate).format('DD-MM-YYYY'),
        endDate: moment(datesData.endDate).format('DD-MM-YYYY'),
        tableHeadings: ['Sales Invoice Number', 'Sub Total','Tax ','Discount','Total','Payment Type','Payment Value'],
        tableKeys: ['invoiceNumber', 'subtotal' ,'tax','gotDiscount','totalAmount','posPaymentType','totalAmount'],
        tableData: [
          ...data
        ],
        templateName: 'normal'
      };
      // this.config = {
      //   countTotalAmount,
      //   countTotalTax,
      //   countSubTotal,
      //   cashierEmail : datesData.cashierEmail?.email,
      //   cashierName : datesData.cashierEmail?.name,
      //   currency : response.data[0]?.currencyDetails.currencySymbol ?? '',
      //   startDate: moment(datesData.startDate).format('DD-MM-YYYY'),
      //   endDate: moment(datesData.endDate).format('DD-MM-YYYY'),
      //   tableData: [
      //     ...response.data
      //   ],
      //   templateName: 'cashierSales'
      // };
      console.log(this.config)
    },
    error : (error)=> {
      this.spinner.hide();
      console.log(error)
    }
  })
  }
  currencier(amount): string {
    return this.currenyPipe.transform(amount, this.currencyDetails?.currency, 'symbol-narrow', this.number);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
