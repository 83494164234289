export const AccountType = [
    {
        accountType: [
            'Cash and Bank',
            'Money in Transit',
            'Accounts Receivables',
            'Sales Taxes',
            'Inventory',
            'Property, Plant, Equipment',
            'Depreciation and Amortization',
            'Other Short-Term Asset',
            'Other Long-Term Asset',
        ],
        section: 'Assets',
    },
    {
        accountType: [
            'Short Term Loans',
            'Long Term Loans',
            'Accounts Payables',
            'Sales Taxes',
            'Other Short-Term Liability',
            'Other Long-Term Liability',
            'Due For Payroll',
            'Due to Business Owners',
        ],
        section: 'Liabilities',
    },
    {
        accountType: [
            'Operating Expense',
            'Cost of Goods Sold',
            'Payroll Expense',
            'Uncategorized Expense',
            'Loss On Foreign Exchange'
        ],
        section: 'Expenses',
    },
    {
        accountType: [
            'Income',
            'Discount',
            'Other Income',
            'Gain on Foreign Exchange',
            'Uncategorized Income'
        ],
        section: 'Income',
    },
    {
        accountType: [
            'Business Owner Contribution',
            "Owner's Equity",
        ],
        section: 'Equity',
    }


];

export const getAllAccountType = (): any => {
    const newData = [];
    AccountType.map(section => {
        section.accountType.forEach((type) => {
            const newObject = {
                accountType: type,
                section: section.section,
                accounts: []
            };
            newData.push(newObject);
        });
    });
    return newData;
};

export const loadAccountsOf = (type): any => {
    return getAllAccountType().filter(account => account.section.toLowerCase() === type);
};
